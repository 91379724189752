import { VBtn } from 'vuetify/lib/components/VBtn';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"unit-name"},[_vm._v(_vm._s(_vm.selectedUnit && _vm.selectedUnit.name))]),_c('ul',{staticClass:"scenes"},_vm._l((_vm.pscbScenes),function(ref){
var id = ref.id;
var name = ref.name;
var channel = ref.channel;
var disabled = ref.disabled;
return _c('li',{key:id},[_c('div',{staticClass:"scene"},[_c('div',{staticClass:"scene__name"},[_vm._v(_vm._s(name))]),_c('div',{staticClass:"scene__controls"},[_c(VBtn,{staticClass:"scene__display-button",attrs:{"color":"warning","disabled":disabled || _vm.disabledScenes[id],"outlined":channel !== 'Preview'},on:{"click":function($event){return _vm.toggleScene({
              id: id,
              channel:'Preview',
              tournamentNodeId: _vm.selectedUnit ? _vm.selectedUnit.id : null,
              additionalData: _vm.additionalData[id]
            })}}},[_vm._v(" Preview ")]),_c(VBtn,{staticClass:"scene__display-button",attrs:{"color":"error","disabled":disabled || _vm.disabledScenes[id],"outlined":channel !== 'Live'},on:{"click":function($event){return _vm.toggleScene({
              id: id,
              channel: 'Live',
              tournamentNodeId: _vm.selectedUnit ? _vm.selectedUnit.id : null,
              additionalData: _vm.additionalData[id]
            })}}},[_vm._v(" Live ")])],1)]),(id === _vm.SceneId.Freetext)?_c('div',{staticClass:"scene__additional-data"},[_c(VTextarea,{attrs:{"clearable":"","full-width":"","rows":"3"},model:{value:(_vm.additionalData[id].text),callback:function ($$v) {_vm.$set(_vm.additionalData[id], "text", $$v)},expression:"additionalData[id].text"}})],1):_vm._e(),(id === _vm.SceneId.TechnicalReview)?_c('div',{staticClass:"scene__additional-data"},[_c(VTextarea,{attrs:{"clearable":"","full-width":"","rows":"3"},model:{value:(_vm.additionalData[id].technicalReviewText),callback:function ($$v) {_vm.$set(_vm.additionalData[id], "technicalReviewText", $$v)},expression:"additionalData[id].technicalReviewText"}})],1):_vm._e(),(id === _vm.SceneId.Podium)?_c('div',{staticClass:"scene__podium-data"},[_c(VSelect,{attrs:{"label":"Place","items":['1st', '2nd', '3rd']},model:{value:(_vm.additionalData[id].place),callback:function ($$v) {_vm.$set(_vm.additionalData[id], "place", $$v)},expression:"additionalData[id].place"}}),_c(VRadioGroup,{model:{value:(_vm.additionalData[id].ceremony),callback:function ($$v) {_vm.$set(_vm.additionalData[id], "ceremony", $$v)},expression:"additionalData[id].ceremony"}},[_c(VRadio,{attrs:{"label":"Venue Ceremony","value":"1"}}),_c(VRadio,{attrs:{"label":"Medal Ceremony","value":"2"}})],1)],1):_vm._e(),(id === _vm.SceneId.Score)?_c('div',{staticClass:"scene__scores-data"},[_c(VSelect,{attrs:{"label":"Select participant","items":_vm.assessedParticipants,"item-value":function (x) { return x.id; },"item-text":function (x) { return x.members.join(' / '); }},model:{value:(_vm.additionalData[id].participantId),callback:function ($$v) {_vm.$set(_vm.additionalData[id], "participantId", $$v)},expression:"additionalData[id].participantId"}})],1):_vm._e(),_c('flat-spinner',{attrs:{"active":_vm.isPscbDisplaySceneInProgress[id]}})],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }