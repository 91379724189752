<template>
  <ul
    class="scores"
    :style="`grid-template-rows: repeat(${displayableScores.length}, 1fr)`">
    <li
      v-for="({value, label, order, index}) in displayableScores"
      :style="`grid-row: ${order || index + 1} / span 1`"
      class="scores__entry"
      :key="index"
      :ref="index">
      <span
        class="scores__label">
        {{order ? order + '. ' : ''}}{{ label }}
      </span>
      <ScoreBox
        :disabled="disabled"
        :score="value"
        :placeholder="selectedScorePlaceholder"
        :selected="index === selected"
        :tabindex="index"
        class="scores__score"
        readonly
        @clicked="!disabled && $emit('scoreSelected', index)"
        @scoreUpdated="onScoreUpdated"
        @scoreDeleted="onScoreDeleted">
      </ScoreBox>
    </li>
  </ul>
</template>

<script>
import ScoreBox from '@/components/ScoreBox'
import { judge } from '@/store/modules'
import { mapActions } from 'vuex'

export default {
  name: 'ScoreCard',
  components: {
    ScoreBox
  },
  emits: ['scoreSelected'],
  props: {
    selected: {
      type: Number,
      default: NaN
    },
    scores: {
      type: Array,
      default: () => []
    },
    selectedScorePlaceholder: {
      type: String,
      default: () => null
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    panelId: {
      type: Number,
      default: NaN
    }
  },
  methods: {
    ...mapActions(
      judge.namespace,
      [
        judge.actions.keypadKeyPressed,
        judge.actions.eraseSelectedScoreOrLast10
      ]
    ),
    onScoreUpdated ({ value, goToNext }) {
      this.keypadKeyPressed({ value, goToNext })
    },
    onScoreDeleted () {
      this.eraseSelectedScoreOrLast10()
    }
  },
  watch: {
    selected () {
      const scoreIndexToScrollTo = this.selected
      const ref = this.$refs[scoreIndexToScrollTo]
      if (ref) {
        ref[0].scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  },
  computed: {
    displayableScores () {
      return (this.scores || [])
        .map((score, index) => ({ ...score, index }))
        .filter(score => !score.isTransition)
    }
  }
}
</script>

<style lang="scss">
@import "~@/styles/font";
@import "~@/styles/variables";
@import "~@/styles/container";

.scores {
  display: grid;

  grid-gap: 1rem;

  padding: 0 1rem 0 0;
  margin: 0;

  & > li {
    @include container("justified", "center");
    width: 100%;

    list-style: none;
  }

  &__label {
    text-align: left;
  }

  &__score {
    min-width: 7rem;
  }
}
</style>
