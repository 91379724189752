<template>
  <v-switch
    v-model='value'
    class='switch'
    @change="$emit('changed', settingName)"
    :label="value ? switchLabel : ''"
    inset
    :disabled="isDisabled"
  />
</template>

<script>
import store from '@/store'
import { configuration } from '@/store/modules'

const toSetting = name => ({
  getter: configuration.globals.getters[`settings.${name}`],
  setter: configuration.globals.actions[`settings.${name}`]
})

export default {
  name: 'SettingsSwitch',
  data () {
    return {
      isSnackBarOpened: false
    }
  },
  emits: ['changed'],
  props: {
    settingName: {
      type: String,
      required: true
    },
    switchLabel: {
      type: String,
      required: true
    }
  },
  computed: {
    value: {
      get () {
        return toSetting(this.settingName).getter(store).value
      },
      set (val) {
        toSetting(this.settingName).setter(store, val)
      }
    },
    isDisabled () {
      return toSetting(this.settingName).getter(store)['read-only']
    }
  }
}
</script>

<style lang="scss" scoped>
td {
  position: relative;
  vertical-align: center;
  text-align: left;
}

.switch-button {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
