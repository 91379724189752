import { httpget, httppost } from '../base-api'

const uri = 'publisher'

export default {
  publishData: async (publishType) => await httppost(`${uri}`, { publishType }),
  connect: async () => await httppost(`${uri}/connection`),
  disconnect: async () => await httppost(`${uri}/disconnection`),
  isConnected: async () => await httpget(`${uri}/status`)
}
