<template>
  <div v-if="authorized">
      <go-to-current-participant-button/>
      <Menu :authState="authState"/>
      <page-title title="Coach Card Validation"/>
      <coach-card-validation-table/>
  </div>
</template>

<script>
import Menu from '@/components/Menu'
import GoToCurrentParticipantButton from '@/components/GoToCurrentParticipantButton'
import PageTitle from '@/components/PageTitle'
import CoachCardValidationTable from '@/components/CoachCardValidationTable'
import { authorize, setupAuthorization } from '@/services/auth-service'

export default {
  name: 'CoachCardValidation',
  components: {
    GoToCurrentParticipantButton,
    Menu,
    PageTitle,
    CoachCardValidationTable
  },
  data () {
    return {
      ...setupAuthorization({ component: this, requiredRoles: ['olr-operator'] })
    }
  },
  created () {
    if (authorize({ component: this })) {
      // placeholder for init logic
    }
  }
}
</script>
