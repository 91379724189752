import { actions } from './actions'
import { mutations } from './mutations'

export const subscribe = ({ store, dispatchLocal, commitLocal } = {}) => {
  return store.subscribeAction(action => {
    switch (action.type) {
      case 'fetchingParticipantScores':
      case 'submittingMissingParticipantScores':
      case 'submittingModifyJudgeScores':
        dispatchLocal(actions.scoreRecalculationActionStarted)
        commitLocal(mutations.setIsInProgress, { element: 'fetchParticipantScores', pending: true })
        break
      case 'fetchParticipantScoresFailed':
      case 'submitMissingParticipantScoresFailed':
      case 'submitModifyJudgeScoresFailed':
      case 'fetchParticipantScoresSucceeded':
      case 'submitMissingParticipantScoresSucceeded':
      case 'submitModifyJudgeScoresSucceeded':
        commitLocal(mutations.setIsInProgress, { element: 'fetchParticipantScores', pending: false })
        dispatchLocal(actions.scoreRecalculationActionFinished)
        break
      case 'submitRecallParticipantScoresSucceeded': {
        const {
          unitId,
          participantId,
          panel
        } = action.payload

        dispatchLocal(actions.lowerHand, {
          unitId,
          participantId,
          panelId: panel.id,
          seat: panel.seat
        })
        break
      }
      case 'submittingSendScores':
        commitLocal(mutations.setIsInProgress, { element: 'sendScores', pending: true })
        break
      case 'submitSendScoresSucceeded':
        commitLocal(mutations.setIsInProgress, { element: 'sendScores', pending: false })
        commitLocal(mutations.setSendScoresError, { error: null })
        break
      case 'submitSendScoresFailed':
        commitLocal(mutations.setIsInProgress, { element: 'sendScores', pending: false })
        commitLocal(mutations.setSendScoresError, { error: 'Sending scores failed' })
        break
      case 'submittingPenalties': {
        const { payload } = action.payload
        commitLocal(mutations.setIsInProgress, { element: payload.penaltyName, pending: true })
        break
      }
      case 'submitPenaltiesSucceeded': {
        const { payload } = action.payload
        commitLocal(mutations.setIsInProgress, { element: payload.penaltyName, pending: false })
        break
      }
      case 'submitPenaltiesFailed': {
        const { payload } = action.payload
        commitLocal(mutations.setIsInProgress, { element: payload.penaltyName, pending: false })
        break
      }

      case 'submittingPresentParticipant':
        commitLocal(mutations.setIsInProgress, { element: 'present', pending: true })
        break
      case 'submitPresentParticipantSucceeded':
        commitLocal(mutations.setIsInProgress, { element: 'present', pending: false })
        break
      case 'submitPresentParticipantFailed':
        commitLocal(mutations.setIsInProgress, { element: 'present', pending: false })
        break

      case 'submittingAbortRoutine':
        commitLocal(mutations.setIsInProgress, { element: 'abort', pending: true })
        break
      case 'submitAbortRoutineSucceeded':
        commitLocal(mutations.setIsInProgress, { element: 'abort', pending: false })
        break
      case 'submitAbortRoutineFailed':
        commitLocal(mutations.setIsInProgress, { element: 'abort', pending: false })
        break

      case 'fetchingAllParticipants':
        commitLocal(mutations.setIsInProgress, { element: 'fetchingAllParticipants', pending: true })
        break
      case 'fetchAllParticipantsSucceeded':
        commitLocal(mutations.setIsInProgress, { element: 'fetchingAllParticipants', pending: false })
        break
      case 'fetchAllParticipantsFailed':
        commitLocal(mutations.setIsInProgress, { element: 'fetchingAllParticipants', pending: false })
        break

      case 'fetchingParticipantDetails':
        commitLocal(mutations.setIsInProgress, { element: 'fetchingParticipantDetails', pending: true })
        break
      case 'fetchParticipantDetailsSucceeded':
        commitLocal(mutations.setIsInProgress, { element: 'fetchingParticipantDetails', pending: false })
        break
      case 'fetchParticipantDetailsFailed':
        commitLocal(mutations.setIsInProgress, { element: 'fetchingParticipantDetails', pending: false })
        break

      case 'submittingCalculateScores':
        commitLocal(mutations.setIsInProgress, { element: 'submittingCalculateScores', pending: true })
        break
      case 'submitCalculateScoresSucceeded':
      case 'submitCalculateScoresFailed':
        commitLocal(mutations.setIsInProgress, { element: 'submittingCalculateScores', pending: false })
        break

      case 'submittingGenerateRandomScores':
        commitLocal(mutations.setIsInProgress, { element: 'generateRandomScores', pending: true })
        break
      case 'submitGenerateRandomScoresSucceeded':
      case 'submitGenerateRandomScoresFailed':
        commitLocal(mutations.setIsInProgress, { element: 'generateRandomScores', pending: false })
        break
    }
  })
}
