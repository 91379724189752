import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"50%","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",null,null,{ on: on, attrs: attrs })]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_vm._l((_vm.prompt),function(line,idx){return _c(VCardText,{key:idx},[_vm._v(" "+_vm._s(line)+" ")])}),_c(VCardActions,[_c(VSpacer),_vm._l((_vm.actions),function(ref){
var id = ref.id;
var label = ref.label;
return _c(VBtn,{key:id,attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.onActionClicked(id)}}},[_vm._v(" "+_vm._s(label)+" ")])})],2)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }