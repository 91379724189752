<template>
  <section
    class="file-importer">
    <h2>
      {{name}}
    </h2>
    <v-file-input
      :accept="accept"
      :clearable="false"
      :id="id"
      :loading="inProgress"
      @change="onFileSelected"
      @click="clearSelectedFilePath"
      single-line
      :success-messages="successMessage"
      :error-messages="erorrMessages"
      :error-count="2"
    >
      <template v-slot:label>
        <span style="color: rgba(0, 0, 0, 0.87);">
          Choose and import file
        </span>
      </template>
      <template v-slot:selection>
        <span style="color: rgba(0, 0, 0, 0.87);">
          Choose and import file
        </span>
      </template>
      <template v-slot:message="{ message }">
        <v-tooltip bottom v-if="erorrMessages.length > 2">
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs">
              {{ message }}
            </span>
          </template>

          <div v-for="(msg, index) in erorrMessages" :key="index">
            {{ msg }}
          </div>
        </v-tooltip>
        <span v-else>
          {{message}}
        </span>
      </template>
    </v-file-input>
  </section>
</template>

<script>

import { uniqueId } from 'lodash'

export default {
  name: 'FileImporter',
  data () {
    return {
      id: uniqueId('file-importer-')
    }
  },
  props: {
    accept: {
      type: String,
      default: '*'
    },
    name: {
      type: String,
      default: null
    },
    inProgress: {
      type: Boolean,
      default: false
    },
    result: {
      type: Object
    }
  },
  emits: ['fileSelected'],
  methods: {
    onFileSelected (file) {
      this.$emit('fileSelected', file)
    },
    clearSelectedFilePath (event) {
      event.target.value = null
    }
  },
  computed: {
    successMessage () { return this.result?.success || '' },
    erorrMessages () { return this.result?.errors || [] }
  }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/container";

.file-importer {
  @include container('left', 'center');
}
</style>
