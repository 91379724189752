<template>
  <v-select
    v-model="selectedId"
    :items="data"
    item-value="id"
    item-text="name"
    :label="placeholder"
    single-line
    @change="$emit('update:selectedItemId', $event)"
  />
</template>

<script>
export default {
  name: 'DropDownList',
  emits: ['update:selectedItemId'],
  props: {
    data: {
      type: Array,
      required: true,
      default: () => []
    },
    placeholder: {
      type: String,
      default: () => 'Choose one...'
    },
    selectedItemId: {
      type: String,
      default: null
    }
  },
  computed: {
    selectedId: {
      get () {
        return this.selectedItemId
      },
      set () {
        // Intentionally left blank, setter required for v-model
      }
    }
  }
}
</script>
