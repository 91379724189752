import { callApi, dispatchGlobal, routine } from '@/api'
import { getters } from '@/store/routine/getters'
import { isNil } from 'lodash'
import { mutations } from './mutations'

export const actions = {
  fetchCurrent: 'fetchCurrent',
  abort: 'abort',
  setUnit: 'setUnit',
  setParticipantInfo: 'setParticipantInfo',
  setGetLastAssessedIfCurrentMissing: 'setGetLastAssessedIfCurrentMissing'
}

const createSimpleHandler = (
  actionName,
  { dispatch },
  payload = undefined
) => () => dispatchGlobal(dispatch, actionName, payload)

const createFetchCurrentSucceeded = ({ dispatch, commit }, payload) => data => {
  commit(mutations.updateRoutine, data)
  dispatchGlobal(dispatch, 'fetchCurrentRoutineInfoSucceeded', payload)
}
const createFetchCurrentFailed = ({ dispatch, commit }, payload) => () => {
  commit(mutations.setCurrentRoutineNotExisting)
  dispatchGlobal(dispatch, 'fetchCurrentRoutineInfoFailed', payload)
}

const createSubmitAbortRoutineSucceeded = (
  { dispatch, commit },
  payload
) => data => {
  commit(mutations.clearRoutine)
  dispatchGlobal(dispatch, 'submitAbortRoutineSucceeded', payload)
}

export const actionsDefinition = {
  async [actions.fetchCurrent] (store) {
    const { getters: localGetters, commit } = store
    const queryParameters = {
      unitId: localGetters[getters.unitId],
      getLastAssessedIfCurrentMissing: localGetters[getters.getLastAssessedIfCurrentMissing]
    }
    commit(mutations.clearRoutine)
    await callApi(
      async () => {
        return routine.current(queryParameters)
      },
      createSimpleHandler('fetchingCurrentRoutineInfo', store, queryParameters),
      createFetchCurrentSucceeded(store, queryParameters),
      createFetchCurrentFailed(store)
    )
  },
  async [actions.abort] (store) {
    const { getters: localGetters } = store
    const unitId = localGetters[getters.unitId]
    const participantId = localGetters[getters.participantId]
    if (isNil(unitId) || isNil(participantId)) {
      return
    }

    const payload = { unitId, participantId }
    await callApi(
      async () => {
        return routine.abort(payload)
      },
      createSimpleHandler('submittingAbortRoutine', store, payload),
      createSubmitAbortRoutineSucceeded(store, payload),
      createSimpleHandler('submitAbortRoutineFailed', store)
    )
  },
  [actions.setUnit] ({ commit }, { id }) {
    commit(mutations.setUnit, { id })
  },
  async [actions.setParticipantInfo] ({ commit }, routine) {
    commit(mutations.updateRoutine, routine)
  },
  [actions.setGetLastAssessedIfCurrentMissing] ({ commit }, value) {
    commit(mutations.setGetLastAssessedIfCurrentMissing, value)
  }
}
