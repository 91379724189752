import { validateObject } from '../base-api'

export const validateUnit = object => {
  const { status, ...rest } = validateObject(
    'id',
    'scheduledDateStart',
    'scheduledDateEnd',
    'name',
    'status',
    'eventId',
    'phaseId',
    'teamMemberCount',
    'sessionKindCategoryType'
  )(object)
  return {
    ...rest,
    status: status.toLowerCase()
  }
}

export const validateCurrentUnit = object => {
  const { status, ...rest } = validateObject(
    'id',
    'scheduledDateStart',
    'scheduledDateEnd',
    'name',
    'status',
    'eventId'
  )(object)
  return {
    ...rest,
    status: status.toLowerCase()
  }
}
