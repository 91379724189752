import { compact, conformsTo, isBoolean, isEmpty, isNil, isNumber, join, map, pick } from 'lodash'
import { API_URI, API_URI_BASE } from '../apiconsts'
import { currentContextId } from '../authorization/context'
import { getAuthHeader } from '../authorization/token'

const notEmpty = x => isBoolean(x) || isNumber(x) || (!isNil(x) && !isEmpty(x))

export const validateObject = (...whitelist) => data => {
  if (isNil(data) || isEmpty(data)) {
    throw Error('Received no data')
  }

  if (
    !conformsTo(
      data,
      whitelist.reduce(
        (schema, prop) => ({
          ...schema,
          [prop]: notEmpty
        }),
        {}
      )
    )
  ) {
    throw Error(
      `Received data doesn't match schema. Expected props: [${whitelist}]` +
        ` on object: ${JSON.stringify(data)}`
    )
  }

  return pick(data, whitelist)
}

export const withJsonContentType = () => ({
  'Content-Type': 'application/json'
})

export const withAuthorization = ({ allowAnonymous = false } = {}) => {
  if (allowAnonymous) {
    return {}
  }

  const header = getAuthHeader()
  if (!header) {
    return {}
  }

  return { Authorization: header }
}

export const toApiUri = uri => {
  const baseUri = joinUri(API_URI, currentContextId())
  return joinUri(baseUri, uri)
}
export const toApiRootUri = uri => joinUri(API_URI, uri)

export const toApiUriBase = uri => join([API_URI_BASE, ...compact([uri])], '')

export const joinUri = (base, uri = '', keepFalsy) => {
  if (keepFalsy) {
    return join([base, uri], '/')
  } else {
    return join([base, ...compact([uri])], '/')
  }
}

export const newUrl = (uri, queryStringParams) => {
  if (uri) {
    return queryStringParams
      ? `${uri}${toQueryString(queryStringParams)}`
      : uri
  }

  return null
}

export const toQueryString = object => {
  const query = map(object, (value, key) => `${key}=${value}`).join('&')

  return query ? `?${query}` : query
}

export const handleApiCall = async (call, options = {}) => {
  const response = await call()
  const { allow: allowed = [] } = options
  if (
    response.status >= 400 &&
    response.status < 600 &&
    allowed.findIndex(x => x === response.status) === -1
  ) {
    const error = await response.json()

    if (isEmpty(error)) {
      throw new Error(`API call returned error. Status = ${response.status}`)
    }

    if (!isEmpty(error.detail)) {
      throw new Error(error.detail)
    }

    throw error
  }

  return response
}

export const extractResult = async response => {
  const contentType = response.headers.get('content-type')
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return await response.json()
  } else {
    return {}
  }
}
