import { get, map, merge } from 'lodash'
import { UnitStatus } from '@/enums/UnitStatus'

export const defaultState = {
  units: [],
  current: null,
  judgesOnline: {},
  isUnitStateChangeInProgress: false,
  isInProgress: {},
  cancellationTokens: {}
}

export const mutations = {
  setUnits: 'setUnits',
  setCurrentUnit: 'setCurrentUnit',
  setCurrentUnitById: 'setCurrentUnitById',
  updateUnit: 'updateUnit',
  setJudgesOnline: 'setJudgesOnline',
  setUnitStateChangeInProgress: 'setUnitStateChangeInProgress',
  setIsInProgress: 'setIsInProgress',
  replaceCancellationTokenForContext: 'replaceCancellationTokenForContext'
}

const mapUnit = (unit = {}) => ({
  id: get(unit, 'id'),
  scheduledDateStart: get(unit, 'scheduledDateStart'),
  scheduledDateEnd: get(unit, 'scheduledDateEnd'),
  name: get(unit, 'name'),
  status: get(unit, 'status'),
  eventId: get(unit, 'eventId'),
  phaseId: get(unit, 'phaseId'),
  teamMemberCount: get(unit, 'teamMemberCount'),
  sessionKindCategoryType: get(unit, 'sessionKindCategoryType')
})

export const mutationsDefinition = {
  [mutations.setJudgesOnline]: (store, judges = []) => {
    store.judgesOnline = {
      ...judges.reduce(
        (onlineJudgesMap, { email, judgePanel: { id, seat } }) =>
          merge(onlineJudgesMap, {
            [id]: [...Array(seat - 1), email]
          }),
        {}
      )
    }
  },
  [mutations.setCurrentUnit]: (store, unit = {}) => {
    store.current = mapUnit(unit)
  },
  [mutations.setCurrentUnitById] (store, { unitId }) {
    const unit = store.units.find(p => p.id === unitId)
    const expectedStatus = UnitStatus.Running
    if (unit.status !== expectedStatus) {
      unit.status = expectedStatus
    }
    store.current = mapUnit(unit)
  },
  [mutations.setUnits]: (store, units = []) => {
    store.units = map(units, mapUnit)
  },
  [mutations.updateUnit]: (store, unit = {}) => {
    store.units = store.units.map(u => u.id === unit.id
      ? ({ ...u, ...unit })
      : u)
  },
  [mutations.setUnitStateChangeInProgress]: (store, newValue) => {
    store.isUnitStateChangeInProgress = newValue === true
  },
  [mutations.setIsInProgress]: (store, { element, pending }) => {
    const isInProgress = { ...store.isInProgress }
    isInProgress[element] = pending
    store.isInProgress = isInProgress
  },
  [mutations.replaceCancellationTokenForContext]: (store, payload) => {
    store.cancellationTokens = {
      ...store.cancellationTokens,
      ...payload
    }
  }
}
