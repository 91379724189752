import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"value":_vm.opened},on:{"input":function($event){return _vm.$emit('update:opened', $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({on:{"click":function($event){return _vm.$emit('menuOpened')}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icon)+" ")])]}}])},_vm._l((_vm.options),function(ref){
var group = ref.group;
var items = ref.items;
return _c('div',{key:group},[_c(VList,[_c(VSubheader,[_vm._v(_vm._s(group))]),_vm._l((items),function(ref){
var disabled = ref.disabled;
var title = ref.title;
var promptTitle = ref.promptTitle;
var promptMessageLines = ref.promptMessageLines;
return _c('div',{key:title},[(promptTitle)?_c('prompt',{attrs:{"prompt":promptMessageLines,"title":promptTitle},on:{"onAction":function($event){return _vm.onPromptAction($event, group, title)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VListItem,_vm._g(_vm._b({attrs:{"disabled":disabled}},'v-list-item',attrs,false),on),[_c(VListItemTitle,[_vm._v(" "+_vm._s(title)+" ")])],1)]}}],null,true)}):_c(VListItem,{attrs:{"disabled":disabled},on:{"click":function($event){return _vm.onOptionSelected(group, title)}}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(title)+" ")])],1)],1)})],2),_c(VDivider)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }