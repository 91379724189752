import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({directives:[{def: Ripple,name:"ripple",rawName:"v-ripple"}],staticClass:"all-off-button",attrs:{"color":"error","loading":_vm.isPscbAllOffInProgress},on:{"click":_vm.pscbAllOff}},'v-btn',attrs,false),on),[_vm._v(" All off ")])]}}])},[_vm._v(" Takes all PSCB scenes off air ")])}
var staticRenderFns = []

export { render, staticRenderFns }