import { chain, isNil, merge } from 'lodash'
import { mutations } from './names'
import { expectedActualScoresCount, expectedActualScoresMaxIndex, fromPaddedIndex, scoreCachedValue, selectedScore } from '../shared'

const defaultSelectedScoreCachedValue = Object.freeze({
  value: undefined,
  index: -1
})
const defaultKeypad = Object.freeze({
  value: '',
  mode: 'whole',
  isLatelyCompletedScoreEqual10: false,
  keystrokes: ''
})
export const scoresDefaultState = Object.freeze({
  selectedScoreCachedValue: {
    ...defaultSelectedScoreCachedValue
  },
  selectedScoreIndex: 0,
  scoresSubmissionTimestamp: null,
  scores: [],
  keypad: {
    ...defaultKeypad
  },
  requests: {
    submitScores: false
  },
  isHandRaised: false
})

const clearKeypad = store => {
  store.keypad = {
    ...defaultKeypad
  }
}

const clearScoreCachedValue = store => {
  store.selectedScoreCachedValue = {
    ...defaultSelectedScoreCachedValue
  }
}

const cacheScoreValue = (store, [value], index) => {
  if (!isNil(value)) {
    store.selectedScoreCachedValue = {
      value,
      index
    }
  }
}

const restoreCachedValue = store => {
  const { value, index } = scoreCachedValue(store) ?? {}
  if (index >= 0) {
    store.scores = [...store.scores]
    store.scores[index] = value
  }

  clearScoreCachedValue(store)
}

const clearAndSelectScore = ({ store, index }) => {
  const { scores } = store
  restoreCachedValue(store)
  if (index < scores.length) {
    cacheScoreValue(
      store,
      store.scores.splice(index, 1, undefined),
      index
    )
  }
  store.scoresSubmissionTimestamp = null
  store.selectedScoreIndex = index
  clearKeypad(store)
}

const replaceErasedKeystroke = ({ store, keystrokes = '', value = '' }) => {
  store.keypad = {
    ...store.keypad,
    isLatelyCompletedScoreEqual10: false,
    value,
    keystrokes
  }
  clearScoreCachedValue(store)
}

const selectFirstEmptyScore = store => {
  const { scores } = store

  const index = scores.findIndex(isNil)
  store.selectedScoreIndex = index > -1 ? index : scores.length

  if (store.selectedScoreIndex >= expectedActualScoresCount(store)) {
    store.selectedScoreIndex = null
  }
}

const updateScore = (store, payload) => {
  const size = expectedActualScoresCount(store)
  const score = payload.score.value
  const index = fromPaddedIndex(store, payload.score.index)
  if (isNil(index) || isNaN(index) || index < 0 || size < index) {
    return
  }

  store.scores = merge(
    Array(Math.min(size, index)).fill(undefined),
    store.scores,
    [...Array(Math.max(0, index)), score]
  )
  store.scoresSubmissionTimestamp = null
}

const tryCommittingIncompleteScore = store => {
  const { value = '', index } = selectedScore(store) ?? {}
  if (/^(\d|10)$/.test(value)) {
    clearKeypad(store)
    clearScoreCachedValue(store)
    updateScore(store, {
      score: {
        value: value === '10' ? '10' : `${value}.00`,
        index
      }
    })
  }
}

const modulo = (dividend, divisor) =>
  dividend - divisor * Math.floor(dividend / divisor)

const advanceScoreIndexBy = (store, advanceStrategy) => {
  const {
    scores: { length }
  } = store
  const oldIndex = fromPaddedIndex(store, selectedScore(store)?.index)
  return modulo(
    advanceStrategy({
      index: oldIndex,
      min: 0,
      max: expectedActualScoresMaxIndex(store)
    }),
    Math.min(length + 1, expectedActualScoresCount(store))
  )
}

export const scoresMutations = Object.freeze({
  [mutations.selectFirstEmptyScore] (store) {
    restoreCachedValue(store)
    selectFirstEmptyScore(store)
  },

  [mutations.selectNextScore] (store) {
    tryCommittingIncompleteScore(store)
    clearAndSelectScore({
      store,
      index: advanceScoreIndexBy(store, ({ index, min }) =>
        isNil(index) ? min : index + 1
      )
    })
  },

  [mutations.selectPreviousScore] (store) {
    tryCommittingIncompleteScore(store)
    clearAndSelectScore({
      store,
      index: advanceScoreIndexBy(store, ({ index, max }) =>
        isNil(index) ? max : index - 1
      )
    })
  },

  [mutations.updateScore]: updateScore,

  [mutations.clearScores]: store => {
    store.scores = []
    store.selectedScoreIndex = 0
    store.scoresSubmissionTimestamp = null
    clearScoreCachedValue(store)
  },

  [mutations.clearAndSelectScoreByPaddedIndex]: (store, paddedIndex) => {
    const index = fromPaddedIndex(store, paddedIndex)
    clearAndSelectScore({ store, index })
  },

  [mutations.clearAndSelectScore]: (store, index) => {
    clearAndSelectScore({ store, index })
  },

  [mutations.markScoresSubmission]: store => {
    store.scoresSubmissionTimestamp = Date.now()
  },

  [mutations.overwriteScores]: (
    store,
    { scores: { values, index }, submittedAt }
  ) => {
    clearScoreCachedValue(store)
    store.scores = [...values]
    store.selectedScoreIndex = fromPaddedIndex(store, index)
    store.scoresSubmissionTimestamp = submittedAt
  },

  [mutations.storeKeypadValue]: (store, { newValue }) => {
    store.keypad.value = newValue
    clearScoreCachedValue(store)
  },
  [mutations.clearKeypadValue]: store => {
    clearKeypad(store)
    clearScoreCachedValue(store)
  },
  [mutations.eraseCurrentScoreOrLast10]: store => {
    if (store.keypad?.isLatelyCompletedScoreEqual10 === true) {
      clearAndSelectScore({
        store,
        index:
          store.selectedScoreIndex === null
            ? expectedActualScoresMaxIndex(store)
            : store.selectedScoreIndex - 1
      })
    } else {
      clearAndSelectScore({ store, index: store.selectedScoreIndex })
    }
    replaceErasedKeystroke({ store })
  },
  [mutations.addKeystroke]: (store, key) => {
    store.keypad.keystrokes += key
  },
  [mutations.removeTailKeystroke]: store => {
    const keystrokes = chain(store.keypad.keystrokes)
      .trimEnd('.')
      .slice(0, -1)
      .join('')
      .trimEnd('.')
      .value()

    replaceErasedKeystroke({
      store,
      keystrokes,
      value: keystrokes
    })
  },
  [mutations.commitScoreAndAdvance] (
    store,
    isLatelyCompletedScoreEqual10 = false
  ) {
    selectFirstEmptyScore(store)
    clearKeypad(store)
    clearScoreCachedValue(store)
    store.keypad = {
      ...store.keypad,
      isLatelyCompletedScoreEqual10,
      keystrokes: ''
    }
  },
  [mutations.tryCommittingIncompleteScore]: tryCommittingIncompleteScore,

  [mutations.isSubmitScorePending]: (store, { pending }) => {
    store.requests.submitScores = pending
  },

  [mutations.setIsHandRaised]: (store, isHandRaised) => {
    store.isHandRaised = isHandRaised
  }
})
