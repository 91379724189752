<template>
  <div v-if="authorized">
    <go-to-current-participant-button/>
    <Menu :authState="authState"/>
    <page-title title="Teams Management"/>
  </div>
</template>

<script>
import Menu from '@/components/Menu'
import GoToCurrentParticipantButton from '@/components/GoToCurrentParticipantButton'
import PageTitle from '@/components/PageTitle'
import { authorize, setupAuthorization } from '@/services/auth-service'

export default {
  name: 'TeamsManagement',
  components: {
    Menu,
    GoToCurrentParticipantButton,
    PageTitle
  },
  data () {
    return {
      ...setupAuthorization({ component: this, requiredRoles: ['olr-operator'] })
    }
  },
  created () {
    if (authorize({ component: this })) {
      // placeholder for init logic
    }
  }
}
</script>
