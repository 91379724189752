import { callApi, coachCards, competitions, configuration, createSimpleHandler, dispatchGlobal, events, importApi, participants, phases, unit, odf, pscb, publisher } from '@/api'
import { retrieveCancellationTokenFor } from './cancellation-token-provider'
import { isEmpty, isNil } from 'lodash'
import { toHierarchy, toUpdateHierarchy } from './eventsTree'
import { inProgress, mutations, tournamentNodeContextualScenes } from './mutations'
import { getters } from './getters'

export const actions = {
  importOdf: 'importOdf',
  importCoachCard: 'importCoachCard',
  setSelectedUnit: 'setSelectedUnit',
  fetchRegisteredParticipants: 'fetchRegisteredParticipants',
  fetchAllParticipants: 'fetchAllParticipants',
  fetchRegisteredJudges: 'fetchRegisteredJudges',
  fetchAllocatedJudges: 'fetchAllocatedJudges',
  fetchConfiguration: 'fetchConfiguration',
  modifyStatus: 'modifyStatus',
  setParticipantStatus: 'setParticipantStatus',
  fetchCompetitionPrintoutsStatuses: 'fetchCompetitionPrintoutsStatuses',
  fetchEventPrintoutsStatuses: 'fetchEventPrintoutsStatuses',
  fetchUnitPrintoutsStatuses: 'fetchUnitPrintoutsStatuses',
  generateCompetitionPrintout: 'generateCompetitionPrintout',
  generateEventPrintout: 'generateEventPrintout',
  generateUnitPrintout: 'generateUnitPrintout',
  downloadPrintout: 'downloadPrintout',
  deleteScheduleEntry: 'deleteScheduleEntry',
  createScheduleEntry: 'createScheduleEntry',
  updateScheduleEntry: 'updateScheduleEntry',
  judgesAllocationSaveStarted: 'judgesAllocationSaveStarted',
  judgesAllocationSaveEnded: 'judgesAllocationSaveEnded',
  startListSaveStarted: 'startListSaveStarted',
  startListSaveEnded: 'startListSaveEnded',
  unitStatusChangeStarted: 'unitStatusChangeStarted',
  unitStatusChangeFinished: 'unitStatusChangeFinished',
  fetchOdfMessages: 'fetchOdfMessages',
  generateOdfMessage: 'generateOdfMessage',
  sendPublisher: 'sendPublisher',
  addInProgressIndicator: 'addInProgressIndicator',
  releaseInProgressIndicator: 'releaseInProgressIndicator',
  pscbAllOff: 'pscbAllOff',
  fetchPscbScenes: 'fetchPscbScenes',
  toggleScene: 'toggleScene',
  connectPublisher: 'connectPublisher',
  disconnectPublisher: 'disconnectPublisher',
  fetchIsPublisherConnected: 'fetchIsPublisherConnected'
}

const PreviewChannel = 'Preview'
const LiveChannel = 'Live'

const createRegisteredParticipantsSucceeded = (
  { dispatch, commit },
  payload
) => eventRegisteredParticipants => {
  commit(mutations.setRegisteredParticipants, eventRegisteredParticipants)
  dispatchGlobal(dispatch, 'fetchRegisteredParticipantsSucceeded', payload)
}

const createFetchRegisteredParticipantsFailed = ({
  dispatch,
  commit
}) => () => {
  commit(mutations.clearSelectedRegisteredParticipants)
  dispatchGlobal(dispatch, 'fetchRegisteredParticipantsFailed')
}

const createRegisteredJudgesSucceeded = (
  { dispatch, commit },
  payload
) => eventRegisteredJudges => {
  commit(mutations.setRegisteredJudges, eventRegisteredJudges)
  dispatchGlobal(dispatch, 'fetchRegisteredJudgesSucceeded', payload)
}

const createFetchingOdfAllParticipants = ({ dispatch, commit }) => () => {
  commit(mutations.addInProgress, {
    scope: inProgress.unitParticipantsLoading
  })
  dispatchGlobal(dispatch, 'fetchingOdfAllParticipants')
}

const createFetchOdfAllParticipantsSucceeded = (
  { dispatch, commit },
  payload
) => participants => {
  const { unitId } = payload
  commit(mutations.setParticipants, { participants })
  commit(mutations.releaseInProgress, {
    scope: inProgress.unitParticipantsLoading
  })
  dispatchGlobal(dispatch, 'fetchOdfAllParticipantsSucceeded', {
    unitId,
    participants
  })
}

const createFetchOdfAllParticipantsFailed = ({ dispatch, commit }) => error => {
  commit(mutations.clearParticipants)
  commit(mutations.releaseInProgress, {
    scope: inProgress.unitParticipantsLoading
  })
  dispatchGlobal(dispatch, 'fetchOdfAllParticipantsFailed', error)
}

const createFetchRegisteredJudgesFailed = ({ dispatch, commit }) => () => {
  commit(mutations.clearSelectedRegisteredJudges)
  dispatchGlobal(dispatch, 'fetchRegisteredJudgesFailed')
}

const createFetchingAllocatedJudges = ({ dispatch, commit }) => () => {
  commit(mutations.addInProgress, {
    scope: inProgress.allocatedJudgesLoading
  })
  dispatchGlobal(dispatch, 'fetchingAllocatedJudges')
}

const createFetchAllocatedJudgesSucceeded = (
  { dispatch, commit },
  payload
) => judges => {
  commit(mutations.setAllocatedJudges, { judges })
  commit(mutations.releaseInProgress, {
    scope: inProgress.allocatedJudgesLoading
  })
  dispatchGlobal(dispatch, 'fetchAllocatedJudgesSucceeded', payload)
}

const createFetchAllocatedJudgesFailed = ({ dispatch, commit }) => () => {
  commit(mutations.clearSelectedJudges)
  commit(mutations.releaseInProgress, {
    scope: inProgress.allocatedJudgesLoading
  })
  dispatchGlobal(dispatch, 'fetchAllocatedJudgesFailed')
}

const createFetchOlrConfigSucceeded = ({
  dispatch,
  commit
}) => configuration => {
  commit(mutations.setConfiguration, configuration)
  dispatchGlobal(dispatch, 'fetchOlrConfigSucceeded')
}

const createCompetitionPrintoutsStatusesSucceeded = (
  { dispatch, commit },
  payload
) => competitionPrintoutsStatuses => {
  commit(
    mutations.setCompetitionPrintoutsStatuses,
    competitionPrintoutsStatuses
  )
  dispatchGlobal(
    dispatch,
    'fetchCompetitionPrintoutsStatusesSucceeded',
    payload
  )
}

const createEventPrintoutsStatusesSucceeded = (
  { dispatch, commit },
  payload
) => eventPrintoutsStatuses => {
  commit(mutations.setEventPrintoutsStatuses, eventPrintoutsStatuses)
  dispatchGlobal(dispatch, 'fetchEventPrintoutsStatusesSucceeded', payload)
}

const createUnitPrintoutsStatusesSucceeded = (
  { dispatch, commit },
  payload
) => unitPrintoutsStatuses => {
  commit(mutations.setUnitPrintoutsStatuses, unitPrintoutsStatuses)
  dispatchGlobal(dispatch, 'fetchUnitPrintoutsStatusesSucceeded', payload)
}

const createSubmittingPrintoutHandler = (
  { dispatch, commit },
  { actionName, tournamentNodeId, printoutCode }
) => () => {
  commit(mutations.addObjectInProgress, {
    path: [inProgress.printoutGeneration, `${tournamentNodeId}${printoutCode}`]
  })
  dispatchGlobal(dispatch, actionName, { tournamentNodeId, printoutCode })
}

const createSubmitPrintoutSucceededHandler = (
  { dispatch, commit },
  { actionName, tournamentNodeId, printoutCode }
) => () => {
  commit(mutations.releaseObjectInProgress, {
    path: [inProgress.printoutGeneration, `${tournamentNodeId}${printoutCode}`]
  })
  dispatchGlobal(dispatch, actionName, { tournamentNodeId, printoutCode })
}

const createSubmitPrintoutFailedHandler = (
  { commit, dispatch },
  { actionName, tournamentNodeId, printoutCode }
) => () => {
  commit(mutations.releaseObjectInProgress, {
    path: [inProgress.printoutGeneration, `${tournamentNodeId}${printoutCode}`]
  })
  alert(`Downloading ${printoutCode} failed`)
  dispatchGlobal(dispatch, actionName, { tournamentNodeId, printoutCode })
}

const createSubmittingCoachCardArchiveHandler = ({
  dispatch,
  commit
}) => () => {
  commit(mutations.setCoachCardImportResult, null)
  commit(mutations.addInProgress, {
    scope: inProgress.coachCardImport
  })
  dispatchGlobal(dispatch, 'submittingCoachCardArchive')
}

const createSubmitCoachCardArchiveSucceeded = ({
  dispatch,
  commit
}) => payload => {
  commit(mutations.releaseInProgress, {
    scope: inProgress.coachCardImport
  })
  dispatchGlobal(dispatch, 'submitCoachCardArchiveSucceeded', {
    errors: payload
  })
}

const createSubmitCoachCardArchiveFailedHandler = ({
  dispatch,
  commit
}) => payload => {
  commit(mutations.releaseInProgress, {
    scope: inProgress.coachCardImport
  })
  dispatchGlobal(dispatch, 'submitCoachCardArchiveFailed', payload)
}

const createSubmittingOdfArchiveHandler = ({ dispatch, commit }) => () => {
  commit(mutations.setOdfImportResult, null)
  commit(mutations.addInProgress, { scope: inProgress.odfImport })
  dispatchGlobal(dispatch, 'submittingOdfArchive')
}

const createSubmitOdfArchiveSucceededHandler = ({
  dispatch,
  commit
}) => payload => {
  commit(mutations.releaseInProgress, { scope: inProgress.odfImport })
  dispatchGlobal(dispatch, 'submitOdfArchiveSucceeded', payload)
}

const createSubmitOdfArchiveFailedHandler = ({
  dispatch,
  commit
}) => payload => {
  commit(mutations.releaseInProgress, { scope: inProgress.odfImport })
  dispatchGlobal(dispatch, 'submitOdfArchiveFailed', payload)
}

const createFetchOdfMessagesSucceededHandler = ({ dispatch, commit }) => response => {
  commit(mutations.setOdfMessages, response)
  dispatchGlobal(dispatch, 'fetchOdfMessagesSucceeded')
}

const createFetchOdfMessagesFailedHandler = ({ dispatch, commit }) => () => {
  commit(mutations.setOdfMessages, [])
  dispatchGlobal(dispatch, 'fetchOdfMessagesFailed')
}

const createSubmittingGenerateOdfMessageHandler = ({ dispatch, commit }, name) => () => {
  commit(mutations.addObjectInProgress, {
    path: [inProgress.odfMessageGeneration, name]
  })
  dispatchGlobal(dispatch, 'submittingGenerateOdfMessage', name)
}

const createSubmitGenerateOdfMessageSucceededHandler = ({ dispatch, commit }, name) => response => {
  commit(mutations.releaseObjectInProgress, {
    path: [inProgress.odfMessageGeneration, name]
  })
  commit(mutations.setOdfMessage, response)
  dispatchGlobal(dispatch, 'submitGenerateOdfMessageSucceeded', name)
}

const isCustomOdfError = (error = {}) => {
  const { name, lastGeneratedAt, errors } = error

  if (isEmpty(name) || isEmpty(lastGeneratedAt) || isEmpty(errors)) {
    return false
  }

  return true
}

const createSubmitGenerateOdfMessageFailedHandler = ({ dispatch, commit }, name) =>
  ({ error } = {}) => {
    commit(mutations.releaseObjectInProgress, {
      path: [inProgress.odfMessageGeneration, name]
    })

    if (isCustomOdfError(error)) {
      commit(mutations.setOdfMessage, error)
    }

    dispatchGlobal(dispatch, 'submitGenerateOdfMessageFailed', name)
  }

const createSubmittingSendPublisher = ({ dispatch, commit }) => () => {
  commit(mutations.addInProgress, { scope: inProgress.sendPublisher })
  dispatchGlobal(dispatch, 'submittingSendPublisher')
}

const createSubmitSendPublisherSucceededHandler = ({ dispatch, commit }) => () => {
  commit(mutations.releaseInProgress, { scope: inProgress.sendPublisher })
  dispatchGlobal(dispatch, 'submitSendPublisherSucceeded')
}

const createSubmitSendPublisherFailedHandler = ({ dispatch, commit }) => () => {
  commit(mutations.releaseInProgress, { scope: inProgress.sendPublisher })
  dispatchGlobal(dispatch, 'submitSendPublisherFailed')
}

const createSubmittingPscbAllOffHandler = ({ dispatch, commit }) => () => {
  commit(mutations.addInProgress, { scope: inProgress.pscbAllOff })
  dispatchGlobal(dispatch, 'submittingPscbAllOff')
}

const createSubmitPscbAllOffSucceededHandler = ({ dispatch, commit }, scenes) => () => {
  const scenesWithLiveOff = scenes.map(s => ({
    ...s,
    channel: s.channel === LiveChannel ? null : s.channel
  }))
  commit(mutations.setPscbScenes, scenesWithLiveOff)
  commit(mutations.releaseInProgress, { scope: inProgress.pscbAllOff })
  dispatchGlobal(dispatch, 'submitPscbAllOffSucceeded')
}

const createSubmitPscbAllOffFailedHandler = ({ dispatch, commit }) => () => {
  commit(mutations.releaseInProgress, { scope: inProgress.pscbAllOff })
  dispatchGlobal(dispatch, 'submitPscbAllOffFailed')
}

const createFetchingPscbScenesHandler = ({ dispatch, commit }) => () => {
  commit(mutations.addInProgress, { scope: inProgress.fetchPscbScenes })
  dispatchGlobal(dispatch, 'fetchingPscbScenes')
}

const createFetchPscbScenesSucceededHandler = ({ dispatch, commit }) => (scenes) => {
  commit(mutations.setPscbScenes, scenes)
  commit(mutations.releaseInProgress, { scope: inProgress.fetchPscbScenes })
  dispatchGlobal(dispatch, 'fetchPscbScenesSucceeded')
}

const createFetchPscbScenesFailedHandler = ({ dispatch, commit }) => () => {
  commit(mutations.setPscbScenes, [])
  commit(mutations.releaseInProgress, { scope: inProgress.fetchPscbScenes })
  dispatchGlobal(dispatch, 'fetchPscbScenesFailed')
}

const shouldPassTournamentNodeId = sceneId => tournamentNodeContextualScenes.includes(sceneId)

const createSubmittingPscbSceneDisplayHandler = (
  { dispatch, commit },
  { id, channel, publish, tournamentNodeId }) => () => {
  commit(mutations.addObjectInProgress, {
    path: [inProgress.displayScene, id]
  })
  dispatchGlobal(dispatch, 'submittingPscbSceneDisplay', { id, channel, publish, tournamentNodeId })
}

const createSubmitPscbSceneDisplaySucceededHandler = (
  { dispatch, commit },
  { id, channel, publish, tournamentNodeId, scenes }) => () => {
  commit(mutations.releaseObjectInProgress, {
    path: [inProgress.displayScene, id]
  })
  const modifiedScenes = scenes.map(s => {
    if (s.id === id) {
      return { ...s, channel: publish ? channel : null }
    }

    if (s.channel === channel) {
      return { ...s, channel: publish ? null : s.channel }
    }

    return s
  })
  commit(mutations.setPscbScenes, modifiedScenes)
  dispatchGlobal(
    dispatch,
    'submitPscbSceneDisplaySucceeded',
    { id, channel, publish, tournamentNodeId })
}

const createSubmitPscbSceneDisplayFailedHandler = (
  { dispatch, commit },
  { id, channel, publish, tournamentNodeId }) => () => {
  commit(mutations.releaseObjectInProgress, {
    path: [inProgress.displayScene, id]
  })
  dispatchGlobal(
    dispatch,
    'submitPscbSceneDisplayFailed',
    { id, channel, publish, tournamentNodeId })
}

const createSubmittingConnectPublisher = ({ dispatch, commit }) => () => {
  commit(mutations.addInProgress, { scope: inProgress.connectPublisher })
  dispatchGlobal(dispatch, 'submittingConnectPublisher')
}

const createSubmitConnectPublisherSucceededHandler = ({ dispatch, commit }) => () => {
  commit(mutations.releaseInProgress, { scope: inProgress.connectPublisher })
  dispatchGlobal(dispatch, 'submitConnectPublisherSucceeded')
}

const createSubmitConnectPublisherFailedHandler = ({ dispatch, commit }) => () => {
  commit(mutations.releaseInProgress, { scope: inProgress.connectPublisher })
  dispatchGlobal(dispatch, 'submitConnectPublisherFailed')
}

const createSubmittingDisconnectPublisher = ({ dispatch, commit }) => () => {
  commit(mutations.addInProgress, { scope: inProgress.disconnectPublisher })
  dispatchGlobal(dispatch, 'submittingDisconnectPublisher')
}

const createSubmitDisconnectPublisherSucceededHandler = ({ dispatch, commit }) => () => {
  commit(mutations.releaseInProgress, { scope: inProgress.disconnectPublisher })
  dispatchGlobal(dispatch, 'submitDisconnectPublisherSucceeded')
}

const createSubmitDisconnectPublisherFailedHandler = ({ dispatch, commit }) => () => {
  commit(mutations.releaseInProgress, { scope: inProgress.disconnectPublisher })
  dispatchGlobal(dispatch, 'submitDisconnectPublisherFailed')
}

const createFetchingIsConnectedPublisher = ({ dispatch, commit }) => () => {
  commit(mutations.addInProgress, { scope: inProgress.fetchIsPublisherConnected })
  dispatchGlobal(dispatch, 'fetchingIsConnectedPublisher')
}

const createFetchIsPublisherConnectedSucceededHandler = ({ dispatch, commit }) => (response) => {
  commit(mutations.setIsConnectedPublisher, response)
  commit(mutations.releaseInProgress, { scope: inProgress.fetchIsPublisherConnected })
  dispatchGlobal(dispatch, 'fetchIsPublisherConnectedSucceeded')
}

const createFetchIsPublisherConnectedFailedHandler = ({ dispatch, commit }) => () => {
  commit(mutations.setIsConnectedPublisher, {})
  commit(mutations.releaseInProgress, { scope: inProgress.fetchIsPublisherConnected })
  dispatchGlobal(dispatch, 'fetchIsPublisherConnectedFailed')
}

export const actionsDefinition = {
  async [actions.importOdf] (store, file) {
    if (!file) {
      return
    }

    await callApi(
      async () => await importApi.odf({ file }),
      createSubmittingOdfArchiveHandler(store),
      createSubmitOdfArchiveSucceededHandler(store),
      createSubmitOdfArchiveFailedHandler(store)
    )
  },
  async [actions.importCoachCard] (store, file) {
    if (!file) {
      return
    }

    await callApi(
      async () => await coachCards.import({ file }),
      createSubmittingCoachCardArchiveHandler(store),
      createSubmitCoachCardArchiveSucceeded(store),
      createSubmitCoachCardArchiveFailedHandler(store)
    )
  },
  [actions.setSelectedUnit] ({ commit }, selectedUnit) {
    commit(mutations.setSelectedUnit, selectedUnit)
  },
  async [actions.fetchRegisteredParticipants] (store, { phaseId, unitId }) {
    const payload = { phaseId }
    const token = retrieveCancellationTokenFor(store, {
      phase: phaseId
    })

    await callApi(
      async () => {
        return await phases.registeredParticpants({ phaseId, unitId }, token)
      },
      createSimpleHandler('fetchingRegisteredParticipants', store),
      createRegisteredParticipantsSucceeded(store, payload),
      createFetchRegisteredParticipantsFailed(store)
    )
  },
  async [actions.fetchAllParticipants] (store, { unitId }) {
    const { commit } = store
    if (isNil(unitId) || isEmpty(unitId)) {
      commit(mutations.clearParticipants)
      return
    }

    const payload = { unitId }
    const token = retrieveCancellationTokenFor(store, { unit: unitId })
    await callApi(
      async () => {
        return await participants.list(payload, token)
      },
      createFetchingOdfAllParticipants(store, payload),
      createFetchOdfAllParticipantsSucceeded(store, payload),
      createFetchOdfAllParticipantsFailed(store)
    )
  },
  async [actions.modifyStatus] (store, { unitId, expectedStatus }) {
    await callApi(
      async () => {
        return unit.modifyStatus({
          unitId,
          expectedStatus,
          uri: 'olroperator'
        })
      },
      createSimpleHandler('submittingModifyStatusByOlr', store, { unitId }),
      createSimpleHandler('submitModifyStatusByOlrSucceeded', store, {
        unitId
      }),
      createSimpleHandler('submitModifyStatusByOlrFailed', store, { unitId })
    )
  },
  [actions.setParticipantStatus] ({ commit }, { participantId, status }) {
    commit(mutations.setParticipantStatus, { participantId, status })
  },
  async [actions.fetchRegisteredJudges] (store, { eventId }) {
    const payload = { eventId }
    const token = retrieveCancellationTokenFor(store, { event: eventId })
    await callApi(
      async () => {
        return await events.registeredJudges({ eventId }, token)
      },
      createSimpleHandler('fetchingRegisteredJudges', store),
      createRegisteredJudgesSucceeded(store, payload),
      createFetchRegisteredJudgesFailed(store)
    )
  },
  async [actions.fetchAllocatedJudges] (store, { unitId }) {
    const payload = { unitId }
    const token = retrieveCancellationTokenFor(store, { unit: unitId })
    await callApi(
      async () => {
        return await participants.judgesAllocation(payload, token)
      },
      createFetchingAllocatedJudges(store, payload),
      createFetchAllocatedJudgesSucceeded(store, payload),
      createFetchAllocatedJudgesFailed(store)
    )
  },
  async [actions.fetchConfiguration] (store, { unitId }) {
    const token = retrieveCancellationTokenFor(store, { unit: unitId })
    await callApi(
      async () => {
        return configuration.olr({ unitId }, token)
      },
      createSimpleHandler('fetchingOlrConfig', store),
      createFetchOlrConfigSucceeded(store),
      createSimpleHandler('fetchOlrConfigFailed', store)
    )
  },
  async [actions.fetchCompetitionPrintoutsStatuses] (store) {
    await callApi(
      async () => {
        return competitions.printoutStatusesList()
      },
      createSimpleHandler('fetchingCompetitionPrintoutsStatuses', store),
      createCompetitionPrintoutsStatusesSucceeded(store),
      createSimpleHandler('fetchCompetitionPrintoutsStatusesFailed', store)
    )
  },
  async [actions.fetchEventPrintoutsStatuses] (store, { eventId }) {
    await callApi(
      async () => {
        return events.printoutStatusesList({ eventId })
      },
      createSimpleHandler('fetchingEventPrintoutsStatuses', store),
      createEventPrintoutsStatusesSucceeded(store),
      createSimpleHandler('fetchEventPrintoutsStatusesFailed', store)
    )
  },
  async [actions.fetchUnitPrintoutsStatuses] (store, { unitId }) {
    await callApi(
      async () => {
        return unit.printoutStatusesList({ unitId })
      },
      createSimpleHandler('fetchingUnitPrintoutsStatuses', store),
      createUnitPrintoutsStatusesSucceeded(store),
      createSimpleHandler('fetchUnitPrintoutsStatusesFailed', store)
    )
  },
  async [actions.generateCompetitionPrintout] (store, { printoutCode }) {
    await callApi(
      async () => {
        return competitions.generatePrintout({ printoutCode })
      },
      createSubmittingPrintoutHandler(store, {
        actionName: 'submittingCompetitionPrintout',
        tournamentNodeId: '',
        printoutCode
      }),
      createSubmitPrintoutSucceededHandler(store, {
        actionName: 'submitCompetitionPrintoutSucceeded',
        tournamentNodeId: '',
        printoutCode
      }),
      createSubmitPrintoutFailedHandler(store, {
        actionName: 'submitCompetitionPrintoutFailed',
        tournamentNodeId: '',
        printoutCode
      })
    )
  },
  async [actions.generateEventPrintout] (store, { eventId, printoutCode }) {
    await callApi(
      async () => {
        return events.generatePrintout({ eventId, printoutCode })
      },
      createSubmittingPrintoutHandler(store, {
        actionName: 'submittingEventPrintout',
        tournamentNodeId: eventId,
        printoutCode
      }),
      createSubmitPrintoutSucceededHandler(store, {
        actionName: 'submitEventPrintoutSucceeded',
        tournamentNodeId: eventId,
        printoutCode
      }),
      createSubmitPrintoutFailedHandler(store, {
        actionName: 'submitEventPrintoutFailed',
        tournamentNodeId: eventId,
        printoutCode
      })
    )
  },
  async [actions.generateUnitPrintout] (store, { unitId, printoutCode, options }) {
    await callApi(
      async () => {
        return unit.generatePrintout({ unitId, printoutCode, options })
      },
      createSubmittingPrintoutHandler(store, {
        actionName: 'submittingUnitPrintout',
        tournamentNodeId: unitId,
        printoutCode
      }),
      createSubmitPrintoutSucceededHandler(store, {
        actionName: 'submitUnitPrintoutSucceeded',
        tournamentNodeId: unitId,
        printoutCode
      }),
      createSubmitPrintoutFailedHandler(store, {
        actionName: 'submitUnitPrintoutFailed',
        tournamentNodeId: unitId,
        printoutCode
      })
    )
  },
  async [actions.downloadPrintout] (store, { uri, filename }) {
    await callApi(
      async () => {
        return await competitions.downloadPrintout({ uri, filename })
      },
      createSimpleHandler('submittingDownloadPrintout', store),
      createSimpleHandler('submitDownloadPrintoutSucceeded', store),
      createSimpleHandler('submitDownloadPrintoutFailed', store)
    )
  },
  async [actions.deleteScheduleEntry] (store, { eventId }) {
    await callApi(
      async () => await events.delete({ eventId }),
      createSimpleHandler('submittingDeleteScheduleEntry', store),
      createSimpleHandler('submitDeleteScheduleEntrySucceeded', store),
      createSimpleHandler('submitDeleteScheduleEntryFailed', store)
    )
  },
  async [actions.createScheduleEntry] (
    store,
    { newScheduleItemEventCode, newScheduleItem }
  ) {
    const payload = toHierarchy(newScheduleItemEventCode, newScheduleItem)

    return (
      (await callApi(
        async () => await events.createEvent(payload),
        createSimpleHandler('submittingCreateScheduleEntry', store),
        createSimpleHandler('submitCreateScheduleEntrySucceeded', store),
        createSimpleHandler('submitCreateScheduleEntryFailed', store)
      )) !== null
    )
  },

  async [actions.updateScheduleEntry] (
    store,
    { scheduleItemEventCode, scheduleItem }
  ) {
    const payload = toUpdateHierarchy(scheduleItemEventCode, scheduleItem)

    return (
      (await callApi(
        async () => await events.updateEvent(payload),
        createSimpleHandler('submittingUpdateScheduleEntry', store),
        createSimpleHandler('submitUpdateScheduleEntrySucceeded', store),
        createSimpleHandler('submitUpdateScheduleEntryFailed', store)
      )) !== null
    )
  },

  [actions.judgesAllocationSaveStarted] ({ commit }) {
    commit(mutations.addInProgress, {
      scope: inProgress.saveJudgeAllocation
    })
  },

  [actions.judgesAllocationSaveEnded] ({ commit }) {
    commit(mutations.releaseInProgress, {
      scope: inProgress.saveJudgeAllocation
    })
  },

  [actions.startListSaveStarted] ({ commit }) {
    commit(mutations.addInProgress, {
      scope: inProgress.saveStartList
    })
  },

  [actions.startListSaveEnded] ({ commit }) {
    commit(mutations.releaseInProgress, {
      scope: inProgress.saveStartList
    })
  },

  [actions.unitStatusChangeStarted] ({ commit }, { unitId }) {
    commit(mutations.addObjectInProgress, {
      path: [inProgress.changeUnitStatus, unitId]
    })
  },

  [actions.unitStatusChangeFinished] ({ commit }, { unitId }) {
    commit(mutations.releaseObjectInProgress, {
      path: [inProgress.changeUnitStatus, unitId]
    })
  },

  async [actions.fetchOdfMessages] (store, { unitId }) {
    await callApi(
      async () => await odf.list(unitId),
      createSimpleHandler('fetchingOdfMessages', store),
      createFetchOdfMessagesSucceededHandler(store),
      createFetchOdfMessagesFailedHandler(store)
    )
  },

  async [actions.generateOdfMessage] (store, { name, unitId, additionalData }) {
    await callApi(
      async () => await odf.post(name, unitId, additionalData),
      createSubmittingGenerateOdfMessageHandler(store, name),
      createSubmitGenerateOdfMessageSucceededHandler(store, name),
      createSubmitGenerateOdfMessageFailedHandler(store, name)
    )
  },

  async [actions.sendPublisher] (store, publishType) {
    await callApi(
      async () => await publisher.publishData(publishType),
      createSubmittingSendPublisher(store, publishType),
      createSubmitSendPublisherSucceededHandler(store, publishType),
      createSubmitSendPublisherFailedHandler(store, publishType)
    )
  },

  async [actions.pscbAllOff] (store) {
    const scenes = store.getters[getters.pscbScenes]
    await callApi(
      async () => await pscb.allOff(),
      createSubmittingPscbAllOffHandler(store),
      createSubmitPscbAllOffSucceededHandler(store, scenes),
      createSubmitPscbAllOffFailedHandler(store)
    )
  },

  async [actions.fetchPscbScenes] (store) {
    await callApi(
      async () => await pscb.list(),
      createFetchingPscbScenesHandler(store),
      createFetchPscbScenesSucceededHandler(store),
      createFetchPscbScenesFailedHandler(store)
    )
  },

  async [actions.toggleScene] (store, { id, channel, tournamentNodeId, additionalData }) {
    const scenes = store.getters[getters.pscbScenes]
    const scene = scenes.find(s => s.id === id)
    const publish = scene.channel !== channel
    const shouldTakeOtherChannelOff = publish && !!scene.channel
    await callApi(
      async () => pscb.display({
        id,
        channel,
        publish,
        tournamentNodeId: shouldPassTournamentNodeId(id) ? tournamentNodeId : null,
        additionalData
      }),
      createSubmittingPscbSceneDisplayHandler(
        store,
        { id, channel, publish, tournamentNodeId }),
      createSubmitPscbSceneDisplaySucceededHandler(
        store,
        { id, channel, publish, tournamentNodeId, scenes }),
      createSubmitPscbSceneDisplayFailedHandler(
        store,
        { id, channel, publish, tournamentNodeId })
    )

    if (shouldTakeOtherChannelOff) {
      const channelToTakeOff = channel === PreviewChannel ? LiveChannel : PreviewChannel
      await callApi(
        async () => pscb.display({ id, channel: channelToTakeOff, publish: false }),
        createSimpleHandler(
          'submittingPscbSceneDisplay',
          store,
          { id, channel: channelToTakeOff, publish: false }),
        createSimpleHandler(
          'submitPscbSceneDisplaySucceeded',
          store,
          { id, channel: channelToTakeOff, publish: false, scenes }),
        createSimpleHandler(
          'submitPscbSceneDisplayFailed',
          store,
          { id, channel: channelToTakeOff, publish: false })
      )
    }
  },

  [actions.addInProgressIndicator] ({ commit }, { scope }) {
    commit(mutations.addInProgress, { scope })
  },

  [actions.releaseInProgressIndicator] ({ commit }, { scope }) {
    commit(mutations.releaseInProgress, { scope })
  },

  async [actions.connectPublisher] (store) {
    await callApi(
      async () => await publisher.connect(),
      createSubmittingConnectPublisher(store),
      createSubmitConnectPublisherSucceededHandler(store),
      createSubmitConnectPublisherFailedHandler(store)
    )
  },
  async [actions.disconnectPublisher] (store) {
    await callApi(
      async () => await publisher.disconnect(),
      createSubmittingDisconnectPublisher(store),
      createSubmitDisconnectPublisherSucceededHandler(store),
      createSubmitDisconnectPublisherFailedHandler(store)
    )
  },
  async [actions.fetchIsPublisherConnected] (store) {
    await callApi(
      async () => await publisher.isConnected(),
      createFetchingIsConnectedPublisher(store),
      createFetchIsPublisherConnectedSucceededHandler(store),
      createFetchIsPublisherConnectedFailedHandler(store)
    )
  }
}
