<template>
    <v-text-field
      v-model="value"
      persistent-hint
      single-line
      class="ma-0 px-2 py-1 align-center"
      @change="$emit('changed', value)"
      :rules="validation"
      :disabled="isDisabled"
    ></v-text-field>
</template>

<script>
import store from '@/store'
import { configuration } from '@/store/modules'

const toSetting = name => ({
  getter: configuration.globals.getters[`settings.${name}`],
  setter: configuration.globals.actions[`settings.${name}`]
})

export default {
  name: 'SettingsText',
  emits: ['changed'],
  props: {
    settingName: {
      type: String,
      required: true
    },
    validation: {
      type: Array,
      default: () => [() => true]
    }
  },
  computed: {
    value: {
      get () {
        return toSetting(this.settingName).getter(store).value
      },
      set (val) {
        toSetting(this.settingName).setter(store, val)
      }
    },
    isDisabled () {
      return toSetting(this.settingName).getter(store)['read-only']
    }
  }
}
</script>
