import { callApi, createSimpleHandler, competitions, dispatchGlobal } from '@/api'
import { chooseContext } from '@/api/authorization'
import { isNil } from 'lodash'
import { getters } from './getters'
import { mutations } from './mutations'

const contextIdLocalStorageKey = 'contextIdLocalStorageKey'

export const actions = {
  fetchCompetitions: 'fetchCompetitions',
  selectContext: 'selectContext',
  restoreContext: 'restoreContext'
}

const createFetchCompetitionsSucceededHandler = ({ dispatch, commit }) => data => {
  commit(mutations.updateCompetitions, data)
  dispatchGlobal(dispatch, 'fetchCompetitionsSucceeded')
}

export const actionsDefinition = {
  async [actions.fetchCompetitions] (store) {
    await callApi(
      async () => await competitions.listCompetitions(),
      createSimpleHandler('fetchingCompetitions', store),
      createFetchCompetitionsSucceededHandler(store),
      createSimpleHandler('fetchCompetitionsFailed', store)
    )
  },
  [actions.selectContext] ({ commit, dispatch }, competitionId) {
    chooseContext(competitionId)
    commit(mutations.updateSelectedContext, competitionId)
    localStorage.setItem(contextIdLocalStorageKey, competitionId)
    dispatchGlobal(dispatch, 'contextSelected')
  },
  [actions.restoreContext] ({ dispatch, getters: localGetters }) {
    const currentContextId = localGetters[getters.selectedContext]
    if (!isNil(currentContextId)) {
      return
    }

    const savedContext = localStorage.getItem(contextIdLocalStorageKey)
    if (!isNil(savedContext)) {
      dispatch(actions.selectContext, savedContext)
    }
  }
}
