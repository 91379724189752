export const defaultState = {
  competitions: [],
  selectedContext: null
}

export const mutations = {
  updateCompetitions: 'updateCompetitions',
  updateSelectedContext: 'updateSelectedContext'
}

export const mutationsDefinition = {
  [mutations.updateCompetitions]: (store, competitions = []) => {
    store.competitions = competitions
  },
  [mutations.updateSelectedContext] (store, selectedContext) {
    store.selectedContext = selectedContext
  }
}
