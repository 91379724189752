import { joinUri } from '../base-api'

export const toUnitsBaseUri = ({ uri = '' } = {}) => joinUri('units', uri)

export const toUri = ({ unitId, uri = '' } = {}) =>
  joinUri(
    `${toUnitsBaseUri()}/${unitId}`,
    uri)

export const toUriStatus = ({ unitId, expectedStatus, uri = '' } = {}) =>
  joinUri(
      `${toUri({ unitId })}/status/${expectedStatus}`,
      uri)

export const toPrintoutUri = ({ unitId, printoutCode, uri = '' } = {}) =>
  joinUri(
    `${toUri({ unitId })}/printouts/${printoutCode}`,
    uri)
