<template>
  <div
    v-ripple
    :class="{ 'button--disabled': disabled }"
    class='button'
    @click="!disabled && $emit('clicked', number)">
    <span class="button__caption">{{ number }}</span>
  </div>
</template>

<script>

export default {
  name: 'NumberButton',
  emits: ['clicked'],
  props: {
    number: {
      type: String,
      required: true,
      default: ' '
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/container';
@import '~@/styles/button';

@mixin number-button($disabled: false) {
  @include vcontainer('center', 'center');
  @include button($disabled, $large);
}

.button {
  @include number-button;

  user-select: none;
  &--disabled {
    @include number-button(true);
  }
}
</style>
