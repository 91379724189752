<template>
  <article>
    <div class='unit-name'>{{ selectedUnit && selectedUnit.name }}</div>
    <div v-if="selectedUnit && selectedUnit.name">
      <section
      v-for="{ name, lastGeneratedAt, errors } in displayableOdfMessages"
      :key="name"
      class="odf-message">
      <span class="odf-message__name">
        {{ name }}
        <v-checkbox
          class="odf-message__name--checkbox ma-0 ml-8"
          v-if="name === 'DT_RESULT'"
          v-model="additionalData[name].startList"
          @change="newValue => additionalData[name].startListNoCC =
            (newValue ? false : additionalData[name].startListNoCC)"
          hide-details
        >
          <template v-slot:label>
            <span class="odf-message__name--checkbox-label">As START_LIST with CC</span>
          </template>
        </v-checkbox>
        <v-checkbox
          class="odf-message__name--checkbox ma-0 ml-8"
          v-if="name === 'DT_RESULT'"
          v-model="additionalData[name].startListNoCC"
          @change="newValue => additionalData[name].startList =
            (newValue ? false : additionalData[name].startList)"
          hide-details
        >
          <template v-slot:label>
            <span class="odf-message__name--checkbox-label">As START_LIST without CC</span>
          </template>
        </v-checkbox>
      </span>
      <span class="odf-message__errors" v-if="errors && errors.length > 0">
        <v-dialog
          v-model="showErrorsDialog[name]"
          max-width="50vw"
          scrollable>
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs">
              {{ lastGeneratedAt ? 'Message generated with errors' : 'Message generation failed' }}
            </span>
          </template>
          <v-card class="odf-message__errors-card">
            <v-card-title>ODF message generation errors</v-card-title>
            <v-card-text>
              <div
                v-for="({fileName, errorMessages}, index) in errors" :key="index">
                <span class="odf-message__errors-card__filename">
                  {{ fileName }}
                </span>
                <ul>
                  <li v-for="(msg, index) in errorMessages" :key="index">
                    {{ msg }}
                  </li>
                </ul>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="showErrorsDialog[name] = false">
                Close
              </v-btn>
        </v-card-actions>
          </v-card>
        </v-dialog>
      </span>
      <section>
        <span class="odf-message__timestamp">{{ lastGeneratedAt }}</span>
        <v-btn
          class="odf-message__generate"
          color="primary"
          small
          :loading="isOdfMessageGenerationInProgress[name]"
          @click="generateOdfMessage({
            name,
            unitId: selectedUnit.id,
            additionalData: additionalData[name]
          })">
          Generate
        </v-btn>
      </section>
      </section>
    </div>
    <div v-else>
      <span>Select a unit to see ODF messages</span>
    </div>
  </article>
</template>

<script>
import OdfMessages from '@/enums/OdfMessages'
import { mapActions, mapGetters } from 'vuex'
import { olr } from '@/store/modules'
import { formatTimeStamp } from '@/components/helpers'
import { isEmpty } from 'lodash'

export default {
  name: 'OdfMessages',
  data () {
    return {
      showErrorsDialog: {},
      additionalData: {
        [OdfMessages.DT_RESULT]: {
          startList: false,
          startListNoCC: false
        }
      }
    }
  },
  watch: {
    selectedUnit: {
      handler: async function () {
        if (!isEmpty(this.selectedUnit)) {
          await this.fetchOdfMessages({ unitId: this.selectedUnit.id })
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions(
      olr.namespace,
      [
        olr.actions.fetchOdfMessages,
        olr.actions.generateOdfMessage
      ])
  },
  computed: {
    ...mapGetters(
      olr.namespace,
      [
        olr.getters.odfMessages,
        olr.getters.isOdfMessageGenerationInProgress,
        olr.getters.selectedUnit
      ]
    ),
    displayableOdfMessages () {
      return Object.keys(OdfMessages).map(message => {
        const existingMessage = this.odfMessages.find(m => m.name === message)
        return {
          name: message,
          lastGeneratedAt: formatTimeStamp(existingMessage?.lastGeneratedAt),
          errors: existingMessage?.errors
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/container";
@import "~@/styles/typology";

.unit-name {
  @include primary-bold-caption-l4;
}

.odf-message {
  @include container('justified', 'center');
  margin: 0.5rem 0;

  &__name {
    @include primary-bold-caption-l4;
    @include vcontainer('left');

    &--checkbox {
      &-label {
        @include secondary-caption-l5;
      }
    }
  }

  &__timestamp {
    @include primary-caption-l4;
    margin-right: 1rem;
  }

  &__errors {
    text-decoration: underline;
    cursor: pointer;
  }

  &__errors-card {
    max-height: 50vh;
    text-align: left;

    &__filename {
      @include primary-bold-caption-l4;
    }
  }
}
</style>
