export const getters = Object.freeze({
  competitionCoachCards: 'competitionCoachCards',
  isInProgress: 'isInProgress'
})

export const gettersDefinition = Object.freeze({
  [getters.competitionCoachCards]: ({ competitionCoachCards }) => competitionCoachCards,
  [getters.isInProgress]: ({ isInProgress }) => (element) => {
    return isInProgress[element]
  }
})
