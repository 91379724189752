<template>
  <section>
    <button
      v-ripple
      v-if="visible"
      class="button"
      @click="action">
      Edit Scores
    </button>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import { participants } from '@/store/modules'

export default {
  name: 'EditScoresButton',
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    ...mapActions(
      participants.namespace,
      [
        participants.actions.isEditMode
      ]
    ),
    async action () {
      await this.isEditMode(true)
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/container';
@import '~@/styles/button';
@import '~@/styles/variables';

.button {
  @include button($size: $small);
  float: right
}
</style>
