<template>
  <table class="sync-errors-table">
    <thead>
      <tr>
        <th>Time</th>
        <th>Votes</th>
        <th>Outcome</th>
        <th>Deduction</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="({
          timeStamp,
          elapsed,
          elapsedTooltipText,
          votes,
          deduction,
          outcome
        }, index) in synchronizationErrors"
        :key="index">
          <td class="sync-errors-table-cell">
              <div class="sync-errors-table-cell__content">
                <v-tooltip bottom>
                  <template v-slot:activator="{ attrs, on}">
                    <span v-on="on" v-bind="attrs">
                      {{ elapsed }}
                    </span>
                  </template>
                  <span style="white-space: pre-line;">
                    {{ elapsedTooltipText }}
                  </span>
                </v-tooltip>
            </div>
          </td>
          <td>
            <div class="sync-errors-table-cell__content">
              <span
                v-for="(vote, index) in votes"
                :key="index"
                class="sync-errors-votes-wrapper">
                <v-tooltip bottom v-if="vote">
                  <template v-slot:activator="{ attrs, on}">
                    <div :class="['vote', vote.mark]" v-on="on" v-bind="attrs"/>
                  </template>
                  <span style="white-space: pre-line;">
                    {{ vote.tooltipText }}
                  </span>
                </v-tooltip>
              </span>
            </div>
          </td>
          <td >
            <span
              :class="{ 'disable-click': readonly }"
              class="sync-errors-outcome-wrapper"
              @click="$emit('outcomeChanged', { timeStamp, outcome: 'Major' })">
              <div
                :class="[
                  'outcome',
                  'major',
                  { 'active': outcome === 'Major' }
                ]" />
            </span>
            <span
              :class="{ 'disable-click': readonly }"
              class="sync-errors-outcome-wrapper"
              @click="$emit('outcomeChanged', { timeStamp, outcome: 'Obvious' })">
              <div
                :class="[
                  'outcome',
                  'obvious',
                  { 'active': outcome === 'Obvious' }
                ]" />
            </span>
            <span
              :class="{ 'disable-click': readonly }"
              class="sync-errors-outcome-wrapper"
              @click="$emit('outcomeChanged', { timeStamp, outcome: 'Small' })">
              <div
                :class="[
                  'outcome',
                  'small',
                  { 'active': outcome === 'Small' }
                ]" />
            </span>
            <span
              :class="{ 'disable-click': readonly }"
              class="sync-errors-outcome-wrapper"
              @click="$emit('outcomeChanged', { timeStamp, outcome: 'Discarded' })">
              <v-icon
                :class="[
                  'discarded',
                  { 'active': outcome === 'Discarded' }
                ]" >
                mdi-cancel
              </v-icon>
            </span>
          </td>
          <td>
            <Suspense :isReady="isScoreRecalculated">{{ deduction }} pts</Suspense>
          </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Suspense from '@/components/Suspense'
import { chiefRecorder } from '@/store/modules'
import { mapGetters } from 'vuex'

export default {
  name: 'SynchronizationErrorsLog',
  props: {
    synchronizationErrors: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Suspense
  },
  emits: ['outcomeChanged'],
  computed: {
    ...mapGetters(
      chiefRecorder.namespace,
      [
        chiefRecorder.getters.isScoreRecalculated
      ]
    )
  }

}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables";

.disable-click {
  pointer-events: none;
}

.sync-errors {
  &-table {
    width: 100%;
    border-spacing: 0;

    th, td {
      padding: 0.5rem 1rem;
    }

    tbody td {
      border-top: solid thin $lightgray;
    }

    & &-cell {
      &__content {
        position: relative;
      }
    }
  }

  &-votes-wrapper {
    display: inline-block;
    min-width: calc(1.25rem + 4px);
    width: calc(1.25rem + 4px);
    height: calc(1.25rem + 4px);
    margin: 0.25rem;

    .vote {
      width: 1.25rem;
      height: 1.25rem;
      font-size: 1.25rem;
      border-radius: 50%;
      border-style: solid;
      border-width: 2px;

      &.major {
        border-color: $red;
        background-color: $pink;
      }

      &.obvious {
        border-color: $orange;
        background-color: $lightYellow;
      }

      &.small {
        border-color: $green;
        background-color: $lightergreen;
      }
    }
  }

  &-outcome-wrapper {
    display: inline-block;
    min-width: calc(1.25rem + 4px);
    width: calc(1.25rem + 4px);
    height: calc(1.25rem + 4px);
    margin: 0.25rem;
    cursor: pointer;

    .outcome {
      width: 1.25rem;
      height: 1.25rem;
      font-size: 1.25rem;
      border-radius: 4px;
      border-style: solid;
      border-width: 2px;
      opacity: 0.25;

      &.major {
        border-color: $red;
        background-color: $pink;

        &.active {
          background-color: $red;
          opacity: 1;
        }
      }

      &.obvious {
        border-color: $orange;
        background-color: $lightYellow;

        &.active {
          background-color: $orange;
          opacity: 1;
        }
      }

      &.small {
        border-color: $green;
        background-color: $lightergreen;

        &.active {
          background-color: $green;
          opacity: 1;
        }
      }
    }

    .discarded {
      color: $lightgray;
      vertical-align: 0.5rem;
      opacity: 0.25;

      &.active {
        color: $darkergray;
        opacity: 1;
      }
    }
  }
}
</style>
