export default Object.freeze({
  GENERAL: 0,
  CHIEF_RECORDER: 1,
  JUDGE: 2,
  TIMEKEEPER: 3,
  OLR_OPERATOR: 4,
  REFEREE: 5,
  MUSIC_MASTER: 6,
  VIDEO_REPLAY_OPERATOR: 7
})
