<template>
  <v-dialog
    v-model="isOpen"
    max-width="40vw">
    <template #activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs">mdi-dots-horizontal</v-icon>
    </template>
    <v-card>
      <v-card-title>Change Judged Degree of Difficulty</v-card-title>
      <v-card-text>
        <v-radio-group v-model="selection">
          <v-radio label="✓ Confirmed" color="success" value="Confirmed"></v-radio>
          <v-radio label="🗙 Base Mark" color="error" value="BaseMark"></v-radio>
          <v-radio label="Custom" value="custom">
            <template v-slot:label>
              <v-text-field
                v-model="customDD"
                :disabled="selection !== 'custom'"
                label="Custom"
                placeholder="Enter custom DD"
                min="0"
                step="0.0001"
                type="number"
                :rules="[rules.nonNegative]">
              </v-text-field>
            </template>
          </v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">Cancel</v-btn>
        <v-btn
          color="primary"
          :disabled="okDisabled || inProgress"
          :loading="inProgress"
          @click="onJudgedDegreeOfDifficultyChanged">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { chiefRecorder } from '@/store/modules'

export default {
  name: 'ChangeJudgedDegreeOfDifficulty',
  props: {
    unitId: {
      type: String,
      required: true
    },
    participantId: {
      type: String,
      required: true
    },
    elementIndex: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      inProgress: false,
      isOpen: false,
      selection: null,
      customDD: null,
      rules: {
        nonNegative: value => {
          const customDD = parseInt(value)
          if (isNaN(customDD) || customDD < 0) {
            return 'Value must be non-negative number'
          }

          return true
        }
      }
    }
  },
  methods: {
    ...mapActions(
      chiefRecorder.namespace,
      [
        chiefRecorder.actions.changeDegreeOfDifficulty
      ]
    ),
    async onJudgedDegreeOfDifficultyChanged () {
      this.inProgress = true
      const mark = this.selection === 'custom'
        ? this.customDD
        : this.selection

      await this.changeDegreeOfDifficulty({
        unitId: this.unitId,
        participantId: this.participantId,
        elementIndex: this.elementIndex,
        mark
      })

      this.inProgress = false
      this.close()
    },
    close () {
      this.isOpen = false
      this.selection = null
      this.customDD = null
    }
  },
  computed: {
    okDisabled () {
      if (!this.selection) {
        return true
      }

      if (this.selection === 'custom' && typeof this.rules.nonNegative(this.customDD) === 'string') {
        return true
      }

      return false
    }
  }
}
</script>
