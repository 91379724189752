<template>
<article class="tabbed-navigation">
  <nav class="tabbed-navigation__nav">
    <span v-for='tab in tabsNames'
      :key="tab"
      :class="classForNav(tab)"
      @click="selectTab(tab)">
      {{tab}}
    </span>
  </nav>
  <section class="tabbed-navigation__content" >
    <div
      v-for='tab in tabsNames'
      :key="tab"
      v-show='isSelectedTab(tab)'>
        <slot :name="tab"/>
    </div>
  </section>
</article>
</template>

<script>

export default {
  name: 'TabbedNavigation',
  data: () => ({
    selectedTab: ''
  }),
  props: {
    tabsNames: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    selectTab (val) {
      this.selectedTab = val
    },
    isSelectedTab (val) {
      return (this.selectedTab ? this.selectedTab : this.tabsNames[0]) === val
    },
    classForNav (val) {
      return {
        'tabbed-navigation__nav--selected': this.isSelectedTab(val)
      }
    }
  }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/container";
@import "~@/styles/variables";
@import "~@/styles/typology";

.tabbed-navigation {
  &__nav {
    @include container('left');

    margin-left: 0.5rem;

    text-align: left;

    & > span {
      border: solid $blue 0.1rem;
      padding: 0.2rem 2rem;

      cursor: pointer;
    }

    &--selected {
      @include button-caption-l4;

      background: $blue;
    }
  }

  &__content {
    @include border($radius: 0.5rem);

    border-top: solid $blue 0.5rem;
    padding: 1rem;
  }
}
</style>
