export default Object.freeze({
  fetchConfiguration: 'fetchConfiguration',
  fetchSavedScores: 'fetchSavedScores',
  fetchAllScores: 'fetchAllScores',
  updateScore: 'updateScore',
  commitScore: 'commitScore',
  clearScores: 'clearScores',
  eraseSelectedScoreOrLast10: 'eraseSelectedScoreOrLast10',
  submitScores: 'submitScores',
  selectScore: 'selectScore',
  raiseHand: 'raiseHand',
  clearScoresSubmission: 'clearScoresSubmission',
  keypadKeyPressed: 'keypadKeyPressed',
  keyboardKeyPressed: 'keyboardKeyPressed',
  setScoresSummary: 'setScoresSummary',
  fetchIsHandRaised: 'fetchIsHandRaised',
  setIsHandRaised: 'setIsHandRaised',
  updateJudgeJoinedStartTime: 'updateJudgeJoinedStartTime',
  fetchMarks: 'fetchMarks',
  updateMarkAndSubmitAll: 'updateMarkAndSubmitAll',
  clearMarks: 'clearMarks',
  updateAssistantMarkAndSubmitAll: 'updateAssistantMarkAndSubmitAll',
  updateAssistantScores: 'updateAssistantScores'
})
