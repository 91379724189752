export const mutations = Object.freeze({
  updateScore: 'updateScore',
  clearScores: 'clearScores',
  clearAndSelectScoreByPaddedIndex: 'clearAndSelectScoreByPaddedIndex',
  clearAndSelectScore: 'clearAndSelectScore',
  selectFirstEmptyScore: 'selectFirstEmptyScore',
  selectNextScore: 'selectNextScore',
  selectPreviousScore: 'selectPreviousScore',
  updateConfiguration: 'updateConfiguration',
  markScoresSubmission: 'markScoresSubmission',
  overwriteScores: 'overwriteScores',
  updateScoresSummary: 'updateScoresSummary',
  storeKeypadValue: 'storeKeypadValue',
  clearKeypadValue: 'clearKeypadValue',
  eraseCurrentScoreOrLast10: 'eraseCurrentScoreOrLast10',
  isSubmitScorePending: 'isSubmitScorePending',
  addKeystroke: 'addKeystroke',
  removeTailKeystroke: 'removeTailKeystroke',
  commitScoreAndAdvance: 'commitScoreAndAdvance',
  tryCommittingIncompleteScore: 'tryCommittingIncompleteScore',
  setIsHandRaised: 'setIsHandRaised',
  updateJudgeJoinedStartTime: 'updateJudgeJoinedStartTime',
  updateDtcScores: 'updateDtcScores',
  updateDtcScore: 'updateDtcScore',
  setInProgress: 'setInProgress',
  updateAssistantScore: 'updateAssistantScore'
})
