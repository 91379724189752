import { forOwn, isArray, isBoolean, isEmpty, isFunction, isNil, isNumber, keys, noop, partition, pick, pullAll } from 'lodash'
import { validateObject } from '../base-api'

const invokeValidator = (validatorDefinition, ...args) => {
  const validator = isValidator(validatorDefinition)
    ? validatorDefinition
    : validatorDefinition.validator
  return validator(...args)
}

const validateRequiredPropertiesExistAndNotEmpty = (
  requiredProperties,
  object
) => validateObject(...requiredProperties)(object)

const runAllValidations = (validators, object) => {
  forOwn(validators, (validator, key) =>
    invokeValidator(validator, object[key])
  )
}

const validateRequiredPropertiesExist = (properties = [], data = {}) => {
  const missingProperties = pullAll(properties, keys(data)) || []

  if (missingProperties.length > 0) {
    throw Error(
      `Received data doesn't match schema. Expected props: [${properties}]` +
        ` on object: ${JSON.stringify(data)}`
    )
  }
}

const validateObjectBy = validators => object => {
  const expectedProperties = keys(validators)
  const [requiredProperties, optionalProperties] = partition(
    expectedProperties,
    property =>
      !validators[property].isOptional && !validators[property].allowEmpty
  )
  const filteredObject = validateRequiredPropertiesExistAndNotEmpty(
    requiredProperties,
    object
  )
  validateRequiredPropertiesExist(
    expectedProperties.filter(property => validators[property].allowEmpty),
    object
  )
  runAllValidations(validators, object)

  return {
    ...filteredObject,
    ...pick(object, optionalProperties)
  }
}

const isValidator = isFunction
const expectToBe = validatorFactory => fieldName => {
  const result = {
    [fieldName]: validatorFactory(fieldName)
  }
  if (!isValidator(result[fieldName])) {
    const { isOptional = false, allowEmpty = false, validator } = result[fieldName]
    result[fieldName] = {
      isOptional,
      allowEmpty,
      validator
    }
  }

  return result
}
const throwExpect = (fieldName, value, expectation) => {
  throw Error(
    `Expected '${fieldName}' to be a ${expectation} but got ` +
      `${value} (${typeof value}`
  )
}

const or = (...validators) => value => {
  const errors = []
  for (const validator of validators) {
    try {
      validator(value)
    } catch (e) {
      errors.push(e)
    }
  }

  if (errors.length === validators.length && errors.length > 0) {
    throw Error(errors.join('\n-- or --\n'))
  }
}

const expectToBeNumber = fieldName => value => {
  if (!isNumber(value)) {
    throwExpect(fieldName, value, 'number')
  }
}
const expectFieldToBeNumber = expectToBe(expectToBeNumber)
const expectFieldToBeBoolean = expectToBe(fieldName => value => {
  if (!isBoolean(value)) {
    throwExpect(fieldName, value, 'boolean')
  }
})
const expectFieldNotToBeEmpty = expectToBe(() => noop)
const fromOptions = option => {
  const { validator = option, method = validateObjectBy, ...options } =
    option || {}
  return {
    ...options,
    validator,
    method
  }
}

const expectFieldToBeArrayOf = (fieldName, itemValidatorConfiguration) =>
  expectToBe(fieldName => {
    const { isOptional, validator, allowEmpty, method } = fromOptions(
      itemValidatorConfiguration
    )
    return {
      isOptional,
      allowEmpty,
      validator: value => {
        if (isOptional && isNil(value)) {
          return
        }

        if (!isArray(value)) {
          throw Error(
            `Expected '${fieldName}' to be an array but got ${typeof value}`
          )
        }

        if (!allowEmpty && isEmpty(value)) {
          throw Error(`Expected '${fieldName}' not to be an empty array`)
        }

        const itemValidator = method(validator)
        value.forEach((item, index) => {
          try {
            itemValidator(item)
          } catch (e) {
            const { message: msg } = e
            throw Error(
              `Item ${fieldName}[${index}] failed validation with message: ${msg}`
            )
          }
        })
      }
    }
  })(fieldName)
const validateUnit = validateObject('id', 'scheduledDateStart', 'status', 'name')
const aiPanelValidationConfig = {
  ...expectFieldToBeNumber('id'),
  ...expectFieldNotToBeEmpty('name'),
  ...expectFieldToBeNumber('seatsCount'),
  ...expectFieldToBeArrayOf('parts', {
    ...expectFieldNotToBeEmpty('name')
  })
}
const elementsPanelValidationConfig = {
  ...expectFieldToBeNumber('id'),
  ...expectFieldNotToBeEmpty('name'),
  ...expectFieldToBeNumber('seatsCount'),
  ...expectFieldToBeArrayOf('parts', {
    isOptional: true,
    validator: {
      ...expectFieldNotToBeEmpty('name'),
      ...expectFieldToBeBoolean('isTransition')
    }
  })
}

export const validateJudgeConfiguration = validateObjectBy({
  panel: or(
    validateObjectBy(aiPanelValidationConfig),
    validateObjectBy(elementsPanelValidationConfig)),
  unit: validateUnit,
  ...expectFieldToBeNumber('seat')
})
export const validateChiefRecorderConfiguration = validateObjectBy({
  unit: validateUnit,
  panels: validateObjectBy({
    artisticImpression: validateObjectBy({
      ...aiPanelValidationConfig,
      ...expectFieldToBeNumber('allocatedSeatsCount')
    }),
    elements: validateObjectBy({
      ...elementsPanelValidationConfig,
      ...expectFieldToBeNumber('allocatedSeatsCount')
    })
  })
})
