import { get } from 'lodash'

const mutations = {
  setTimers (state, timers) {
    state.timers = timers
  },
  setAll (state, timers) {
    state.timers = [
      {
        start: get(timers, 'entry.start'),
        stop: get(timers, 'entry.stop')
      },
      {
        start: get(timers, 'deck.start'),
        stop: get(timers, 'deck.stop')
      },
      {
        start: get(timers, 'routine.start'),
        stop: get(timers, 'routine.stop')
      }
    ]
  },
  resetTimers (state) {
    state.timers = []
  }
}

export default mutations
