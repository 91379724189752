import { invokeMap, isNil, keys } from 'lodash'

export const retrieveCancellationTokenForBase =
({ store, payload, moduleMapping, shouldReturnExistingToken }) => {
  const { getters, commit } = store
  if (isNil(getters) || isNil(commit)) {
    throw Error('Invalid invocation - valid store required')
  }

  const contexts = keys(payload)
  if (contexts?.length !== 1) {
    throw Error('Invalid invocation - required object with single property')
  }
  const contextName = contexts[0]

  const allContexts = getters[moduleMapping.getters.getAll] || {}
  const context = allContexts[contextName] || {}
  const requestedId = payload[contextName]
  const token = context[requestedId]
  if (token && shouldReturnExistingToken) {
    return token
  }

  const newToken = new AbortController()
  commit(moduleMapping.mutations.replaceContext, {
    [contextName]: {
      [requestedId]: newToken
    }
  })
  invokeMap(context, 'abort')
  return newToken
}
