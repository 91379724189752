<template>
    <v-slider
      v-model="value"
      class="align-center px-2"
      :max="max"
      :min="min"
      :disabled="isDisabled"
      @change="$emit('changed', value)">
      <template v-slot:prepend>
        <v-text-field
          v-model.number="value"
          :hint="units"
          persistent-hint
          single-line
          style="width: 60px"
          class="ma-0 pa-0 align-center"
          type="number"
          :disabled="isDisabled"
        ></v-text-field>
      </template>
    </v-slider>
</template>

<script>
import store from '@/store'
import { configuration } from '@/store/modules'

const toSetting = name => ({
  getter: configuration.globals.getters[`settings.${name}`],
  setter: configuration.globals.actions[`settings.${name}`]
})

export default {
  name: 'SettingsSlider',
  emits: ['changed'],
  props: {
    settingName: {
      type: String,
      required: true
    },
    units: {
      type: String,
      default: () => ''
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    }
  },
  computed: {
    value: {
      get () {
        return toSetting(this.settingName).getter(store).value
      },
      set (val) {
        toSetting(this.settingName).setter(store, val)
      }
    },
    isDisabled () {
      return toSetting(this.settingName).getter(store)['read-only']
    }
  }
}
</script>

<style lang="scss" scoped>
td {
  vertical-align: center;
  text-align: left;
}
</style>
