import keypadActions, { SpecialKeys } from './keypad'
import otherActions from './other-actions'
import difficultyTchnicalControllerActions from './difficulty-technical-controller'
import actions from './names'

export { actions, SpecialKeys }
export const actionsDefinition = {
  ...keypadActions,
  ...otherActions,
  ...difficultyTchnicalControllerActions
}
