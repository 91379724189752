import { validateObject } from '../base-api'

export const validateParticipant = object => {
  const { totalScore, irm, startOrder, rank, ...participant } = object
  const { status, ...rest } = validateObject(
    'id',
    'members',
    'noc',
    'status',
    'hasCoachCard'
  )(participant)

  return {
    ...rest,
    status: status.toLowerCase(),
    totalScore,
    irm,
    startOrder,
    rank
  }
}
