import { httpget, httpput } from '../base-api'
import { get, mapValues } from 'lodash'
import { toUri } from './base'

const toTimersUri = ({ unitId, participantId }) =>
  toUri({
    unitId,
    participantId,
    uri: 'timers'
  })
const toUtcString = value => value ? `${value}Z` : value

export default {
  async get ({ unitId, participantId }) {
    const result = await httpget(
      toTimersUri({ unitId, participantId }),
      { allow: [404] })

    return {
      entry: mapValues(get(result, 'entry', {}), toUtcString),
      deck: mapValues(get(result, 'deck', {}), toUtcString),
      routine: mapValues(get(result, 'routine', {}), toUtcString)
    }
  },

  put: async ({ unitId, participantId, payload }) => await httpput(
    toTimersUri({ unitId, participantId }),
    {
      entry: {
        start: get(payload, 'entry.start', null),
        stop: get(payload, 'entry.stop', null)
      },
      deck: {
        start: get(payload, 'deck.start', null),
        stop: get(payload, 'deck.stop', null)
      },
      routine: {
        start: get(payload, 'routine.start', null),
        stop: get(payload, 'routine.stop', null)
      }
    })
}
