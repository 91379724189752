import Events from '@/enums/Events'
import ParticipantStatus from '@/enums/ParticipantStatus'
import { Container } from '@/router/base'
import store from '@/store'
import { refereeListeners } from '@/store/actionListeners'
import { registerActionListeners } from '@/store/base/store-listeners'
import { routine, unit } from '@/store/modules'
import Referee from '@/views/Referee'
import RefereeWaiting from '@/views/RefereeWaiting.vue'
import { get } from 'lodash'

const refereeSubRoutes = Object.freeze({
  [ParticipantStatus.Performing]: {
    path: '/referee/read-only',
    enterWhenEvent: Events.ROUTINE_STARTED
  },
  [ParticipantStatus.Assessed]: {
    path: '/referee/read-only',
    enterWhenEvent: Events.ROUTINE_STARTED
  }
})

const routes = [
  {
    path: '/referee',
    component: Container,
    name: 'referee',
    children: [
      { path: 'wait', component: RefereeWaiting, name: 'referee' },
      { path: 'read-only', component: Referee, name: 'referee' }
    ],
    async beforeEnter (to, from, next) {
      registerActionListeners({ store, subscribers: refereeListeners })
      routine.globals.actions.setGetLastAssessedIfCurrentMissing(store, true)
      await unit.globals.actions.fetchCurrent(store)
      handleRoutineStatus(to, next)
    }
  }
]

export default {
  routes
}

function handleRoutineStatus (to, next) {
  const status = routine.globals.getters.status(store)
  // todo https://dev.azure.com/SWTIDEVEXTERNAL/SWA-OLR/_workitems/edit/3555
  if (status === undefined) {
    setTimeout(() => handleRoutineStatus(to, next), 200)
  } else {
    const nextPage = get(
      refereeSubRoutes[status],
      'path',
      '/referee/wait')

    if (nextPage === to.path) {
      next()
    } else {
      next(nextPage)
    }
  }
}
