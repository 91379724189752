import { mutationsDefinition, defaultState } from './mutations'
import { actionsDefinition } from './actions'
import { gettersDefinition } from './getters'

const judgeModule = {
  namespaced: true,
  state: () => defaultState,
  getters: gettersDefinition,
  mutations: mutationsDefinition,
  actions: actionsDefinition
}

export default judgeModule
export { getters } from './getters'
export { actions, SpecialKeys } from './actions'
export { subscribe } from './action-listeners'
