import { isEmpty, isFunction, isNil } from 'lodash'
import { VUE_APP_FRONTEGG_CLIENT_ID, VUE_APP_FRONTEGG_URI } from './fronteggConsts'
import RoleToMenuItemMap from '@/maps/RoleToMenuItemMap'

const adminRoles = ['owner', 'co-owner', 'admin']
const isAdminRole = roleKey => adminRoles.includes(roleKey)

const addAllRolesKeys = rolesSet =>
  Object.keys(RoleToMenuItemMap).forEach(key => rolesSet.add(key))

export const userRoles = user => {
  const roles = user?.roles
  if (isEmpty(roles)) {
    return []
  }

  const roleKeys = roles
    .reduce((effectiveRoles, { key }) => {
      if (isAdminRole(key)) {
        addAllRolesKeys(effectiveRoles)
      } else {
        effectiveRoles.add(key)
      }

      return effectiveRoles
    }, new Set())

  return [...roleKeys]
    .filter(roleKey => !isNil(RoleToMenuItemMap[roleKey]))
    .map(key => RoleToMenuItemMap[key])
}

export const isAdmin = user => {
  const olrRoleKey = 'olr-operator'
  const roles = userRoles(user)
  return roles.some(r => r.code === olrRoleKey)
}

export const accessToken = user => user?.accessToken

const hasRequiredRole = (user, requiredRoles) => {
  const roles = userRoles(user)
  return roles?.some(r => requiredRoles.includes(r.code))
}

export const setupAuthorization = ({ component, requiredRoles } = {}) => {
  if (isNil(component) || isNil(requiredRoles)) {
    return
  }

  return {
    ...component.mapAuthState(),
    requiredRoles,
    authorized: false
  }
}

export const authorize = ({ component, additionalCheck }) => {
  if (isNil(component)) {
    return false
  }

  if (!hasRequiredRole(component.authState?.user, component.requiredRoles)) {
    component.$router.push('/')
    return false
  }

  if (isFunction(additionalCheck) && !additionalCheck()) {
    component.$router.push('/')
    return false
  }

  component.authorized = true
  return true
}

export const fronteggOptions = {
  contextOptions: {
    baseUrl: VUE_APP_FRONTEGG_URI,
    clientId: VUE_APP_FRONTEGG_CLIENT_ID
  },
  authOptions: {
    keepSessionAlive: true
  },
  hostedLoginBox: true
}
