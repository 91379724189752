import PanelId from '@/enums/PanelId'
import { get, isEmpty, isNil, isUndefined, omitBy, take, zipWith, merge } from 'lodash'
import { expectedActualScoresCount, selectedScoreHadValueBeforehand, isScorePart, isTransitionPart, parts, selectedScore, selectedScoreValueBeforeSelection, selectedScoreHasNoValue, hasScoreCachedValue } from './shared'

export const getters = Object.freeze({
  paddedScores: 'paddedScores',
  scores: 'scores',
  selectedScore: 'selectedScore',
  selectedScoreValueBeforeSelection: 'selectedScoreValueBeforeSelection',
  scoresSummary: 'scoresSummary',
  panelBasedIdentifier: 'panelBasedIdentifier',
  panelInfo: 'panelInfo',
  unitName: 'unitName',
  isReadyForSubmission: 'isReadyForSubmission',
  isSubmitPending: 'isSubmitPending',
  hasAlreadySubmitted: 'hasAlreadySubmitted',
  isSyncJudge: 'isSyncJudge',
  isDTCJudge: 'isDTCJudge',
  isDATCJudge: 'isDATCJudge',
  isJudge: 'isJudge',
  lowestScoreAllowed: 'lowestScoreAllowed',
  highestScoreAllowed: 'highestScoreAllowed',
  keypadState: 'keypadState',
  isEraseAllowed: 'isEraseAllowed',
  isClearAllowed: 'isClearAllowed',
  keypadMode: 'keypadMode',
  isHandRaised: 'isHandRaised',
  startTime: 'startTime',
  coachCardItemsWithMarks: 'coachCardItemsWithMarks',
  marksAlignedToCoachCardParts: 'marksAlignedToCoachCardParts',
  maxFamiliesSize: 'maxFamiliesSize',
  maxBonusesSize: 'maxBonusesSize',
  inProgress: 'inProgress',
  assistantCoachCardItemsWithMarks: 'assistantCoachCardItemsWithMarks',
  assistantMarksAlignedToCoachCardParts: 'assistantMarksAlignedToCoachCardParts',
  datcCount: 'datcCount'
})

const nullBool = (value, ifTrue, ifFalse) => {
  if (value === null) {
    return null
  }

  return value === true ? ifTrue : ifFalse
}

const joinScoresWithParts = (scores = [], store) => {
  const allParts = parts(store)
  const result = []
  let s = 0
  let p = 0
  const pushPart = (p, s) =>
    result.push(
      omitBy(
        {
          label: allParts[p].name,
          isTransition: allParts[p].isTransition,
          value: scores[s],
          order: allParts[p].order
        },
        isUndefined
      )
    )
  while (s < scores.length || p < allParts.length) {
    if (isScorePart(allParts[p])) {
      pushPart(p++, s++)
      continue
    }

    if (isTransitionPart(allParts[p])) {
      pushPart(p++, s)
      continue
    }

    throw Error(
      'Failed to join scores with panel parts. Ended up with some ' +
        `leftovers: ${scores.length - s} in scores and ` +
        `${allParts.length - p} in parts.`
    )
  }

  return result
}

const getScores = ({ scores }) => scores

const isReadyForSubmission = ({
  scores = [],
  scoresSubmissionTimestamp,
  selectedScoreIndex,
  ...store
}) =>
  scores.length === expectedActualScoresCount(store) &&
  (selectedScoreIndex === null || hasScoreCachedValue(store)) &&
  scoresSubmissionTimestamp === null

const mapScore = (part, score) => {
  if (part.isTransition) {
    return null
  }

  return score || 'confirmed'
}

const getCoachCardItemsWithMarks = (
  { assignedPanel = {}, dtcScores = {} } = {},
  maxFamiliesSize,
  maxBonusesSize) => {
  const { parts = [] } = assignedPanel

  if (isEmpty(parts)) {
    return []
  }

  return take(
    zipWith(
      parts,
      dtcScores.difficultyTechnicalControllerFeedback,
      getAssistantsDifficultyTechnicalControllerFeedbackMerged(dtcScores),
      ({ families, bonuses, ...part } = {}, score, assistantScores) => ({
        families: merge(new Array(maxFamiliesSize).fill(null), families),
        bonuses: merge(new Array(maxBonusesSize).fill(null), bonuses),
        ...part,
        score: mapScore(part, score),
        assistantScores
      })
    ),
    parts.length
  )
}

const getAssistantCoachCardItemsWithMarks = (
  { assignedPanel = {}, dtcScores = {} } = {},
  maxFamiliesSize,
  maxBonusesSize) => {
  const { parts = [], seat } = assignedPanel

  if (isEmpty(parts)) {
    return []
  }

  return take(
    zipWith(
      parts,
      (dtcScores.assistantsDifficultyTechnicalControllerFeedback &&
        dtcScores.assistantsDifficultyTechnicalControllerFeedback[seat - 1]) ?? [],
      ({ families, bonuses, ...part } = {}, score) => ({
        families: merge(new Array(maxFamiliesSize).fill(null), families),
        bonuses: merge(new Array(maxBonusesSize).fill(null), bonuses),
        ...part,
        score: mapScore(part, score)
      })
    ),
    parts.length
  )
}

function getAssistantsDifficultyTechnicalControllerFeedbackMerged (dtcScores) {
  return dtcScores.assistantsDifficultyTechnicalControllerFeedback?.reduce(
    (accumulator, assistantScores, seatIndex) => {
      assistantScores.forEach((value, index) => {
        if (isEmpty(accumulator) || isEmpty(accumulator[index])) {
          accumulator[index] = [null, null]
        }
        accumulator[index][seatIndex] = value
      })

      return accumulator
    },
    []
  )
}

export const gettersDefinition = Object.freeze({
  [getters.paddedScores] (store) {
    return joinScoresWithParts(getScores(store), store)
  },

  [getters.selectedScore]: selectedScore,
  [getters.selectedScoreValueBeforeSelection]: selectedScoreValueBeforeSelection,
  [getters.scoresSummary]: ({ summary }) => summary,
  [getters.scores]: getScores,

  [getters.panelBasedIdentifier]: ({ assignedPanel: { id, seat } = {} }) => ({
    id,
    seat
  }),

  [getters.unitName]: store => get(store, 'routineInfo.unitName'),

  [getters.isReadyForSubmission]: isReadyForSubmission,
  [getters.isSubmitPending]: ({ requests: { submitScores } }) =>
    submitScores === true,

  [getters.hasAlreadySubmitted]: ({ scoresSubmissionTimestamp }) =>
    !isNil(scoresSubmissionTimestamp),

  [getters.panelInfo]: ({ assignedPanel: { name, seat } = {} } = {}) => ({
    name,
    seat
  }),

  [getters.isSyncJudge]: ({ assignedPanel: { id } }) =>
    id === PanelId.Synchronization,

  [getters.isDTCJudge]: ({ assignedPanel: { id } }) =>
    id === PanelId.DifficultyTechnicalController,

  [getters.isDATCJudge]: ({ assignedPanel: { id } }) =>
    id === PanelId.DifficultyAssistantTechnicalController,

  [getters.isJudge]: ({ assignedPanel: { id } }) =>
    id > 0,

  [getters.lowestScoreAllowed]: store => store?.assignedPanel?.minScore,
  [getters.highestScoreAllowed]: store => store?.assignedPanel?.maxScore,
  [getters.keypadState]: store => store?.keypad?.value || '',
  [getters.isClearAllowed]: store =>
    isReadyForSubmission(store) ||
    !isEmpty(
      getScores(store).filter((_, index) => index !== store.selectedScoreIndex)
    ),
  [getters.isEraseAllowed]: store =>
    nullBool(
      selectedScoreHadValueBeforehand(store) ||
      !selectedScoreHasNoValue(store) ||
        (selectedScoreHasNoValue(store) &&
          store?.keypad?.isLatelyCompletedScoreEqual10 === true),
      true,
      false
    ),
  [getters.keypadMode]: store =>
    nullBool(
      isReadyForSubmission(store) || selectedScoreHasNoValue(store),
      'whole',
      'fraction'
    ),
  [getters.isHandRaised]: ({ isHandRaised }) => isHandRaised,
  [getters.startTime]: ({ startTime }) => startTime,
  [getters.coachCardItemsWithMarks]: (store, { maxFamiliesSize, maxBonusesSize }) => {
    return getCoachCardItemsWithMarks(store, maxFamiliesSize, maxBonusesSize)
  },
  [getters.assistantCoachCardItemsWithMarks]: (store, { maxFamiliesSize, maxBonusesSize }) => {
    return getAssistantCoachCardItemsWithMarks(store, maxFamiliesSize, maxBonusesSize)
  },
  [getters.marksAlignedToCoachCardParts]: (store, { maxFamiliesSize, maxBonusesSize }) => {
    return getCoachCardItemsWithMarks(store, maxFamiliesSize, maxBonusesSize)
      .map(({ score }) => score)
  },
  [getters.assistantMarksAlignedToCoachCardParts]: (store, { maxFamiliesSize, maxBonusesSize }) => {
    return getAssistantCoachCardItemsWithMarks(store, maxFamiliesSize, maxBonusesSize)
      .map(({ score }) => score)
  },
  [getters.maxFamiliesSize]: ({ assignedPanel: { parts = [] } = {} }) =>
    Math.max(0, ...parts.map(p => p.families?.length || 0)),
  [getters.maxBonusesSize]: ({ assignedPanel: { parts = [] } = {} }) =>
    Math.max(0, ...parts.map(p => p.bonuses?.length || 0)),
  [getters.inProgress]: ({ inProgress }) => inProgress,
  [getters.datcCount]: ({ datcCount }) => datcCount
})
