<template>
  <v-app class="app" v-if="fronteggLoaded">
  <v-main>
    <router-view v-if="authState.isAuthenticated && !connecting"/>
    <div
      v-else-if="authState.isAuthenticated && connecting"
      class="loading">
      <v-progress-circular indeterminate color="primary" :size="100"/>
    </div>
    <Login v-else @loginButtonClicked="onLoginButtonClicked" />
  </v-main>
  </v-app>
</template>

<script>
import { connectToWebsocket, setToken } from '@/api'
import Login from '@/components/Login'
import { configuration, unit } from '@/store/modules'
import Events from '@/enums/Events'
import EventsMap from '@/maps/EventsMap'
import { mapActions } from 'vuex'
import { mapLoginActions } from '@frontegg/vue'

export default {
  name: 'App',
  data () {
    return {
      ...this.mapAuthState(),
      connecting: true
    }
  },
  components: {
    Login
  },
  watch: {
    async authState (value) {
      const token = value.user?.accessToken
      setToken(token)

      if (value.isAuthenticated && !value.tenantsState.loading) {
        try {
          await connectToWebsocket({
            setup: async connection => {
              connection.on(EventsMap[Events.UNIT_STARTED], async () =>
                await this.fetchCurrent()
              )
            },
            onconnect: async () => {
              await Promise.allSettled([
                this.fetchCurrent(),
                this.fetchConfiguration()
              ])
            }
          })
        } finally {
          this.connecting = false
        }
      }
    }
  },
  methods: {
    ...mapActions(
      unit.namespace,
      [
        unit.actions.fetchCurrent
      ]
    ),
    ...mapActions(
      configuration.namespace,
      [
        configuration.actions.fetchConfiguration
      ]
    ),
    login: mapLoginActions('loginWithRedirect'),
    onLoginButtonClicked () {
      this.login()
    }
  }
}
</script>

<style lang="scss">
@use "~@/styles";
@import '~@/styles/container';

.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 1rem;
  text-align: center;

  @include styles.media('lg-only') {
    padding: 1rem 10vh;
  }

  @include styles.media('xl-only') {
    padding: 1rem 10vh;
  }

  .loading {
    @include container("center", "center");
    width: 100%;
    height: 100%;
  }
}
</style>
