import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Suspense',{attrs:{"isReady":!_vm.isInProgress("fetchingAllParticipants")}},[_c('ol',{staticClass:"start-list"},_vm._l((_vm.displayableParticipants),function(ref){
      var id = ref.id;
      var status = ref.status;
      var scoreOrIrm = ref.scoreOrIrm;
      var irmOptions = ref.irmOptions;
      var hasCoachCard = ref.hasCoachCard;
      var rank = ref.rank;
      var startOrder = ref.startOrder;
      var members = ref.members;
      var noc = ref.noc;
return _c('li',{key:id,staticClass:"start-list__item",class:{ 'start-list__item--selected': _vm.selectedParticipant && _vm.selectedParticipant.id === id },on:{"click":function($event){return _vm.onParticipantSelected(id)}}},[_c('span',{staticClass:"start-list-item__participant-details"},[_vm._v(" "+_vm._s(startOrder)+". "),_c('b',[_vm._v(_vm._s(noc))]),_vm._v(" "+_vm._s(members)+" - ("+_vm._s(status)+") ")]),_c('span',{staticClass:"start-list-item__participant-score-or-irm"},[(!hasCoachCard)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var attrs = ref.attrs;
      var on = ref.on;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"missing-cc-icon"},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}],null,true)},[_vm._v(" Coach card is missing ")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(rank ? ("[" + rank + "]") : '')+" "+_vm._s(scoreOrIrm)+" ")]),_c('DropDownMenu',{attrs:{"disabled":status === 'performing',"options":irmOptions},on:{"optionSelected":function($event){return _vm.onIrmOptionSelected($event, id)}}})],1)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }