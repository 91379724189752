<template>
  <div v-if="authorized" class="home">
    <go-to-current-participant-button/>
    <Menu :authState="authState" skipRole="difficulty-technical-controller"/>
    <panel-info :unitName="currentUnit.name" :panel="panelInfo" hideSeat/>
    <participant-info :participant="participant"/>
    <difficulty-technical-controller-pane/>
  </div>
</template>

<script>
import Menu from '@/components/Menu'
import PanelInfo from '@/components/PanelInfo'
import ParticipantInfo from '@/components/ParticipantInfo'
import GoToCurrentParticipantButton from '@/components/GoToCurrentParticipantButton'
import DifficultyTechnicalControllerPane from '@/components/difficulty-technical-controller/DifficultyTechnicalControllerPane'
import { routine, unit, judge } from '@/store/modules'
import { mapGetters, mapActions } from 'vuex'
import { authorize, setupAuthorization } from '@/services/auth-service'
import { listenOn } from '@/api'
import Events from '@/enums/Events'
import GroupNames from '@/enums/GroupNames'
import EventsMap from '@/maps/EventsMap'
import { isNil } from 'lodash'

export default {
  name: 'DifficultyTechnicalController',
  components: {
    Menu,
    PanelInfo,
    ParticipantInfo,
    DifficultyTechnicalControllerPane,
    GoToCurrentParticipantButton
  },
  data () {
    return {
      stopListeningHandler: null,
      isDestroyed: false,
      ...setupAuthorization({ component: this, requiredRoles: ['judge'] })
    }
  },
  async created () {
    if (authorize({
      component: this,
      additionalCheck: () => !isNil(this.panelBasedIdentifier.id)
    })) {
      this.stopListeningHandler = await listenOn({
        isDestroyed: () => this.isDestroyed,
        group: GroupNames.JUDGE,
        events: {
          [EventsMap[Events.DTAC_SCORE_SUBMITTED]]:
            async ({ seat, judgedDegreeOfDifficultyCard }) => {
              this.updateAssistantScores({ seat, judgedDegreeOfDifficultyCard })
            }
        }
      })
    }
  },
  async beforeDestroy () {
    this.isDestroyed = true
    await this.stopListeningHandler?.()
  },
  computed: {
    ...mapGetters(
      routine.namespace,
      [
        routine.getters.participant
      ]
    ),
    ...mapGetters(
      unit.namespace,
      [
        unit.getters.currentUnit
      ]
    ),
    ...mapGetters(
      judge.namespace,
      [
        judge.getters.panelInfo,
        judge.getters.panelBasedIdentifier
      ]
    )
  },
  methods: {
    ...mapActions(
      judge.namespace,
      [
        judge.actions.updateAssistantScores
      ]
    )
  }
}
</script>
