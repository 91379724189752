<template>
  <section class="schedule-event-unit">
    <span
      class="schedule-event-unit__name">
        {{ name }}
    </span>
    <span
      class="schedule-event-unit__delimiter schedule-event-unit__delimiter--name">
      :
    </span>
    <input
      v-model="selectedTimeStart"
      class="schedule-event-unit__date"
      list="times"
      type="time"
      @change="$emit('update:timeStart', $event.target.value)"/>
    <span
      class="schedule-event-unit__delimiter">
      &mdash;
    </span>
    <input
      v-model="selectedTimeEnd"
      class="schedule-event-unit__date"
      list="times"
      type="time"
      :min="minTimeEnd"
      @change="$emit('update:timeEnd', $event.target.value)"/>
    <datalist id="times">
      <option
        v-for="time in quarterHours"
        :key="time"
        :value="time"/>
    </datalist>
  </section>
</template>

<script>
const formatTime = (hours, minutes) => {
  const hoursFormatted = `${hours}`.padStart(2, '0')
  const minutesFormatted = `${minutes}`.padStart(2, '0')

  return `${hoursFormatted}:${minutesFormatted}:00`
}

export default {
  name: 'ScheduleWizardUnit',
  emits: ['update:time'],
  props: {
    name: {
      type: String,
      default: ''
    },
    timeStart: {
      type: String,
      default: ''
    },
    timeEnd: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selectedTimeStart: this.timeStart,
      selectedTimeEnd: this.timeEnd
    }
  },
  watch: {
    timeStart (newValue) {
      this.selectedTimeStart = newValue
    },
    timeEnd (newValue) {
      this.selectedTimeEnd = newValue
    }
  },
  computed: {
    quarterHours () {
      const quartersInAnHour = 4
      const minutesInAQuarter = 15
      const hoursInADay = 24
      return Array(quartersInAnHour * hoursInADay)
        .fill(0)
        .map((_, quartersPassed) => {
          const hours = Math.floor(quartersPassed / quartersInAnHour)
          const minutes = (quartersPassed % quartersInAnHour) * minutesInAQuarter

          return formatTime(hours, minutes)
        })
    },
    minTimeEnd () {
      if (this.selectedTimeStart === '23:59') {
        return '23:59:59'
      }

      let [hours, minutes] = this.selectedTimeStart.split(':').map(x => parseInt(x))
      minutes++

      if (minutes === 60) {
        minutes = 0
        hours++
      }

      return formatTime(hours, minutes)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

.schedule-event-unit {
  &__delimiter {
    padding: 0 0.5rem;

    &--name {
      padding-left: 0;
    }
  }

  &__date:invalid {
    color: $red;
  }
}
</style>
