import { expectedScoresCount, parts } from '@/store/judge/shared'
import { mutations } from './names'

export const configurationDefaultState = Object.freeze({
  assignedPanel: {
    name: '',
    seat: 0,
    size: 0,
    parts: []
  },
  routineInfo: {
    unitName: null
  },
  startTime: null
})

const partition = (predicate, collection) => {
  const successes = []
  const failures = []
  collection.forEach((item, index) =>
    (predicate(item) ? successes : failures).push(index)
  )
  return [successes, failures]
}

const buildScoresMap = store => {
  const [scores] = partition(
    ({ isTransition }) => isTransition !== true,
    parts(store)
  )

  const reversedScores = Array.from(expectedScoresCount(store), () => -1)
  scores.forEach((paddedScoreIndex, scoreIndex) => {
    reversedScores[paddedScoreIndex] = scoreIndex
  })
  return [scores, reversedScores]
}

export const configurationMutations = Object.freeze({
  [mutations.updateConfiguration] (store, payload) {
    store.assignedPanel = {
      id: payload?.panel?.id,
      name: payload?.panel?.name,
      seat: payload?.seat,
      size: payload?.panel?.seatsCount,
      parts: payload?.panel?.parts?.map(({
        name,
        elementType,
        isTransition,
        bonuses,
        degreeOfDifficulty,
        families,
        order
      }) => ({
        name,
        elementType,
        isTransition,
        bonuses,
        degreeOfDifficulty,
        families,
        order
      })),
      minScore: 0,
      maxScore: 10
    }
    store.routineInfo = {
      unitName: payload?.unit?.name
    }

    const [scoresMap, scoresReverseMap] = buildScoresMap(store)
    store.scoresReverseMap = scoresReverseMap
    store.scoresMap = scoresMap
  },
  [mutations.updateJudgeJoinedStartTime] (store) {
    store.startTime = Date.now()
  }
})
