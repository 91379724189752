import OlrOperator from '@/views/OlrOperator'
import store from '@/store'
import { olrListeners } from '@/store/actionListeners'
import { registerActionListeners } from '@/store/base/store-listeners'

export default {
  routes: [
    {
      path: '/olr-operator',
      name: 'olr',
      component: OlrOperator,
      async beforeEnter (to, from, next) {
        registerActionListeners({ store, subscribers: olrListeners })
        next()
      }
    }
  ]
}
