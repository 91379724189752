<template>
  <DropDownMenu
    :options='options'
    :disabledOptions="disabledOptions"
    @optionSelected="onOptionSelected">
    <template #activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs">mdi-dots-vertical</v-icon>
    </template>
  </DropDownMenu>
</template>

<script>
import { participants, judge } from '@/store/modules'
import { mapActions, mapGetters } from 'vuex'
import DropDownMenu from '@/components/DropDownMenu'
import ParticipantStatus from '@/enums/ParticipantStatus'

export default {
  name: 'JudgeActionsMenu',
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    ...mapGetters(
      participants.namespace,
      [
        participants.getters.selectedParticipant
      ]
    ),
    isParticipantPerforming () {
      return this.selectedParticipant?.status === ParticipantStatus.Performing
    },
    options () {
      return this.isMissing
        ? []
        : ['Missing', 'Recall', 'Enter scores for this judge']
    },
    disabledOptions () {
      return this.isParticipantPerforming ? [] : ['Enter scores for this judge']
    }
  },
  props: {
    isMissing: {
      type: Boolean,
      default: undefined
    },
    seat: {
      type: Number,
      default: undefined
    },
    id: {
      type: Number,
      default: undefined
    }
  },
  components: {
    DropDownMenu
  },
  methods: {
    ...mapActions(
      participants.namespace,
      [
        participants.actions.recallScores,
        participants.actions.setMissingScores
      ]
    ),
    ...mapActions(
      judge.namespace,
      [
        judge.actions.fetchConfiguration
      ]
    ),
    async onOptionSelected (option) {
      switch (option) {
        case 'Recall':
          await this.recallScores({
            panel: {
              id: this.id,
              seat: this.seat
            }
          })
          break
        case 'Missing':
          await this.setMissingScores({
            panel: {
              id: this.id,
              seat: this.seat
            }
          })
          break
        case 'Enter scores for this judge':
          await this.fetchConfiguration({ id: this.id, seat: this.seat })
          this.$router.push(`/judge/score?id=${this.id}&seat=${this.seat}`)
          break
      }
    }
  }
}
</script>
