<template>
  <div class='participant-info'>
    <span>Waiting for competition to start</span>
  </div>
</template>

<script>
export default {
  name: 'WaitingInfo'
}
</script>

<style lang='scss' scoped>
@use '~@/styles/mixins';
@import '~@/styles/container';

.participant-info {
  @include mixins.from-map(font-size, $medium);
  @include border('regular' 'rounded');

  margin: auto;
  width: 80%;
  padding: 30px;
  font-weight: bold;
}
</style>
