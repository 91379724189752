import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"file-importer"},[_c('h2',[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c(VFileInput,{attrs:{"accept":_vm.accept,"clearable":false,"id":_vm.id,"loading":_vm.inProgress,"single-line":"","success-messages":_vm.successMessage,"error-messages":_vm.erorrMessages,"error-count":2},on:{"change":_vm.onFileSelected,"click":_vm.clearSelectedFilePath},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticStyle:{"color":"rgba(0, 0, 0, 0.87)"}},[_vm._v(" Choose and import file ")])]},proxy:true},{key:"selection",fn:function(){return [_c('span',{staticStyle:{"color":"rgba(0, 0, 0, 0.87)"}},[_vm._v(" Choose and import file ")])]},proxy:true},{key:"message",fn:function(ref){
var message = ref.message;
return [(_vm.erorrMessages.length > 2)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(message)+" ")])]}}],null,true)},_vm._l((_vm.erorrMessages),function(msg,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(msg)+" ")])}),0):_c('span',[_vm._v(" "+_vm._s(message)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }