import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',[_c('div',{staticClass:"unit-name"},[_vm._v(_vm._s(_vm.selectedUnit && _vm.selectedUnit.name))]),(_vm.selectedUnit && _vm.selectedUnit.name)?_c('div',_vm._l((_vm.displayableOdfMessages),function(ref){
var name = ref.name;
var lastGeneratedAt = ref.lastGeneratedAt;
var errors = ref.errors;
return _c('section',{key:name,staticClass:"odf-message"},[_c('span',{staticClass:"odf-message__name"},[_vm._v(" "+_vm._s(name)+" "),(name === 'DT_RESULT')?_c(VCheckbox,{staticClass:"odf-message__name--checkbox ma-0 ml-8",attrs:{"hide-details":""},on:{"change":function (newValue) { return _vm.additionalData[name].startListNoCC =
          (newValue ? false : _vm.additionalData[name].startListNoCC); }},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"odf-message__name--checkbox-label"},[_vm._v("As START_LIST with CC")])]},proxy:true}],null,true),model:{value:(_vm.additionalData[name].startList),callback:function ($$v) {_vm.$set(_vm.additionalData[name], "startList", $$v)},expression:"additionalData[name].startList"}}):_vm._e(),(name === 'DT_RESULT')?_c(VCheckbox,{staticClass:"odf-message__name--checkbox ma-0 ml-8",attrs:{"hide-details":""},on:{"change":function (newValue) { return _vm.additionalData[name].startList =
          (newValue ? false : _vm.additionalData[name].startList); }},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"odf-message__name--checkbox-label"},[_vm._v("As START_LIST without CC")])]},proxy:true}],null,true),model:{value:(_vm.additionalData[name].startListNoCC),callback:function ($$v) {_vm.$set(_vm.additionalData[name], "startListNoCC", $$v)},expression:"additionalData[name].startListNoCC"}}):_vm._e()],1),(errors && errors.length > 0)?_c('span',{staticClass:"odf-message__errors"},[_c(VDialog,{attrs:{"max-width":"50vw","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(lastGeneratedAt ? 'Message generated with errors' : 'Message generation failed')+" ")])]}}],null,true),model:{value:(_vm.showErrorsDialog[name]),callback:function ($$v) {_vm.$set(_vm.showErrorsDialog, name, $$v)},expression:"showErrorsDialog[name]"}},[_c(VCard,{staticClass:"odf-message__errors-card"},[_c(VCardTitle,[_vm._v("ODF message generation errors")]),_c(VCardText,_vm._l((errors),function(ref,index){
          var fileName = ref.fileName;
          var errorMessages = ref.errorMessages;
return _c('div',{key:index},[_c('span',{staticClass:"odf-message__errors-card__filename"},[_vm._v(" "+_vm._s(fileName)+" ")]),_c('ul',_vm._l((errorMessages),function(msg,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(msg)+" ")])}),0)])}),0),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.showErrorsDialog[name] = false}}},[_vm._v(" Close ")])],1)],1)],1)],1):_vm._e(),_c('section',[_c('span',{staticClass:"odf-message__timestamp"},[_vm._v(_vm._s(lastGeneratedAt))]),_c(VBtn,{staticClass:"odf-message__generate",attrs:{"color":"primary","small":"","loading":_vm.isOdfMessageGenerationInProgress[name]},on:{"click":function($event){return _vm.generateOdfMessage({
          name: name,
          unitId: _vm.selectedUnit.id,
          additionalData: _vm.additionalData[name]
        })}}},[_vm._v(" Generate ")])],1)])}),0):_c('div',[_c('span',[_vm._v("Select a unit to see ODF messages")])])])}
var staticRenderFns = []

export { render, staticRenderFns }