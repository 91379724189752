export const getters = {
  allUnits: 'allUnits',
  currentUnit: 'currentUnit',
  onlineJudges: 'onlineJudges',
  isUnitStateChangeInProgress: 'isUnitStateChangeInProgress',
  isInProgress: 'isInProgress',
  getAllCancellationTokens: 'getAllCancellationTokens'
}

export const gettersDefinition = {
  [getters.allUnits]: ({ units = [] } = {}) => units,
  [getters.currentUnit]: ({ current = null } = {}) => current,
  [getters.onlineJudges]: ({ judgesOnline = {} } = {}) => judgesOnline,
  [getters.isUnitStateChangeInProgress]: ({ isUnitStateChangeInProgress }) =>
    isUnitStateChangeInProgress === true,
  [getters.isInProgress]: ({ isInProgress }) => (element) => {
    return isInProgress[element]
  },
  [getters.getAllCancellationTokens]: store => store.cancellationTokens
}
