import { map, max, min } from 'lodash'

const newEvent = ({ name, code, shortname, phases }) => ({
  [code]: {
    code,
    type: code,
    name,
    shortname,
    phases
  }
})

export const PHASES = {
  PRELIMINARY: {
    code: 'PREL',
    name: 'Preliminary',
    shortname: 'Preliminary',
    type: 'Preliminary'
  },
  FINALS: {
    code: 'FNL-',
    name: 'Finals',
    shortname: 'Finals',
    type: 'Finals'
  }
}

export const EVENTS_PHASES = {
  ...newEvent({
    name: 'Solo Technical Routine',
    shortname: 'Solo Technical Routine',
    code: 'WSOLOTECH',
    phases: [PHASES.PRELIMINARY, PHASES.FINALS]
  }),
  ...newEvent({
    name: 'Solo Free Routine',
    shortname: 'Solo Free Routine',
    code: 'WSOLOFREE',
    phases: [PHASES.PRELIMINARY, PHASES.FINALS]
  }),
  ...newEvent({
    name: 'Male Solo Technical Routine',
    shortname: 'Male Solo Technical Routine',
    code: 'MSOLOTECH',
    phases: [PHASES.PRELIMINARY, PHASES.FINALS]
  }),
  ...newEvent({
    name: 'Male Solo Free Routine',
    shortname: 'Male Solo Free Routine',
    code: 'MSOLOFREE',
    phases: [PHASES.PRELIMINARY, PHASES.FINALS]
  }),
  ...newEvent({
    name: 'Duet Technical Routine',
    shortname: 'Duet Technical Routine',
    code: 'WDUETTECH',
    phases: [PHASES.PRELIMINARY, PHASES.FINALS]
  }),
  ...newEvent({
    name: 'Duet Free Routine',
    shortname: 'Duet Free Routine',
    code: 'WDUETFREE',
    phases: [PHASES.PRELIMINARY, PHASES.FINALS]
  }),
  ...newEvent({
    name: 'Mixed Duet Technical Routine',
    shortname: 'Duet Technical Routine',
    code: 'XDUETTECH',
    phases: [PHASES.PRELIMINARY, PHASES.FINALS]
  }),
  ...newEvent({
    name: 'Mixed Duet Free Routine',
    shortname: 'Duet Free Routine',
    code: 'XDUETFREE',
    phases: [PHASES.PRELIMINARY, PHASES.FINALS]
  }),
  ...newEvent({
    name: 'Team Technical Routine',
    shortname: 'Team Technical Routine',
    code: 'XTEAMTECH',
    phases: [PHASES.PRELIMINARY, PHASES.FINALS]
  }),
  ...newEvent({
    name: 'Team Free Routine',
    shortname: 'Team Free Routine',
    code: 'XTEAMFREE',
    phases: [PHASES.PRELIMINARY, PHASES.FINALS]
  }),
  ...newEvent({
    name: 'Acrobatic Routine',
    shortname: 'Acrobatic Routine',
    code: 'XACRO',
    phases: [PHASES.FINALS]
  }),
  ...newEvent({
    name: 'Free Combination',
    shortname: 'Free Combination',
    code: 'WFREECOMB',
    phases: [PHASES.PRELIMINARY, PHASES.FINALS]
  })
}

const toTournamentId = (
  shortEventCode,
  phaseCode = '----',
  unitCode = '--------'
) => `SWA${shortEventCode.padEnd(9, '-')}----------${phaseCode}${unitCode}`

const buildUnit = ({
  unitDetails: { code, timeStart, timeEnd, timeZone },
  eventCode,
  phaseCode,
  phaseName,
  phaseShortname,
  date
}) => ({
  id: toTournamentId(eventCode, phaseCode, code),
  code: `SWA${eventCode.padEnd(9, '-')}----------${phaseCode}${code}`,
  name: phaseName,
  shortname: phaseShortname,
  scheduledStartDate: `${date}T${timeStart}:00${timeZone}`,
  scheduledEndDate: `${date}T${timeEnd}:00${timeZone}`
})

const buildPhaseUnits = ({
  phaseAndUnitDetails,
  elementIndex,
  eventCode,
  phaseCode,
  phaseName,
  phaseShortname,
  date
}) => {
  const units = []
  while (
    ++elementIndex < phaseAndUnitDetails.length &&
    phaseAndUnitDetails[elementIndex].type === 'UNIT'
  ) {
    const unit = buildUnit({
      unitDetails: phaseAndUnitDetails[elementIndex],
      eventCode,
      phaseCode,
      phaseName,
      phaseShortname,
      date
    })
    units.push(unit)
  }

  return [units, elementIndex]
}

const computeScheduleDates = collection => ({
  scheduledStartDate: min(map(collection, 'scheduledStartDate')),
  scheduledEndDate: max(map(collection, 'scheduledEndDate'))
})

const buildPhase = ({
  event: { code: eventCode, name: eventName, phases },
  phaseIndex,
  phaseAndUnitDetails,
  elementIndex
}) => {
  const { code: phaseCode, name, date } = phaseAndUnitDetails[elementIndex]
  const phaseName = `${eventName} ${name}`
  const { type, shortname } = phases[phaseIndex]
  const [units, nextPhaseIndex] = buildPhaseUnits({
    phaseAndUnitDetails,
    elementIndex,
    eventCode,
    phaseCode,
    phaseName,
    phaseShortname: shortname,
    date
  })

  const phase = {
    id: toTournamentId(eventCode, phaseCode),
    type,
    code: `SWA${eventCode.padEnd(9, '-')}----------${phaseCode}`.padEnd(34, '-'),
    name: phaseName,
    shortname,
    ...computeScheduleDates(units),
    units
  }
  return [phase, nextPhaseIndex]
}

const buildPhases = (flatList, event) => {
  const phases = []
  for (let i = 0, phaseIndex = 0; i < flatList.length; ++phaseIndex) {
    const [phase, nextElementIdx] = buildPhase({
      event,
      phaseIndex,
      phaseAndUnitDetails: flatList,
      elementIndex: i
    })

    phases.push(phase)
    i = nextElementIdx
  }

  return phases
}

export const toHierarchy = (eventCode, flatList = []) => {
  const referenceEvent = EVENTS_PHASES[eventCode]
  const phases = buildPhases(flatList, referenceEvent)

  return {
    id: toTournamentId(eventCode),
    ...referenceEvent,
    code: `SWA${referenceEvent.code.padEnd(9, '-')}`.padEnd(34, '-'),
    ...computeScheduleDates(phases),
    phases
  }
}

export const toUpdateHierarchy = (eventCode, flatList = []) => {
  const shortEventCode = eventCode.replaceAll('-', '')
  const referenceEvent = EVENTS_PHASES[shortEventCode]
  const phases = buildPhases(flatList, referenceEvent)

  return {
    id: toTournamentId(shortEventCode),
    ...computeScheduleDates(phases),
    phases
  }
}
