import { cond, filter, has, invoke, map, matches, stubTrue } from 'lodash'
import { getConnection } from '../api/websocket/signalR'

const detachFromWebsocket = async (callbacks, route, router) => {
  const connection = getConnection()
  if (connection) {
    await callbacks({ route, router, connection })
  }
}

const attachToWebsocket = async (callbacks, to, router) => {
  const connection = getConnection()
  if (connection) {
    await callbacks({ connection, route: to, router })
  }
}

const findComponentsRequiringWebsockets = router => {
  const routes = filter(
    router.getRoutes(),
    route => has(route, 'meta.socket.open') && has(route, 'meta.socket.close')
  )

  return routes
}

const createCallbacksMap = (components = [], method) =>
  map(components, component => [
    matches({ route: { name: component.name } }),
    async ({ route, ...args }) =>
      await invoke(component, `meta.socket.${method}`, args)
  ])

const hookup = router => {
  const asyncComponents = findComponentsRequiringWebsockets(router)
  const attachCallbacks = cond([
    ...createCallbacksMap(asyncComponents, 'open'),
    [stubTrue, async () => null]
  ])
  const detachCallbacks = cond([
    ...createCallbacksMap(asyncComponents, 'close'),
    [stubTrue, async () => null]
  ])

  router.afterEach(async (to, from) => {
    await detachFromWebsocket(detachCallbacks, from, router)
    await attachToWebsocket(attachCallbacks, to, router)
  })
}

export default {
  routes: [],
  hookup
}
