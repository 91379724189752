<template>
  <table>
    <thead>
      <tr>
        <th colspan="2"/>
      </tr>
      <tr>
        <th>EL #</th>
        <th>EL TYPE</th>
        <th>ELEMENT</th>
        <th>DD</th>
      </tr>
      <tr>
      </tr>
    </thead>
    <tbody>
      <coach-card-entry
        v-for="(entry, index) in assistantCoachCardItemsWithMarks"
        :key="`cc-${index}`"
        #default="{ score }"
        :entry="entry">
        <difficulty-technical-controller-score
          :score="score"
          :loading="inProgress[index]"
          @scoreChanged="mark => updateAssistantMarkAndSubmitAll({ index, mark })"/>
      </coach-card-entry>
      <tr>
        <td colspan="3" align="right"><b>Total DD</b></td>
        <td>{{ getTotalDD() }}</td>
        <td></td>
      </tr>
    </tbody>
  </table>
</template>

<script>

import CoachCardEntry from '@/components/difficulty-technical-controller/CoachCardEntry'
import DifficultyTechnicalControllerScore from '@/components/difficulty-technical-controller/DifficultyTechnicalControllerScore'
import { judge } from '@/store/modules'
import { mapActions, mapGetters } from 'vuex'
import { sumBy } from 'lodash'

export default {
  name: 'DifficultyAssistantTechnicalControllerPanel',
  components: {
    CoachCardEntry,
    DifficultyTechnicalControllerScore
  },
  computed: {
    ...mapGetters(
      judge.namespace,
      [
        judge.getters.assistantCoachCardItemsWithMarks,
        judge.getters.maxFamiliesSize,
        judge.getters.maxBonusesSize,
        judge.getters.inProgress
      ]
    )
  },
  async created () {
    await Promise.allSettled([
      this.fetchMarks()
    ])
  },
  methods: {
    ...mapActions(
      judge.namespace,
      [
        judge.actions.updateAssistantMarkAndSubmitAll,
        judge.actions.fetchMarks
      ]
    ),
    getTotalDD () {
      return sumBy(this.assistantCoachCardItemsWithMarks, 'degreeOfDifficulty').toFixed(4)
    }
  }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables";
table {
  width: 80%;
  margin: 0 auto;
  border-spacing: 0;

  thead tr {
    height: 1.5rem;
  }
  tbody tr {
    height: 3rem;

    th, td {
      padding: 0.5rem 1rem;
      border-top: solid thin $lightgray;
    }
  }
}
</style>
