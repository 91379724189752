<template>
  <div>
    <table class='scores-table'>
      <thead>
        <tr>
          <th/>
          <slot name="after-row-label-caption"/>
          <th
            v-for="judgeNumber in seatsCount"
            :key="`judge-${panelId}-${judgeNumber}`"
            class="scores-table__header scores-table__header--judge">
            {{ panelShortName }}{{ judgeNumber }}
            <slot
              name="judge-header-actions"
              v-bind="({
                panelId,
                seat: judgeNumber,
                isMissing: isMissing(judgeNumber)
              })"
            />
          </th>
          <slot v-if="withTotals"
            name="after-results-caption">
            <th colspan="2"/>
          </slot>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="({
            rowLabel,
            isTransition,
            scores,
            total,
            order,
            partIndex
          }) in scoresRows"
          :key="`${panelId}-${partIndex}`">
          <th v-if="!isTransition" class="scores-table__cell
            scores-table__cell--left scores-table__cell--no-wrap">
            <span v-if="order">{{order}}.&nbsp;</span>
            <span>{{rowLabel}}</span>
          </th>
          <slot
            v-if="!isTransition"
            name="after-row-label-data"
            v-bind="{ partIndex }"/>
          <td
            v-for="(score, scoreIndex) in scores"
            :key="`score-${panelId}-${partIndex}-${scoreIndex}`"
            class="scores-table__cell">
            <slot
              v-bind="{
                panelId,
                partIndex,
                score,
                seat: scoreIndex + 1
              }">
              <ScoreBox
                :class="['scores-table__result', {'scores-table__result--missing': !score}]"
                :score="score && score.value"
                disabled/>
            </slot>
          </td>
          <slot
            v-if="withTotals && !isTransition"
            name="after-results-data"
            v-bind="{ total, isReady }">
            <td/>
          </slot>
        </tr>
      </tbody>
      <tfoot
        v-if="withTotals">
        <tr
          v-for="({
            rowLabel,
            value,
            withSuspense,
            onValueUpdated,
            additionalAction,
            penaltyName,
            readonly
          }, rowIndex) in displayableTotals"
          :key="`${panelId}-${rowIndex}`">
          <th
            :colspan="seatsCount + additionalColumnsCount + 1"
            class="scores-table__cell scores-table__cell--left">
            <div class="space-between">
              <span>{{ rowLabel }}</span>
              <button
                v-ripple
                v-if="!!additionalAction && !readonly"
                class="scores-table__cell-container__item
                      scores-table__cell-container__item--action-button"
                @click.stop="additionalAction.buttonAction()">
                {{ additionalAction.buttonLabel }}
              </button>
            </div>
          </th>
          <td
            class="scores-table__cell"
            colspan="2">
            <div v-if="readonly">
              <span class="scores-table__cell-container">
                <ScoreBox
                  :score="value"
                  :class="['scores-table__result', {'scores-table__result--missing': !value}]"
                  flat readonly/>
              </span>
            </div>
            <span v-else>
              <span v-if="withSuspense">
                <Suspense
                  :isReady="isReady">
                  <div class="scores-table__cell-container">
                    <ScoreBox
                      :score="value"
                      class="scores-table__result scores-table__result--total"
                      flat readonly/>
                  </div>
                </Suspense>
              </span>
              <span v-else>
                <!-- oninput wad added to prevent default behavior
                which is showing a value not passing validation rules -->
                <v-text-field
                  :loading='isInProgress(penaltyName)'
                  :rules=penaltyValidationRules
                  :value="value || '0.0'"
                  class="scores-table__cell-input"
                  filled
                  :readonly="readonly"
                  min="0.0"
                  step="0.05"
                  type="number"
                  @click="({ target })=> invokeValueUpdatedHandler(
                    onValueUpdated,
                    target.value,
                    penaltyName)"
                  @input="v => invokeValueUpdatedHandler(
                    onValueUpdated,
                    v,
                    penaltyName)"
                  oninput="if (this.value < 0) this.value = 0"
                />
              </span>
            </span>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import ScoreBox from '@/components/ScoreBox'
import Suspense from '@/components/Suspense'
import { chiefRecorder } from '@/store/modules'
import { debounce, isEmpty, isFunction, isNil } from 'lodash'
import { mapGetters } from 'vuex'
import { getPenaltyValidationRules } from '@/services/validation-service'

const mapTotal = (value, precision) => isNil(value) ? '--.----' : (+value).toFixed(precision)

export default {
  name: 'ScoresTable',
  props: {
    scores: {
      type: Object,
      default: null
    },
    totals: {
      type: Array,
      default: () => []
    },
    isReady: {
      type: Boolean,
      default: () => true
    },
    panelShortName: {
      type: String,
      default: null
    },
    noTotals: {
      type: Boolean,
      default: false
    },
    additionalColumnsCount: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      penaltyValidationRules: getPenaltyValidationRules()
    }
  },
  components: {
    Suspense,
    ScoreBox
  },
  computed: {
    ...mapGetters(
      chiefRecorder.namespace,
      [
        chiefRecorder.getters.isInProgress
      ]
    ),
    withTotals () {
      return !this.noTotals
    },
    panelId () {
      return this.scores?.id ?? 0
    },
    seatsCount () {
      return this.scores?.seatsCount ?? 0
    },
    scoresRows () {
      if (isEmpty(this.scores?.parts)) {
        return []
      }
      return (this.scores.parts || [])
        .map(({ isTransition, name, scores, total, order }, partIndex) => ({
          rowLabel: name,
          total: mapTotal(total, 4),
          isTransition,
          scores: (!isTransition && scores) || [],
          order,
          partIndex
        }))
        .filter(({ isTransition }) => !isTransition)
    },
    displayableTotals () {
      return this.totals.map(
        ({ value, onValueUpdated, ...rest }) => ({
          value: mapTotal(value, !isFunction(onValueUpdated) ? 4 : 2),
          withSuspense: !isFunction(onValueUpdated),
          onValueUpdated,
          ...rest
        }))
    }
  },
  methods: {
    invokeValueUpdatedHandler: debounce(async function (callback, value, penaltyName) {
      if (isFunction(callback)) {
        await callback(value, penaltyName)
      }
    }, 1000),
    isMissing (judgeNumber) {
      return this.scores.parts
        .some(p => p.scores.some(s => s && s.isMissing && s.seat === judgeNumber))
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/typology';

.scores-table {
  &__result--total .score {
    @include value-l4;
  }

  &__cell-input input {
    text-align: right;
  }
}
</style>

<style lang="scss" scoped>
@import "~@/styles/button";
@import '~@/styles/typology';
@import '~@/styles/background';
@import '~@/styles/container';

.scores-table {
  width: 100%;
  table-layout: fixed;

  &__header {
    &--judge {

      @include secondary-caption-l4();

      position: relative;
    }
  }

  &__result {
    @include value-l4();

    padding-top: 0;
    padding-bottom: 0;
  }

  &__penalty {
    @include value-l4();

    padding: 0.5rem 0;
  }

  &__cell {
    & > * {
      width: 100%;
    }

    & > .space-between {
      @include container("justified", "center");
    }

    &-input {
      @include value-l3();

      & input {
        text-align: right;
      }
    }

    &-container {
      @include container("right", "center");
      position: relative;
      margin-right: 0.5rem;

      &__item {
        @include value-l3();

        &--action-button {
          @include button($size: $small);
        }
      }
    }

    &--left {
      text-align: left;
    }

    &--no-wrap {
      text-wrap: nowrap;
    }

    &--transition {
      text-align: center;
      font-style: italic;

      &:before, &:after {
        content: '---';
      }
    }
  }
}
</style>
