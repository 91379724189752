<template>
  <div class="container">
    <button
      v-ripple
      type="button"
      class="small"
      @click="onButtonClicked('small')">
      Small
    </button>
    <button
      v-ripple
      type="button"
      class="major"
      @click="onButtonClicked('major')">
      Major
    </button>
    <button
      v-ripple
      type="button"
      class="obvious"
      @click="onButtonClicked('obvious')">
      Obvious
    </button>
    <KeyboardKeypad @keyPressed="onKeyboardKeyPressed" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import KeyboardKeypad from '@/components/judge/KeyboardKeypad'
import { synchronizationErrors, judge } from '@/store/modules'

const keysToMarksMap = {
  1: 'small',
  2: 'major',
  3: 'obvious'
}

export default {
  name: 'JudgeSyncPane',
  components: {
    KeyboardKeypad
  },
  async created () {
    await this.updateJudgeJoinedStartTime()
  },
  methods: {
    ...mapActions(
      synchronizationErrors.namespace,
      [
        synchronizationErrors.actions.sendSynchronizationError
      ]
    ),
    ...mapActions(
      judge.namespace,
      [
        judge.actions.updateJudgeJoinedStartTime
      ]
    ),
    async onButtonClicked (mark) {
      const now = Date.now()
      const timeElapsed = now - this.startTime

      await this.sendSynchronizationError({ mark, timeElapsed })
    },
    async onKeyboardKeyPressed (key) {
      const now = Date.now()
      const timeElapsed = now - this.startTime

      const mark = keysToMarksMap[key]

      if (mark) {
        await this.sendSynchronizationError({ mark, timeElapsed })
      }
    }
  },
  computed: {
    ...mapGetters(
      judge.namespace,
      [
        judge.getters.startTime
      ]
    )
  }
}
</script>

<style lang="scss" scoped>
@use "~@/styles/mixins";
@import "~@/styles/button";
@import "~@/styles/variables";

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  button {
    @include button($size: $medium);
    @include mixins.from-map(padding, $x-large);

    &.small {
      background-color: $green;
    }

    &.major {
      background-color: $red;
    }

    &.obvious {
      background-color: $orange;
    }
  }
}
</style>
