import { conforms, isArray, isEmpty, isFunction } from 'lodash'

const isStoreValid = conforms({
  subscribeAction: isFunction,
  commit: isFunction,
  dispatch: isFunction
})

const safeArray = (value = []) => {
  if (isEmpty(value)) {
    return []
  }

  if (isArray(value)) {
    return value
  }

  throw Error("Invalid invocation. Invalid 'subscribers' object")
}

const objectSubscriber = store => ({ namespace, subscribe } = {}) => {
  const { commit, dispatch } = store
  return subscribe({
    store,
    commitLocal: (mutation, ...args) => commit(`${namespace}/${mutation}`, ...args),
    dispatchLocal: (action, ...args) => dispatch(`${namespace}/${action}`, ...args)
  })
}

const unsubscribers = []

export const registerActionListeners = ({
  store = {},
  subscribers = []
} = {}) => {
  if (!isStoreValid(store)) {
    throw Error("Invalid invocation. Missing or invalid 'store' object")
  }

  unsubscribeFormerActionListeners()
  safeArray(subscribers).forEach(subscriber => {
    const unsubscribe = objectSubscriber(store)(subscriber)
    if (unsubscribe) {
      unsubscribers.push(unsubscribe)
    }
  })
}
function unsubscribeFormerActionListeners () {
  while (unsubscribers.length) {
    const unsubscriber = unsubscribers.shift()
    unsubscriber()
  }
}
