<template>
  <section class="schedule-event-phase">
    <div>
      <input
        v-model="selectedDate"
        class="schedule-event-phase__date"
        type="date"
        @input="$emit('update:date', $event.target.value)"/>
      <span
        class="schedule-event-phase__name">
        {{ name }}
      </span>
    </div>
    <v-btn
      v-if="code === 'PREL' && !removePreliminaryPhaseDisabled"
      icon
      @click="$emit('prelPhaseRemoved')">
      <v-icon>mdi-delete</v-icon>
    </v-btn>
  </section>
</template>

<script>
export default {
  name: 'ScheduleWizardPhase',
  emits: ['update:date', 'prelPhaseRemoved'],
  props: {
    name: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    removePreliminaryPhaseDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedDate: this.date
    }
  },
  watch: {
    date (newValue) {
      this.selectedDate = newValue
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/container";

.schedule-event-phase {
  @include container("justified", "center");
}
</style>
