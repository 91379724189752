<template>
  <article>
    <section class="publisher">
      <v-btn
        class="publisher__publish"
        color="success"
        @click="connect()"
        >Connect</v-btn
      >
      <v-btn class="publisher__publish"
        color="error"
        @click="disconnect()"
        >Disconnect</v-btn>
    </section>
    <section class="publisher">
      <v-btn
        class="publisher__publish"
        color="primary"
        :disabled="!isConnected"
        @click="sendPublisher(PublishType.PB_ALL)"
        >Publish All</v-btn
      >
    </section>
    <section class="publisher">
      <v-btn
          class="publisher__publish"
          color="primary"
          :disabled="!isConnected"
          @click="sendPublisher(PublishType.PB_COMMON)"
          >Publish Common</v-btn
        >
    </section>
    <section class="publisher">
      <v-btn
          class="publisher__publish"
          color="primary"
          :disabled="!isConnected"
          @click="sendPublisher(PublishType.PB_PARTICIPANTS)"
          >Publish Participants</v-btn
        >
    </section>
    <section class="publisher">
      <v-btn
          class="publisher__publish"
          color="primary"
          :disabled="!isConnected"
          @click="sendPublisher(PublishType.PB_RESULT)"
          >Publish Results</v-btn
        >
    </section>
  </article>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { olr } from '@/store/modules'

const PublishType = Object.freeze({
  PB_ALL: 'PB_ALL',
  PB_RESULT: 'PB_RESULT',
  PB_COMMON: 'PB_COMMON',
  PB_PARTICIPANTS: 'PB_PARTICIPANTS'
})

export default {
  name: 'Publisher',
  async created () {
    this.PublishType = PublishType
    await this.fetchIsPublisherConnected()
  },
  methods: {
    ...mapActions(
      olr.namespace,
      [
        olr.actions.sendPublisher,
        olr.actions.connectPublisher,
        olr.actions.disconnectPublisher,
        olr.actions.fetchIsPublisherConnected
      ]
    ),
    async connect () {
      await this.connectPublisher()
      await this.fetchIsPublisherConnected()
    },
    async disconnect () {
      await this.disconnectPublisher()
      await this.fetchIsPublisherConnected()
    }
  },
  computed: {
    ...mapGetters(
      olr.namespace,
      [
        olr.getters.isConnectedPublisher
      ]
    ),
    isConnected () {
      return this.isConnectedPublisher?.isConnected
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/container";

.publisher {
  @include container("justified", "center");
  margin: 0.5rem 0;

  &__publish {
    margin-left: 0.5rem;
    margin-top: 0.5rem;
  }
}
</style>
