import { VTextField } from 'vuetify/lib/components/VTextField';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',{staticClass:"scores-table"},[_c('thead',[_c('tr',[_c('th'),_vm._t("after-row-label-caption"),_vm._l((_vm.seatsCount),function(judgeNumber){return _c('th',{key:("judge-" + _vm.panelId + "-" + judgeNumber),staticClass:"scores-table__header scores-table__header--judge"},[_vm._v(" "+_vm._s(_vm.panelShortName)+_vm._s(judgeNumber)+" "),_vm._t("judge-header-actions",null,null,({
              panelId: _vm.panelId,
              seat: judgeNumber,
              isMissing: _vm.isMissing(judgeNumber)
            }))],2)}),(_vm.withTotals)?_vm._t("after-results-caption",function(){return [_c('th',{attrs:{"colspan":"2"}})]}):_vm._e()],2)]),_c('tbody',_vm._l((_vm.scoresRows),function(ref){
        var rowLabel = ref.rowLabel;
        var isTransition = ref.isTransition;
        var scores = ref.scores;
        var total = ref.total;
        var order = ref.order;
        var partIndex = ref.partIndex;
return _c('tr',{key:(_vm.panelId + "-" + partIndex)},[(!isTransition)?_c('th',{staticClass:"scores-table__cell scores-table__cell--left scores-table__cell--no-wrap"},[(order)?_c('span',[_vm._v(_vm._s(order)+". ")]):_vm._e(),_c('span',[_vm._v(_vm._s(rowLabel))])]):_vm._e(),(!isTransition)?_vm._t("after-row-label-data",null,null,{ partIndex: partIndex }):_vm._e(),_vm._l((scores),function(score,scoreIndex){return _c('td',{key:("score-" + _vm.panelId + "-" + partIndex + "-" + scoreIndex),staticClass:"scores-table__cell"},[_vm._t("default",function(){return [_c('ScoreBox',{class:['scores-table__result', {'scores-table__result--missing': !score}],attrs:{"score":score && score.value,"disabled":""}})]},null,{
              panelId: _vm.panelId,
              partIndex: partIndex,
              score: score,
              seat: scoreIndex + 1
            })],2)}),(_vm.withTotals && !isTransition)?_vm._t("after-results-data",function(){return [_c('td')]},null,{ total: total, isReady: _vm.isReady }):_vm._e()],2)}),0),(_vm.withTotals)?_c('tfoot',_vm._l((_vm.displayableTotals),function(ref,rowIndex){
        var rowLabel = ref.rowLabel;
        var value = ref.value;
        var withSuspense = ref.withSuspense;
        var onValueUpdated = ref.onValueUpdated;
        var additionalAction = ref.additionalAction;
        var penaltyName = ref.penaltyName;
        var readonly = ref.readonly;
return _c('tr',{key:(_vm.panelId + "-" + rowIndex)},[_c('th',{staticClass:"scores-table__cell scores-table__cell--left",attrs:{"colspan":_vm.seatsCount + _vm.additionalColumnsCount + 1}},[_c('div',{staticClass:"space-between"},[_c('span',[_vm._v(_vm._s(rowLabel))]),(!!additionalAction && !readonly)?_c('button',{directives:[{def: Ripple,name:"ripple",rawName:"v-ripple"}],staticClass:"scores-table__cell-container__item scores-table__cell-container__item--action-button",on:{"click":function($event){$event.stopPropagation();return additionalAction.buttonAction()}}},[_vm._v(" "+_vm._s(additionalAction.buttonLabel)+" ")]):_vm._e()])]),_c('td',{staticClass:"scores-table__cell",attrs:{"colspan":"2"}},[(readonly)?_c('div',[_c('span',{staticClass:"scores-table__cell-container"},[_c('ScoreBox',{class:['scores-table__result', {'scores-table__result--missing': !value}],attrs:{"score":value,"flat":"","readonly":""}})],1)]):_c('span',[(withSuspense)?_c('span',[_c('Suspense',{attrs:{"isReady":_vm.isReady}},[_c('div',{staticClass:"scores-table__cell-container"},[_c('ScoreBox',{staticClass:"scores-table__result scores-table__result--total",attrs:{"score":value,"flat":"","readonly":""}})],1)])],1):_c('span',[_c(VTextField,{staticClass:"scores-table__cell-input",attrs:{"loading":_vm.isInProgress(penaltyName),"rules":_vm.penaltyValidationRules,"value":value || '0.0',"filled":"","readonly":readonly,"min":"0.0","step":"0.05","type":"number","oninput":"if (this.value < 0) this.value = 0"},on:{"click":function (ref){
                var target = ref.target;

                return _vm.invokeValueUpdatedHandler(
                  onValueUpdated,
                  target.value,
                  penaltyName);
        },"input":function (v) { return _vm.invokeValueUpdatedHandler(
                  onValueUpdated,
                  v,
                  penaltyName); }}})],1)])])])}),0):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }