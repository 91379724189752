export const defaultState = {
  synchronizationErrors: []
}

const mapWithNewOutcome = (syncError, outcome) => ({
  ...syncError,
  outcome: {
    mark: outcome
  }
})

export const mutations = {
  setSynchronizationErrors: 'setSynchronizationErrors',
  clearSynchronizationErrors: 'clearSynchronizationErrors',
  updateSynchronizationError: 'updateSynchronizationError'
}

export const mutationsDefinition = {
  [mutations.setSynchronizationErrors]: (store, synchronizationErrors = []) => {
    store.synchronizationErrors = synchronizationErrors
  },
  [mutations.clearSynchronizationErrors]: (store) => {
    store.synchronizationErrors = []
  },
  [mutations.updateSynchronizationError]: (store, { timeStamp, outcome }) => {
    store.synchronizationErrors = store.synchronizationErrors.map(e =>
      e.timeStamp === timeStamp
        ? mapWithNewOutcome(e, outcome)
        : e)
  }
}
