import { isEmpty, isNull, isNil } from 'lodash'

export const parts = store => store?.assignedPanel?.parts
export const expectedScoresCount = store => parts(store)?.length
export const expectedActualScoresMaxIndex = store => expectedActualScoresCount(store) - 1
export const expectedActualScoresCount = ({ scoresMap }) =>
  scoresMap?.length ?? 0
export const isTransitionPart = part => part?.isTransition === true
export const isScorePart = part => !isTransitionPart(part)
export const indexMap = ({ scoresMap, scoresReverseMap }) => ({
  map: scoresMap,
  reverseMap: scoresReverseMap
})
export const toPaddedIndex = (store, index) => indexMap(store).map[index]
export const fromPaddedIndex = (store, index) =>
  isNull(index) ? null : indexMap(store).reverseMap[index]

export const isKeypadValueComplete = (value, { max }) => {
  const numericValue = +value
  if (numericValue === max) {
    return true
  }

  const completeStringInputRegex = /^\d.\d{2}$/
  return value.match(completeStringInputRegex)
}

export const isKeypadValueValid = (newValue, { min, max }) => {
  const validStringInputRegex = /^\d{1,2}(?:.\d{2}?)?$/

  if (!newValue.match(validStringInputRegex)) {
    return false
  }

  const value = parseFloat(newValue)
  return min <= value && value <= max
}

const hasSelectedScore = ({ selectedScoreIndex, assignedPanel }) =>
  selectedScoreIndex !== null &&
  selectedScoreIndex < expectedScoresCount({ assignedPanel })

export const selectedScore = store =>
  !hasSelectedScore(store)
    ? null
    : {
      index: toPaddedIndex(store, store.selectedScoreIndex) ?? null,
      value: store.scores[store.selectedScoreIndex]
    }

export const selectedScoreValueBeforeSelection = store =>
  selectedScoreHadValueBeforehand(store)
    ? store.selectedScoreCachedValue.value
    : null

export const selectedScoreHadValueBeforehand = ({
  selectedScoreCachedValue: { index }
}) => index >= 0

export const selectedScoreHasNoValue = store => isEmpty(selectedScore(store)?.value)

export const scoreCachedValue = ({ selectedScoreCachedValue: { value, index } } = {}) =>
  index >= 0
    ? { value, index }
    : null
export const hasScoreCachedValue = store => scoreCachedValue(store) !== null

export const initializeDifficultyTechnicalControllerScores = (scores, datcCount) => {
  if (isNil(scores.difficultyTechnicalControllerFeedback)) {
    scores.difficultyTechnicalControllerFeedback = []
  }

  if (isEmpty(scores.assistantsDifficultyTechnicalControllerFeedback) ||
    scores.assistantsDifficultyTechnicalControllerFeedback?.length < datcCount) {
    scores.assistantsDifficultyTechnicalControllerFeedback =
      new Array(datcCount).fill(
        new Array(scores.difficultyTechnicalControllerFeedback.length).fill(null))
  }
}
