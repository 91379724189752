import { isEmpty, isNil, matches, overSome, reject } from 'lodash'

export const defaultState = {
  raisedHands: [],
  recomputeRequests: [],
  isInProgress: { },
  sendScoresError: null
}

export const mutations = {
  setRaisedHands: 'setRaisedHands',
  resetRaisedHand: 'resetRaisedHand',
  clearRaisedHands: 'clearRaisedHands',
  pushRecomputeRequest: 'pushRecomputeRequest',
  popRecomputeRequest: 'popRecomputeRequest',
  setIsInProgress: 'setIsInProgress',
  setSendScoresError: 'setSendScoresError'
}

export const mutationsDefinition = {
  [mutations.setRaisedHands]: (store, hands) => {
    if (overSome([isEmpty, isNil])(hands)) {
      store.raisedHands = []
    } else {
      store.raisedHands = hands
    }
  },
  [mutations.clearRaisedHands]: (store) => {
    store.raisedHands = []
  },
  [mutations.resetRaisedHand]: (store, hand) => {
    store.raisedHands = [
      ...reject(store.raisedHands || [], matches(hand))
    ]
  },
  [mutations.pushRecomputeRequest]: (store) => {
    store.recomputeRequests.push(0)
  },
  [mutations.popRecomputeRequest]: (store) => {
    store.recomputeRequests.pop()
  },
  [mutations.setIsInProgress]: (store, { element, pending }) => {
    const isInProgress = { ...store.isInProgress }
    isInProgress[element] = pending
    store.isInProgress = isInProgress
  },
  [mutations.setSendScoresError]: (store, { error }) => {
    store.sendScoresError = error
  }
}
