<template>
  <v-progress-linear
    :active="active"
    indeterminate height="8"
    class="progress" />
</template>

<script>
export default {
  name: 'FlatSpinner',
  props: {
    active: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>

<style scoped>
.progress {
  position: relative;
}
</style>
