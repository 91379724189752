import ParticipantStatus from '@/enums/ParticipantStatus'
import { actions } from './actions'
import { mutations } from './mutations'
import { get } from 'lodash'

export const subscribe = ({ store, commitLocal, dispatchLocal } = {}) => {
  return store.subscribeAction(action => {
    switch (action.type) {
      case 'fetchCurrentUnitSucceeded': {
        const { unitId } = action.payload
        commitLocal(mutations.setUnit, get(action, 'payload', {}))
        dispatchLocal(actions.fetchCurrent, { unitId })
        break
      }
      case 'submitPresentParticipantSucceeded': {
        const { participantId: id, noc, members } = action.payload
        commitLocal(mutations.updateRoutine,
          {
            id,
            noc,
            members,
            status: ParticipantStatus.Performing
          })
        break
      }
    }
  })
}
