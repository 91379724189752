<template>
  <FontAwesomeLayers
    class="hand"
    :class="{ 'hand--disabled': disabled }"
    v-ripple="!disabled"
    @click="clicked">
    <Picto
      class="hand__border"
      icon="hand-paper"></Picto>
    <Picto
      class="hand__interior"
      :class="{ 'hand__interior--disabled': disabled }"
      icon="hand-paper"
      transform="shrink-1 down-.1"></Picto>
    <span
      v-if="disabled"
      class="hand__raised-info">
      Hand is raised
    </span>
  </FontAwesomeLayers>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'HandPushButton',
  props: {
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['clicked'],
  methods: {
    clicked: debounce(function () {
      if (!this.disabled) {
        this.$emit('clicked')
      }
    },
    1000,
    {
      leading: true
    })
  }
}
</script>

<style lang="scss">
@import "~@/styles/variables";
@import "~@/styles/typology";

.hand {
  margin: auto;
  font-size: 12rem;
  z-index: 0;
  cursor: pointer;

  &--disabled {
    cursor: not-allowed;
  }

  &__border {
    color: $black;
  }

  &__interior {
    color: $orange;

    &--disabled {
      color: $lightgray;
    }
  }

  &__raised-info {
    @include value-l5;
  }
}
</style>
