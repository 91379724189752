<template>
  <div>
    <div v-if='isOpen' class="overlay" @click="isOpen = !isOpen"></div>
    <div class="burger-menu">
      <div
        class="burger-menu__button"
        @click="isOpen = !isOpen">
        <span
          :class="{ 'burger-menu__button-bar--opened': isOpen }"
          class="burger-menu__button-bar"/>
        <span
          :class="{ 'burger-menu__button-bar--opened': isOpen }"
          class="burger-menu__button-bar"/>
        <span
          :class="{ 'burger-menu__button-bar--opened': isOpen }"
          class="burger-menu__button-bar"/>
      </div>
      <div
        :class="{'burger-menu__window--opened': isOpen}"
        class="burger-menu__window">
        <ul>
          <li
            v-for='{value, inactive, name} in displayableRoles'
            :key="value"
            :class="{'burger-menu__menu-item--disabled': inactive }"
            class="burger-menu__menu-item">
            <router-link
              v-slot="{ navigate }"
              :class="{ 'burger-menu__menu-item-link--disabled': inactive }"
              :disabled="inactive"
              :to="value"
              class="burger-menu__menu-item-link"
              custom>
              <span
                role="link" @click="!inactive && navigate($event)">
                {{ name }}
              </span>
            </router-link>
          </li>
          <li
            v-if="hasAnythingElseToDisplay"
            class="burger-menu__menu-item burger-menu__menu-item--separator"/>
          <li
            v-if="isCopyTokenToClipboardEnabled"
            class="burger-menu__menu-item"
            @click="copyToken">
            <a
              class="burger-menu__menu-item-link burger-menu__menu-item-link--debug"
              href="#">Copy my JWT token</a>
          </li>
          <li
            v-if="isCopyTokenToClipboardEnabled"
            class="burger-menu__menu-item burger-menu__menu-item--separator"/>
          <router-link
            v-if="showCoachCardsValidation"
            v-slot="{ navigate, isActive }"
            :to="'/coach-card-validation'"
            class="burger-menu__menu-item burger-menu__menu-item-link"
            custom>
            <li v-if="!isActive" class="burger-menu__menu-item">
              <span role="link" @click="navigate">
                Coach Card Validation
              </span>
            </li>
          </router-link>
          <router-link
            v-if="showTeamsManagement"
            v-slot="{ navigate, isActive }"
            :to="'/teams-management'"
            class="burger-menu__menu-item burger-menu__menu-item-link"
            custom>
            <li v-if="!isActive" class="burger-menu__menu-item">
              <span role="link" @click="navigate">
                Teams Management
              </span>
            </li>
          </router-link>
          <router-link
            v-slot="{ navigate, isActive }"
            :to="'/context-selection'"
            class="burger-menu__menu-item burger-menu__menu-item-link"
            custom>
            <li v-if="!isActive" class="burger-menu__menu-item">
              <span role="link" @click="navigate">
                Switch competition
              </span>
            </li>
          </router-link>
          <router-link
            v-if="showSettings"
            v-slot="{ navigate, isActive }"
            :to="'/settings'"
            class="burger-menu__menu-item burger-menu__menu-item-link"
            custom>
            <li v-if="!isActive" class="burger-menu__menu-item">
              <span role="link" @click="navigate">
                Settings
              </span>
            </li>
          </router-link>
          <li class="burger-menu__menu-item">
            <span
              class="burger-menu__menu-item burger-menu__menu-item-link"
              role="link"
              @click="onLogoutClicked">
              Log out
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { configuration } from '@/store/modules'
import { mapGetters } from 'vuex'
import { accessToken, isAdmin, userRoles } from '@/services/auth-service'
import { ContextHolder } from '@frontegg/vue'

export default {
  name: 'Menu',
  props: {
    skipRole: String,
    skipPages: Array,
    authState: Object
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    ...mapGetters(
      configuration.namespace,
      [
        configuration.getters.isCopyTokenToClipboardEnabled
      ]),
    displayableRoles () {
      return this.skipRole === 'all'
        ? []
        : userRoles(this.authState?.user).map(
          role => ({
            value: `/${role.code}`,
            inactive: role.code === this.skipRole,
            name: role.name
          }))
    },
    hasAnythingElseToDisplay () {
      return this.displayableRoles.length > 0
    },
    showCoachCardsValidation () {
      return !this.skipPages?.includes('coach-card-validation') && isAdmin(this.authState?.user)
    },
    showSettings () {
      return !this.skipPages?.includes('settings') && isAdmin(this.authState?.user)
    },
    showTeamsManagement () {
      return !this.skipPages?.includes('teams-management') && isAdmin(this.authState?.user)
    }
  },
  methods: {
    copyToken () {
      navigator.clipboard.writeText(accessToken(this.authState?.user))
    },
    onLogoutClicked () {
      const baseUrl = ContextHolder.getContext().baseUrl
      window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location.origin}`
    }
  }
}
</script>

<style lang="scss">
@import "~@/styles/variables";
@import '~@/styles/font';

.overlay {
  background-color: $black;
  opacity: 0.5;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  transition: all 0.5s linear;
}

$burger-button-size: 48px;
$burger-button-offset: 2rem;

.burger-menu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;

  &__button {
    position: absolute;
    right: $burger-button-offset;
    top: $burger-button-offset;

    width: $burger-button-size;
    height: $burger-button-size;
    z-index: 1000;

    &-bar {
      position: absolute;
      top: 0;
      right: 0;

      width: 100%;
      height: 6px;
      background-color: $black;
      border-radius: 6px;

      transform-origin: left center;
      transition: all 0.3s linear;

      &:nth-of-type(2n) {
        top: 17px;
      }

      &:last-of-type {
        top: 34px;
      }

      &--opened {
        &:first-of-type {
          transform: rotate(45deg)
        }

        &:nth-of-type(2n) {
          top: 34px;
          transform: rotate(-45deg)
        }

        &:last-of-type {
          transform: rotate(-45deg)
        }
      }
    }
  }

  &__window {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;

    border: 1px solid $black;
    background-color: $white;

    transition: all 0.3s linear;

    &--opened {
      height: auto;
      width: 33vw;
    }
  }

  &__menu-item {
    @include fontMain;

    margin: 1rem 0;
    list-style-type: none;
    text-align: left;

    &--separator {
      border-bottom: solid $darkgray 1px;

      margin-right: calc(1rem + #{$burger-button-offset} + #{$burger-button-size});
    }

    &-link {
      cursor: pointer;

      font-size: 2rem;
      color: $darkgray;
      text-transform: capitalize;
      text-decoration: none;
      text-wrap: none;
      white-space: nowrap;

      &:hover {
        text-decoration: underline;
      }

      &--disabled {
        text-transform: lowercase;
        color: $lightgray;

        cursor: default;

        &:hover {
          text-decoration: none;
        }
      }

      &--debug {
        font-size: 1rem;
      }
    }
  }
}
</style>
