<template>
  <v-tooltip v-if="!hidden" bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-ripple
        v-on="on"
        v-bind="attrs"
        @click="showCurrentParticipant"
        color="primary"
        class="go-to-button">
        {{ buttonLabel }}
      </v-btn>
    </template>
    {{ formattedParticipant }}
  </v-tooltip>
</template>

<script>
import { getSelectedParticipant } from '@/components/helpers'
import ParticipantStatus from '@/enums/ParticipantStatus'
import { userRoles } from '@/services/auth-service'
import { participants, unit } from '@/store/modules'
import { mapActions, mapGetters } from 'vuex'
import { isNil, join } from 'lodash'

export default {
  name: 'GoToCurrentParticipantButton',
  props: {
    hideIfTargetParticipantSelected: {
      type: Boolean,
      default: () => false
    },
    text: {
      type: String,
      default: () => null
    }
  },
  data () {
    return {
      ...this.mapAuthState()
    }
  },
  async created () {
    if (!this.currentUnit) {
      await this.fetchCurrent()
    }
  },
  emits: ['clicked'],
  methods: {
    ...mapActions(
      unit.namespace,
      [
        unit.actions.fetchCurrent
      ]
    ),
    async showCurrentParticipant () {
      if (this.$router.currentRoute.path === '/chief-recorder') {
        this.$emit('clicked', this.participant)
      } else {
        this.$router.push('/chief-recorder')
      }
    }
  },
  computed: {
    ...mapGetters(
      unit.namespace,
      [
        unit.getters.currentUnit
      ]
    ),
    ...mapGetters(
      participants.namespace,
      [
        participants.getters.currentUnitParticipants,
        participants.getters.selectedParticipant
      ]
    ),
    participant () {
      return getSelectedParticipant(this.currentUnitParticipants)
    },
    buttonLabel () {
      if (this.text) {
        return this.text
      }

      const { status } = this.participant || { }
      switch (status) {
        case ParticipantStatus.Performing:
          return 'Current'
        case ParticipantStatus.Active:
          return 'Next'
        case ParticipantStatus.Assessed:
          return 'Previous'
        default:
          return ''
      }
    },
    formattedParticipant () {
      if (isNil(this.participant)) {
        return ''
      }

      const { members } = this.participant
      return `${join(members, ' / ')}`
    },
    hidden () {
      return userRoles(this.authState?.user).every(r => r.code !== 'chief-recorder') ||
        isNil(this.currentUnit?.id) ||
        isNil(this.participant?.id) ||
        (
          this.hideIfTargetParticipantSelected &&
          this.selectedParticipant?.id === this.participant.id
        )
    }
  }
}
</script>

<style lang="scss" scoped>
.go-to-button {
  position: fixed;
  top: 2rem;
  left: 2rem;
}
</style>
