import { http } from '../base-api/http'
import { withJsonContentType } from './helpers'

export const httpput = async (uri, payload, options) => {
  const rawPayload = JSON.stringify(payload)
  const params = {
    method: 'PUT',
    body: rawPayload,
    headers: {
      ...withJsonContentType()
    }
  }
  return http(uri, params, options)
}
