import { mutationsDefinition, defaultState } from './mutations.js'
import { actionsDefinition } from './actions.js'
import { gettersDefinition } from './getters.js'

const chiefRecorderModule = {
  namespaced: true,
  state: () => defaultState,
  getters: gettersDefinition,
  mutations: mutationsDefinition,
  actions: actionsDefinition
}

export default chiefRecorderModule
export { getters } from './getters'
export { actions } from './actions'
export { subscribe } from './action-listeners'
