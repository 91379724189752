export const UnitStatus = Object.freeze({
  Scheduled: 'scheduled',
  Delayed: 'delayed',
  Postponed: 'postponed',
  GettingReady: 'gettingready',
  Running: 'running',
  Unofficial: 'unofficial',
  Official: 'official',
  Protested: 'protested'
})
