<template>
  <button
    v-ripple
    :disabled="disabled && !busy"
    :style="buttonStyle"
    class="push-button"
    @click.stop="$emit('clicked')">
    <v-progress-circular
      v-if="busy"
      indeterminate
      color="white"
      size="50"
    />
    <slot v-else />
    <div class="push-button__background"/>
  </button>
</template>

<script>

export default {
  name: 'PushButton',
  emits: ['clicked'],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    busy: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'default',
      validator: value => ['default', 'confirm', 'cancel'].includes(value)
    }
  },
  computed: {
    buttonStyle () {
      const color = ({
        confirm: '#0c0',
        cancel: '#f33'
      })[this.type] || '#008cff'

      return { '--color': color }
    }
  }
}
</script>

<style lang='scss' scoped>
@use "sass:math";
@import '~@/styles/container';
@import '~@/styles/button';
@import '~@/styles/variables';

@mixin push-button($disabled: false) {
  @include vcontainer('center', 'center');
  @include button($disabled, $medium);

  font-weight: bold;
}

.push-button {
  @include push-button;

  position: relative;

  background-color: var(--color);
  &:disabled {
    @include push-button(true);
  }

  &__background {
    position: absolute;

    width: 100%;
    height: 100%;

    z-index: -1;
  }
}
</style>
