import { inProgress, tournamentNodeContextualScenes } from '@/store/olr/mutations'
import { isEmpty, mapValues } from 'lodash'
import { EVENTS_PHASES } from './eventsTree'

const inProgressGetters = {
  isOdfImportInProgress: inProgress.odfImport,
  isCoachCardImportInProgress: inProgress.coachCardImport,
  isSaveJudgeAllocationInProgress: inProgress.saveJudgeAllocation,
  isSaveStartListInProgress: inProgress.saveStartList,
  isRegisteredParticipantsLoadingInProgress:
    inProgress.registeredParticipantsLoading,
  isUnitParticipantsLoadingInProgress: inProgress.unitParticipantsLoading,
  isRegisteredJudgesLoadingInProgress: inProgress.registeredJudgesLoading,
  isAllocatedJudgesLoadingInProgress: inProgress.allocatedJudgesLoading,
  isPscbAllOffInProgress: inProgress.pscbAllOff
}

const inProgressObjectGetters = {
  unitsChangeInProgress: inProgress.changeUnitStatus,
  isOdfMessageGenerationInProgress: inProgress.odfMessageGeneration,
  isPrintoutGenerationInProgress: inProgress.printoutGeneration,
  isPscbDisplaySceneInProgress: inProgress.displayScene
}

export const getters = {
  selectedUnit: 'selectedUnit',
  selectedRegisteredParticipants: 'selectedRegisteredParticipants',
  allParticipants: 'allParticipants',
  selectedRegisteredJudges: 'selectedRegisteredJudges',
  selectedAllocatedJudges: 'selectedAllocatedJudges',
  configuration: 'configuration',
  printoutsStatuses: 'printoutsStatuses',
  eventsTree: 'eventsTree',
  odfMessages: 'odfMessages',
  odfImportResult: 'odfImportResult',
  coachCardImportResult: 'coachCardImportResult',
  pscbScenes: 'pscbScenes',
  unitsChangeInProgress: 'unitsChangeInProgress',
  getAllCancellationTokens: 'getAllCancellationTokens',
  isOdfMessageGenerationInProgress: 'isOdfMessageGenerationInProgress',
  isPrintoutGenerationInProgress: 'isPrintoutGenerationInProgress',
  isOdfImportInProgress: 'isOdfImportInProgress',
  isCoachCardImportInProgress: 'isCoachCardImportInProgress',
  isSaveJudgeAllocationInProgress: 'isSaveJudgeAllocationInProgress',
  isSaveStartListInProgress: 'isSaveStartListInProgress',
  isRegisteredParticipantsLoadingInProgress: 'isRegisteredParticipantsLoadingInProgress',
  isUnitParticipantsLoadingInProgress: 'isUnitParticipantsLoadingInProgress',
  isRegisteredJudgesLoadingInProgress: 'isRegisteredJudgesLoadingInProgress',
  isAllocatedJudgesLoadingInProgress: 'isAllocatedJudgesLoadingInProgress',
  isPscbAllOffInProgress: 'isPscbAllOffInProgress',
  isPscbDisplaySceneInProgress: 'isPscbDisplaySceneInProgress',
  isConnectedPublisher: 'isConnectedPublisher'
}

const freeRoutine = 'FREE_ROUTINE_KIND'
const technicalRoutine = 'TECHNICAL_ROUTINE_KIND'
const acrobaticRoutine = 'ACRO_KIND'

const isSceneDisabled = (sceneId, isUnitSelected) =>
  tournamentNodeContextualScenes.includes(sceneId) && !isUnitSelected

export const gettersDefinition = {
  [getters.selectedUnit]: ({ selectedUnit } = {}) => {
    return {
      ...selectedUnit,
      ...(selectedUnit &&
        parseInt(selectedUnit.teamMemberCount) === 1 && {
        isSoloRoutine: true
      }),
      ...(selectedUnit &&
        parseInt(selectedUnit.teamMemberCount) === 2 && {
        isDuetRoutine: true
      }),
      ...(selectedUnit &&
        parseInt(selectedUnit.teamMemberCount) > 2 && { isTeamRoutine: true }),
      ...(selectedUnit &&
        selectedUnit.sessionKindCategoryType === freeRoutine && {
        isFreeRoutine: true
      }),
      ...(selectedUnit &&
        selectedUnit.sessionKindCategoryType === technicalRoutine && {
        isTechnicalRoutine: true
      }),
      ...(selectedUnit &&
        selectedUnit.sessionKindCategoryType === acrobaticRoutine && {
        isAcrobaticRoutine: true
      })
    }
  },
  [getters.selectedRegisteredParticipants]: ({
    selectedRegisteredParticipants
  } = {}) => selectedRegisteredParticipants,
  [getters.allParticipants]: ({ participants }) => participants,
  [getters.selectedRegisteredJudges]: ({ selectedRegisteredJudges } = {}) =>
    selectedRegisteredJudges,
  [getters.selectedAllocatedJudges]: ({ selectedAllocatedJudges } = {}) =>
    selectedAllocatedJudges,
  [getters.configuration]: ({ configuration } = {}) => configuration,
  [getters.printoutsStatuses]: ({
    competitionPrintoutsStatuses,
    eventPrintoutsStatuses,
    unitPrintoutsStatuses
  } = []) => {
    return [
      ...competitionPrintoutsStatuses,
      ...eventPrintoutsStatuses,
      ...unitPrintoutsStatuses
    ]
  },
  [getters.odfMessages]: ({ odfMessages }) => odfMessages,
  [getters.eventsTree]: () => EVENTS_PHASES,
  [getters.odfImportResult]: store => store.odfImportResult,
  [getters.coachCardImportResult]: store => store.coachCardImportResult,
  [getters.pscbScenes]: ({ pscbScenes, selectedUnit }) => pscbScenes
    .filter(scene => !Array.isArray(scene.events) ||
      scene.events.some(s => s === selectedUnit?.eventId))
    .map(scene => ({
      ...scene,
      disabled: isSceneDisabled(scene.id, !isEmpty(selectedUnit))
    })),
  [getters.getAllCancellationTokens]: store => store.cancellationTokens,
  [getters.isConnectedPublisher]: store => store.isConnectedPublisher,

  ...mapValues(inProgressGetters, context => ({ inProgressCounters }) =>
    (inProgressCounters[context] ?? 0) > 0
  ),
  ...mapValues(inProgressObjectGetters, context => ({ inProgressCounters }) =>
    mapValues(inProgressCounters[context], x => x > 0)
  )
}
