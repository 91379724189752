import ChiefRecorder from '@/views/ChiefRecorder'
import { unit } from '@/store/modules'
import store from '@/store'
import { chiefRecorderListeners } from '@/store/actionListeners'
import { registerActionListeners } from '@/store/base/store-listeners'

const routes = [
  {
    path: '/chief-recorder',
    component: ChiefRecorder,
    name: 'chief-recorder',
    async beforeEnter (to, from, next) {
      registerActionListeners({ store, subscribers: chiefRecorderListeners })
      await unit.globals.actions.fetchCurrent(store)
      next()
    }
  }
]

export default {
  routes
}
