import CoachCardValidation from '@/views/CoachCardValidation'
import store from '@/store'
import { coachCardsListeners } from '@/store/actionListeners'
import { registerActionListeners } from '@/store/base/store-listeners'

const routes = [{
  path: '/coach-card-validation',
  name: 'coach-card-validation',
  component: CoachCardValidation,
  async beforeEnter (to, from, next) {
    registerActionListeners({ store, subscribers: coachCardsListeners })
    next()
  }
}]

export default {
  routes
}
