<template>
  <section>
    <Suspense class="summary"
      :isReady='!isInProgress("fetchingAllParticipants")'>
      <section class="summary__items">
        <section
          class="item"
        >
          <span class="item__caption">Rank</span>
          <Suspense :isReady="isReady"
                    class="item__value item__value--total">
            <span>{{ displayedRank }}</span>
          </Suspense>
        </section>
        <section class="item">
          <span class="item__caption">Other penalties</span>
          <!-- oninput wad added to prevent default behavior
          which is showing a value not passing validation rules -->
          <v-text-field
            v-model="displayedPenalty"
            class="item__input pa-0 ma-0"
            :rules=penaltyValidationRules
            type="number"
            filled
            step="0.05"
            min="0"
            oninput="if (this.value < 0) this.value = 0"
            :loading='isInProgress("otherPenalties")'
            :readonly="readonly"
            v-if="!readonly"
          />
          <Suspense :isReady="isReady"
                    class="item__value item__value--total"
          v-else>
            <span>{{ displayedPenalty }}</span>
          </Suspense>
        </section>
        <section class="item">
          <span class="item__caption">TOTAL ROUTINE SCORE</span>
          <Suspense :isReady="isReady"
            class="item__value item__value--total">
            <span>{{ displayedTotalScore }}</span>
          </Suspense>
        </section>
      </section>
      <v-btn
        v-if="shouldShowAbort"
        color="primary"
        small
        :loading="isInProgress('abort')"
        @click="onAbortRoutine">
        Abort
      </v-btn>
    </Suspense>
  </section>
</template>

<script>
import Suspense from '@/components/Suspense'
import { participants, routine, chiefRecorder } from '@/store/modules'
import { isNil, debounce } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { getPenaltyValidationRules } from '@/services/validation-service'

export default {
  name: 'ScoresSummary',
  props: {
    value: {
      type: Object
    },
    readonly: {
      type: Boolean,
      default: () => false
    },
    isReady: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      penaltyValidationRules: getPenaltyValidationRules()
    }
  },
  components: {
    Suspense
  },
  methods: {
    ...mapActions(
      routine.namespace,
      [
        routine.actions.abort,
        routine.actions.fetchCurrent
      ]
    ),
    ...mapActions(
      participants.namespace,
      [
        participants.actions.updateSelectedParticipantPenalties
      ]
    ),
    async onAbortRoutine () {
      await this.abort()
      await this.fetchCurrent()
    }
  },
  computed: {
    ...mapGetters(
      participants.namespace,
      [
        participants.getters.performingParticipant,
        participants.getters.selectedParticipant
      ]
    ),
    ...mapGetters(
      chiefRecorder.namespace,
      [
        chiefRecorder.getters.isInProgress
      ]
    ),
    displayedPenalty: {
      get () {
        return (this.value?.penalty || 0).toFixed(2)
      },
      set: debounce(async function (value) {
        await this.updateSelectedParticipantPenalties({
          otherPenalties: value,
          penaltyName: 'otherPenalties'
        })
      }, 1000)
    },
    displayedTotalScore () {
      if (!this.value || !this.value.totalScore) {
        return '--:--'
      }

      return this.value.totalScore.toFixed(4)
    },
    displayedRank () {
      if (!this.value || !this.value.rank) {
        return '--'
      }

      return this.value.rank
    },
    shouldShowAbort () {
      return this.isSelectedParticipantPerforming && !this.readonly
    },
    isSelectedParticipantPerforming () {
      const { id: pid } = this.performingParticipant || {}
      const { id: sid } = this.selectedParticipant || {}
      return pid === sid && !isNil(sid) && !isNil(pid)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/container';
@import '~@/styles/typology';

.summary {
  @include container('justified', 'center');
  @include border('regular', 1rem);
  @include primary-caption-l4;

  flex-direction: row-reverse;

  padding: 1rem;

  & > * {
    margin: 0 0.5rem;
  }

  &__items {
    @include container('right', 'center');
  }
}

.item {
  @include container($vAlignTo: 'center');

  &__input {
    @include value-l3;

    max-width: 10rem;
  }

  &__caption {
    @include secondary-caption-l4;

    padding: 0.5rem;

    font-weight: bold;
  }

  &__value {
    @include value-l4;

    padding: 0.5rem;

    &--total {
      font-weight: bold;
    }
  }
}
</style>
