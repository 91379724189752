import { subscribe as timekeeperSubscribe, actions as timekeeperActions, getters as timekeeperGetters } from './timekeeper'
import { subscribe as routineSubscribe, actions as routineActions, getters as routineGetters } from './routine'
import { subscribe as judgeSubscribe, actions as judgeActions, getters as judgeGetters, SpecialKeys } from './judge'
import { actions as unitActions, getters as unitGetters, subscribe as unitSubscriber } from './unit'
import { actions as participantsActions, getters as participantsGetters, subscribe as participantSubscriber } from './participants'
import { actions as olrActions, getters as olrGetters, subscribe as olrSubscriber } from './olr'
import { actions as chiefRecorderActions, getters as chiefRecorderGetters, subscribe as chiefRecorderSubscriber } from './chief-recorder'
import { subscribe as configurationSubscriber, actions as configurationActions, getters as configurationGetters } from './configuration'
import { actions as synchronizationErrorsActions, getters as synchronizationErrorsGetters } from './synchronization-errors'
import { actions as contextSelectionActions, getters as contextSelectionGetters } from './context-selection'
import { subscribe as coachCardsSubscriber, actions as coachCardsActions, getters as coachCardsGetters } from './coach-cards'
import { mapValues } from 'lodash'

const rootGettersFactory = namespace => name => store => {
  const { rootGetters, getters } = store
  return (rootGetters || getters)[`${namespace}/${name}`]
}

const rootActionsFactory = namespace => name => ({ dispatch }, ...args) =>
  dispatch(`${namespace}/${name}`, ...args)

export const timekeeper = {
  namespace: 'timekeeper',
  getters: timekeeperGetters,
  actions: timekeeperActions,
  subscriber: timekeeperSubscribe,
  globals: {
    getters: mapValues(timekeeperGetters, rootGettersFactory('timekeeper')),
    actions: mapValues(timekeeperActions, rootActionsFactory('timekeeper'))
  }
}

export const routine = {
  namespace: 'routine',
  getters: routineGetters,
  actions: routineActions,
  subscriber: routineSubscribe,
  globals: {
    getters: mapValues(routineGetters, rootGettersFactory('routine')),
    actions: mapValues(routineActions, rootActionsFactory('routine'))
  }
}

export const judge = {
  namespace: 'judge',
  consts: Object.freeze({
    SpecialKeys
  }),
  getters: judgeGetters,
  actions: judgeActions,
  subscriber: judgeSubscribe,
  globals: {
    getters: mapValues(judgeGetters, rootGettersFactory('judge')),
    actions: mapValues(judgeActions, rootActionsFactory('judge'))
  }
}

export const unit = {
  namespace: 'unit',
  getters: unitGetters,
  actions: unitActions,
  subscriber: unitSubscriber,
  globals: {
    getters: mapValues(unitGetters, rootGettersFactory('unit')),
    actions: mapValues(unitActions, rootActionsFactory('unit'))
  }
}

export const participants = {
  namespace: 'participants',
  getters: participantsGetters,
  actions: participantsActions,
  subscriber: participantSubscriber,
  globals: {
    getters: mapValues(participantsGetters, rootGettersFactory('participants')),
    actions: mapValues(participantsActions, rootActionsFactory('participants'))
  }
}

export const chiefRecorder = {
  namespace: 'chiefRecorder',
  getters: chiefRecorderGetters,
  actions: chiefRecorderActions,
  subscriber: chiefRecorderSubscriber,
  globals: {
    getters: mapValues(chiefRecorderGetters, rootGettersFactory('chiefRecorder')),
    actions: mapValues(chiefRecorderActions, rootActionsFactory('chiefRecorder'))
  }
}

export const olr = {
  namespace: 'olr',
  getters: olrGetters,
  actions: olrActions,
  subscriber: olrSubscriber,
  globals: {
    getters: mapValues(olrGetters, rootGettersFactory('olr')),
    actions: mapValues(olrActions, rootActionsFactory('olr'))
  }
}

export const configuration = {
  namespace: 'configuration',
  getters: configurationGetters,
  actions: configurationActions,
  subscriber: configurationSubscriber,
  globals: {
    getters: mapValues(configurationGetters, rootGettersFactory('configuration')),
    actions: mapValues(configurationActions, rootActionsFactory('configuration'))
  }
}

export const synchronizationErrors = {
  namespace: 'synchronizationErrors',
  getters: synchronizationErrorsGetters,
  actions: synchronizationErrorsActions,
  globals: {
    getters: mapValues(synchronizationErrorsGetters, rootGettersFactory('synchronizationErrors')),
    actions: mapValues(synchronizationErrorsActions, rootActionsFactory('synchronizationErrors'))
  }
}

export const contextSelection = {
  namespace: 'contextSelection',
  getters: contextSelectionGetters,
  actions: contextSelectionActions,
  globals: {
    getters: mapValues(contextSelectionGetters, rootGettersFactory('contextSelection')),
    actions: mapValues(contextSelectionActions, rootActionsFactory('contextSelection'))
  }
}

export const coachCards = {
  namespace: 'coachCards',
  getters: coachCardsGetters,
  actions: coachCardsActions,
  subscriber: coachCardsSubscriber,
  globals: {
    getters: mapValues(coachCardsGetters, rootGettersFactory('coachCards')),
    actions: mapValues(coachCardsActions, rootActionsFactory('coachCards'))
  }
}
