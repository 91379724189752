<template>
  <td>
    <div class="buttons">
      <bi-stable-push-button
        v-if="assistantScore.toLowerCase() === 'confirmed'"
        kind="green"
        class="buttons-item"
        value
        disabled
        >
        ✓ Confirmed
      </bi-stable-push-button>
      <bi-stable-push-button
        v-if="assistantScore.toLowerCase() === 'review'"
        kind="orange"
        class="buttons-item"
        value
        disabled
        >
        ⚠ Review
      </bi-stable-push-button>
      <bi-stable-push-button
        v-if="assistantScore.toLowerCase() === 'basemark'"
        kind="red"
        class="buttons-item"
        value
        disabled
        >
        🗙 Base&nbsp;Mark
      </bi-stable-push-button>
    </div>
  </td>
</template>

<script>
import BiStablePushButton from '@/components/difficulty-technical-controller/BiStablePushButton'

export default {
  name: 'DifficultyTechnicalAssistantEntry',
  components: { BiStablePushButton },
  props: {
    assistantScore: {
      type: String
    }
  }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables";
@import '~@/styles/container';

.buttons {
  @include container('center', 'center');
  max-width: 12rem;

  &-item {
    margin: 0 0.5rem;
  }

  button {
    width: 80%;
  }
}

</style>
