import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"value":_vm.opened},on:{"input":function($event){return _vm.$emit('update:opened', $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",function(){return [_c(VIcon,_vm._g(_vm._b({attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('menuOpened')}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icon)+" ")])]},null,{ on: on, attrs: attrs, disabled: _vm.disabled })]}}],null,true)},[_c(VList,[_vm._l((_vm.displayableOptions),function(ref){
var option = ref.option;
var disabled = ref.disabled;
return _vm._t("option",function(){return [_c(VListItem,{key:option,attrs:{"disabled":disabled},on:{"click":function($event){return _vm.onOptionSelected(option)}}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(option)+" ")])],1)]},null,{
        option: option,
        disabled: disabled })})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }