export default Object.freeze({
  ENTRY_TIMER_STARTED: 1,
  DECK_TIMER_STARTED: 2,
  ROUTINE_TIMER_STARTED: 3,
  TIMERS_COMPLETED: 4,
  JUDGE_HAND_IS_RAISED: 5,
  ROUTINE_STARTED: 6,
  ROUTINE_FINISHED: 7,
  ROUTINE_ABORTED: 8,
  UNIT_STARTED: 9,
  SCORE_SUBMITTED: 10,
  SCORES_RECALLED: 11,
  ENTRY_TIMER_STOPPED: 12,
  JUDGE_HAND_IS_LOWERED: 13,
  JUDGE_JOINED: 14,
  JUDGE_LEFT: 15,
  DTAC_SCORE_SUBMITTED: 16,
  UNIT_STATUS_CHANGED: 17,
  PRINTOUT_FINISHED: 18
})
