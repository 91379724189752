import { cond, conforms, isNumber, noop, overEvery, stubTrue } from 'lodash'

export const getters = {
  raisedHandsByPanel: 'raisedHandsByPanel',
  isScoreRecalculated: 'isScoreRecalculated',
  isInProgress: 'isInProgress',
  sendScoresError: 'sendScoresError'
}

const isPositive = x => x > 0
const isPanelValid = conforms({
  id: overEvery([isNumber, isPositive]),
  seat: overEvery([isNumber, isPositive])
})

const validatePanel = cond([
  [isPanelValid, noop],
  [
    stubTrue,
    x => {
      throw Error(`Invalid hand panel information. Got: ${JSON.stringify(x)}`)
    }
  ]
])

const byPanel = (acc, value) => {
  validatePanel(value)

  acc[value.id] = acc[value.id] || []
  acc[value.id][value.seat] = true
  return acc
}

export const gettersDefinition = {
  [getters.raisedHandsByPanel]: ({ raisedHands = [] } = {}) =>
    raisedHands.reduce(byPanel, []),
  [getters.isScoreRecalculated]: ({ isInProgress }) =>
    !isInProgress.submittingCalculateScores,
  [getters.isInProgress]: ({ isInProgress }) => (element) => {
    return isInProgress[element]
  },
  [getters.sendScoresError]: ({ sendScoresError }) => sendScoresError
}
