const createEmptyApiActions = (infinitive, continuous) =>
  name =>
    ({
      [`${continuous}${name}`]: () => {},
      [`${infinitive}${name}Succeeded`]: () => {},
      [`${infinitive}${name}Failed`]: () => {}
    })

export const emptyFetchApiActions = createEmptyApiActions('fetch', 'fetching')
export const emptySubmitApiActions = createEmptyApiActions('submit', 'submitting')
