<template>
  <div v-if="authorized" class="home">
    <go-to-current-participant-button/>
    <Menu :authState="authState" skipRole="timekeeper" />
    <panel-info :unitName="currentUnit.name" :panel="panelInfo" />
    <participant-info :participant="participant"/>
    <div class='timekeeper'>
      <timers-box
        :unit-id="unitId"
        :participant-id="participantId"/>
    </div>
  </div>
</template>

<script>
import TimersBox from '@/components/TimersBox.vue'
import Menu from '@/components/Menu'
import PanelInfo from '@/components/PanelInfo'
import GoToCurrentParticipantButton from '@/components/GoToCurrentParticipantButton'
import ParticipantInfo from '@/components/ParticipantInfo'
import { routine, unit } from '@/store/modules'
import { mapGetters } from 'vuex'
import { authorize, setupAuthorization } from '@/services/auth-service'

export default {
  name: 'Timekeeper',
  components: {
    TimersBox,
    Menu,
    PanelInfo,
    GoToCurrentParticipantButton,
    ParticipantInfo
  },
  data () {
    return setupAuthorization({ component: this, requiredRoles: ['timekeeper'] })
  },
  created () {
    if (authorize({ component: this })) {
      // placeholder for init logic
    }
  },
  computed: {
    ...mapGetters(
      unit.namespace,
      [
        unit.getters.currentUnit
      ]
    ),
    ...mapGetters(
      routine.namespace,
      [
        routine.getters.unitId,
        routine.getters.participantId,
        routine.getters.participant
      ]
    ),
    panelInfo () {
      return {
        name: 'Timekeeper'
      }
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/variables';
@import '~@/styles/typology';

.timekeeper {
  max-width: $max-view-width;
  margin: 0 auto;
  padding: 2rem;
}
.routine-info-title {
  @include secondary-caption-l2;

  margin: 1rem 0;
}
</style>
