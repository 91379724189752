<template>
  <v-dialog
    v-model="dialog"
    max-width="50%"
    persistent>
    <template #activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }" />
    </template>
    <v-card>
      <v-card-title class="text-h5">
        {{ title }}
      </v-card-title>
      <v-card-text
        v-for="(line, idx) in prompt"
        :key="idx">
        {{ line }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-for="({id, label}) in actions"
          :key="id"
          color="green darken-1"
          text
          @click="onActionClicked(id)">
          {{ label }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog></template>

<script>
export default {
  name: 'Prompt',
  emits: ['onAction'],
  props: {
    title: {
      type: String,
      required: true
    },
    prompt: {
      type: Array,
      required: true
    },
    actions: {
      type: Array,
      default: () => [
        { id: 'cancel', label: 'Cancel' },
        { id: 'ok', label: 'Confirm' }
      ]
    }
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    onActionClicked (id) {
      this.dialog = false
      this.$emit('onAction', id)
    }
  },
  computed: {
  }
}
</script>
