import store from '@/store'
import { contextSelection } from '@/store/modules'
import { find, isNil } from 'lodash'

const exists = (router, path) => {
  const basePath = path.substr(0, path.indexOf('?')) ?? path
  return find(router.getRoutes(), ['path', basePath])
}

const continueWith = (path = undefined) => ({ path })

const denyContextlessAccess = async (to, _from, _next, _router) => {
  const currentContextId = contextSelection.globals.getters.selectedContext(store)

  if (to.name !== 'context-selection' && isNil(currentContextId)) {
    return continueWith('/context-selection')
  }
}

const denyNotExistingRoute = (to, _from, _next, router) => {
  if (
    to.path !== '/' &&
    to.path !== '/oauth/callback' &&
    !exists(router, to.fullPath)
  ) {
    return continueWith('/')
  }
}

const findFirstNonEmpty = async ({ to, from, next, router }, ...functions) => {
  for (const fnc of functions) {
    const redirect = await fnc(to, from, next, router)
    if (redirect !== undefined) {
      return redirect
    }
  }
}

export const setupRoutesGuard = router => router.beforeEach(async (to, from, next) => {
  contextSelection.globals.actions.restoreContext(store)

  const redirectTo = await findFirstNonEmpty(
    { to, from, next, router },
    denyContextlessAccess,
    denyNotExistingRoute
  )

  if (redirectTo) {
    next(redirectTo.path)
    return
  }

  next()
})
