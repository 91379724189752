import { callApi, configuration, createSimpleHandler, dispatchGlobal } from '@/api'
import { getters } from './getters'
import { mutations } from './mutations'
import { mapValues } from 'lodash'

export const actions = {
  fetchConfiguration: 'fetchConfiguration',
  changeSetting: 'changeSetting',
  saveSettings: 'saveSettings',
  'settings.synchroTimeframeWindow': 'settings.synchroTimeframeWindow',
  'settings.competitionName': 'settings.competitionName',
  'settings.aiJudgesCount': 'settings.aiJudgesCount',
  'settings.elJudgesCount': 'settings.elJudgesCount',
  'settings.coachCardPushEnabled': 'settings.coachCardPushEnabled',
  'settings.timezone': 'settings.timezone',
  'settings.oneCountryOneRankEnabled': 'settings.oneCountryOneRankEnabled',
  'settings.automaticODFGeneration': 'settings.automaticODFGeneration',
  'settings.automaticPublisher': 'settings.automaticPublisher',
  'settings.datcJudgesCount': 'settings.datcJudgesCount',
  'settings.dtcJudgesCount': 'settings.dtcJudgesCount',
  'settings.testMode': 'settings.testMode'
}

const handleConfigurationChange = localGetters => {
  if (localGetters[getters.isUserSnapEnabled]) {
    window.onUsersnapCXLoad = function (api) {
      api.init()
    }
    const userSnapUri = localGetters[getters.getUserSnapUri]
    const userSnapScript = document.createElement('script')
    userSnapScript.setAttribute('src', userSnapUri || '')
    document.head.appendChild(userSnapScript)
  }
}

const createGlobalConfigurationSucceededHandler = ({
  dispatch,
  commit,
  getters: localGetters
}) => configuration => {
  commit(mutations.setFeatureFlags, configuration)
  commit(mutations.setSettings, configuration)
  dispatchGlobal(dispatch, 'fetchConfigurationSucceeded')

  handleConfigurationChange(localGetters)
}

export const actionsDefinition = {
  async [actions.fetchConfiguration] (store) {
    return await callApi(
      async () => configuration.configuration(),
      createSimpleHandler('fetchingConfiguration', store),
      createGlobalConfigurationSucceededHandler(store),
      createSimpleHandler('fetchConfigurationFailed', store)
    )
  },

  async [actions.saveSettings] (store) {
    const mappedSettings = mapValues(store.getters[getters.allSettings], 'value')
    await callApi(
      async () => configuration.saveSettings(mappedSettings),
      createSimpleHandler('submittingSaveSettings', store),
      createSimpleHandler('submitSaveSettingsSucceeded', store),
      createSimpleHandler('submitSaveSettingsFailed', store)
    )
  },

  [actions['settings.synchroTimeframeWindow']] ({ commit }, value) {
    commit(mutations.changeSetting, {
      key: 'synchroTimeframeWindow.value',
      value
    })
  },
  [actions['settings.competitionName']] ({ commit }, value) {
    commit(mutations.changeSetting, {
      key: 'competitionName.value',
      value
    })
  },
  [actions['settings.aiJudgesCount']] ({ commit }, value) {
    commit(mutations.changeSetting, {
      key: 'judges.value.requiredAI',
      value
    })
  },
  [actions['settings.elJudgesCount']] ({ commit }, value) {
    commit(mutations.changeSetting, {
      key: 'judges.value.requiredElements',
      value
    })
  },
  [actions['settings.coachCardPushEnabled']] ({ commit }, value) {
    commit(mutations.changeSetting, {
      key: 'coachCardPushEnabled.value',
      value
    })
  },
  [actions['settings.timezone']] ({ commit }, value) {
    commit(mutations.changeSetting, {
      key: 'timezone.value',
      value
    })
  },
  [actions['settings.oneCountryOneRankEnabled']] ({ commit }, value) {
    commit(mutations.changeSetting, {
      key: 'oneCountryOneRankEnabled.value',
      value
    })
  },
  [actions['settings.testMode']] ({ commit }, value) {
    commit(mutations.changeSetting, {
      key: 'testMode.value',
      value
    })
  },
  [actions['settings.automaticODFGeneration']] ({ commit }, value) {
    commit(mutations.changeSetting, {
      key: 'automaticODFGeneration.value',
      value
    })
  },
  [actions['settings.automaticPublisher']] ({ commit }, value) {
    commit(mutations.changeSetting, {
      key: 'automaticPublisher.value',
      value
    })
  },
  [actions['settings.dtcJudgesCount']] ({ commit }, value) {
    commit(mutations.changeSetting, {
      key: 'judges.value.requiredDTC',
      value
    })
  },
  [actions['settings.datcJudgesCount']] ({ commit }, value) {
    commit(mutations.changeSetting, {
      key: 'judges.value.requiredDATC',
      value
    })
  }
}
