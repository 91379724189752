<template>
  <div class='participant-info'>
    <div class='participant-info__noc'>
      <img src="@/assets/logos/st-logo.png"/>
      <span>{{ noc }}</span>
    </div>
    <div class='participant-info__members'>{{startOrder}}. {{members}}</div>
  </div>
</template>

<script>
import { get, join } from 'lodash'

export default {
  name: 'ParticipantInfo',
  props: {
    participant: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    members () {
      return join(
        get(this.participant, 'members', []),
        ' / ')
    },
    noc () {
      return get(this.participant, 'noc')
    },
    startOrder () {
      return get(this.participant, 'startOrder')
    }
  }
}
</script>

<style lang='scss' scoped>
@use "sass:map";
@use "~@/styles/mixins";
@import '~@/styles/container';
@import '~@/styles/font';

.participant-info {
  @include fontMain;
  @include mixins.from-map(
      font-size,
      map.merge(
          $medium,
          (
            'default': map.get($regular, 'default')
          )));

  @include container($alignTo: 'left', $vAlignTo: 'center');

  & > div {
    @include container($alignTo: 'left', $vAlignTo: 'center');

    height: 30px;
    margin: 0 1rem;
  }

  &__noc {
    img {
      width: 26px;
    }
  }
}
</style>
