<template>
  <Suspense :isReady='!isInProgress("fetchingAllParticipants")'>
    <ol
      class="start-list">
      <li
        v-for="({
          id, status, scoreOrIrm, irmOptions, hasCoachCard, rank, startOrder, members, noc
        }) in displayableParticipants"
        :key="id"
        :class="{ 'start-list__item--selected': selectedParticipant && selectedParticipant.id === id }"
        class="start-list__item"
        @click="onParticipantSelected(id)">
        <span
          class="start-list-item__participant-details">
          {{ startOrder }}. <b>{{noc}}</b> {{ members }} - ({{status}})
        </span>
        <span
          class="start-list-item__participant-score-or-irm">
          <v-tooltip
            bottom
            v-if="!hasCoachCard">
            <template v-slot:activator="{ attrs, on}">
              <v-icon
                v-on="on"
                v-bind="attrs"
                class="missing-cc-icon">
                mdi-alert
              </v-icon>
            </template>
            Coach card is missing
          </v-tooltip>
          <span>
            {{rank ? `[${rank}]` : ''}} {{ scoreOrIrm }}
          </span>
          <DropDownMenu
            :disabled="status === 'performing'"
            :options="irmOptions"
            @optionSelected="onIrmOptionSelected($event, id)"/>
        </span>
      </li>
    </ol>
  </Suspense>
</template>

<script>
import DropDownMenu from '@/components/DropDownMenu'
import Suspense from '@/components/Suspense'
import { participants, chiefRecorder } from '@/store/modules'
import { join, map, reject } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import Irms from '@/enums/Irms'

const defaultIRM = 'Default'
const removeIRMOption = 'Remove IRM'

const toParticipantNumber = index => index + 1

const toScoreOrIrm = ({ totalScore, irm }) => {
  return irm?.toUpperCase() || (totalScore || 0).toFixed(2)
}

const toIrmOptions = (irm) => {
  const irmOptions = reject(Irms, value => value === irm)
  if (irm && irm !== defaultIRM) {
    irmOptions.push(removeIRMOption)
  }

  return irmOptions
}

export default {
  name: 'StartList',
  components: { DropDownMenu, Suspense },
  emits: ['participantIdChanged', 'participantNumberChanged'],
  props: {
    unitId: {
      type: String,
      default: null
    }
  },
  async created () {
    await this.fetchAllParticipants({
      unitId: this.unitId
    })
  },
  watch: {
    async unitId (value) {
      await this.fetchAllParticipants({
        unitId: value
      })
    }
  },
  methods: {
    ...mapActions(
      participants.namespace,
      [
        participants.actions.fetchAllParticipants,
        participants.actions.fetchDetails,
        participants.actions.updateIrm
      ]
    ),
    async onParticipantSelected (participantId) {
      if (this.selectedParticipant?.id === participantId) {
        return
      }

      await this.fetchDetails({
        unitId: this.unitId,
        participantId: participantId
      })
    },
    async onIrmOptionSelected (irmOption, participantId) {
      const irm = irmOption === removeIRMOption ? defaultIRM : irmOption
      await this.updateIrm({ irm, participantId })
    }
  },
  computed: {
    displayableParticipants () {
      return map(
        this.allParticipants,
        (participant, index) => ({
          startOrder: toParticipantNumber(index),
          status: participant.status,
          number: toParticipantNumber(index),
          scoreOrIrm: toScoreOrIrm(participant),
          id: participant.id,
          irmOptions: toIrmOptions(participant.irm),
          hasCoachCard: participant.hasCoachCard,
          rank: participant.rank,
          noc: participant.noc,
          members: join(participant.members, ' / ')
        }))
    },
    ...mapGetters(
      participants.namespace,
      [
        participants.getters.allParticipants,
        participants.getters.hasParticipants,
        participants.getters.selectedParticipant
      ]
    ),
    ...mapGetters(
      chiefRecorder.namespace,
      [
        chiefRecorder.getters.isInProgress
      ]
    )
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/container";
@import "~@/styles/variables";

.start-list {
  overflow: hidden;

  margin: 0;
  padding: 0;

  list-style: inside decimal;

  &__item {
    @include container('justified');

    padding: 0.25rem;

    cursor: default;

    &--selected {
      background-color: $blue;
      color: $white;
    }
  }

  &-item__participant-details {
    text-align: left;
    max-width: 350px;
  }

  &-item__participant-score-or-irm {
    @include container('justified');
    position: relative;

    column-gap: 1rem;

    .missing-cc-icon {
      color: $orange;
    }
  }
}
</style>
