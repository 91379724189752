<template>
  <div v-if="authorized">
    <go-to-current-participant-button/>
    <Menu :authState="authState"/>
    <div class="settings">
      <h1>Settings</h1>
      <v-form v-model="isFormValid">
        <v-simple-table
          class="settings__form"
          fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Property
                </th>
                <th class="text-left">
                  Value
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-once>Competition name</td>
                <td>
                  <settings-text
                  :validation="[required]"
                  setting-name="competitionName"
                  @changed="onSettingChanged"/>
                </td>
              </tr>
              <tr>
                <td v-once>Synchronization error time frame</td>
                <td>
                  <settings-slider
                    :max="5000"
                    :min="250"
                    setting-name="synchroTimeframeWindow"
                    units="ms"
                    @changed="onSettingChanged"/>
                </td>
              </tr>
              <tr>
                <td>Elements judges count</td>
                <td>
                  <settings-slider
                    :max="5"
                    :min="0"
                    setting-name="elJudgesCount"
                    @changed="onSettingChanged"/>
                </td>
              </tr>
              <tr>
                <td>Artistic Impression judges count</td>
                <td>
                  <settings-slider
                    :max="5"
                    :min="0"
                    setting-name="aiJudgesCount"
                    @changed="onSettingChanged"/>
                </td>
              </tr>
              <tr>
                <td>Difficulty Technical Controller judges count</td>
                <td>
                  <settings-slider
                    :max="1"
                    :min="0"
                    setting-name="dtcJudgesCount"
                    @changed="onSettingChanged"/>
                </td>
              </tr>
              <tr>
                <td>Difficulty Assistant Technical Controller  judges count</td>
                <td>
                  <settings-slider
                    :max="2"
                    :min="0"
                    setting-name="datcJudgesCount"
                    @changed="onSettingChanged"/>
                </td>
              </tr>
              <tr>
                <td>Coach Card push enabled</td>
                <td>
                  <CoachCardPushEnabledSettingsSwitch
                    @changed="onSettingChanged"
                  />
                </td>
              </tr>
              <tr>
                <td>Timezone</td>
                <td>
                  <settings-text
                    :validation="[required, timezoneValidation]"
                    setting-name="timezone"
                    @changed="onSettingChanged"/>
                </td>
              </tr>
              <tr>
                <td>Enable 'one country/one rank' rule?</td>
                <td>
                  <settings-switch
                    setting-name="oneCountryOneRankEnabled"
                    switch-label="One country/one rank enabled"
                    @changed="onSettingChanged"
                  />
                </td>
              </tr>
              <tr>
                <td>Enable automatic ODF generation</td>
                <td>
                  <settings-switch
                    setting-name="automaticODFGeneration"
                    switch-label="Automatic ODF generation"
                    @changed="onSettingChanged"
                  />
                </td>
              </tr>
              <tr>
                <td>Enable automatic Publisher</td>
                <td>
                  <settings-switch
                    setting-name="automaticPublisher"
                    switch-label="Automatic Publisher"
                    @changed="onSettingChanged"
                  />
                </td>
              </tr>
              <tr>
                <td>Enable test mode</td>
                <td>
                  <settings-switch
                    setting-name="testMode"
                    switch-label="Test mode"
                    @changed="onSettingChanged"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-form>
      <v-btn
        :disabled="!areSettingsTouched || !isFormValid"
        :loading="isSettingsSaveInProgress"
        color="primary"
        @click="onSaveSettingsClicked">Save
      </v-btn>
    </div>
  </div>
</template>

<script>
import Menu from '@/components/Menu'
import SettingsSlider from '@/components/settings/SettingsSlider'
import SettingsText from '@/components/settings/SettingsText'
import SettingsSwitch from '@/components/settings/SettingsSwitch'
import GoToCurrentParticipantButton from '@/components/GoToCurrentParticipantButton'
import { mapActions, mapGetters } from 'vuex'
import { configuration, contextSelection } from '@/store/modules'
import { authorize, setupAuthorization } from '@/services/auth-service'
import CoachCardPushEnabledSettingsSwitch from '@/components/settings/CoachCardPushEnabledSettingsSwitch.vue'

export default {
  name: 'Settings',
  components: {
    CoachCardPushEnabledSettingsSwitch,
    SettingsText,
    SettingsSlider,
    SettingsSwitch,
    Menu,
    GoToCurrentParticipantButton
  },
  data () {
    return {
      areSettingsTouched: false,
      isFormValid: false,
      ...setupAuthorization({ component: this, requiredRoles: ['olr-operator'] })
    }
  },
  created () {
    if (authorize({ component: this })) {
      // placeholder for init logic
    }
  },
  computed: {
    ...mapGetters(
      configuration.namespace,
      [
        configuration.getters.isSettingsSaveInProgress
      ]
    ),
    ...mapGetters(
      contextSelection.namespace,
      [
        contextSelection.getters.selectedContext
      ]
    )
  },
  methods: {
    ...mapActions(
      configuration.namespace,
      [
        configuration.actions.saveSettings
      ]
    ),
    required (v) {
      return !!v || 'Field is required'
    },
    timezoneValidation (v) {
      const pattern = /[+|-]\d\d:\d\d/sg
      if (!v?.match(pattern)) {
        return 'Timezone must be in format +00:00 or -00:00'
      }
      const parsedMinutes = parseInt(v?.split(':')[1])
      const parsedHours = parseInt(v?.split(':')[0].slice(1))
      if (parsedMinutes > 59) {
        return 'Minutes must be less than 60'
      }
      if (parsedHours > 14) {
        return 'Hours must be less than 14'
      }
      return true
    },
    onSettingChanged (setting) {
      this.areSettingsTouched = true
    },
    onSaveSettingsClicked () {
      if (this.isFormValid) {
        this.areSettingsTouched = false
        this.saveSettings()
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.settings {
  &__form table > thead > tr > th {
    &:first-of-type {
      min-width: 30%;
      width: 30%;
    }
  }
}
td {
  vertical-align: center;
  text-align: left;
}
</style>
