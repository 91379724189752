import { callApi, coachCards, dispatchGlobal, createSimpleHandler } from '@/api'
import { mutations } from './mutations'

export const actions = Object.freeze({
  fetchCompetitionCoachCards: 'fetchCompetitionCoachCards',
  acceptCoachCard: 'acceptCoachCard',
  rejectCoachCard: 'rejectCoachCard'
})

const createFetchCompetitionCoachCardsSucceededHandler = ({ commit, dispatch }) => data => {
  commit(mutations.setCompetitionCoachCards, data)
  dispatchGlobal(dispatch, 'fetchCompetitionCoachCardsSucceeded')
}

const createFetchCompetitionCoachCardsFailedHandler = ({ commit, dispatch }) => () => {
  commit(mutations.setCompetitionCoachCards, [])
  dispatchGlobal(dispatch, 'fetchCompetitionCoachCardsFailed')
}

export const actionsDefinition = Object.freeze({
  async [actions.fetchCompetitionCoachCards] (store) {
    await callApi(
      async () => await coachCards.getPendingCoachCars(),
      createSimpleHandler('fetchingCompetitionCoachCards', store),
      createFetchCompetitionCoachCardsSucceededHandler(store),
      createFetchCompetitionCoachCardsFailedHandler(store)
    )
  },
  async [actions.acceptCoachCard] (store, { coachCardId }) {
    await callApi(
      async () => await coachCards.sendCoachCardAction(coachCardId, 'accept'),
      createSimpleHandler('submittingAcceptCoachCard', store, { coachCardId }),
      createSimpleHandler('submitAcceptCoachCardSucceeded', store, { coachCardId }),
      createSimpleHandler('submitAcceptCoachCardFailed', store, { coachCardId })
    )
  },
  async [actions.rejectCoachCard] (store, { coachCardId }) {
    await callApi(
      async () => await coachCards.sendCoachCardAction(coachCardId, 'reject'),
      createSimpleHandler('submittingRejectCoachCard', store, { coachCardId }),
      createSimpleHandler('submitRejectCoachCardSucceeded', store, { coachCardId }),
      createSimpleHandler('submitRejectCoachCardFailed', store, { coachCardId })
    )
  }
})
