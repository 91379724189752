import { isArray, isNil, map } from 'lodash'
import { httpget, httppost, httpput } from '../base-api'
import { validateCurrentUnit, validateUnit } from '../validations'
import { toPrintoutUri, toUnitsBaseUri, toUri, toUriStatus } from './base'

const validateRestoredValue = data => {
  if (isNil(data)) {
    throw Error('Received no data')
  }

  if (!isArray(data)) {
    throw Error(
      `Corrupted data: expected an array but received ${typeof data}`
    )
  }

  return map(data, validateUnit)
}

export default {
  list: async () => {
    return validateRestoredValue(await httpget(toUnitsBaseUri()))
  },
  start: async ({ unitId }) => {
    return await httppost(toUri({ unitId, uri: 'start' }))
  },
  current: async () => {
    return validateCurrentUnit(await httpget(toUnitsBaseUri({ uri: 'current' })))
  },
  modifyStatus: async ({ unitId, expectedStatus, uri = '' }) => {
    return await httpput(toUriStatus({ unitId, expectedStatus, uri }))
  },
  saveStartList: async ({ unitId, participantsIds }) => {
    return await httppost(toUri({ unitId, uri: 'start-list' }), {
      participantsIds
    })
  },
  saveJudgeList: async ({ unitId, panels }) => {
    return await httppost(toUri({ unitId, uri: 'judges-list' }), { panels })
  },
  generatePrintout: async ({ unitId, printoutCode, uri = '', options }) => {
    return await httppost(toPrintoutUri({ unitId, printoutCode, uri }), {
      ...options
    })
  },
  printoutStatusesList: async ({ unitId }) => {
    return await httpget(toUri({ unitId, uri: 'printouts' }))
  },
  onlineJudges: async ({ unitId }, { signal }) => {
    return await httpget(toUri({ unitId, uri: 'online-judges' }), { signal })
  },
  resetUnit: async ({ unitId }) => {
    return await httppost(toUri({ unitId, uri: 'reset' }))
  }
}
