import { callApi, configuration, createSimpleHandler, dispatchGlobal, hands, scores } from '@/api'
import { fromPaddedIndex } from '@/store/judge/shared'
import { routine } from '@/store/modules'
import { debounce, isArray, isEmpty, isNil } from 'lodash'
import { getters } from '../getters'
import { mutations } from '../mutations'
import actions from './names'

const withUnitId = ({ rootGetters }) => ({
  unitId: rootGetters[`${routine.namespace}/${routine.getters.unitId}`]
})

const withParticipantId = ({ rootGetters }) => ({
  participantId:
    rootGetters[`${routine.namespace}/${routine.getters.participantId}`]
})

const createFetchJudgeConfigSucceeded = ({ dispatch, commit }) => data => {
  commit(mutations.updateConfiguration, data)
  dispatchGlobal(dispatch, 'fetchJudgeConfigSucceeded')
}

const createSubmitJudgeScoresSucceeded = ({ dispatch, commit }) => () => {
  commit(mutations.markScoresSubmission)
  dispatchGlobal(dispatch, 'submitModifyJudgeScoresSucceeded')
}

const createFetchScoresSucceeded = ({ dispatch, commit }) => data => {
  commit(mutations.updateScoresSummary, data)
  dispatchGlobal(dispatch, 'fetchScoresSucceeded')
}

const createSubmitRaiseHandeSuccededHandler = ({ dispatch, commit }) => () => {
  commit(mutations.setIsHandRaised, true)
  dispatchGlobal(dispatch, 'submitRaiseHandSucceeded')
}

const buildOverwriteScoresPayloadFromGet = scores => {
  if (isNil(scores) || !isArray(scores) || isEmpty(scores)) {
    return
  }

  return {
    scores: {
      values: scores,
      index: null
    },
    submittedAt: Date.now()
  }
}

const buildOverwriteScoresPayloadFromRestore = params => {
  if (isNil(params)) {
    return
  }

  const { scoreIndex, scores } = params
  return {
    scores: {
      values: scores,
      index: scoreIndex
    },
    submittedAt: null
  }
}

const createFetchJudgeScoresSucceeded = ({ dispatch, commit }) => ({
  getResults,
  restoreResults
}) => {
  const overwriteScoresPayload =
    buildOverwriteScoresPayloadFromGet(getResults) ||
    buildOverwriteScoresPayloadFromRestore(restoreResults)

  if (overwriteScoresPayload) {
    commit(mutations.overwriteScores, overwriteScoresPayload)
  } else {
    commit(mutations.clearScores)
  }
  dispatchGlobal(dispatch, 'fetchJudgeScoresSucceeded')
}

const saveScoresState = debounce(({ localGetters, ...store }) => {
  const panel = localGetters[getters.panelBasedIdentifier]
  const { index } = localGetters[getters.selectedScore] || {}
  const scoresResult = localGetters[getters.scores]
  const savePayload = {
    ...withUnitId(store),
    ...withParticipantId(store),
    panel,
    index,
    scores: scoresResult
  }
  callApi(
    async () => await scores.save(savePayload),
    createSimpleHandler('submittingJudgeSingleScore', store),
    createSimpleHandler('submitJudgeSingleScoreSucceeded', store),
    createSimpleHandler('submitJudgeSingleScoreFailed', store)
  )
}, 1000)

const createFetchIsHandRaisedSucceded = ({ commit, dispatch }) => () => {
  commit(mutations.setIsHandRaised, true)
  dispatchGlobal(dispatch, 'fetchIsHandRaisedSucceeded')
}

const createFetchIsHandRaisedFailed = ({ commit, dispatch }) => () => {
  commit(mutations.setIsHandRaised, false)
  dispatchGlobal(dispatch, 'fetchIsHandRaisedFailed')
}

export default {
  [actions.updateScore] ({ getters: localGetters, ...store }, payload) {
    const { commit } = store
    const { scoreIndex, scoreValue } = payload

    const score = {
      index: scoreIndex,
      value: scoreValue
    }
    commit(mutations.updateScore, { score })

    saveScoresState({ localGetters, ...store })
  },

  [actions.commitScore] ({ commit }, isFromScoreCompletedWithKeypad = false) {
    commit(mutations.selectFirstEmptyScore)
    commit(mutations.clearKeypadValue)
    commit(mutations.commitScoreAndAdvance, isFromScoreCompletedWithKeypad)
  },

  [actions.clearScores] ({ commit }) {
    commit(mutations.clearScores)
    commit(mutations.clearKeypadValue)
  },

  [actions.clearScoresSubmission] ({ commit }) {
    commit(mutations.clearAndSelectScore, 0)
  },

  async [actions.fetchConfiguration] (store, panel = null) {
    const judgeConfigRequest = { ...withUnitId(store), panel }

    await callApi(
      async () => {
        return configuration.judge(judgeConfigRequest)
      },
      createSimpleHandler('fetchingJudgeConfig', store),
      createFetchJudgeConfigSucceeded(store),
      createSimpleHandler('fetchJudgeConfigFailed', store)
    )
  },

  async [actions.submitScores] ({ getters: localGetters, ...store }) {
    const panel = localGetters[getters.panelBasedIdentifier]
    const results = localGetters[getters.scores]
    const selectedScorePreviousValue = localGetters[getters.selectedScoreValueBeforeSelection]
    if (selectedScorePreviousValue !== null) {
      const { index } = localGetters[getters.selectedScore]
      results[fromPaddedIndex(store.state, index)] = selectedScorePreviousValue
    }
    const payload = {
      ...withUnitId(store),
      ...withParticipantId(store),
      panel,
      scores: results
    }
    await callApi(
      async () => await scores.submit(payload),
      createSimpleHandler('submittingModifyJudgeScores', store),
      createSubmitJudgeScoresSucceeded(store),
      createSimpleHandler('submitModifyJudgeScoresFailed', store)
    )
  },

  async [actions.fetchSavedScores] ({ getters: localGetters, ...store }) {
    const panel = localGetters[getters.panelBasedIdentifier]
    const queryParams = {
      ...withUnitId(store),
      ...withParticipantId(store),
      panel
    }

    if (!queryParams.unitId || !queryParams.participantId) {
      return
    }
    await callApi(
      async () => {
        const [
          { value: getValue },
          { value: restoreValue }
        ] = await Promise.allSettled([
          scores.get(queryParams),
          scores.restore(queryParams)
        ])
        return {
          getResults: getValue,
          restoreResults: restoreValue
        }
      },
      createSimpleHandler('fetchingJudgeScores', store),
      createFetchJudgeScoresSucceeded(store),
      createSimpleHandler('fetchJudgeScoresFailed', store)
    )
  },

  [actions.selectScore] ({ commit }, scoreIndex) {
    commit(mutations.tryCommittingIncompleteScore)
    commit(mutations.clearAndSelectScoreByPaddedIndex, scoreIndex)
  },

  async [actions.fetchAllScores] (store) {
    const queryParameters = {
      ...withUnitId(store),
      ...withParticipantId(store)
    }

    await callApi(
      async () => await scores.summary(queryParameters),
      createSimpleHandler('fetchingScores', store),
      createFetchScoresSucceeded(store),
      createSimpleHandler('fetchScoresFailed', store)
    )
  },
  async [actions.raiseHand] (store, { panel }) {
    const { id, seat } = panel
    const payload = {
      ...withUnitId(store),
      ...withParticipantId(store),
      panel: {
        id,
        seat
      }
    }
    await callApi(
      async () => await hands.raise(payload),
      createSimpleHandler('submittingRaiseHand', store),
      createSubmitRaiseHandeSuccededHandler(store),
      createSimpleHandler('submitRaiseHandFailed', store)
    )
  },
  async [actions.setScoresSummary] ({ commit }, scoreSummary) {
    commit(mutations.updateScoresSummary, scoreSummary)
  },

  async [actions.fetchIsHandRaised] (store) {
    const localGetters = store.getters
    const { id, seat } = localGetters[getters.panelBasedIdentifier]
    const payload = {
      ...withUnitId(store),
      ...withParticipantId(store),
      panel: {
        id,
        seat
      }
    }

    await callApi(
      async () => await hands.get(payload),
      createSimpleHandler('fetchingIsHandRaised', store),
      createFetchIsHandRaisedSucceded(store),
      createFetchIsHandRaisedFailed(store)
    )
  },

  [actions.setIsHandRaised] ({ commit }, isHandRaised) {
    commit(mutations.setIsHandRaised, isHandRaised)
  },
  async [actions.updateJudgeJoinedStartTime] ({ commit }) {
    commit(mutations.updateJudgeJoinedStartTime)
  }
}
