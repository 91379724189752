<template>
  <td>
    <div class="buttons">
      <bi-stable-push-button
        :value="score === 'confirmed'"
        kind="green"
        class="buttons-item"
        @toggled="onButtonPressed('confirmed')">
        ✓ Confirmed
      </bi-stable-push-button>
      <bi-stable-push-button
        :value="score === 'review'"
        kind="orange"
        class="buttons-item"
        @toggled="onButtonPressed('review')">
        ⚠ Review
      </bi-stable-push-button>
      <bi-stable-push-button
        :value="score === 'basemark'"
        kind="red"
        class="buttons-item"
        @toggled="onButtonPressed('basemark')">
        🗙 Base&nbsp;Mark
      </bi-stable-push-button>
    </div>
    <div
      v-if="loading"
      class="progress-bar">
      <v-progress-linear
        color="primary"
        indeterminate />
    </div>
  </td>
</template>
<script>
import BiStablePushButton from '@/components/difficulty-technical-controller/BiStablePushButton'

export default {
  name: 'DifficultyTechnicalControllerScore',
  components: { BiStablePushButton },
  emits: ['scoreChanged'],
  props: {
    loading: {
      type: Boolean,
      default: () => false
    },
    score: {
      type: String,
      default: () => 'confirmed'
    }
  },
  methods: {
    onButtonPressed (buttonKind) {
      if (buttonKind !== this.score) {
        this.$emit('scoreChanged', buttonKind)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/button";
@import "~@/styles/variables";
@import '~@/styles/container';

td {
  @include vcontainer('center', 'center');

  .buttons {
    @include container('center', 'center');

    &-item {
      margin: 0 0.5rem;
    }
  }

  .progress-bar {
    width: 100%;
    margin-top: 8px;
    padding: 0 0.5rem;
  }
}
</style>
