<template>
  <tr v-if="!entry.isTransition">
    <td>{{entry.order}}.</td>
    <td>{{ entry.elementType }}</td>
    <td>{{ entry.name }}</td>
    <td>{{ entry.degreeOfDifficulty.toFixed(4) }}</td>
    <slot v-bind:score="entry.score"/>
  </tr>
</template>

<script>

export default {
  name: 'CoachCardEntry',
  props: {
    entry: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/variables";

tr {
  height: 3rem;

  th, td {
    padding: 0.5rem 1rem;
    border-top: solid thin $lightgray;
  }
}
</style>
