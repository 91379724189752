<template lang="">
  <div>
    <div :style="timerStyles">{{title}}</div>
    <div class='timer' :style="timerStyles">
      {{minutes}}:{{seconds}}.{{tenths}}
    </div>
  </div>
</template>
<script>
import { padStart } from 'lodash'

const format = ({
  value,
  offset,
  base = Number.MAX_VALUE,
  minDigits = 1
}) =>
  padStart(
    Math.floor(value / offset) % base,
    minDigits,
    '0'
  )

export default {
  name: 'Timer',
  props: {
    title: {
      type: String
    },
    startTimeStamp: {
      type: [String, Date]
    },
    stopTimeStamp: {
      type: [String, Date]
    },
    isReadOnlyMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    elapsed () {
      return (this.stop - this.start) > 0
        ? (this.stop - this.start)
        : 0 ||
        0
    },
    minutes () {
      return format({
        value: this.elapsed,
        offset: 1000 * 60,
        minDigits: 2
      })
    },
    seconds () {
      return format({
        value: this.elapsed,
        offset: 1000,
        base: 60,
        minDigits: 2
      })
    },
    tenths () {
      return format({
        value: this.elapsed,
        offset: 100,
        base: 10
      })
    },
    timerStyles () {
      return {
        'font-size': this.isReadOnlyMode ? '1.7rem' : '2.6rem'
      }
    }
  },
  data () {
    return {
      start: this.startTimeStamp !== null ? new Date(this.startTimeStamp) : new Date(),
      stop: this.stopTimeStamp !== null ? new Date(this.stopTimeStamp) : new Date(),
      timer: null
    }
  },
  watch: {
    startTimeStamp () {
      this.start = this.startTimeStamp !== null ? new Date(this.startTimeStamp) : new Date()
      this.timer = setInterval(() => {
        this.stop = new Date()
      }, 10)
    },
    stopTimeStamp () {
      this.stop = this.stopTimeStamp !== null ? new Date(this.stopTimeStamp) : new Date()
      clearInterval(this.timer)
    }
  }
}
</script>
<style scoped lang="scss">
  @import '~@/styles/typology';
  @import '~@/styles/container';

  .timer {
    @include border();

    padding: 5px;
    font-family: Verdana, sans-serif, Arial;
    font-weight: bold;
    text-decoration: none;
    margin: 5px;
    display: inline-block;
  }
</style>
