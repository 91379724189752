import mutations from './mutations.js'
import { actionsDefinition } from './actions.js'
import { gettersDefinition } from './getters.js'

const timekeeperModule = {
  namespaced: true,
  state: () => ({
    timers: []
  }),
  getters: gettersDefinition,
  mutations,
  actions: actionsDefinition
}

export default timekeeperModule
export { getters } from './getters'
export { actions } from './actions'
export { subscribe } from './action-listeners'
