// status: undefined means that we do not know if currently any participant is perforimng
// status: null means that there is no participant perforimng
const defaultParticipant = {
  id: undefined,
  noc: undefined,
  members: undefined,
  status: undefined,
  startOrder: undefined
}

export const defaultState = {
  unitId: null,
  participant: defaultParticipant,
  getLastAssessedIfCurrentMissing: false
}

export const mutations = {
  updateRoutine: 'updateRoutine',
  clearRoutine: 'clearRoutine',
  setUnit: 'setUnit',
  setCurrentRoutineNotExisting: 'setCurrentRoutineNotExisting',
  setGetLastAssessedIfCurrentMissing: 'setGetLastAssessedIfCurrentMissing'
}
export const mutationsDefinition = {
  [mutations.setUnit]: (store, { id }) => {
    store.unitId = id
  },

  [mutations.updateRoutine]: (store, routine) => {
    const { id, members, noc, status, startOrder } = routine
    store.participant = {
      id,
      noc,
      members,
      status,
      startOrder
    }
  },

  [mutations.setCurrentRoutineNotExisting]: (store) => {
    store.participant = {
      id: null,
      noc: null,
      members: null,
      status: null,
      startOrder: null
    }
  },

  [mutations.clearRoutine]: store => {
    store.participant = {
      ...defaultParticipant
    }
  },
  [mutations.setGetLastAssessedIfCurrentMissing]: (store, value) => {
    store.getLastAssessedIfCurrentMissing = value
  }
}
