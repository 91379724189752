import { COACHCARD_BUILDER_URI } from '@/api/apiconsts'
import { joinUri, http, httpget, httppost } from '../base-api'
import { toApiUriBase } from '../base-api/helpers'
import { getAuthHeader } from '../authorization/token'

const toUri = ({ uri } = {}) => joinUri('coach-cards', uri)

const newFormData = file => {
  const formData = new FormData()
  formData.append('file', file)

  return formData
}
export default {
  import: async ({ file }) => {
    const formData = newFormData(file)

    return await http(toUri({ uri: 'import' }), {
      body: formData,
      method: 'POST'
    })
  },
  getPendingCoachCars: async () => {
    return await httpget(toUri({ uri: 'inbox' }))
  },
  sendCoachCardAction: async (coachCardId, action) => {
    return await httppost(toUri({ uri: `${coachCardId}/${action}` }))
  },
  viewParticipantCoachCardUri: (olrUri) => {
    const coachCardTokenObject = {
      url: toApiUriBase(olrUri),
      token: getAuthHeader()
    }
    const caochCardToken = Buffer.from(
      JSON.stringify(coachCardTokenObject),
      'utf8'
    ).toString('base64')
    return `${COACHCARD_BUILDER_URI}?coachCard=${caochCardToken}`
  }
}
