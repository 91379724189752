<template>
  <v-menu :value="opened" @input="$emit('update:opened', $event)">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs, disabled }">
        <v-icon
          v-on="on"
          v-bind="attrs"
          @click="$emit('menuOpened')"
          :disabled="disabled">
          {{ icon }}
        </v-icon>
      </slot>
    </template>

    <v-list>
      <slot
        v-for="{ option, disabled } in displayableOptions"
        v-bind="{
          option,
          disabled }"
        name="option">
        <v-list-item
          :key="option"
          :disabled="disabled"
          @click="onOptionSelected(option)">
            <v-list-item-title>
              {{ option }}
            </v-list-item-title>
        </v-list-item>
      </slot>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  emits: ['menuOpened', 'optionSelected', 'update:opened'],
  model: {
    prop: 'opened',
    event: 'update:opened'
  },
  props: {
    opened: {
      type: Boolean,
      default: () => false
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    disabledOptions: {
      type: Array,
      default: () => []
    },
    icon: {
      type: String,
      default: () => 'mdi-dots-horizontal'
    },
    absolute: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    onOptionSelected (option) {
      this.$emit('optionSelected', option)
    }
  },
  computed: {
    displayableOptions () {
      return this.options.map(option => ({
        option,
        disabled: this.disabledOptions.includes(option)
      }))
    }
  }
}
</script>
