import { Container, createStandardWebsocketConnector, createStandardWebsocketDisconnector } from './base'
import TimekeeperWaiting from '@/views/TimekeeperWaiting'
import Timekeeper from '@/views/Timekeeper'
import { unit, routine, timekeeper } from '@/store/modules'
import store from '@/store'
import ParticipantStatus from '@/enums/ParticipantStatus'
import { get } from 'lodash'
import Events from '@/enums/Events'
import GroupNames from '@/enums/GroupNames'
import { timekeeperListeners } from '@/store/actionListeners'
import { registerActionListeners } from '@/store/base/store-listeners'

const timekeeperSubRoutes = Object.freeze({
  [ParticipantStatus.Performing]: {
    path: '/timekeeper/timers',
    enterWhenEvent: Events.ROUTINE_STARTED
  }
})

const routes = [
  {
    path: '/timekeeper',
    component: Container,
    name: 'timekeeper',
    children: [
      { path: 'wait', component: TimekeeperWaiting, name: 'timekeeper' },
      { path: 'timers', component: Timekeeper, name: 'timekeeper' }
    ],
    meta: {
      socket: {
        open: createStandardWebsocketConnector({
          store,
          abortPath: '/timekeeper/wait',
          hubGroup: GroupNames.TIMEKEEPER,
          subRoutes: timekeeperSubRoutes,
          additionalAction: async ({ eventName }) => {
            switch (eventName) {
              case Events.ROUTINE_STARTED:
                await timekeeper.globals.actions.resetTimers(store)
                break
            }
          }
        }),
        close: createStandardWebsocketDisconnector({
          hubGroup: GroupNames.TIMEKEEPER
        })
      }
    },
    async beforeEnter (to, from, next) {
      registerActionListeners({ store, subscribers: timekeeperListeners })
      await unit.globals.actions.fetchCurrent(store)
      handleRoutineStatus(to, next)
    }
  }
]

export default {
  routes
}
function handleRoutineStatus (to, next) {
  const status = routine.globals.getters.status(store)
  // todo https://dev.azure.com/SWTIDEVEXTERNAL/SWA-OLR/_workitems/edit/3555
  if (status === undefined) {
    setTimeout(() => handleRoutineStatus(to, next), 200)
  } else {
    const nextPage = get(
      timekeeperSubRoutes[status],
      'path',
      '/timekeeper/wait')

    if (nextPage === to.path) {
      next()
    } else {
      next(nextPage)
    }
  }
}
