<template>
  <section class="labelled-value">
    <label
      class="labelled-value__caption">
      {{ label }}
    </label>
    <ScoreBox
      flat
      disabled
      :score="formattedValue"
      class="labelled-value__score"/>
  </section>

</template>

<script>
import ScoreBox from '@/components/ScoreBox'
import { isNil, trim } from 'lodash'

const DEFAULT_VALUE = '-.-'

export default {
  name: 'LabelledValue',
  components: {
    ScoreBox
  },
  props: {
    label: {
      type: String,
      required: true,
      default: () => ''
    },
    value: {
      type: [String, Number],
      default: () => DEFAULT_VALUE
    },
    digits: {
      type: Number,
      default: () => 0
    }
  },
  computed: {
    formattedValue () {
      if (isNil(this.value) ||
        isNaN(this.value) ||
        trim(this.value) === '') {
        return DEFAULT_VALUE
      }

      return parseFloat(this.value).toFixed(this.digits)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/typology";

.labelled-value {
  &__caption {
    @include secondary-caption-l2();

    display: block;
  }

  &__score {
    @include value-l2();

    width: 100%;
    padding-top: 0;
  }
}
</style>
