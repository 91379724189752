import { countBy, isNil, reduce } from 'lodash'

export const isJudgeAllocationFragmented = (
  data,
  testForUnallocatedSeat = isNil
) => {
  const { blocksCount } = reduce(
    data,
    ({ prevSeatTestResult, blocksCount }, judge) => {
      const testResult = testForUnallocatedSeat(judge)
      return {
        prevSeatTestResult: testResult,
        blocksCount:
          prevSeatTestResult !== testResult ? blocksCount + 1 : blocksCount
      }
    },
    {
      prevSeatTestResult: null,
      blocksCount: 0
    }
  )
  const [firstSeat] = data
  return blocksCount > 2 || (blocksCount === 2 && testForUnallocatedSeat(firstSeat) === true)
}

export const allocatedSeatsCount = judges => countBy(judges, isNil).false || 0

export const getPanelAndSeatIndexToAllocateJudge = (allocatedJudges, elementIndex) => {
  let counter = elementIndex
  let panel = null
  let seatIndex = null
  for (let i = 0; i < allocatedJudges.length; i++) {
    // we need to subtract one on the beginning because
    // the list that is used to display judges also count panel names
    if (counter - 1 <= 0) {
      panel = allocatedJudges[i]
      seatIndex = 0
      break
    }
    counter--

    if (counter - allocatedJudges[i].judges.length <= 0) {
      panel = allocatedJudges[i]
      seatIndex = counter
      break
    }
    counter -= allocatedJudges[i].judges.length
  }
  if (!panel) {
    throw new Error("Couldn't find panel and seat index to allocate judge")
  }
  return { panel, seatIndex }
}
