import { actions } from './actions'
import { mutations } from './mutations'

export const subscribe = ({ store, commitLocal, dispatchLocal } = {}) => {
  return store.subscribeAction(action => {
    switch (action.type) {
      case 'submitPresentParticipantSucceeded': {
        const { unitId } = action.payload
        commitLocal(mutations.setCurrentUnitById, { unitId })
        break
      }

      case 'submitOdfArchiveSucceeded':
      case 'submitCreateScheduleEntrySucceeded':
      case 'submitUpdateScheduleEntrySucceeded':
        dispatchLocal(actions.fetchCurrent)
        dispatchLocal(actions.fetchAll)
        break

      case 'fetchingCurrentUnit':
      case 'submittingModifyStatus':
      case 'submittingStartUnit':
        dispatchLocal(actions.unitStatusChangeStarted)
        break

      case 'submitStartUnitSucceeded':
      case 'submitModifyStatusSucceeded':
      case 'submitModifyStatusByOlrSucceeded':
        dispatchLocal(actions.unitStatusChangeFinished)
        dispatchLocal(actions.fetchCurrent)
        dispatchLocal(actions.fetchAll)
        break

      case 'fetchCurrentUnitSucceeded':
      case 'fetchCurrentUnitFailed':
      case 'submitStartUnitFailed':
      case 'submitModifyStatusFailed':
        dispatchLocal(actions.unitStatusChangeFinished)
        break

      case 'submitAbortRoutineSucceeded':
      case 'submitSendScoresSucceeded':
        dispatchLocal(actions.fetchCurrent)
        break

      case 'submitResetUnitSucceeded':
        dispatchLocal(actions.fetchAll)
        dispatchLocal(actions.fetchCurrent)
        break
    }
  })
}
