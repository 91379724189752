import { http, httpdownload } from '../base-api/http'

export const httpget = async (uri, options) => {
  const params = {
    method: 'GET'
  }
  return http(uri, params, options)
}

export const httpgetdownload = async ({ uri, options, filename }) => {
  const params = {
    method: 'GET'
  }
  return httpdownload({ uri, params, options, filename })
}
