import Settings from '@/views/Settings'
import store from '@/store'
import { configuration } from '@/store/modules'

const routes = [{
  path: '/settings',
  name: 'settings',
  component: Settings,
  async beforeEnter (to, from, next) {
    await configuration.globals.actions.fetchConfiguration(store)
    next()
  }
}]

export default {
  routes
}
