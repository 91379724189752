import { COACHCARD_BUILDER_URI } from '@/api/apiconsts'
import { getAuthHeader } from '../authorization/token'
import { httpget, joinUri } from '../base-api'
import { toApiUri } from '../base-api/helpers'
import { toPhasesBaseUri } from './base'

export default {
  registeredParticpants: async ({ phaseId, unitId }, { signal } = {}) => {
    return await httpget(
      toPhasesBaseUri({ phaseId, uri: `${unitId}/participants` }),
      { signal }
    )
  },
  updateParticipantCoachCardUri: ({ phaseId, unitId, participantId }) => {
    const phaseUri = toPhasesBaseUri({
      phaseId,
      uri: `${joinUri(`${unitId}/participants`, participantId)}/coach-card`
    })
    const coachCardTokenObject = {
      url: toApiUri(phaseUri),
      token: getAuthHeader()
    }
    const caochCardToken = Buffer.from(
      JSON.stringify(coachCardTokenObject),
      'utf8'
    ).toString('base64')
    return `${COACHCARD_BUILDER_URI}?coachCard=${caochCardToken}`
  }
}
