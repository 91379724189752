import { merge } from 'lodash'
import { v4 as guid } from 'uuid'
import { extractResult, handleApiCall, toApiUri, toApiUriBase, withAuthorization } from './helpers'

export const http = async (uri, params, options = {}) => {
  const httpParams = merge(params, {
    headers: {
      'x-correlationid': guid(),
      'User-Agent': 'OlrSwaWebUI',
      ...withAuthorization(options)
    },
    signal: options?.signal
  })
  const uriConverter = options?.uriConverter ?? toApiUri
  const response = await handleApiCall(
    () => fetch(uriConverter(uri), httpParams),
    options
  )
  return extractResult(response)
}

export const httpdownload = async ({ uri, params, options, filename }) => {
  let headerFilename = ''

  const httpParams = merge(params, {
    headers: {
      'x-correlationid': guid(),
      'User-Agent': 'OlrSwaWebUI',
      ...withAuthorization(options)
    }
  })

  fetch(toApiUriBase(uri), httpParams)
    .then(res => {
      const header = res.headers.get('Content-Disposition')
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      var matches = filenameRegex.exec(header)
      if (matches != null && matches[1]) {
        headerFilename = matches[1].replace(/['"]/g, '')
      }
      return res.blob()
    })
    .then(data => {
      var a = document.createElement('a')
      a.href = window.URL.createObjectURL(data)
      a.download = headerFilename
      a.click()
    })
}
