<template>
  <div class="home">
    <GoToCurrentParticipantButton/>
    <Menu :authState="authState" skipRole="all"/>
    <div class="wireframe">
      <router-link
        v-for='role in roles'
        v-bind:key="role.code"
        :to="{ path: role.code }"
        class="wireframe__item"
        v-ripple
        @click.native="startLoader">
        {{ `${role.name}` }}
      </router-link>
      <router-link
        v-if="isAdmin"
        :to="'/coach-card-validation'"
        class="wireframe__item">
        Coach Card Validation
      </router-link>
      <router-link
        v-if="isAdmin"
        :to="'/teams-management'"
        class="wireframe__item">
        Teams Management
      </router-link>
      <flat-spinner :active="loading" />
    </div>
  </div>
</template>

<script>
import Menu from '@/components/Menu'
import { isAdmin, userRoles } from '@/services/auth-service'
import GoToCurrentParticipantButton from '@/components/GoToCurrentParticipantButton'
import FlatSpinner from '@/components/FlatSpinner.vue'

export default {
  name: 'Home',
  components: {
    Menu,
    GoToCurrentParticipantButton,
    FlatSpinner
  },
  data () {
    return {
      ...this.mapAuthState(),
      loading: false
    }
  },
  computed: {
    roles () {
      return userRoles(this.authState?.user)
    },
    isAdmin () {
      return isAdmin(this.authState?.user)
    }
  },
  methods: {
    startLoader () {
      this.loading = true
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/container';

.home {
  @include container;
}

.wireframe {
  @include wireframe;
}
</style>
