import { render, staticRenderFns } from "./SettingsSlider.vue?vue&type=template&id=d99fa8be&scoped=true&"
import script from "./SettingsSlider.vue?vue&type=script&lang=js&"
export * from "./SettingsSlider.vue?vue&type=script&lang=js&"
import style0 from "./SettingsSlider.vue?vue&type=style&index=0&id=d99fa8be&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d99fa8be",
  null
  
)

export default component.exports