<template>
  <div v-if="loading" class="loader-container">
    <v-progress-circular indeterminate size="72" color="grey" />
  </div>
  <ol v-else>
    <li
      v-for='(item, index) in listData'
      @click.stop='!item.readOnly && onSelectedItem(item, index)'
      :class="classForItem(index)"
      :key='index'>
        <slot :name="index"/>
    </li>
  </ol>
</template>

<script>

export default {
  name: 'List',
  emits: ['itemSelected', 'itemActionCalled'],
  data () {
    return {
      selectedItemIndex: null,
      selectedTimeStamp: null
    }
  },
  props: {
    listData: {
      type: Array,
      default: () => ['..']
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  watch: {
    listData () {
      this.selectedItemIndex = null
    }
  },
  methods: {
    onSelectedItem (item, index) {
      if (this.selectedItemIndex === index) {
        if (this.selectedTimeStamp + 300 > Date.now()) {
          this.$emit('itemActionCalled', { item, index })
        }
        this.selectedItemIndex = null
      } else {
        this.selectedItemIndex = index
        this.selectedTimeStamp = Date.now()
        this.$emit('itemSelected', { item, index })
      }
    },
    classForItem (index) {
      return {
        'selected-item': this.selectedItemIndex === index
      }
    }
  }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/container";
@import "~@/styles/variables";

div.loader-container {
  height: 100%;
  width: 100%;

  @include container('center', 'center');
}

ol {
  @include border($radius: 1rem);

  overflow-y: auto;
  overflow-x: hidden;

  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

  & li {
    padding: 0.1rem;
    margin-left: 0.5rem;

    text-align: left;

    white-space: nowrap;
    list-style-type: none;

    cursor: pointer;
  }
}

.selected-item {
  background: $blue
}

</style>
