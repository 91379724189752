import { callApi, dispatchGlobal, hands, degreeOfDifficulty } from '@/api'
import { isNil } from 'lodash'
import { mutations } from './mutations'

export const actions = {
  fetchRaisedHands: 'fetchRaisedHands',
  lowerHand: 'lowerHand',
  scoreRecalculationActionStarted: 'scoreRecalculationActionStarted',
  scoreRecalculationActionFinished: 'scoreRecalculationActionFinished',
  setIsInProgress: 'setIsInProgress',
  changeDegreeOfDifficulty: 'changeDegreeOfDifficulty'
}

const createSimpleHandler = (actionName, { dispatch }, payload) =>
  () =>
    dispatchGlobal(dispatch, actionName, payload)

const createRaisedHandsSucceeded = ({ dispatch, commit }) =>
  hands => {
    commit(mutations.setRaisedHands, hands)
    dispatchGlobal(dispatch, 'fetchRaisedHandsSucceeded')
  }

const createLowerHandSucceeded = ({ dispatch, commit }, panel) =>
  () => {
    commit(mutations.resetRaisedHand, panel)
    dispatchGlobal(dispatch, 'submitLowerHandSucceeded')
  }

export const actionsDefinition = {
  async [actions.fetchRaisedHands] (store, { unitId, participantId }) {
    const { commit } = store
    if (isNil(unitId) || isNil(participantId)) {
      commit(mutations.clearRaisedHands)
      return
    }

    const payload = { unitId, participantId }
    await callApi(
      async () => {
        return await hands.list(payload)
      },
      createSimpleHandler('fetchingRaisedHands', store),
      createRaisedHandsSucceeded(store),
      createSimpleHandler('fetchRaisedHandsFailed', store)
    )
  },
  async [actions.lowerHand] (store, { unitId, participantId, panelId, seat }) {
    if (
      isNil(panelId) ||
      isNil(seat) ||
      isNil(unitId) ||
      isNil(participantId)) {
      return
    }

    const panel = {
      id: panelId,
      seat
    }
    const payload = {
      unitId,
      participantId,
      panel
    }
    await callApi(
      async () => {
        return await hands.lower(payload)
      },
      createSimpleHandler('submittingLowerHand', store),
      createLowerHandSucceeded(store, panel),
      createSimpleHandler('submitLowerHandFailed', store)
    )
  },
  [actions.scoreRecalculationActionStarted]: ({ commit }) => {
    commit(mutations.pushRecomputeRequest)
  },
  [actions.scoreRecalculationActionFinished]: ({ commit }) => {
    commit(mutations.popRecomputeRequest)
  },
  [actions.setIsInProgress]: ({ commit }, { element, pending }) => {
    commit(mutations.setIsInProgress, { element, pending })
  },
  [actions.changeDegreeOfDifficulty]:
    async (store, { unitId, participantId, elementIndex, mark }) => {
      await callApi(
        async () => await degreeOfDifficulty.postSingle(
          { unitId, participantId, elementIndex, mark, keepFalsy: true }),
        createSimpleHandler('submittingChangeDegreeOfDifficulty', store),
        createSimpleHandler('submitChangeDegreeOfDifficultySucceeded', store),
        createSimpleHandler('submitChangeDegreeOfDifficultyFailed', store)
      )
    }
}
