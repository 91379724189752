import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.hidden)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({directives:[{def: Ripple,name:"ripple",rawName:"v-ripple"}],staticClass:"go-to-button",attrs:{"color":"primary"},on:{"click":_vm.showCurrentParticipant}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.buttonLabel)+" ")])]}}],null,false,1296106657)},[_vm._v(" "+_vm._s(_vm.formattedParticipant)+" ")]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }