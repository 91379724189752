import { joinUri, http } from '../base-api'

const toUri = ({ uri } = {}) => joinUri('import', uri)

const newFormData = file => {
  const formData = new FormData()
  formData.append('file', file)

  return formData
}

export default {
  odf: async ({ file }) => {
    const formData = newFormData(file)

    return await http(toUri({ uri: 'odf' }), {
      body: formData,
      method: 'POST'
    })
  }
}
