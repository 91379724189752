import { validateChiefRecorderConfiguration, validateJudgeConfiguration } from '@/api/validations'
import { httpget, httpput, joinUri, newUrl } from '../base-api'

const toUri = ({ unitId, uri }) =>
  joinUri(`units/${unitId}/configuration`, uri)

export default {
  judge: async ({ unitId, panel }) =>
    validateJudgeConfiguration(
      await httpget(newUrl(toUri({ unitId, uri: 'judge' }), panel))
    ),
  chiefRecorder: async ({ unitId, participantId }) =>
    validateChiefRecorderConfiguration(
      await httpget(
        newUrl(toUri({ unitId, uri: 'chief-recorder' }), { participantId })
      )
    ),
  referee: async ({ unitId, participantId }) =>
    validateChiefRecorderConfiguration(
      await httpget(
        newUrl(toUri({ unitId, uri: 'referee' }), { participantId })
      )
    ),
  olr: async ({ unitId }, { signal } = {}) =>
    await httpget(toUri({ unitId, uri: 'olr' }), { signal }),
  configuration: async () => await httpget(joinUri('settings')),
  saveSettings: async settings => await httpput(joinUri('settings'), settings)
}
