import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"sync-errors-table"},[_vm._m(0),_c('tbody',_vm._l((_vm.synchronizationErrors),function(ref,index){
      var timeStamp = ref.timeStamp;
      var elapsed = ref.elapsed;
      var elapsedTooltipText = ref.elapsedTooltipText;
      var votes = ref.votes;
      var deduction = ref.deduction;
      var outcome = ref.outcome;
return _c('tr',{key:index},[_c('td',{staticClass:"sync-errors-table-cell"},[_c('div',{staticClass:"sync-errors-table-cell__content"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var attrs = ref.attrs;
      var on = ref.on;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(elapsed)+" ")])]}}],null,true)},[_c('span',{staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(elapsedTooltipText)+" ")])])],1)]),_c('td',[_c('div',{staticClass:"sync-errors-table-cell__content"},_vm._l((votes),function(vote,index){return _c('span',{key:index,staticClass:"sync-errors-votes-wrapper"},[(vote)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var attrs = ref.attrs;
      var on = ref.on;
return [_c('div',_vm._g(_vm._b({class:['vote', vote.mark]},'div',attrs,false),on))]}}],null,true)},[_c('span',{staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(vote.tooltipText)+" ")])]):_vm._e()],1)}),0)]),_c('td',[_c('span',{staticClass:"sync-errors-outcome-wrapper",class:{ 'disable-click': _vm.readonly },on:{"click":function($event){return _vm.$emit('outcomeChanged', { timeStamp: timeStamp, outcome: 'Major' })}}},[_c('div',{class:[
                'outcome',
                'major',
                { 'active': outcome === 'Major' }
              ]})]),_c('span',{staticClass:"sync-errors-outcome-wrapper",class:{ 'disable-click': _vm.readonly },on:{"click":function($event){return _vm.$emit('outcomeChanged', { timeStamp: timeStamp, outcome: 'Obvious' })}}},[_c('div',{class:[
                'outcome',
                'obvious',
                { 'active': outcome === 'Obvious' }
              ]})]),_c('span',{staticClass:"sync-errors-outcome-wrapper",class:{ 'disable-click': _vm.readonly },on:{"click":function($event){return _vm.$emit('outcomeChanged', { timeStamp: timeStamp, outcome: 'Small' })}}},[_c('div',{class:[
                'outcome',
                'small',
                { 'active': outcome === 'Small' }
              ]})]),_c('span',{staticClass:"sync-errors-outcome-wrapper",class:{ 'disable-click': _vm.readonly },on:{"click":function($event){return _vm.$emit('outcomeChanged', { timeStamp: timeStamp, outcome: 'Discarded' })}}},[_c(VIcon,{class:[
                'discarded',
                { 'active': outcome === 'Discarded' }
              ]},[_vm._v(" mdi-cancel ")])],1)]),_c('td',[_c('Suspense',{attrs:{"isReady":_vm.isScoreRecalculated}},[_vm._v(_vm._s(deduction)+" pts")])],1)])}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Time")]),_c('th',[_vm._v("Votes")]),_c('th',[_vm._v("Outcome")]),_c('th',[_vm._v("Deduction")])])])}]

export { render, staticRenderFns }