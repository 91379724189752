<template>
  <div style="display: none" />
</template>

<script>
export default {
  name: 'KeyboardKeypad',
  emits: ['keyPressed'],
  created () {
    window.addEventListener('keyup', this.handler)
  },
  destroyed () {
    window.removeEventListener('keyup', this.handler)
  },
  methods: {
    handler (event) {
      const { key } = event
      this.$emit('keyPressed', key)
      event.preventDefault()
      event.stopPropagation()
    }
  }
}
</script>
