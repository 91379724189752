import { httpget, httppost, httpput, joinUri, newUrl } from '../base-api'

const toUri = ({ unitId, participantId }) =>
  `units/${unitId}/participants/${participantId}/synchro`

export default {
  post: async ({ unitId, participantId, synchronizationError }) => {
    await httppost(
      toUri({ unitId, participantId }),
      synchronizationError)
  },
  get: async ({ unitId, participantId }) => {
    return await httpget(toUri({ unitId, participantId }))
  },
  updateSynchronizationError: async ({
    unitId,
    participantId,
    timeStamp,
    outcome
  }) => {
    await httpput(
      newUrl(
        joinUri(
          toUri({ unitId, participantId }),
          timeStamp
        ),
        { synchronizationError: outcome }
      )
    )
  }
}
