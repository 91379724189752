import { each, isFunction, noop } from 'lodash'
import EventsMap from '@/maps/EventsMap'
import { unit, judge } from '@/store/modules'
import Events from '@/enums/Events'
import HubEventsMap from '@/maps/HubEventsMap'
import HubEvents from '@/enums/HubEvents'
import GroupNames from '@/enums/GroupNames'
export { default as Container } from '@/components/EmptyComponent'

const mapRoutineStates = ({
  connection,
  store,
  router,
  map: theMap,
  additionalAction = noop
}) =>
  each(theMap, ({ path, enterWhenEvent }) => {
    connection.on(EventsMap[enterWhenEvent], async (payload) => {
      await unit.globals.actions.fetchCurrent(store)
      await additionalAction({ eventName: enterWhenEvent, payload })

      const thePath = isFunction(path) ? path(store) : path
      const currentPath = router.history.current.path

      if (thePath && thePath !== currentPath) {
        await router.replace(thePath)
      }
    })
  })

export const createStandardWebsocketDisconnector = ({
  hubGroup
}) => async ({ connection }) => {
  await connection.invoke(HubEventsMap[HubEvents.LEAVE_GROUP], hubGroup)
}

export const createStandardWebsocketConnector = ({
  store,
  abortPath,
  hubGroup,
  subRoutes,
  additionalAction
}) =>
  async ({ connection, router }) => {
    const mapRoutineStatesBoilerplateParams = {
      connection,
      store,
      router
    }

    mapRoutineStates({
      ...mapRoutineStatesBoilerplateParams,
      map: subRoutes,
      additionalAction
    })
    mapRoutineStates({
      ...mapRoutineStatesBoilerplateParams,
      map: [
        {
          path: abortPath,
          enterWhenEvent: Events.ROUTINE_ABORTED
        }
      ]
    })

    const onConnectAndReconnectCallback = async () => {
      if (hubGroup === GroupNames.JUDGE) {
        await judge.globals.actions.updateJudgeJoinedStartTime(store)
      }
      await connection.invoke(HubEventsMap[HubEvents.JOIN_GROUP], hubGroup)
    }

    connection.onreconnected(() => onConnectAndReconnectCallback())
    await onConnectAndReconnectCallback()
  }
