import Vue from 'vue'
import VueRouter from 'vue-router'
import judge from './judge'
import chiefRecorder from './chief-recorder'
import timekeeper from './timekeeper'
import settings from './settings'
import websockets from './websockets'
import contextSelection from './context-selection'
import home from './home'
import olrOperator from './olr-operator'
import { setupRoutesGuard } from './guard'
import coachCardsValidation from './coachCardsValidation'
import teamsManagement from './teams-management'
import referee from './referee'

Vue.use(VueRouter)

const routes = [
  ...olrOperator.routes,
  ...home.routes,
  ...contextSelection.routes,
  ...settings.routes,
  ...judge.routes,
  ...timekeeper.routes,
  ...chiefRecorder.routes,
  ...coachCardsValidation.routes,
  ...teamsManagement.routes,
  ...referee.routes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

setupRoutesGuard(router)
websockets.hookup(router)

export default router
