<template>
  <article
    :style="dynamicLayoutStyles"
    class="scores-chief-recorder"
    @click="onTableClicked">
    <section
      class="scores-chief-recorder__scores">
      <v-dialog
        v-model="showSyncErrorsModal"
        max-width="80vw"
        scrollable
        >
        <v-card>
          <v-card-title>
            <span>Synchronization errors</span>
            <v-spacer></v-spacer>
            <span
              @click="showSyncErrorsModal = false"
              style="cursor: pointer;">
              &times;
            </span>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <SynchronizationErrorsLog
            :synchronizationErrors="synchronizationErrors"
            :readonly="readonly"
            @outcomeChanged="onSyncErrorOutomeChanged($event)"/>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              @click="showSyncErrorsModal = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <Suspense :isReady="isParticipantScoreConfigurationReady">
        <v-alert
          v-if="showMajorErrorAlert"
          type="error"
          text>
          Major synchronization error reported. Video review required.
        </v-alert>
        <scores-table
          :additional-columns-count="5"
          :isReady="isScoreRecalculated"
          :scores="selectedParticipantScores.panels.elements"
          :totals="elementsTotals"
          class="scores-table"
          panel-short-name="EL">
          <template #after-row-label-caption>
            <th class="scores-table__cell
              scores-table__cell--caption
              scores-table-cell--caption--small">
              DD
            </th>
            <th class="scores-table__cell
              scores-table__cell--caption
              scores-table-cell--caption--small">
              DTC
            </th>
            <th class="
              scores-table__cell
              scores-table__cell--caption
              scores-table-cell--caption--small">
              DATC1
            </th>
            <th class="
              scores-table__cell
              scores-table__cell--caption
              scores-table-cell--caption--small">
              DATC2
            </th>
            <th class="
              scores-table__cell
              scores-table__cell--caption
              scores-table-cell--caption--small">
              Factor
            </th>
          </template>
          <template #judge-header-actions="{ panelId, seat, isMissing }">
            <JudgeActionsMenu
              :id="panelId"
              :isMissing="isMissing"
              :seat="seat"
              v-if="!readonly"/>
            <HandPushButton
              v-if="raisedHands[panelId] && raisedHands[panelId][seat]"
              class="scores-table__hand"
              @clicked="onHandClicked(panelId, seat)"/>
          </template>
          <template #after-row-label-data="{ partIndex }">
            <td class="scores-table__cell">
              {{ declaredDDForPart(partIndex) }}
            </td>
            <td :class="classForDDPart(partIndex)">
              {{ judgedDDForPart(partIndex) }}
              <change-judged-degree-of-difficulty
                v-if="participantId && !readonly"
                :unitId="unitId"
                :participantId="participantId"
                :elementIndex="partIndex"
              />
            </td>
            <td :class="classForDATCPart(partIndex, 1)"></td>
            <td :class="classForDATCPart(partIndex, 2)"></td>
            <td class="scores-table__cell">
              {{ factorForPart(partIndex) }}
            </td>
          </template>
          <template
            #default="{ panelId, partIndex, score, seat }">
            <div
              class="scores-table-cell">
              <Suspense :isReady="!missingJudgeInProgress.elements[seat]">
                <ScoreBox
                  :class="classForScore({ panelId, partIndex, score, seat })"
                  :readonly="readonly || !isSelectedScore({ panelId, partIndex, seat }) ||
                    !isSelectedScoreTouched || score.isMissing"
                  :score="score && (score.isMissing ? '' : score.value)"
                  class="scores-table__result"
                  @clicked="selectScore({ panelId, partIndex, seat, value: score && score.value } )"
                  @scoreUpdated="scoreUpdated"/>
              </Suspense>
              <PushButton
                v-if="!readonly && isSelectedScore({ panelId, partIndex, seat }) &&
                  isSelectedScoreTouched && !score.isMissing"
                class='confirm-button'
                @clicked="updateScore">CONFIRM</PushButton>
            </div>
          </template>
          <template
            #after-results-data="{ total, isReady }">
            <td class="scores-table__cell" colspan="2">
              <Suspense
                :isReady="isReady">
                <ScoreBox
                  :score="total"
                  class="scores-table-cell__score scores-table-cell__score--total"
                  flat readonly/>
              </Suspense>
            </td>
          </template>
        </scores-table>
        <scores-table
          :additional-columns-count="1"
          :isReady="isScoreRecalculated"
          :scores="selectedParticipantScores.panels.artisticImpression"
          :totals="artisticImpressionTotals"
          class="scores-table"
          panel-short-name="AI">
          <template #after-row-label-caption>
            <th class="scores-table__cell
              scores-table__cell--caption
              scores-table-cell--caption--big
              text-right">
              <!--I left this styles on purpose, its just manipulating this small part-->
              <!--just to justify it, not a class to use anymore-->
              <span style="margin-right: 6px">Factor</span>
            </th>
          </template>
          <template #judge-header-actions="{ panelId, seat, isMissing }">
            <JudgeActionsMenu
              :id="panelId"
              :isMissing="isMissing"
              :seat="seat"
              v-if="!readonly"
            />
            <HandPushButton
              v-if="raisedHands[panelId] && raisedHands[panelId][seat]"
              class="scores-table__hand"
              @clicked="onHandClicked(panelId, seat)"/>
          </template>
          <template #after-row-label-data="{ partIndex }">
            <td class="scores-table__cell text-right">
              <!--I left this styles on purpose, its just manipulating this small part-->
              <!--just to justify it, not a class to use anymore-->
              <span style="margin-right: 16px">{{ factorForPanel(partIndex) }}</span>
            </td>
          </template>
          <template
            #default="{ panelId, partIndex, score, seat }">
            <div class="scores-table-cell">
              <Suspense :isReady="!missingJudgeInProgress.artisticImpression[seat]">
                <ScoreBox
                  :class="classForScore({ panelId, partIndex, score, seat })"
                  :readonly="readonly || !isSelectedScore({ panelId, partIndex, seat }) ||
                    !isSelectedScoreTouched || score.isMissing"
                  :score="score && (score.isMissing ? '' : score.value)"
                  class="scores-table__result"
                  @clicked="selectScore({ panelId, partIndex, seat, value: score && score.value } )"
                  @scoreUpdated="scoreUpdated"/>
              </Suspense>
              <PushButton
                v-if="!readonly && isSelectedScore({ panelId, partIndex, seat }) &&
                  isSelectedScoreTouched && !score.isMissing"
                class='confirm-button'
                @clicked="updateScore">
                CONFIRM
              </PushButton>
            </div>
          </template>
          <template
            #after-results-data="{ total, isReady }">
            <td class="scores-table-cell" colspan="2">
              <Suspense
                :isReady="isReady">
                <ScoreBox
                  :score="total"
                  class="scores-table-cell__score scores-table-cell__score--total"
                  flat readonly/>
              </Suspense>
            </td>
          </template>
        </scores-table>
      </Suspense>
    </section>
    <scores-summary
      :isReady="isScoreRecalculated"
      :readonly="readonly"
      :value="selectedParticipantScoresSummary"
      class="scores-chief-recorder__summary"/>
  </article>
</template>

<script>
import HandPushButton from '@/components/HandPushButton'
import PushButton from '@/components/PushButton'
import ScoreBox from '@/components/ScoreBox'
import ScoresTable from '@/components/ScoresTable'
import Suspense from '@/components/Suspense'
import PanelId from '@/enums/PanelId'
import { chiefRecorder, participants, synchronizationErrors, unit } from '@/store/modules'
import { isEqual, isNil, pick } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import JudgeActionsMenu from './JudgeActionsMenu'
import ScoresSummary from './ScoresSummary'
import SynchronizationErrorsLog from './SynchronizationErrorsLog'
import ChangeJudgedDegreeOfDifficulty from './ChangeJudgedDegreeOfDifficulty'

const toFixedValue = (value, precision) => {
  if (!isNil(value) && !isNaN(value)) {
    return (+value).toFixed(precision)
  }
}

export default {
  name: 'ChiefRecorderScores',
  components: {
    PushButton,
    Suspense,
    HandPushButton,
    ScoresTable,
    ScoresSummary,
    ScoreBox,
    JudgeActionsMenu,
    SynchronizationErrorsLog,
    ChangeJudgedDegreeOfDifficulty
  },
  props: {
    unitId: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: () => false
    },
    participantId: {
      type: String,
      default: null
    },
    useOnlineJudges: {
      type: Boolean,
      default: () => true
    },
    showSynErrorLogButton: {
      type: Boolean,
      default: () => true
    }
  },
  data: () => ({
    updatedScore: '0.0',
    judgesOnLine: {},
    showSyncErrorsModal: false
  }),
  async created () {
    await Promise.all([
      this.fetchHands(),
      this.fetchParticipantScores({
        unitId: this.unitId,
        participantId: this.participantId
      }),
      this.useOnlineJudges && this.fetchOnlineJudges({ unitId: this.unitId }),
      this.fetchSynchronizationErrors({
        unitId: this.unitId,
        participantId: this.participantId
      })
    ])
  },
  computed: {
    ...mapGetters(
      participants.namespace,
      [
        participants.getters.selectedParticipantScores,
        participants.getters.selectedParticipantModifiedScores,
        participants.getters.selectedScore,
        participants.getters.artisticImpressionFactors,
        participants.getters.elementFactors,
        participants.getters.isSelectedScoreTouched,
        participants.getters.missingJudgeInProgress
      ]
    ),
    ...mapGetters(
      chiefRecorder.namespace,
      [
        chiefRecorder.getters.raisedHandsByPanel,
        chiefRecorder.getters.isScoreRecalculated,
        chiefRecorder.getters.isInProgress
      ]
    ),
    ...mapGetters(
      unit.namespace,
      [
        unit.getters.onlineJudges
      ]
    ),
    ...mapGetters(
      synchronizationErrors.namespace,
      [
        synchronizationErrors.getters.synchronizationErrors
      ]
    ),
    artisticImpressionTotals () {
      const { totalBeforePenalties, penalty, total } = this
        .selectedParticipantScores
        .panels
        .artisticImpression
      return [
        {
          rowLabel: 'Total artistic impression before penalties',
          value: totalBeforePenalties
        },
        {
          readonly: this.readonly,
          rowLabel: 'Other artistic impression penalties',
          value: penalty,
          min: 0,
          max: Infinity,
          step: 0.1,
          penaltyName: 'artisticImpressionPenalty',
          onValueUpdated: (value, penaltyName) => this.updateSelectedParticipantPenalties(
            {
              artisticImpressionPenalty: value,
              penaltyName
            }
          )
        },
        {
          rowLabel: 'TOTAL ARTISTIC IMPRESSION',
          value: total
        }
      ]
    },
    elementsTotals () {
      const { totalBeforePenalties, penalty, total, synchronizationErrors, parts = [] } = this
        .selectedParticipantScores
        ?.panels
        ?.elements ?? {}
      return parts.length < 1
        ? []
        : [
          {
            rowLabel: `TOTAL ELEMENTS BEFORE PENALTIES`,
            value: totalBeforePenalties
          },
          {
            rowLabel: 'Synchronisation (Sy) errors',
            value: synchronizationErrors.toFixed(2),
            additionalAction: {
              buttonLabel: 'Log',
              buttonAction: () => this.showSynchronizationErrorsModal()
            },
            readonly: !this.showSynErrorLogButton
          },
          {
            readonly: this.readonly,
            rowLabel: 'Other elements penalties',
            value: penalty,
            penaltyName: 'elementsPenalty',
            onValueUpdated: (value, penaltyName) => this.updateSelectedParticipantPenalties(
              {
                elementsPenalty: value,
                penaltyName
              }
            )
          },
          {
            rowLabel: 'TOTAL ELEMENTS',
            value: total
          }
        ]
    },
    dynamicLayoutStyles () {
      const { panels: { artisticImpression, elements } = {} } = this.selectedParticipantScores

      const rows =
        (artisticImpression?.parts?.length || 0) +
        (elements?.parts?.length || 0)

      return `grid-template-rows: ${rows}fr 1fr`
    },
    selectedParticipantScoresSummary () {
      return pick(this.selectedParticipantScores, ['penalty', 'totalScore', 'rank'])
    },
    raisedHands () {
      return this.raisedHandsByPanel || []
    },
    isParticipantScoreConfigurationReady () {
      return !this.isInProgress('fetchingAllParticipants') && !this.isInProgress('fetchingParticipantDetails')
    },
    showMajorErrorAlert () {
      return this.synchronizationErrors?.some(e => e.outcome === 'Major')
    }
  },
  watch: {
    async participantId () {
      const unitId = this.unitId
      const participantId = this.participantId
      await Promise.all([
        this.fetchRaisedHands({ unitId, participantId }),
        this.fetchParticipantScores({ unitId, participantId }),
        this.fetchSynchronizationErrors({ unitId, participantId })
      ])
    },
    async unitId (unitId) {
      if (this.useOnlineJudges) {
        await Promise.all([
          this.fetchOnlineJudges({ unitId })
        ])
      }
    }
  },
  methods: {
    ...mapActions(
      participants.namespace,
      [
        participants.actions.fetchParticipantScores,
        participants.actions.updateSelectedParticipantPenalties,
        participants.actions.selectParticipantScore,
        participants.actions.modifyJudgeScore,
        participants.actions.sendJudgeScore,
        participants.actions.clearSelectedScore
      ]
    ),
    ...mapActions(
      chiefRecorder.namespace,
      [
        chiefRecorder.actions.fetchRaisedHands,
        chiefRecorder.actions.lowerHand
      ]
    ),
    ...mapActions(
      unit.namespace,
      [
        unit.actions.fetchOnlineJudges
      ]
    ),
    ...mapActions(
      synchronizationErrors.namespace,
      [
        synchronizationErrors.actions.fetchSynchronizationErrors,
        synchronizationErrors.actions.changeOutcome
      ]
    ),
    declaredDDForPart (partIndex) {
      return toFixedValue(this.elementFactors[partIndex]?.declaredDegreeOfDifficulty, 4)
    },
    judgedDDForPart (partIndex) {
      return toFixedValue(this.elementFactors[partIndex]?.judgedDegreeOfDifficulty, 4)
    },
    factorForPart (partIndex) {
      return toFixedValue(this.elementFactors[partIndex]?.factor, 2)
    },
    classForDDPart (partIndex) {
      const mark = (this.elementFactors[partIndex]?.mark ?? '').toLowerCase()
      const modified = this.elementFactors[partIndex]?.degreeOfDifficultyModifiedByChiefRecorder
      return {
        'scores-table__cell': true,
        [`scores-table__cell--${mark}`]: true,
        [`scores-table__cell--${mark}--modified`]: modified,
        'scores-table__cell--modified': modified
      }
    },
    classForDATCPart (partIndex, assistantSeat) {
      const mark = (this.elementFactors[partIndex]?.assistantMarks[assistantSeat - 1] ?? 'confirmed').toLowerCase()
      return {
        'scores-table__cell': true,
        [`scores-table__cell--${mark}`]: true
      }
    },
    factorForPanel (factor) {
      return toFixedValue(this.artisticImpressionFactors[factor], 2)
    },
    isSelectedScore (checkedItem) {
      return isEqual(checkedItem, this.selectedScore)
    },
    isJudgeNotMissing (score) {
      return !score || !score.isMissing
    },
    isScoreModified ({ panelId, partIndex, seat }) {
      const { artisticImpression, elements } = this.selectedParticipantModifiedScores

      const panel = panelId === PanelId.Elements ? elements : artisticImpression

      if (isNil(panel)) {
        return false
      }

      const judgeModifiedScores = panel.parts[partIndex].scores

      return judgeModifiedScores[seat - 1]
    },
    async updateScore () {
      await this.modifyJudgeScore(this.updatedScore)
      this.updatedScore = '0.0'
    },
    selectScore: function ({ panelId, partIndex, seat, value }) {
      this.selectParticipantScore({ panelId, partIndex, seat, value })
      this.updatedScore = '0.0'
    },
    classForScore ({ panelId, partIndex, score, seat }) {
      return {
        'scores-table__result--judge-offline':
          this.isJudgeAbsent({ panelId, judgeIndex: seat - 1 }) &&
          !this.isSelectedScore({ panelId, partIndex, seat }) &&
          this.isJudgeNotMissing(score),
        'scores-table__result--judge-offline-missing': this.isJudgeAbsent({ panelId, judgeIndex: seat - 1 }) &&
          score && score.isMissing,
        'scores-table__result--judge-online-missing': !this.isJudgeAbsent({ panelId, judgeIndex: seat - 1 }) &&
          score && score.isMissing && this.useOnlineJudges,
        'scores-table__result--modified': this.isScoreModified({ panelId, partIndex, seat })
      }
    },
    scoreUpdated ({ value }) {
      this.updatedScore = value
    },
    async fetchHands () {
      await this.fetchRaisedHands({
        unitId: this.unitId,
        participantId: this.participantId
      })
    },
    async onHandClicked (panelId, seat) {
      await this.lowerHand({
        unitId: this.unitId,
        participantId: this.participantId,
        panelId,
        seat
      })
    },
    onTableClicked () {
      this.clearSelectedScore()
    },
    isJudgeAbsent ({ panelId, judgeIndex }) {
      if (!this.useOnlineJudges) {
        return false
      }
      return !this.onlineJudges[panelId]?.[judgeIndex]
    },
    showSynchronizationErrorsModal () {
      this.showSyncErrorsModal = true
    },
    async onSyncErrorOutomeChanged ({ timeStamp, outcome }) {
      await this.changeOutcome({
        unitId: this.unitId,
        participantId: this.participantId,
        timeStamp,
        outcome
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/typology';
@import '~@/styles/variables';
@import '~@/styles/background';
@import '~@/styles/container';

.scores {
  $shrinkBy: 20%;

  &-table {
    height: 100%;
    width: 100%;

    &__cell {
      &--confirmed {
        background-color: $green;

        &--modified {
          background-color: rgba($green, 0.5);
        }
      }

      &--review {
        background-color: $orange;

        &--modified {
          background-color: rgba($orange, 0.5);
        }
      }

      &--basemark {
        background-color: $red;
        color: $white;

        &--modified {
          background-color: rgba($red, 0.5);
        }
      }

      &--modified {
        font-style: italic;
      }
    }

    &-cell {
      position: relative;

      &--caption {
        @include primary-caption-l4;

        &--small {
          width: 60px;
        }

        &--big {
          width: 307px;
        }
      }

      &__score--total {
        @include value-l4;

        float: right;
        margin-right: 0.5rem;
      }
    }

    &__hand {
      @include value-l4;

      position: absolute;
      left: calc(#{$shrinkBy});

      transform: translate(-50%, -50%);
    }

    &__result-header {
      @include primary-caption-l4;
      position: absolute;
      top: -1.3rem;
      left: 0.25rem;

      width: 200%;
      text-align: left;
    }

    &__result {
      @include value-l4();

      padding-top: 0;
      padding-bottom: 0;
      width: calc(100% - #{$shrinkBy});

      &--judge-online-missing {
        background: $pink
      }

      &--judge-offline-missing {
        @include background($pink)
      }

      &--judge-offline {
        @include background($white)
      }

      &--modified {
        color: $pink
      }
    }
  }

  &-chief-recorder {
    line-height: 0.9;
    font-size: 15px;
    &__scores {
      @include vcontainer('justified', 'justified');
      @include border('regular', 1rem);
      overflow: hidden;
    }

    &__summary {
      margin-top: 1rem;
    }
  }
}

.confirm-button {
  @include button-caption-l4;

  margin: 0.1rem auto 0 auto;
  padding: 0.2rem;

  position: absolute;
  z-index: 1;
}
</style>
