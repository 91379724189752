import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.selectedUnit)?_c('olr-editor',{attrs:{"unitName":_vm.selectedUnit && _vm.selectedUnit.name},on:{"addToList":_vm.moveParticipantToUnit,"removeFromList":_vm.removeParticipantFromUnit,"filterChanged":_vm.filterChanged},scopedSlots:_vm._u([{key:"registred-list",fn:function(){return [_c('list',{attrs:{"loading":_vm.isRegisteredParticipantsLoadingInProgress,"listData":_vm.eventRegisteredParticipantsFiltered},on:{"itemSelected":_vm.onSelectedRegisteredParticipant,"itemActionCalled":_vm.onDoubleSelectedRegisteredParticipant}},[_vm._l((_vm.eventRegisteredParticipantsFiltered),function(participant,index){return _c('template',{slot:index},[_c('div',{key:participant.id,staticClass:"member"},[(!_vm.allEventRegisteredParticipantsFilteredHaveCoachCards)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [(!participant.hasCoachCard)?_c(VIcon,_vm._g(_vm._b({staticClass:"member__missing-cc"},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")]):_vm._e()]}}],null,true)},[_vm._v(" Coach card is missing ")]):_vm._e(),(participant.hasCoachCard)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('a',_vm._g(_vm._b({staticClass:"member__edit-cc-link",attrs:{"target":"_blank","href":_vm.getCcbUpdateLink(participant)},on:{"click":function($event){$event.stopPropagation();}}},'a',attrs,false),on),[_c(VIcon,{staticClass:"member__edit-cc"},[_vm._v("mdi-clipboard-edit-outline")])],1)]}}],null,true)},[_vm._v(" Edit CCB ")]):_vm._e(),_c('span',{staticClass:"member__noc"},[_vm._v(" "+_vm._s(participant.noc)+" ")]),_c('span',{staticClass:"member__name"},[_vm._v(" "+_vm._s(_vm.displayMember(participant.members))+" ")])],1)])})],2)]},proxy:true},{key:"allocated-list",fn:function(){return [_c('list',{attrs:{"loading":_vm.isUnitParticipantsLoadingInProgress,"listData":_vm.unitParticipants},on:{"itemSelected":_vm.onSelectedUnitParticipant,"itemActionCalled":_vm.onDoubleSelectedUnitParticipant}},[_vm._l((_vm.unitParticipants),function(participant,index){return _c('template',{slot:index},[_c('div',{key:participant.id,staticClass:"member"},[(!_vm.allUnitParticipantsHaveCoachCards)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [(!participant.hasCoachCard)?_c(VIcon,_vm._g(_vm._b({staticClass:"member__missing-cc"},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")]):_vm._e()]}}],null,true)},[_vm._v(" Coach card is missing ")]):_vm._e(),(participant.hasCoachCard)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('a',_vm._g(_vm._b({staticClass:"member__edit-cc-link",attrs:{"target":"_blank","href":_vm.getCcbUpdateLink(participant)},on:{"click":function($event){$event.stopPropagation();}}},'a',attrs,false),on),[_c(VIcon,{staticClass:"member__edit-cc"},[_vm._v("mdi-clipboard-edit-outline")])],1)]}}],null,true)},[_vm._v(" Edit CCB ")]):_vm._e(),_c('span',{staticClass:"member__noc"},[_vm._v(" "+_vm._s(index + 1)+" "+_vm._s(participant.noc)+" ")]),_c('span',{staticClass:"member__name"},[_vm._v(" "+_vm._s(_vm.displayMember(participant.members))+" ")])],1)])})],2)]},proxy:true},{key:"buttons",fn:function(){return [_c(VBtn,{staticClass:"start-list-editor__button-add-with-cc",attrs:{"color":"primary"},on:{"click":_vm.addParticipantsWithCoachCardClicked}},[_vm._v(" Add Participants with CC ")]),_c(VBtn,{staticClass:"start-list-editor__button-random-draw",attrs:{"color":"primary"},on:{"click":_vm.randomDrawClicked}},[_vm._v(" Random Draw ")]),_c(VBtn,{staticClass:"start-list-editor__button-clear-startlist",attrs:{"color":"red"},on:{"click":_vm.clearStartListClicked}},[_vm._v(" Clear Startlist ")]),_c(VBtn,{staticClass:"start-list-editor__button-save-startlist",attrs:{"color":"primary","loading":_vm.isSaveStartListInProgress},on:{"click":_vm.saveStartListClicked}},[_vm._v(" Save Startlist ")])]},proxy:true}],null,false,1417438751)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }