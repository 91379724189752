<template>
  <section
    :open="isOpen"
    class="schedule-wizard">
    <section
      class="schedule-wizard__prompt-caption"
      @click="onCaptionClicked">
      <span class="schedule-wizard__prompt-caption-chevron"/>
      <span class="schedule-wizard__prompt-caption-title">
        {{ editedEventId ? 'Edit Event' : 'Create Event' }}
      </span>
    </section>
    <section
      class="schedule-wizard__wizard-section">
      <section
        class="schedule-wizard__inputs">
        <drop-down-list
          v-if="!editedEventId"
          :data="eventTypes"
          :selected-item-id.sync="selectedEventCode"
          class="schedule-wizard__type"/>
        <template
          v-for="({ type, name, code }, index) in scheduleEntryItems">
          <schedule-wizard-phase
            v-if="type === 'PHASE'"
            :key="`item-${selectedEventCode}-${index}`"
            :date.sync="scheduleEntryItems[index].date"
            :name="name"
            :code="code"
            class="schedule-wizard__input-line schedule-wizard__input-line--phase"
            @prelPhaseRemoved="onPrelPhaseRemoved"
            :removePreliminaryPhaseDisabled="isRemovingPreliminaryPhaseDisabled" />
          <schedule-wizard-unit
            v-if="type === 'UNIT'"
            :key="`item-${selectedEventCode}-${index}`"
            :name="name"
            :timeEnd.sync="scheduleEntryItems[index].timeEnd"
            :timeStart.sync="scheduleEntryItems[index].timeStart"
            class="schedule-wizard__input-line schedule-wizard__input-line--unit"/>
        </template>
      </section>
      <section
        class="schedule-wizard__footer">
        <button
          v-ripple
          class="schedule-wizard__button schedule-wizard__button--cancel"
          @click="onDispose">
          Cancel
        </button>
        <button
          v-ripple
          class="schedule-wizard__button schedule-wizard__button--save"
          @click="onSave"
          :disabled="!areSelectedDatesValid">
          {{ editedEventId ? 'Save' : 'Create' }}
        </button>
      </section>
    </section>
  </section>
</template>

<script>
import DropDownList from '@/components/DropDownList'
import { unit, olr } from '@/store/modules'
import { flatMap, isNil } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import ScheduleWizardPhase from './ScheduleWizardPhase'
import ScheduleWizardUnit from './ScheduleWizardUnit'
import { UnitStatus } from '@/enums/UnitStatus'

const isUnitInProgress = ({ status }) => [
  UnitStatus.GettingReady,
  UnitStatus.Running,
  UnitStatus.Unofficial,
  UnitStatus.Official,
  UnitStatus.Protested].includes(status)

export default {
  name: 'ScheduleWizard',
  components: { ScheduleWizardUnit, ScheduleWizardPhase, DropDownList },
  data: () => ({
    isOpen: false,
    selectedEventCode: null,
    scheduleEntryItems: [],
    isRemovingPreliminaryPhaseDisabled: false
  }),
  props: {
    editedEventId: {
      type: String,
      default: null
    }
  },
  emits: ['closed'],
  computed: {
    ...mapGetters(
      olr.namespace,
      [
        olr.getters.eventsTree
      ]
    ),
    ...mapGetters(
      unit.namespace,
      [
        unit.getters.allUnits
      ]
    ),
    eventTypes () {
      return Object
        .entries(this.eventsTree)
        .map(([code, { name }]) => ({
          id: code,
          name
        }))
    },
    areSelectedDatesValid () {
      return this.scheduleEntryItems
        ?.filter(x => x.type === 'UNIT')
        ?.every(x => x.timeEnd > x.timeStart) === true
    }
  },
  watch: {
    selectedEventCode () {
      this.scheduleEntryItems = flatMap(this.eventsTree[this.selectedEventCode]
        ?.phases
        ?.map(({ code, name }) => [
          {
            code,
            name,
            type: 'PHASE',
            date: new Date().toISOString().substring(0, 10)
          },
          {
            code: '000100--',
            name: 'Session A',
            type: 'UNIT',
            timeStart: '09:00',
            timeEnd: '10:30',
            timeZone: '+00:00'
          }]
        ))
    },
    editedEventId (newValue) {
      this.isOpen = !isNil(newValue)

      const eventUnits = this.allUnits
        .filter(({ eventId }) => eventId === this.editedEventId)
      eventUnits.sort((unitA, _) => unitA.id.includes('FNL-') ? 1 : -1)

      this.scheduleEntryItems = flatMap(
        eventUnits
          .map(({ id, name, scheduledDateStart, scheduledDateEnd }) => {
            const phaseCode = id.slice(22, 26)
            const unitCode = id.slice(-8)

            return [
              {
                code: phaseCode,
                name: phaseCode === 'PREL'
                  ? 'Preliminary'
                  : 'Finals',
                type: 'PHASE',
                date: scheduledDateStart.substring(0, 10)
              },
              {
                code: unitCode,
                name,
                type: 'UNIT',
                timeStart: scheduledDateStart.substring(11, 16),
                timeEnd: scheduledDateEnd.substring(11, 16),
                timeZone: scheduledDateStart.substring(19)
              }
            ]
          }))

      this.isRemovingPreliminaryPhaseDisabled = eventUnits
        .some(u => isUnitInProgress(u))
    }
  },
  methods: {
    ...mapActions(
      olr.namespace,
      [
        olr.actions.createScheduleEntry,
        olr.actions.updateScheduleEntry
      ]
    ),
    async onSave () {
      let ok
      if (!this.editedEventId) {
        ok = (await this.createScheduleEntry({
          newScheduleItemEventCode: this.selectedEventCode,
          newScheduleItem: this.scheduleEntryItems
        })) === true
      } else {
        ok = (await this.updateScheduleEntry({
          scheduleItemEventCode: this.editedEventId.slice(3, 12),
          scheduleItem: this.scheduleEntryItems
        })) === true
      }

      if (ok) {
        this.onDispose()
      }
    },
    onCaptionClicked () {
      if (this.isOpen) {
        this.onDispose()
      } else {
        this.isOpen = true
      }
    },
    onPrelPhaseRemoved () {
      this.scheduleEntryItems = this.scheduleEntryItems.slice(2)
    },
    onDispose () {
      this.isOpen = false
      this.selectedEventCode = null
      this.isRemovingPreliminaryPhaseDisabled = false
      this.$emit('closed')
    }
  }
}
</script>

<style lang="scss" scoped>
@use "sass:math";
@import "~@/styles/container";
@import "~@/styles/variables";
@import "~@/styles/button";

.schedule-wizard {
  position: relative;

  &__prompt-caption {
    @include container("left", "center");
    padding: 1rem 1.5rem;

    text-align: left;

    cursor: pointer;

    $parent: &;

    &-chevron {
      width: 0.75rem;
      height: 0.75rem;

      margin: #{math.div((math.sqrt(2) - 1), 2)}rem;

      border-style: solid;
      border-color: $black;
      border-width: 0.25rem 0.25rem 0 0;

      display: inline-block;
      box-sizing: border-box;

      transform: rotate(45deg);
      transition: transform 0.25s ease-in-out;

      @at-root #{$parent}:hover & {
        transform: rotate(405deg);
      }

      @at-root [open] & {
        margin-top: 0;
        transform: rotate(495deg);
      }

      @at-root [open]:hover & {
        transform: rotate(495deg);
      }
    }

    &-title {
      margin-left: 0.5rem;
    }
  }

  $parent: &;
  &__wizard-section {
    position: absolute;
    top: 2.5rem;

    width: 100%;
    box-sizing: border-box;

    padding: 0 0.5rem 1rem 0.5rem;
    background-color: $white;

    z-index: 100;

    transform: scaleY(0);
    transform-origin: top;
    transition: all 0.2s ease-out;

    @at-root #{$parent}[open] & {
      transform: scaleY(1);
    }
  }

  &__inputs {
    text-align: left;

    margin: 0.5rem 0;
  }

  &__input-line {
    padding: 0.25rem 0.5rem;

    &:first-of-type {
      margin-top: 1rem;
    }

    &:last-of-type {
      margin-bottom: 1rem;
    }

    &--unit {
      padding-left: 1.5rem;
    }
  }

  &__button {
    @include button($size: $small);

    margin: 0 0.5rem;

    &--cancel {
      background-color: $white;
      color: $red;
    }

    &--save:disabled {
      @include button($isDisabled: true, $size: $small);
      margin: 0 0.5rem;
    }
  }
}
</style>
