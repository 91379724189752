import { isNil } from 'lodash'
import { formatTimeStamp } from '@/components/helpers'

const getElapsedTimeParts = (elapsedMsString) => {
  const totalElapsedMilliseconds = +elapsedMsString
  const totalElapsedSeconds = Math.floor(totalElapsedMilliseconds / 1000)
  const elapsedMinutes = Math.floor(totalElapsedSeconds / 60)
  const elapsedSeconds = totalElapsedSeconds % 60
  const elapsedMilliseconds = totalElapsedMilliseconds % 1000

  return {
    elapsedMinutes: elapsedMinutes.toString(),
    elapsedSeconds: elapsedSeconds.toString().padStart(2, '0'),
    elapsedMilliseconds: elapsedMilliseconds.toString().padStart(3, '0')
  }
}

const formatElapsed = (elapsedMsString) => {
  const { elapsedMinutes, elapsedSeconds } = getElapsedTimeParts(elapsedMsString)

  return `${elapsedMinutes}' ${elapsedSeconds}"`
}

const formatElapsedTooltip = (timeStamp, elapsedMsString) => {
  const {
    elapsedMinutes,
    elapsedSeconds,
    elapsedMilliseconds
  } = getElapsedTimeParts(elapsedMsString)

  return `${formatTimeStamp(timeStamp)}\n${elapsedMinutes} min ${elapsedSeconds} sec ${elapsedMilliseconds} ms`
}

const mapVotes = (votes) => votes.map(
  (vote) => {
    if (isNil(vote)) {
      return null
    }
    const { mark, timeStamp, timeElapsed, ...rest } = vote
    return ({
      ...rest,
      mark: mark.toLowerCase(),
      tooltipText: formatElapsedTooltip(timeStamp, timeElapsed)
    })
  })

export const getters = Object.freeze({
  synchronizationErrors: 'synchronizationErrors'
})

export const gettersDefinition = Object.freeze({
  [getters.synchronizationErrors]: ({ synchronizationErrors }) =>
    synchronizationErrors
      ?.map(({ timeStamp, timeElapsed, votes, deduction, outcome: { mark } }) => ({
        timeStamp,
        elapsed: formatElapsed(timeElapsed),
        elapsedTooltipText: formatElapsedTooltip(timeStamp, timeElapsed),
        votes: mapVotes(votes),
        deduction: deduction.toFixed(2),
        outcome: mark
      })) || []
})
