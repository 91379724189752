<template>
  <div
      v-if="shouldShowTimers">
    <timers-box
      :is-read-only-mode="true"
      :unit-id="unitId"
      :participant-id="selectedParticipant.id" />
  </div>
</template>

<script>
import TimersBox from '@/components/TimersBox'
import ParticipantStatus from '@/enums/ParticipantStatus'
import { timekeeper, participants } from '@/store/modules'
import { mapActions, mapGetters } from 'vuex'
import { isEmpty } from 'lodash'

export default {
  name: 'ChiefRecorderTimers',
  components: {
    TimersBox
  },
  watch: {
    async selectedParticipant (value) {
      if (this.shouldShowTimers) {
        await this.fetchData({
          unitId: this.unitId,
          participantId: this.selectedParticipant.id
        })
      }
    }
  },
  computed: {
    ...mapGetters(
      participants.namespace,
      [
        participants.getters.selectedParticipant,
        participants.getters.unitId
      ]
    ),
    shouldShowTimers () {
      return !isEmpty(this.selectedParticipant) &&
        this.selectedParticipant.status !== ParticipantStatus.Active
    }
  },
  methods: {
    ...mapActions(
      timekeeper.namespace,
      [
        timekeeper.actions.fetchData
      ])
  }
}
</script>
