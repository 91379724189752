<template>
  <section
    class="olr-editor">
    <div class='olr-editor__unit-name'>{{unitName}}</div>
    <div class='olr-editor__filter'>
      <input placeholder='type to filter by name'
        v-model='filter'
        @change='emitFilterToParent' />
    </div>
    <div class='olr-editor__registered-list'>
      <slot name='registred-list'></slot>
    </div>
    <div class='olr-editor__arrows'>
      <div class="arrow-right"
        @click="$emit('addToList')"/>
      <div class="arrow-left"
        @click="$emit('removeFromList')"/>
    </div>
    <div class='olr-editor__allocation-list'>
      <slot name='allocated-list'></slot>
    </div>
    <div class='olr-editor__buttons'>
      <slot name='buttons'></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OlrEditor',
  data: () => ({
    filter: ''
  }),
  emits: ['addToList', 'removeFromList', 'filterChanged'],
  props: {
    unitName: {
      type: String,
      default: null
    }
  },
  computed: {
    emitFilterToParent () {
      return this.$emit('filterChanged', this.filter)
    }
  }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/container";
@import "~@/styles/button";
@import "~@/styles/typology";
@import "~@/styles/shapes";

.olr-editor {
  display: grid;
  grid-template-columns: [registeredlist] 4fr [arrows] 1fr [allocationlist] 4fr [end];
  grid-template-rows: [unitname] 1fr [filter] 0.5fr [lists] 8fr [buttons] 1fr [end];

  &__unit-name {
    @include primary-bold-caption-l4;

    grid-column: registeredlist / end;
    grid-row: unitname / unitname;

    align-self: center;
  }

  &__filter {
    grid-column: registeredlist / registeredlist;
    grid-row: filter / filter;

    max-height: 1rem;
    padding-bottom: 1rem;

    & > input {
      width: 100%;
      height: 100%;
    }
  }

  &__registered-list {
    grid-column: registeredlist / registeredlist;
    grid-row: lists / lists;

    max-height: 30rem;
  }

  &__allocation-list {
    grid-column: allocationlist / allocationlist;
    grid-row: filter / buttons;

    max-height: 32rem;
  }

  &__arrows {
    grid-column: arrows / arrows;
    grid-row: lists / lists;

    justify-self: center;

    padding: 0.5rem;

    & > * {
      margin: 1rem 0;
    }
  }

  &__buttons {
    grid-column: registeredlist / end;
    grid-row: buttons / buttons;

    align-self: end;
    justify-self: end;

    & button {
      @include button($size: $small);

    }

    & button:not([disabled]) {
      cursor: pointer;
    }
  }
}

.arrow-left {
  @include arrow-left;

  cursor: pointer;
}

.arrow-right {
  @include arrow-right;

  cursor: pointer;
}

</style>
