<template>
  <div class='keypad'>
    <NumberButton
      :disabled="disabled || isFractionMode"
      class="keypad__button"
      number='7'
      @clicked="keyClicked"/>
    <NumberButton
      :disabled="disabled || isFractionMode"
      class="keypad__button"
      number='8'
      @clicked="keyClicked"/>
    <NumberButton
      :disabled="disabled || isFractionMode"
      class="keypad__button"
      number='9'
      @clicked="keyClicked"/>
    <NumberButton
      :disabled="disabled || isFractionMode"
      class="keypad__button"
      number='4'
      @clicked="keyClicked"/>
    <NumberButton
      :disabled="disabled || isFractionMode"
      class="keypad__button"
      number='5'
      @clicked="keyClicked"/>
    <NumberButton
      :disabled="disabled || isFractionMode"
      class="keypad__button"
      number='6'
      @clicked="keyClicked"/>
    <NumberButton
      :disabled="disabled || isFractionMode"
      class="keypad__button"
      number='1'
      @clicked="keyClicked"/>
    <NumberButton
      :disabled="disabled || isFractionMode"
      class="keypad__button"
      number='2'
      @clicked="keyClicked"/>
    <NumberButton
      :disabled="disabled || isFractionMode"
      class="keypad__button"
      number='3'
      @clicked="keyClicked"/>
    <NumberButton
      v-if="isFractionMode"
      :disabled="disabled"
      class="keypad__button keypad__button--4-in-line keypad__button--1"
      number='.00'
      @clicked="keyClicked"/>
    <NumberButton
      v-if="isFractionMode"
      :disabled="disabled"
      class="keypad__button keypad__button--4-in-line keypad__button--2"
      number='.25'
      @clicked="keyClicked"/>
    <NumberButton
      v-if="isFractionMode"
      :disabled="disabled"
      class="keypad__button keypad__button--4-in-line keypad__button--3"
      number='.50'
      @clicked="keyClicked"/>
    <NumberButton
      v-if="isFractionMode"
      :disabled="disabled"
      class="keypad__button keypad__button--4-in-line keypad__button--4"
      number='.75'
      @clicked="keyClicked"/>
    <NumberButton
      v-if="isWholeNumbersMode"
      :disabled="disabled"
      class="keypad__button keypad__button--2-in-line keypad__button--1"
      number='0'
      @clicked="keyClicked"/>
    <NumberButton
      v-if="isWholeNumbersMode"
      :disabled="disabled"
      class="keypad__button keypad__button--2-in-line keypad__button--2"
      number='10'
      @clicked="keyClicked"/>
  </div>
</template>

<script>
import { judge } from '@/store/modules'
import { mapActions } from 'vuex'
import NumberButton from './NumberButton'

export default {
  name: 'KeyPad',
  components: {
    NumberButton
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'whole',
      validator (value) {
        return ['fraction', 'whole'].includes(value)
      }
    }
  },
  computed: {
    isFractionMode () {
      return this.mode === 'fraction'
    },
    isWholeNumbersMode () {
      return this.mode === 'whole'
    }
  },
  methods: {
    ...mapActions(
      judge.namespace,
      [
        judge.actions.keypadKeyPressed
      ]
    ),
    keyClicked (keyValue) {
      this.keypadKeyPressed({ value: keyValue, goToNext: true })
    }
  }
}
</script>

<style lang='scss' scoped>
@use "sass:math";

@mixin set-columns($itemsCount, $mySelector, $parentSelector) {
  $width: math.div(12, $itemsCount);
  @for $i from 0 to $itemsCount {
    @at-root #{$mySelector}#{$parentSelector}--#{$i + 1} {
      grid-column: #{$width * $i + 1} / span #{$width};
    }
  }
  grid-row: 4 / span 1;
}

.keypad {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(4, 1fr);

  &__button {
    grid-column-end: span 4;

    &--last-line {
      grid-row: 4 / span 1;
    }

    $parent: &;
    &--2-in-line {
      @include set-columns(2, &, $parent);
    }

    &--4-in-line {
      @include set-columns(4, &, $parent);
    }
  }
}
</style>
