var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isOpen)?_c('div',{staticClass:"overlay",on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}}):_vm._e(),_c('div',{staticClass:"burger-menu"},[_c('div',{staticClass:"burger-menu__button",on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_c('span',{staticClass:"burger-menu__button-bar",class:{ 'burger-menu__button-bar--opened': _vm.isOpen }}),_c('span',{staticClass:"burger-menu__button-bar",class:{ 'burger-menu__button-bar--opened': _vm.isOpen }}),_c('span',{staticClass:"burger-menu__button-bar",class:{ 'burger-menu__button-bar--opened': _vm.isOpen }})]),_c('div',{staticClass:"burger-menu__window",class:{'burger-menu__window--opened': _vm.isOpen}},[_c('ul',[_vm._l((_vm.displayableRoles),function(ref){
var value = ref.value;
var inactive = ref.inactive;
var name = ref.name;
return _c('li',{key:value,staticClass:"burger-menu__menu-item",class:{'burger-menu__menu-item--disabled': inactive }},[_c('router-link',{staticClass:"burger-menu__menu-item-link",class:{ 'burger-menu__menu-item-link--disabled': inactive },attrs:{"disabled":inactive,"to":value,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('span',{attrs:{"role":"link"},on:{"click":function($event){!inactive && navigate($event)}}},[_vm._v(" "+_vm._s(name)+" ")])]}}],null,true)})],1)}),(_vm.hasAnythingElseToDisplay)?_c('li',{staticClass:"burger-menu__menu-item burger-menu__menu-item--separator"}):_vm._e(),(_vm.isCopyTokenToClipboardEnabled)?_c('li',{staticClass:"burger-menu__menu-item",on:{"click":_vm.copyToken}},[_c('a',{staticClass:"burger-menu__menu-item-link burger-menu__menu-item-link--debug",attrs:{"href":"#"}},[_vm._v("Copy my JWT token")])]):_vm._e(),(_vm.isCopyTokenToClipboardEnabled)?_c('li',{staticClass:"burger-menu__menu-item burger-menu__menu-item--separator"}):_vm._e(),(_vm.showCoachCardsValidation)?_c('router-link',{staticClass:"burger-menu__menu-item burger-menu__menu-item-link",attrs:{"to":'/coach-card-validation',"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [(!isActive)?_c('li',{staticClass:"burger-menu__menu-item"},[_c('span',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v(" Coach Card Validation ")])]):_vm._e()]}}],null,false,4260209157)}):_vm._e(),(_vm.showTeamsManagement)?_c('router-link',{staticClass:"burger-menu__menu-item burger-menu__menu-item-link",attrs:{"to":'/teams-management',"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [(!isActive)?_c('li',{staticClass:"burger-menu__menu-item"},[_c('span',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v(" Teams Management ")])]):_vm._e()]}}],null,false,490548353)}):_vm._e(),_c('router-link',{staticClass:"burger-menu__menu-item burger-menu__menu-item-link",attrs:{"to":'/context-selection',"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [(!isActive)?_c('li',{staticClass:"burger-menu__menu-item"},[_c('span',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v(" Switch competition ")])]):_vm._e()]}}])}),(_vm.showSettings)?_c('router-link',{staticClass:"burger-menu__menu-item burger-menu__menu-item-link",attrs:{"to":'/settings',"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [(!isActive)?_c('li',{staticClass:"burger-menu__menu-item"},[_c('span',{attrs:{"role":"link"},on:{"click":navigate}},[_vm._v(" Settings ")])]):_vm._e()]}}],null,false,984105401)}):_vm._e(),_c('li',{staticClass:"burger-menu__menu-item"},[_c('span',{staticClass:"burger-menu__menu-item burger-menu__menu-item-link",attrs:{"role":"link"},on:{"click":_vm.onLogoutClicked}},[_vm._v(" Log out ")])])],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }