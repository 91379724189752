import { UnitStatus } from '@/enums/UnitStatus'

export const UnitStatusMap = Object.freeze({
  [UnitStatus.Scheduled]: 2,
  [UnitStatus.GettingReady]: 3,
  [UnitStatus.Running]: 4,
  [UnitStatus.Delayed]: 6,
  [UnitStatus.Postponed]: 8,
  [UnitStatus.Official]: 10,
  [UnitStatus.Unofficial]: 11,
  [UnitStatus.Protested]: 12
})
