import { httpget, httppost, joinUri } from '../base-api'

const toUri = ({ unitId, participantId }) =>
  `units/${unitId}/participants/${participantId}/degree-of-difficulty`

const fromApiMark = mark => mark?.toLowerCase() || null
const toApiMark = mark => {
  switch (mark) {
    case 'confirmed': return 'Confirmed'
    case 'review': return 'Review'
    case 'basemark': return 'BaseMark'
    default: return null
  }
}

export default {
  get: async ({ unitId, participantId }) => {
    var response = await httpget(toUri({ unitId, participantId })) || {}
    var difficultyTechnicalControllerFeedbackMapped =
      response.difficultyTechnicalControllerFeedback.map(fromApiMark)
    var assistantsDifficultyTechnicalControllersFeedbackMapped =
      response.assistantsDifficultyTechnicalControllersFeedback.map(
        a => a.map(fromApiMark)
      )

    return {
      difficultyTechnicalControllerFeedback:
        difficultyTechnicalControllerFeedbackMapped,
      assistantsDifficultyTechnicalControllerFeedback:
        assistantsDifficultyTechnicalControllersFeedbackMapped
    }
  },
  post: async ({ unitId, participantId, marks = [] }) =>
    (await httppost(toUri({ unitId, participantId }), marks.map(toApiMark))),
  postAssistant: async ({ unitId, participantId, marks = [], seat }) => {
    const uri = `${toUri({ unitId, participantId })}/assistant`
    await httppost(uri, { judgedDegreeOfDifficultyStatuses: marks.map(toApiMark), seat })
  },
  postSingle: async ({ unitId, participantId, elementIndex, mark, keepFalsy }) => {
    const uri = joinUri(
      joinUri(
        toUri({ unitId, participantId }),
        'element'),
      elementIndex, keepFalsy)
    const body = { changedDegreeOfDifficulty: mark }
    await httppost(uri, body)
  }
}
