// In the frontegg there are more roles like 'music-master' or 'video-replay-operator'
// but they do not have an available view in the app. That's why they are not here.
export default {
  'chief-recorder': {
    code: 'chief-recorder',
    name: 'Chief Recorder'
  },
  referee: {
    code: 'referee',
    name: 'Referee'
  },
  judge: {
    code: 'judge',
    name: 'Judge'
  },
  timekeeper: {
    code: 'timekeeper',
    name: 'Timekeeper'
  },
  'olr-operator': {
    code: 'olr-operator',
    name: 'Olr Operator'
  }
}
