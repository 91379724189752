<template>
    <v-card class="mt-8">
      <v-card-title>
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-icon
          :disabled="isInProgress('fetchingCompetitionCoachCards')"
          @click="fetchCompetitionCoachCards">
          mdi-refresh
        </v-icon>
      </v-card-title>
      <v-btn-toggle
        v-model="statusFilter"
        mandatory
        color='purple'>
          <v-btn v-for="(option, index) in CoachCardFilterOptions" :key="index">
            {{ option }}
          </v-btn>
        </v-btn-toggle>
      <v-data-table
        :headers="headers"
        :items="pendingCoachCardsWithActions"
        :loading="isInProgress('fetchingCompetitionCoachCards')"
        :search="search">
        <template v-slot:item.actions="row">
          <v-btn
            x-small
            color="success"
            class="action-button"
            :disabled="isAccepted(row.item.status)"
            :loading="isInProgress(`accept-coach-card${row.item.id}`)"
            @click='acceptCoachCard({coachCardId: row.item.id})'>
            {{ getAcceptedActionTitle(row.item.status) }}
          </v-btn>
          <v-btn
            x-small
            color="error"
            class="action-button"
            :disabled="isRejected(row.item.status)"
            :loading="isInProgress(`reject-coach-card${row.item.id}`)"
            @click='rejectCoachCard({coachCardId: row.item.id})'>
            {{ getRejectedActionTitle(row.item.status) }}
          </v-btn>
          <v-btn
            x-small
            target="_blank"
            class="action-button"
            :href="getCcbViewLink(row.item.uri)">
            View
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { coachCards } from '@/store/modules'
import { coachCards as coachCardsApi } from '@/api'
import CoachCardStatuses from '@/enums/CoachCardStatuses'
import { sortBy } from 'lodash'

const AllCoachCards = 'All'
const CoachCardFilterOptions = [
  AllCoachCards,
  CoachCardStatuses.COACH_CARD_PENDING,
  CoachCardStatuses.COACH_CARD,
  CoachCardStatuses.COACH_CARD_REJECTED]
export default {
  name: 'CoachCardValidationTable',
  async created () {
    this.CoachCardFilterOptions = CoachCardFilterOptions
    await this.fetchCompetitionCoachCards()
  },
  data () {
    return {
      search: '',
      statusFilter: AllCoachCards
    }
  },
  computed: {
    ...mapGetters(
      coachCards.namespace,
      [
        coachCards.getters.competitionCoachCards,
        coachCards.getters.isInProgress
      ]
    ),
    pendingCoachCardsWithActions () {
      const mappedCoachCards = this.competitionCoachCards
        .map(ccc => ({
          actions: {},
          ...ccc,
          status: CoachCardStatuses[ccc.status]
        }))
      const sortedCoachCards = sortBy(mappedCoachCards, (cc) => {
        return [cc.noc, cc.event, cc.phase, cc.participant, cc.dateOfArrival]
      })

      if (this.statusFilter === CoachCardFilterOptions.indexOf(AllCoachCards)) {
        return sortedCoachCards
      }
      const filteredCoachCards = sortedCoachCards
        .filter(cc => CoachCardFilterOptions.indexOf(cc.status) === this.statusFilter)
      return filteredCoachCards
    },
    headers () {
      const requiredHeaders = [
        { text: 'Participant', value: 'participant' },
        { text: 'Noc', value: 'noc' },
        { text: 'Event', value: 'event' },
        { text: 'Phase', value: 'phase' },
        { text: 'Date', value: 'date' },
        { text: 'Date of Arrival', value: 'dateOfArrival' },
        { text: 'Source', value: 'source' },
        { text: 'Status', value: 'status' },
        { text: 'Actions', value: 'actions' }
      ]
      if (this.pendingCoachCardsWithActions.some(cc => cc.unit)) {
        requiredHeaders.splice(4, 0, { text: 'Unit', value: 'unit' })
      }
      return requiredHeaders
    }

  },
  methods: {
    ...mapActions(
      coachCards.namespace,
      [
        coachCards.actions.fetchCompetitionCoachCards,
        coachCards.actions.acceptCoachCard,
        coachCards.actions.rejectCoachCard
      ]
    ),
    getCcbViewLink (olrUri) {
      return coachCardsApi.viewParticipantCoachCardUri(olrUri)
    },
    isAccepted (coachCardStatus) {
      return coachCardStatus === CoachCardStatuses.COACH_CARD
    },
    isRejected (coachCardStatus) {
      return coachCardStatus === CoachCardStatuses.COACH_CARD_REJECTED
    },
    getAcceptedActionTitle (coachCardStatus) {
      return this.isAccepted(coachCardStatus) ? 'Accepted' : 'Accept'
    },
    getRejectedActionTitle (coachCardStatus) {
      return this.isRejected(coachCardStatus) ? 'Rejected' : 'Reject'
    }
  }
}
</script>

<style scoped>
.action-button {
  opacity: 0.8;
  margin: 0.2rem;
}
</style>
