import { joinUri } from '../base-api'

export const toEventsBaseUri = ({ eventId = '', uri = '' } = {}) =>
  joinUri(
    joinUri('events', eventId),
    uri)

export const toPrintoutUri = ({ eventId, printoutCode } = {}) =>
  joinUri(
    toEventsBaseUri({ eventId, uri: 'printouts' }),
    printoutCode)
