<template>
  <div>
    <slot v-if="isReady">
    </slot>
    <slot v-if="!isReady" name="fallback">
      <v-progress-circular
        indeterminate
        color="primary"
        size="24"
      />
    </slot>
  </div>
</template>

<script>
export default {
  name: 'Suspense',
  props: {
    isReady: {
      type: Boolean
    }
  }
}
</script>
