<template>
  <v-menu :value="opened" @input="$emit('update:opened', $event)">
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-on="on"
        v-bind="attrs"
        @click="$emit('menuOpened')">
        {{ icon }}
      </v-icon>
    </template>

    <div v-for="{ group, items } in options" :key="group">
      <v-list>
        <v-subheader>{{ group }}</v-subheader>
        <div
          v-for="{ disabled, title, promptTitle, promptMessageLines } in items"
          :key="title">
          <prompt
            v-if="promptTitle"
            :prompt="promptMessageLines"
            :title="promptTitle"
            @onAction="onPromptAction($event, group, title)">
            <template #activator="{ on, attrs }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
                :disabled="disabled">
                <v-list-item-title>
                  {{ title }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </prompt>
          <v-list-item
            v-else
            :disabled="disabled"
            @click="onOptionSelected(group, title)">
            <v-list-item-title>
              {{ title }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
      <v-divider></v-divider>
    </div>
  </v-menu>
</template>

<script>
import Prompt from '@/components/Prompt'

export default {
  name: 'DropDownGroupedMenu',
  components: { Prompt },
  emits: ['menuOpened', 'update:opened', 'optionSelected'],
  model: {
    prop: 'opened',
    event: 'update:opened'
  },
  props: {
    opened: {
      type: Boolean,
      default: () => false
    },
    options: {
      type: Array,
      default: () => []
    },
    icon: {
      type: String,
      default: () => 'mdi-dots-horizontal'
    }
  },
  methods: {
    onOptionSelected (group, option) {
      this.$emit('optionSelected', { group, option })
    },
    onPromptAction (action, group, option) {
      if (action === 'ok') {
        this.onOptionSelected(group, option)
      } else {
        this.$emit('update:opened', false)
      }
    }
  }
}
</script>
