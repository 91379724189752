import { isEmpty, isNil } from 'lodash'
import { actions } from './actions'
import { inProgress, mutations } from './mutations'

export const subscribe = ({ store, dispatchLocal, commitLocal } = {}) => {
  return store.subscribeAction(action => {
    switch (action.type) {
      case 'submitCompetitionPrintoutSucceeded':
      case 'submitCompetitionPrintoutFailed': {
        dispatchLocal(actions.fetchCompetitionPrintoutsStatuses)
        break
      }

      case 'submitEventPrintoutSucceeded':
      case 'submitEventPrintoutFailed': {
        const { tournamentNodeId } = action.payload
        dispatchLocal(actions.fetchEventPrintoutsStatuses, { eventId: tournamentNodeId })
        break
      }

      case 'submitUnitPrintoutSucceeded':
      case 'submitUnitPrintoutFailed': {
        const { tournamentNodeId } = action.payload
        dispatchLocal(actions.fetchUnitPrintoutsStatuses, { unitId: tournamentNodeId })
        break
      }

      case 'submitOdfArchiveSucceeded': {
        let message = 'ODF archive import succeeded'
        if (action.payload.message) {
          message += ` with warnings: ${action.payload.message}`
        } else {
          message += '.'
        }
        commitLocal(
          mutations.setOdfImportResult,
          {
            success: message
          })
        break
      }
      case 'submitOdfArchiveFailed': {
        const { error } = action.payload
        commitLocal(
          mutations.setOdfImportResult,
          { errors: ['ODF archive import failed with error:', error.message] })
        break
      }

      case 'submitCoachCardArchiveSucceeded': {
        const { errors } = action.payload

        if (isEmpty(action.payload.errors)) {
          commitLocal(
            mutations.setCoachCardImportResult,
            { success: 'Coach Card import succeeded.' })
        } else {
          const errorsFormatted = formatCoachCardImportErrors(errors)
          commitLocal(
            mutations.setCoachCardImportResult,
            {
              errors: [
                'Coach Card import partially succeeded. Errors:',
                ...errorsFormatted
              ]
            }
          )
        }

        break
      }
      case 'submitCoachCardArchiveFailed': {
        const { error } = action.payload
        const errorsFormatted = !isNil(error?.message)
          ? [error.message]
          : formatCoachCardImportErrors(error)
        commitLocal(
          mutations.setCoachCardImportResult,
          {
            errors: [
              'Coach Card import failed. Errors:',
              ...errorsFormatted
            ]
          }
        )
        break
      }

      case 'submittingSaveStartList':
        dispatchLocal(actions.startListSaveStarted)
        break
      case 'submitSaveStartListSucceeded':
        dispatchLocal(actions.startListSaveEnded)
        alert('Successfully saved')
        break
      case 'submitSaveStartListFailed':
        dispatchLocal(actions.startListSaveEnded)
        alert('Saving failure')
        break
      case 'submittingSaveJudgeList':
        dispatchLocal(actions.judgesAllocationSaveStarted)
        break
      case 'submitSaveJudgeListSucceeded':
        dispatchLocal(actions.judgesAllocationSaveEnded)
        alert('Successfully saved')
        break
      case 'submitSaveJudgeListFailed':
        dispatchLocal(actions.judgesAllocationSaveEnded)
        alert('Saving failure')
        break

      case 'submitCreateScheduleEntrySucceeded':
        alert('Successfully created schedule entry')
        break

      case 'submitCreateScheduleEntryFailed': {
        const { error } = action.payload
        alert(`Failed to create schedule entry:\n${error.message}`)
        break
      }

      case 'submitUpdateScheduleEntrySucceeded':
        alert('Successfully updated schedule entry')
        break

      case 'submitUpdateScheduleEntryFailed': {
        const { error } = action.payload
        alert(`Failed to update schedule entry:\n${error.message}`)
        break
      }

      case 'submittingModifyStatusByOlr':
        dispatchLocal(
          actions.unitStatusChangeStarted,
          action.payload)
        break
      case 'submitModifyStatusByOlrSucceeded':
      case 'submitModifyStatusByOlrFailed':
        dispatchLocal(
          actions.unitStatusChangeFinished,
          action.payload)
        break

      case 'fetchingRegisteredParticipants':
        commitLocal(mutations.addInProgress, { scope: inProgress.registeredParticipantsLoading })
        break
      case 'fetchRegisteredParticipantsSucceeded':
      case 'fetchRegisteredParticipantsFailed':
        commitLocal(mutations.releaseInProgress, {
          scope: inProgress.registeredParticipantsLoading
        })
        break

      case 'fetchingRegisteredJudges':
        commitLocal(mutations.addInProgress, {
          scope: inProgress.registeredJudgesLoading
        })
        break
      case 'fetchRegisteredJudgesSucceeded':
      case 'fetchRegisteredJudgesFailed':
        commitLocal(mutations.releaseInProgress, {
          scope: inProgress.registeredJudgesLoading
        })
        break

      case 'submitConnectPublisherSucceeded':
        alert('Publisher connected')
        break
      case 'submitConnectPublisherFailed': {
        const { error } = action.paylaod
        alert(`Publisher cannot to be connected:\n${error.message}`)
        break
      }

      case 'submitDisconnectPublisherSucceeded':
        alert('Publisher disconnected')
        break
      case 'submitDisconnectPublisherFailed': {
        const { error } = action.paylaod
        alert(`Publisher cannot to be disconnected:\n${error.message}`)
        break
      }
    }
  })
}

const formatCoachCardImportErrors = (errors) => Object
  .keys(errors)
  .filter(file => !isNil(errors[file]))
  .map(file => `${file}: ${errors[file]}`)
