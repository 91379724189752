import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"40vw"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-dots-horizontal")])]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Change Judged Degree of Difficulty")]),_c(VCardText,[_c(VRadioGroup,{model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}},[_c(VRadio,{attrs:{"label":"✓ Confirmed","color":"success","value":"Confirmed"}}),_c(VRadio,{attrs:{"label":"🗙 Base Mark","color":"error","value":"BaseMark"}}),_c(VRadio,{attrs:{"label":"Custom","value":"custom"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c(VTextField,{attrs:{"disabled":_vm.selection !== 'custom',"label":"Custom","placeholder":"Enter custom DD","min":"0","step":"0.0001","type":"number","rules":[_vm.rules.nonNegative]},model:{value:(_vm.customDD),callback:function ($$v) {_vm.customDD=$$v},expression:"customDD"}})]},proxy:true}])})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{on:{"click":_vm.close}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary","disabled":_vm.okDisabled || _vm.inProgress,"loading":_vm.inProgress},on:{"click":_vm.onJudgedDegreeOfDifficultyChanged}},[_vm._v("OK")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }