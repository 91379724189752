import { httpget, httppost, joinUri } from '../base-api'

const uri = 'pscb/scenes'

export default {
  allOff: async () => await httppost(joinUri(uri, 'off')),
  list: async () => await httpget(uri),
  display: async ({ id, channel, publish, tournamentNodeId, additionalData }) =>
    await httppost(joinUri(uri, id), { channel, publish, tournamentNodeId, additionalData })
}
