<template>
  <button
    :class="classes"
    :disabled="disabled"
    :style="customCssVars"
    @click="$emit('toggled')">
    <slot/>
  </button>
</template>

<script>

export default {
  name: 'BiStablePushButton',
  emits: ['toggled'],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    kind: {
      type: String,
      required: true
    }
  },
  computed: {
    classes () {
      return {
        [`button-${this.kind}`]: true,
        [`button-${this.kind}--pressed`]: this.value,
        [`button-${this.kind}--disabled`]: this.disabled
      }
    },
    customCssVars () {
      return {
        '--c1-red': '#f00',
        '--c2-red': '#ff0',
        '--c1-orange': '#ffa500',
        '--c2-orange': '#000',
        '--c1-green': '#0c0',
        '--c2-green': '#fff'
      }
    }
  }
}

</script>

<style lang='scss' scoped>
@import '~@/styles/_container.scss';
@import '~@/styles/_button.scss';
@import '~@/styles/_variables.scss';

@mixin confirm-button($color) {
  $c1: --c1-#{$color};
  $c2: --c2-#{$color};
  .button-#{$color} {
    @include container('center', 'center');
    @include button(false, $small);

    border: solid 1px var($c1);
    background-color: $white;
    color: $black;
    transition: background-color 0.25s;

    &--pressed {
      border: solid 1px $black;
      background-color: var($c1);
      color: var($c2);
    }
  }
}

@include confirm-button('red');
@include confirm-button('orange');
@include confirm-button('green');
</style>
