import { find, findIndex, forOwn, isNil, identity, map, merge, pick, zipWith, take } from 'lodash'

export const getters = {
  currentUnitParticipants: 'currentUnitParticipants',
  allParticipants: 'allParticipants',
  hasParticipants: 'hasParticipants',
  selectedParticipant: 'selectedParticipant',
  performingParticipant: 'performingParticipant',
  unitId: 'unitId',
  selectedParticipantScores: 'selectedParticipantScores',
  selectedParticipantScoresInApiFormat: 'selectedParticipantScoresInApiFormat',
  hasAllScores: 'hasAllScores',
  hasTotalsCalculated: 'hasTotalsCalculated',
  selectedParticipantModifiedScores: 'selectedParticipantModifiedScores',
  selectedScore: 'selectedScore',
  isSelectedScoreTouched: 'isSelectedScoreTouched',
  scoresSent: 'scoresSent',
  isEditMode: 'isEditMode',
  artisticImpressionFactors: 'artisticImpressionFactors',
  elementFactors: 'elementFactors',
  missingJudgeInProgress: 'missingJudgeInProgress'
}

export const statuses = {
  performing: 'performing',
  active: 'active',
  assessed: 'assessed'
}

const alignToSeatsCount = (
  scores,
  {
    seatsCount = 0,
    allocatedSeatsCount = 0,
    unallocatedAlignedSeatsDefaultValue,
    alignedSeatsAfterMapper = identity
  } = {}
) =>
  merge(
    [
      ...new Array(allocatedSeatsCount),
      ...new Array(seatsCount - allocatedSeatsCount)
        .fill(0)
        .map(unallocatedAlignedSeatsDefaultValue)
    ].map(alignedSeatsAfterMapper),
    scores
  )

const mapToScoreValues = source => source.map(item => ({
  ...pick(item, ['seat', 'value', 'isMissing']),
  value: +item?.value
}))

const toPart = panelConfig => ({ isTransition, scores = [] } = []) => ({
  isTransition,
  scores: alignToSeatsCount(
    mapToScoreValues(scores),
    {
      ...panelConfig,
      unallocatedAlignedSeatsDefaultValue: () => ({ isMissing: true }),
      alignedSeatsAfterMapper: (item, index) => ({ seat: index + 1, ...item })
    }
  )
})

const toPanel = ({ id, penalty, parts = [] } = {}, panelConfig) => ({
  id,
  penalty,
  parts: parts.filter(p => !p.isTransition).map(toPart(panelConfig))
})

const toScoresPanel = (
  {
    total,
    totalBeforePenalties,
    synchronizationErrors = 0,
    penalty = 0,
    parts
  } = {},
  config,
  partMapper
) => ({
  ...config,
  total,
  totalBeforePenalties,
  synchronizationErrors,
  penalty,
  parts:
    config?.parts?.map((configPart, index) => {
      const scoresPart = parts?.[index]

      return partMapper(configPart, scoresPart)
    }) ?? []
})

export const gettersDefinition = {
  [getters.currentUnitParticipants]: ({ currentUnitParticipants = [] } = {}) =>
    currentUnitParticipants,
  [getters.allParticipants]: ({ participants = [] } = {}) => participants,
  [getters.hasParticipants]: ({ unitId } = {}) => !isNil(unitId),
  [getters.selectedParticipant]: ({ participant, participants } = {}) => {
    const { status } = participant || {}
    if (status === undefined) {
      return null
    }

    return {
      ...participant,
      couldPerform: status === statuses.active,
      number: findIndex(participants, { id: participant.id }) + 1
    }
  },
  [getters.performingParticipant]: ({ participants = [] } = {}) =>
    find(participants, ['status', statuses.performing]) || null,
  [getters.unitId]: ({ unitId } = {}) => unitId,
  [getters.selectedParticipantScores]: (state = {}) => {
    const { unitScoresStructure: { panels = {} } = {} } = state
    const {
      artisticImpression: artisticImpressionConfig = {},
      elements: elementsConfig = {}
    } = panels
    const {
      participantScores: { totalScore, penalty = 0, panelsScores = {}, rank } = {}
    } = state

    const {
      artisticImpression: artisticImpressionScores,
      elements: elementsScores
    } = panelsScores

    const scoresMapper = (scores, { seatsCount, allocatedSeatsCount } = {}) =>
      alignToSeatsCount(
        take(scores || [], seatsCount),
        {
          seatsCount,
          allocatedSeatsCount,
          unallocatedAlignedSeatsDefaultValue: () => ({ isMissing: true })
        })
    return {
      totalScore,
      penalty,
      panels: {
        artisticImpression: toScoresPanel(
          artisticImpressionScores,
          artisticImpressionConfig,
          ({ name, factor } = {}, { total, scores } = {}) => ({
            name,
            factor,
            total,
            scores: scoresMapper(scores, artisticImpressionConfig)
          })
        ),
        elements: toScoresPanel(
          elementsScores,
          elementsConfig,
          (
            { name, isTransition, synchronizationErrors, order } = {},
            { total, scores } = {}
          ) => ({
            name,
            total,
            isTransition,
            synchronizationErrors,
            order,
            scores: scoresMapper(scores, elementsConfig)
          })
        )
      },
      rank
    }
  },
  [getters.hasAllScores]: (store, getters) => {
    const { panels = {} } = getters.selectedParticipantScores

    let result = true

    forOwn(panels, panel => {
      for (const part of panel.parts.filter(p => !p.isTransition)) {
        for (const score of part.scores) {
          if (!score) {
            result = false
            return false // to exit from `forOwn`
          }
        }
      }
    })

    return result
  },
  [getters.hasTotalsCalculated]: (store, getters) => {
    return getters.selectedParticipantScores.totalScore
  },
  [getters.scoresSent]: ({ scoresSent } = {}) => {
    return scoresSent
  },
  [getters.isEditMode]: ({ isEditMode } = {}) => {
    return isEditMode
  },
  [getters.selectedParticipantModifiedScores]: ({
    selectedParticipantModifiedScores
  } = {}) => {
    return selectedParticipantModifiedScores
  },
  [getters.selectedScore]: ({ selectedScore } = {}) => {
    return selectedScore
  },
  [getters.isSelectedScoreTouched]: ({ isSelectedScoreTouched } = {}) => {
    return isSelectedScoreTouched
  },
  [getters.selectedParticipantScoresInApiFormat]: ({
    participant,
    participantScores,
    unitScoresStructure: { panels } = {}
  } = {}) => {
    if (isNil(participant) || isNil(participantScores) || isNil(panels)) {
      return null
    }

    const {
      artisticImpression: artisticImpressionConfig = {},
      elements: elementsConfig = {}
    } = panels
    const { panelsScores = {}, penalty } = participantScores
    const elementsPanel = toPanel(panelsScores?.elements, elementsConfig)
    const aiPanel = toPanel(
      panelsScores?.artisticImpression,
      artisticImpressionConfig
    )
    return {
      otherPenalties: penalty,
      panels: {
        elements: elementsPanel,
        artisticImpression: aiPanel
      }
    }
  },
  [getters.artisticImpressionFactors]: ({ unitScoresStructure = {} } = {}) => {
    return map(
      unitScoresStructure?.panels?.artisticImpression?.parts,
      'factor'
    )
  },
  [getters.elementFactors]: ({
    participantScores,
    unitScoresStructure
  } = {}) => {
    return zipWith(
      unitScoresStructure?.panels?.elements?.parts ?? [],
      participantScores?.panelsScores?.elements?.parts,
      (
        { declaredDegreeOfDifficulty, factor } = {},
        {
          judgedDegreeOfDifficulty,
          judgedDegreeOfDifficultyChoice,
          judgedDegreeOfDifficultyAssistantsFeedback,
          degreeOfDifficultyModifiedByChiefRecorder
        } = {}
      ) => ({
        declaredDegreeOfDifficulty,
        judgedDegreeOfDifficulty,
        mark: judgedDegreeOfDifficultyChoice || 'confirmed',
        assistantMarks: judgedDegreeOfDifficultyAssistantsFeedback || [],
        degreeOfDifficultyModifiedByChiefRecorder:
          degreeOfDifficultyModifiedByChiefRecorder || false,
        factor
      })
    )
  },
  [getters.missingJudgeInProgress]: ({ missingJudgeInProgress }) => missingJudgeInProgress
}
