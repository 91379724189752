<template>
  <article v-if="authorized">
    <GoToCurrentParticipantButton/>
    <Menu :authState="authState" skipRole="judge"/>
    <panel-info :unitName="unitName" :panel="panelInfo"/>
    <section class="judge-results">
      <participant-info
        :participant="participant"
        class="judge-result__info"/>
      <section>
        <ScoresTable v-for="panel in scoresSummary.panels"
          :key="panel.id"
          :scores="panel"
          no-totals />
      </section>
      <aside>
        <LabelledValue
          :digits="2"
          :value="scoresSummary.totalScore"
          label="Total Score"/>
        <LabelledValue
          :digits="1"
          :value="scoresSummary.penalty"
          label="Penalty"/>
        <LabelledValue
          :value="scoresSummary.rank"
          label="Rank"/>
      </aside>
    </section>
  </article>
</template>

<script>
import LabelledValue from '@/components/judge/LabelledValue'
import Menu from '@/components/Menu.vue'
import PanelInfo from '@/components/PanelInfo'
import ParticipantInfo from '@/components/ParticipantInfo'
import ScoresTable from '@/components/ScoresTable'
import GoToCurrentParticipantButton from '@/components/GoToCurrentParticipantButton'
import { judge, routine } from '@/store/modules'
import { mapActions, mapGetters } from 'vuex'
import { authorize, setupAuthorization } from '@/services/auth-service'
import { isNil } from 'lodash'

export default {
  name: 'JudgeResults',
  components: {
    LabelledValue,
    Menu,
    ParticipantInfo,
    PanelInfo,
    ScoresTable,
    GoToCurrentParticipantButton
  },
  data () {
    return setupAuthorization({ component: this, requiredRoles: ['judge'] })
  },
  async created () {
    if (authorize({
      component: this,
      additionalCheck: () => !isNil(this.panelBasedIdentifier.id)
    })) {
      // todo: uncomment when we fix the bug https://dev.azure.com/SWTIDEVEXTERNAL/SWA-OLR/_workitems/edit/1764
      // fetchAllScores will return data only when there is participant currently performing,
      // which should not happen when JudgeResults is being displayed
      // await this.fetchAllScores()
    }
  },
  methods: {
    ...mapActions(
      judge.namespace,
      [
        judge.actions.fetchAllScores
      ]
    )
  },
  computed: {
    ...mapGetters(
      judge.namespace,
      [
        judge.getters.panelBasedIdentifier,
        judge.getters.panelInfo,
        judge.getters.unitName,
        judge.getters.scoresSummary
      ]
    ),
    ...mapGetters(
      routine.namespace,
      [
        routine.getters.participant
      ]
    )
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/typology';
@import '~@/styles/container';

.judge-results {
  display: grid;
  height: 80vh;
  max-width: $max-view-width;
  margin: 0 auto;
  padding: 2rem;

  grid-template-columns: [scores] 3fr [aside] 1fr [end];
  grid-template-rows: [header] 1fr [scores] 4fr [end];

  &__info {
    grid-column: scores / end;
    grid-row: header / scores;
  }

  & > section {
    grid-column: scores / aside;
    grid-row: scores / end;
  }

  & > aside {
    @include vcontainer('center', 'top');

    grid-column: aside / end;
    grid-row: scores / end;

    padding: 2rem;
  }
}
</style>
