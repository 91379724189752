<template>
  <header class="title">
    <span>{{unitName}}</span>
    <span>{{panelName}} {{panelSeat}}</span>
  </header>
</template>

<script>
export default {
  name: 'PanelInfo',
  props: {
    unitName: {
      typeof: String,
      default: ''
    },
    panel: {
      type: Object,
      default: () => ({})
    },
    hideSeat: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    panelName () { return this.panel?.name || '' },
    panelSeat () { return !this.hideSeat && this.panel?.seat ? this.panel.seat : '' }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/typology';
@import '~@/styles/container';

.title {
  @include primary-caption-l3;
  @include container('justified');
  margin: 0 5rem;
}
</style>
