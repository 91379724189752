<template>
  <header class="title">
    {{title}}
  </header>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: {
      type: String,
      default: () => ('Panel')
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/typology';

.title {
  @include primary-caption-l3;
}
</style>
