import TeamsManagement from '@/views/TeamsManagement'

const routes = [{
  path: '/teams-management',
  name: 'teams-management',
  component: TeamsManagement,
  async beforeEnter (to, from, next) {
    next()
  }
}]

export default {
  routes
}
