<template>
  <div v-if="authorized">
    <GoToCurrentParticipantButton/>
    <Menu :authState="authState" skipRole="referee" />
    <p class='wait-screen'>Waiting for Competition</p>
  </div>
</template>

<script>
import Menu from '@/components/Menu'
import GoToCurrentParticipantButton from '@/components/GoToCurrentParticipantButton'
import { authorize, setupAuthorization } from '@/services/auth-service'

export default {
  name: 'RefereeWaiting',
  components: {
    Menu,
    GoToCurrentParticipantButton
  },
  data () {
    return setupAuthorization({ component: this, requiredRoles: ['referee', 'chief-recorder'] })
  },
  created () {
    if (authorize({ component: this })) {
      // placeholder for init logic
    }
  }
}
</script>

<style scoped lang="scss">
  @import '~@/styles/typology';

  .wait-screen {
    @include secondary-caption-l1;

    font-weight: normal;
  }
</style>
