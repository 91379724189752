import { mutations } from './mutations'

export const subscribe = ({ store, commitLocal } = {}) => {
  return store.subscribeAction(action => {
    switch (action.type) {
      case 'submittingSaveSettings':
        commitLocal(mutations.isSubmitSaveSettingsPending, { pending: true })
        break

      case 'submitSaveSettingsSucceeded':
        commitLocal(mutations.isSubmitSaveSettingsPending, { pending: false })
        alert('Settings saved successfully')
        break
      case 'submitSaveSettingsFailed':
        commitLocal(mutations.isSubmitSaveSettingsPending, { pending: false })
        alert('Saving settings failed')
        break
    }
  })
}
