import { actions } from './actions'
import { mutations } from './mutations'

export const subscribe = ({ store, dispatchLocal, commitLocal } = {}) => {
  return store.subscribeAction(action => {
    switch (action.type) {
      case 'submittingAcceptCoachCard': {
        const { coachCardId } = action.payload
        commitLocal(mutations.setIsInProgress, { element: `accept-coach-card${coachCardId}`, pending: true })
        break
      }
      case 'submittingRejectCoachCard': {
        const { coachCardId } = action.payload
        commitLocal(mutations.setIsInProgress, { element: `reject-coach-card${coachCardId}`, pending: true })
        break
      }
      case 'submitAcceptCoachCardSucceeded': {
        const { coachCardId } = action.payload
        commitLocal(mutations.setIsInProgress, { element: `accept-coach-card${coachCardId}`, pending: false })
        dispatchLocal(actions.fetchCompetitionCoachCards)
        break
      }
      case 'submitRejectCoachCardSucceeded': {
        const { coachCardId } = action.payload
        commitLocal(mutations.setIsInProgress, { element: `reject-coach-card${coachCardId}`, pending: false })
        dispatchLocal(actions.fetchCompetitionCoachCards)
        break
      }
      case 'submitAcceptCoachCardFailed': {
        const { coachCardId } = action.payload
        commitLocal(mutations.setIsInProgress, { element: `accept-coach-card${coachCardId}`, pending: false })
        break
      }
      case 'submitRejectCoachCardFailed': {
        const { coachCardId } = action.payload
        commitLocal(mutations.setIsInProgress, { element: `reject-coach-card${coachCardId}`, pending: false })
        break
      }

      case 'fetchingCompetitionCoachCards': {
        commitLocal(mutations.setIsInProgress, { element: `fetchingCompetitionCoachCards`, pending: true })
        break
      }
      case 'fetchCompetitionCoachCardsSucceeded': {
        commitLocal(mutations.setIsInProgress, { element: `fetchingCompetitionCoachCards`, pending: false })
        break
      }
      case 'fetchCompetitionCoachCardsFailed': {
        commitLocal(mutations.setIsInProgress, { element: `fetchingCompetitionCoachCards`, pending: false })
        break
      }
    }
  })
}
