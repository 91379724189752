import { isNil } from 'lodash'
import { mutations } from './names'

const withPaddedScores = ({ id, name, seatsCount, parts }) =>
  ({
    id,
    name,
    seatsCount,
    parts: parts.map(({ scores = [], ...part }) => ({
      ...part,
      seatsCount,
      scores: scores.reduce(
        (acc, { seat, ...score }) => {
          acc[seat - 1] = { seat, ...score }
          return acc
        },
        Array(seatsCount))
    }))
  })

export const summaryDefaultState = Object.freeze({
  summary: {
    totalScore: null,
    penalty: null,
    rank: null,
    judgesCount: null,
    panels: []
  }
})

export const summaryMutations = Object.freeze({
  [mutations.updateScoresSummary] (
    store,
    { panels = {}, totalScore, rank, otherPenalties } = {}
  ) {
    const panelsWithPaddedScores = [panels?.elements, panels?.artisticImpression]
      .filter(p => !isNil(p))
      .map(withPaddedScores)

    store.summary = {
      totalScore,
      penalty: otherPenalties,
      rank,
      panels: panelsWithPaddedScores
    }
  }
})
