import { httpdelete, httpget, httppost, newUrl } from '../base-api'
import { toUri } from './base'

const toHandsUri = ({ unitId, participantId }) =>
  toUri({ unitId, participantId, uri: 'hands' })

export default {
  raise: async ({ unitId, participantId, panel }) =>
    await httppost(toHandsUri({ unitId, participantId }), panel),
  lower: async ({ unitId, participantId, panel }) =>
    await httpdelete(toHandsUri({ unitId, participantId }), panel),
  list: async ({ unitId, participantId }) =>
    await httpget(
      toHandsUri({ unitId, participantId }),
      {
        allow: [404]
      }),
  get: async ({ unitId, participantId, panel }) =>
    await httpget(newUrl(toHandsUri({ unitId, participantId }), panel))
}
