import { cond, flatMap, flatten, fromPairs, map, stubTrue, times, toString, zip } from 'lodash'
import { getters } from '../getters'
import { mutations } from '../mutations'
import { fromPaddedIndex, isKeypadValueComplete, isKeypadValueValid } from '../shared'
import actions from './names'

const fractions = ['.00', '.25', '.50', '.75']
const wholeNumbers = times(11, toString)
const makeNewValue = (currentValue, keypadMode, newKey) => {
  if (
    (keypadMode === 'fraction' && !fractions.includes(newKey)) ||
    (keypadMode === 'whole' && !wholeNumbers.includes(newKey))
  ) {
    return currentValue
  }

  return `${currentValue}${newKey}`
}

const storeKeyAndUpdateScore = async ({
  store: { commit, dispatch, getters: localGetters },
  newValue,
  keyPressed
}) => {
  commit(mutations.addKeystroke, keyPressed)
  commit(mutations.storeKeypadValue, {
    newValue
  })
  await dispatch(actions.updateScore, {
    scoreIndex: localGetters[getters.selectedScore]?.index,
    scoreValue: newValue
  })
}

const newCompleteCorrectValue = ({ sequence, score, partialScore }) => [
  sequence,
  {
    isComplete: true,
    score,
    partialScore
  }
]
const newPartialCorrectValue = ({ sequence, score, assumedTargetScore }) => [
  sequence,
  {
    isComplete: false,
    score,
    assumedTargetScore
  }
]
const simplifiedFractions = zip([0, 2, 5, 7], fractions)
const correctCompleteValues = [
  ...simplifiedFractions.map(([simplifiedFraction, fraction]) =>
    newCompleteCorrectValue({
      sequence: `1.${simplifiedFraction}`,
      score: `1${fraction}`,
      partialScore: `1`
    })
  ),
  ...flatten(
    times(10, partialScore =>
      simplifiedFractions.map(([simplifiedFraction, fraction]) =>
        newCompleteCorrectValue({
          sequence: `${partialScore}${simplifiedFraction}`,
          score: `${partialScore}${fraction}`,
          partialScore: `${partialScore}`
        })
      )
    )),
  newPartialCorrectValue({
    sequence: '10',
    score: '10',
    assumedTargetScore: '10'
  }),
  newCompleteCorrectValue({
    sequence: '1.0',
    score: '1.00',
    partialScore: '1'
  }),
  newCompleteCorrectValue({
    sequence: '1.0',
    score: '1.00',
    partialScore: '1'
  })
]
const correctPartialValues = flatMap(
  correctCompleteValues,
  ([value, { partialScore }]) =>
    map(value, (_, index) =>
      newPartialCorrectValue({
        sequence: value.substring(0, index + 1),
        score: partialScore,
        assumedTargetScore: `${partialScore}.00`
      })
    )
)
const correctValuesSequences = fromPairs([
  ...correctPartialValues,
  ...correctCompleteValues
])

export const SpecialKeys = Object.freeze({
  backspace: Symbol('<'),
  enter: Symbol('E'),
  comma: Symbol('*'),
  prev: Symbol('↑'),
  next: Symbol('↓')
})

const handleOtherKeys = async ({
  store,
  keystrokes,
  value
}) => {
  const newSequence = `${keystrokes}${value}`
  const newSequenceInfo = correctValuesSequences[newSequence]
  if (newSequenceInfo !== undefined) {
    await storeKeyAndUpdateScore({
      store,
      newValue: newSequenceInfo.score,
      keyPressed: value
    })
  }
}

const handleKeypadKeyPressed = async (store, { value, goToNext }) => {
  const { dispatch, getters: localGetters } = store
  const min = localGetters[getters.lowestScoreAllowed]
  const max = localGetters[getters.highestScoreAllowed]

  const newValue = makeNewValue(
    localGetters[getters.keypadState],
    localGetters[getters.keypadMode],
    value
  )
  if (isKeypadValueValid(newValue, { min, max })) {
    const coercedNewValue = newValue === `${max}.00`
      ? `${max}`
      : newValue
    await storeKeyAndUpdateScore({
      store,
      newValue: coercedNewValue,
      keyPressed: value
    })

    if (isKeypadValueComplete(coercedNewValue, { max }) && goToNext) {
      await dispatch(actions.commitScore, value === '10')
    }
  }
}

const handleEnter = async ({ dispatch, getters: localGetters }, keystrokes) => {
  const { assumedTargetScore } = correctValuesSequences[keystrokes] || {}
  if (assumedTargetScore !== undefined) {
    await dispatch(actions.updateScore, {
      scoreIndex: localGetters[getters.selectedScore]?.index,
      scoreValue: assumedTargetScore
    })
  }
  await dispatch(actions.commitScore)
}

const handleBackspace = async ({ commit, dispatch, getters: localGetters }, state) => {
  commit(mutations.removeTailKeystroke)
  const { score, assumedTargetScore } = correctValuesSequences[state.keypad.keystrokes] ||
    {}
  const { index: scoreIndex } = localGetters[getters.selectedScore] || {}
  const paddedIndex = fromPaddedIndex(state, scoreIndex)

  if (score !== undefined) {
    await dispatch(actions.updateScore, {
      scoreIndex,
      scoreValue: score
    })
  } else {
    commit(mutations.clearAndSelectScore, paddedIndex)
    commit(mutations.clearKeypadValue)
    await dispatch(actions.updateScore, {
      scoreIndex,
      scoreValue: assumedTargetScore
    })
  }
}

const eq = ref => value => ref === value

export default {
  [actions.keypadKeyPressed]: handleKeypadKeyPressed,
  [actions.eraseSelectedScoreOrLast10] ({ commit }) {
    commit(mutations.eraseCurrentScoreOrLast10)
  },
  [actions.keyboardKeyPressed] ({ state, ...store }, value) {
    const {
      keypad: { keystrokes }
    } = state
    cond([
      [eq(SpecialKeys.next), () => store.commit(mutations.selectNextScore)],
      [eq(SpecialKeys.prev), () => store.commit(mutations.selectPreviousScore)],
      [eq(SpecialKeys.backspace), () => handleBackspace(store, state)],
      [eq(SpecialKeys.enter), () => handleEnter(store, keystrokes)],
      [
        eq(SpecialKeys.comma),
        () =>
          handleOtherKeys({
            store,
            keystrokes,
            value: '.'
          })
      ],
      [
        stubTrue,
        () =>
          handleOtherKeys({
            store,
            keystrokes,
            value
          })
      ]
    ])(value)
  }
}
