import { map } from 'lodash'
import { httpget, httppost } from '../base-api'
import { validateParticipant } from '../validations'
import { toJudgesBaseUri, toParticipantsBaseUri, toUri } from './base'

export default {
  list: async ({ unitId }, { signal } = {}) =>
    map(await httpget(toParticipantsBaseUri({ unitId }), { signal }), validateParticipant),
  get: async ({ unitId, participantId }) =>
    validateParticipant(await httpget(toUri({ unitId, participantId }))),
  present: async ({ unitId, participantId }) =>
    await httppost(toUri({ unitId, participantId, uri: 'present' })),
  changeIrm: async ({ unitId, participantId, irm }) =>
    await httppost(toUri({ unitId, participantId, uri: 'irm' }), {
      irm
    }),
  judgesAllocation: async ({ unitId }, { signal } = {}) =>
    map(await httpget(toJudgesBaseUri({ unitId }), { signal }))
}
