<template>
  <olr-editor v-if='selectedUnit'
    :unitName='selectedUnit && selectedUnit.name'
    @addToList='allocateJudge'
    @filterChanged='filterChanged'
    @removeFromList='deallocateJudge'>
    <template #registred-list>
      <list
        :loading="isRegisteredJudgesLoadingInProgress"
        :listData='eventRegisteredJudgesFiltered'
        @itemActionCalled="onDoubleSelectedRegisteredJudge"
        @itemSelected="onSelectedRegisteredJudge">
        <template v-for='(judge, index) in eventRegisteredJudgesFiltered'
          :slot='index'>
            <span :key='"noc" + judge.id' class='member__noc'>
              {{ judge.noc }}
            </span>
          <span :key='"name" + judge.id' class='member__name'>
              {{ judge.name }}
            </span>
        </template>
      </list>
    </template>
    <template #allocated-list>
      <list
        :loading="isAllocatedJudgesLoadingInProgress"
        :listData='judgesConfigurationDisplayable'
        @itemActionCalled="onDoubleSelectedAllocatedJudge"
        @itemSelected="onSelectedAllocatedJudge">
        <template v-for='(item, index) in judgesConfigurationDisplayable'
          :slot='index'>
          <span v-if='item && item.name'
            :key='item.name'
            class='panel-name'>
            {{ item.name }}
          </span>
          <span v-if='item && item.index'
            :key='index'
            class='judge-seat'>
            Seat {{ item.index }}: {{ item.judge && item.judge.name }}
          </span>
        </template>
      </list>
    </template>
    <template #buttons>
      <v-btn
        :disabled='!validJudgeAllocation'
        :loading="isSaveJudgeAllocationInProgress"
        color="primary"
        @click="saveAllocationClicked">
        Save Allocation
      </v-btn>
    </template>
  </olr-editor>
</template>

<script>
import List from '@/components/List.vue'
import {
  allocatedSeatsCount, getPanelAndSeatIndexToAllocateJudge,
  isJudgeAllocationFragmented
} from '@/components/olr-operator/helpers'
import OlrEditor from '@/components/olr-operator/OlrEditor.vue'
import { olr, unit } from '@/store/modules'
import { constant, isEmpty, isNil, join, orderBy, times } from 'lodash'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'JudgesAllocationEditor',
  data: () => ({
    allocatedJudges: [],
    eventRegisteredJudges: [],
    selectedAllocatedJudge: null,
    selectedRegisteredJudge: null,
    selectedAllocatedJudgeIndex: null,
    selectedRegisteredJudgeIndex: null,
    filter: ''
  }),
  components: {
    List,
    OlrEditor
  },
  computed: {
    ...mapGetters(
      olr.namespace,
      [
        olr.getters.selectedUnit,
        olr.getters.selectedRegisteredJudges,
        olr.getters.configuration,
        olr.getters.isSaveJudgeAllocationInProgress,
        olr.getters.isRegisteredJudgesLoadingInProgress,
        olr.getters.isAllocatedJudgesLoadingInProgress,
        olr.getters.selectedAllocatedJudges
      ]
    ),
    eventRegisteredJudgesFiltered () {
      const judgesNotAssigned = orderBy(
        this.eventRegisteredJudges
          .filter(judge => !this.judgesConfigurationDisplayable.some(j =>
            j.judge && j.judge.id === judge.id)),
        j => j.name.toLowerCase())

      if (this.filter) {
        return judgesNotAssigned
          .filter(judge => judge.name.toLowerCase()
            .includes(this.filter.toLowerCase()))
      }

      return judgesNotAssigned
    },
    judgesConfigurationDisplayable () {
      return this.allocatedJudges.reduce((list, item) => {
        list.push({ name: item.name, readOnly: true })
        if (item.judges) {
          item.judges.forEach((judge, index) => list.push({ index: index + 1, judge }))
        }

        return list
      }, [])
    },
    validJudgeAllocation () {
      return this.allocatedJudges.every(({ judges, requiredJudges }) =>
        allocatedSeatsCount(judges) >= requiredJudges &&
        !isJudgeAllocationFragmented(judges)
      )
    }
  },
  watch: {
    selectedUnit: {
      handler: async function () {
        if (!isEmpty(this.selectedUnit)) {
          await Promise.allSettled([
            this.fetchConfiguration({
              unitId: this.selectedUnit.id
            }),
            this.fetchRegisteredJudges({
              eventId: this.selectedUnit.eventId
            }),
            this.fetchAllocatedJudges({
              unitId: this.selectedUnit.id
            })
          ])
        }
      },
      immediate: true
    },
    configuration: {
      handler: function (newVal) {
        if (newVal && newVal.list) {
          this.allocatedJudges = newVal.list.map(({ id, name, seatsCount, requiredJudges }) => ({
            name,
            id,
            judges: times(seatsCount, constant(null)),
            requiredJudges: isNil(requiredJudges) ? seatsCount : requiredJudges
          }))
        }
      },
      immediate: true
    },
    selectedRegisteredJudges: {
      handler: function (newVal) {
        this.eventRegisteredJudges = newVal
          .filter(j => !this.selectedAllocatedJudges.some(a => a.id === j.id))
      },
      immediate: true
    },
    selectedAllocatedJudges: {
      handler: function (allocatedJudges) {
        if (allocatedJudges) {
          const newAllocatedJudges = [...this.allocatedJudges]
          for (const judge of allocatedJudges) {
            const panel = newAllocatedJudges.filter(a => a.id === judge.panel.id)[0]

            if (panel) {
              panel.judges[judge.panel.seat - 1] = judge
            }
          }
          this.allocatedJudges = newAllocatedJudges
          this.eventRegisteredJudges = this.eventRegisteredJudges
            .filter(j => !this.selectedAllocatedJudges.some(a => a.id === j.id))
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions(
      olr.namespace,
      [
        olr.actions.fetchRegisteredJudges,
        olr.actions.fetchConfiguration,
        olr.actions.fetchAllocatedJudges
      ]
    ),
    ...mapActions(
      unit.namespace,
      [
        unit.actions.saveJudgeList
      ]
    ),
    displayMember (members) {
      return join(members, ' / ')
    },
    async saveAllocationClicked () {
      await this.saveJudgeList({
        unitId: this.selectedUnit.id,
        panels: this.allocatedJudges.reduce((panels, panel) => {
          panels.push({
            id: panel.id,
            judgesIds: panel.judges.map(a => a?.id)
          })
          return panels
        }, [])
      })
    },
    allocateJudge () {
      if (!isNil(this.selectedRegisteredJudge) && !isNil(this.selectedAllocatedJudge)) {
        const allocatedJudges = [...this.allocatedJudges]
        const { id } = this.selectedRegisteredJudge
        this.eventRegisteredJudges = this.eventRegisteredJudges
          .filter(x => x.id !== id)
        const { panel, seatIndex } = getPanelAndSeatIndexToAllocateJudge(allocatedJudges,
          this.selectedAllocatedJudgeIndex)
        if (panel.judges[seatIndex]) {
          this.eventRegisteredJudges.push(panel.judges[seatIndex])
        }

        panel.judges[seatIndex] = this.selectedRegisteredJudge
        panel.judges[seatIndex].panel = {
          id: panel.id,
          seat: seatIndex + 1
        }

        this.allocatedJudges = allocatedJudges
        this.selectedRegisteredJudge = null
        this.selectedAllocatedJudge = null
        this.selectedRegisteredJudgeIndex = null
        this.selectedAllocatedJudgeIndex = null
      }
    },
    deallocateJudge () {
      if (!isNil(this.selectedAllocatedJudge)) {
        const allocatedJudges = [...this.allocatedJudges]
        const { panel: allocation, ...judge } = this.selectedAllocatedJudge.judge
        const panel = allocatedJudges.find(x => x.id === allocation.id)
        if (panel) {
          this.eventRegisteredJudges.push(judge)
          panel.judges[allocation.seat - 1] = null
          this.allocatedJudges = allocatedJudges
          this.selectedAllocatedJudge = null
          this.selectedAllocatedJudgeIndex = null
        }
      }
    },
    onSelectedRegisteredJudge (selection) {
      this.selectedRegisteredJudge = selection.item
      this.selectedRegisteredJudgeIndex = selection.index
    },
    onSelectedAllocatedJudge (selection) {
      this.selectedAllocatedJudge = selection.item
      this.selectedAllocatedJudgeIndex = selection.index
    },
    onDoubleSelectedRegisteredJudge (selection) {
      let allocationIndex = 1
      for (const panel of this.allocatedJudges) {
        for (const judge of panel.judges) {
          if (!judge) {
            this.selectedAllocatedJudgeIndex = allocationIndex
            this.selectedAllocatedJudge = selection.item
            return this.allocateJudge()
          }
          allocationIndex++
        }
        allocationIndex++
      }
    },
    onDoubleSelectedAllocatedJudge () {
      this.deallocateJudge()
    },
    filterChanged (value) {
      this.filter = value
    }
  }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/typology";

.panel-name {
  cursor: initial;
}

.judge-seat {
  padding-left: 1rem;
}

.member {
  &__name {
    @include value-l5;
  }

  &__noc {
    @include value-l6;
  }
}
</style>
