<template>
  <article>
    <h1>On-line Result System</h1>
    <p>Please, log in in order to use the system.</p>
    <button v-ripple @click="$emit('loginButtonClicked')">Log in</button>
  </article>
</template>

<script>
export default {
  name: 'Login',
  emits: ['loginButtonClicked']
}
</script>

<style lang='scss' scoped>
@import '~@/styles/button';

button {
  @include button();
}
</style>
