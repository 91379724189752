<template>
  <div v-if="authorized">
    <go-to-current-participant-button/>
    <Menu :authState="authState" skipRole="judge"/>
    <panel-info :unitName="unitName" :panel="panelInfo"/>
    <participant-info :participant="participant"/>
    <judge-sync-pane/>
  </div>
</template>

<script>
import Menu from '@/components/Menu'
import PanelInfo from '@/components/PanelInfo'
import ParticipantInfo from '@/components/ParticipantInfo'
import JudgeSyncPane from '@/components/judge/JudgeSyncPane'
import GoToCurrentParticipantButton from '@/components/GoToCurrentParticipantButton'
import { judge, routine, unit } from '@/store/modules'
import { mapGetters } from 'vuex'
import { authorize, setupAuthorization } from '@/services/auth-service'
import { isNil } from 'lodash'

export default {
  name: 'JudgeSync',
  components: {
    Menu,
    PanelInfo,
    ParticipantInfo,
    JudgeSyncPane,
    GoToCurrentParticipantButton
  },
  data () {
    return setupAuthorization({ component: this, requiredRoles: ['judge'] })
  },
  created () {
    if (authorize({
      component: this,
      additionalCheck: () => !isNil(this.panelBasedIdentifier.id)
    })) {
      // placeholder for init logic
    }
  },
  computed: {
    ...mapGetters(
      routine.namespace,
      [
        routine.getters.participant
      ]
    ),
    ...mapGetters(
      unit.namespace,
      [
        unit.getters.currentUnit
      ]
    ),
    ...mapGetters(
      judge.namespace,
      [
        judge.getters.unitName,
        judge.getters.panelBasedIdentifier,
        judge.getters.panelInfo
      ]
    )
  }
}

</script>
