import { timers as api } from '@/api'

const toApiTimer = ({ start, stop }) => ({
  start,
  stop
})

const withPayload = timers => ({
  payload: {
    entry: toApiTimer(timers[0]),
    deck: toApiTimer(timers[1]),
    routine: toApiTimer(timers[2])
  }
})

export const actions = {
  setTimers: 'setTimers',
  fetchData: 'fetchData',
  fetchConfiguration: 'fetchConfiguration',
  resetTimers: 'resetTimers'
}

export const actionsDefinition = {
  [actions.setTimers] ({ commit }, { unitId, participantId, timers }) {
    commit('setTimers', timers)

    return api.put({
      unitId,
      participantId,
      ...withPayload(timers)
    })
  },

  async [actions.fetchData] ({ commit }, { unitId, participantId }) {
    const timers = await api.get({
      unitId,
      participantId
    })

    commit('setAll', timers)
  },

  async [actions.resetTimers] ({ commit }) {
    commit('resetTimers')
  }
}
