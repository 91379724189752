import { isEmpty, toArray } from 'lodash'

export const getters = {
  timers: 'timers',
  timersState: 'timersState'
}

export const gettersDefinition = {
  timers: state => {
    if (isEmpty(state.timers)) {
      return toArray(getTimers())
    }

    return state.timers
  },
  timersState: state => {
    return state.timersState
  }
}

function getTimers () {
  return {
    entry: initializeTimer(),
    deck: initializeTimer(),
    routine: initializeTimer()
  }
}

function initializeTimer () {
  return {
    start: null,
    stop: null
  }
}
