<template lang="">
  <div>
    <div class='box'>
      <Timer
        v-for="timer in allTimers"
        v-show="timer.isVisible"
        :class="[`timer-${timer.name}`]"
        :key="timer.name"
        :title="timer.title"
        :startTimeStamp="timer.start"
        :stopTimeStamp="timer.stop"
        :isReadOnlyMode='isReadOnlyMode'
      />
    </div>
    <div class='box'>
      <button
        v-ripple
        type="button"
        v-show="shouldShowButton"
        @click="action">
          {{buttonCaption}}
      </button>
    </div>
  </div>
</template>

<script>
import Timer from '@/components/Timer.vue'
import { timekeeper } from '@/store/modules'
import { mapActions, mapGetters } from 'vuex'
import { isNil } from 'lodash'

const buttonDefaultValue = ''

export default {
  name: 'TimersBox',
  components: {
    Timer
  },
  props: {
    isReadOnlyMode: {
      type: Boolean,
      default: false
    },
    participantId: {
      type: String,
      default: null
    },
    unitId: {
      type: String,
      default: null
    }
  },
  async created () {
    await this.refreshTimers()
  },
  computed: {
    ...mapGetters(
      timekeeper.namespace,
      [
        timekeeper.getters.timers
      ]
    ),
    buttonCaption () {
      const timers = this.getTimers()

      if (!isNil(this.timers[2]?.stop)) {
        return buttonDefaultValue
      } else if (!isNil(this.timers[2]?.start)) {
        return timers[2].stopBtnTitle
      } else if (!isNil(this.timers[1]?.start)) {
        return timers[2].startBtnTitle
      } else if (!isNil(this.timers[0]?.stop)) {
        return timers[1].startBtnTitle
      } else if (!isNil(this.timers[0]?.start)) {
        return timers[0].stopBtnTitle
      }

      return timers[0].startBtnTitle
    },
    allTimers () {
      return this.getTimers()
    },
    shouldShowButton () {
      return !this.isReadOnlyMode &&
        this.timers.some((timer) => isNil(timer.start) || isNil(timer.stop))
    }
  },
  methods: {
    ...mapActions(
      timekeeper.namespace,
      [
        timekeeper.actions.setTimers,
        timekeeper.actions.fetchData
      ]),
    async refreshTimers () {
      await this.fetchData({
        unitId: this.unitId,
        participantId: this.participantId
      })
    },
    action () {
      const now = new Date()
      const timers = this.getTimers()
      const visibleTimers = timers.filter(x => x.isVisible)
      const currentTimer = visibleTimers[visibleTimers.length - 1]

      if (!isNil(currentTimer.start) && !isNil(currentTimer.stop)) {
        return
      }

      if (isNil(currentTimer.start)) {
        this.timers[visibleTimers.length - 1].start = now
      } else {
        this.timers[visibleTimers.length - 1].stop = now
        if (currentTimer.name === timers[1].name) {
          this.timers[visibleTimers.length].start = now
        }
      }

      this.setTimers({
        timers: this.timers,
        unitId: this.unitId,
        participantId: this.participantId
      })
    },
    getTimers () {
      return [
        {
          name: 'entry',
          title: 'Entry',
          isVisible: true,
          startBtnTitle: 'Start entry',
          stopBtnTitle: 'Stop entry',
          ...this.timers[0]
        },
        {
          name: 'deck',
          title: 'Deck',
          isVisible: (!isNil(this.timers[0]?.start) && !isNil(this.timers[0]?.stop)) ||
            this.isReadOnlyMode,
          startBtnTitle: 'Start deck',
          ...this.timers[1]
        },
        {
          name: 'routine',
          title: 'Routine',
          isVisible: (!isNil(this.timers[1]?.start) && !isNil(this.timers[1]?.stop)) ||
            this.isReadOnlyMode,
          startBtnTitle: 'Start routine',
          stopBtnTitle: 'Stop',
          ...this.timers[2]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/font';
@import '~@/styles/container';
@import '~@/styles/typology';

button {
  background-color: #008CFF;
  transition-duration: 0.4s;
  width: 200px;
  height: 50px;
  border-style: none;
  font-weight: 600;
  color: white;

  &:hover {
    color: black;
  }
}

.box {
  @include container;

  width: 100%;
}
</style>
