<template>
  <div v-if="authorized">
    <GoToCurrentParticipantButton :text="getGoBackButtonText" />
    <Menu :authState="authState" skipRole="judge"/>
    <panel-info :unitName="unitName" :panel="panelInfo"/>
    <div class='judge-view'>
      <participant-info
        :participant="participant"
        class="judge-view__info"/>
      <ScoreCard
        :disabled="isScreenLocked && !isCRContext"
        :panelId="panelBasedIdentifier.id"
        :scores="paddedScores"
        :selected="selectedScoreIndex"
        :selectedScorePlaceholder="selectedScoreValueBeforeSelection"
        class="judge-view__scores"
        @scoreSelected="selectScore"/>
      <KeyPad
        :disabled="(isScreenLocked && !isCRContext) || isReadyForSubmission"
        :mode="keypadMode"
        :value="selectedScoreValue"
        class='judge-view__keypad'/>
      <KeyboardKeypad @keyPressed="onKeyboardKeyPressed" />
      <PushButton
        :disabled="isEraseDisabled"
        class='judge-view__button judge-view__button--erase'
        type="cancel"
        @clicked="eraseSelectedScoreOrLast10">
        CLEAR SCORE
      </PushButton>
      <PushButton
        :disabled="isClearDisabled"
        class='judge-view__button judge-view__button--clear'
        type="cancel"
        @clicked="clearScores">
        CLEAR ALL SCORES
      </PushButton>
      <HandPushButton
        v-if="!isCRContext"
        :disabled="isHandRaised"
        class='judge-view__button judge-view__button--hand'
        @clicked="handClicked"/>
      <PushButton
        :busy="isSubmitPending"
        :disabled="isConfirmDisabled"
        class='judge-view__button judge-view__button--confirm'
        type="confirm"
        @clicked="confirmClicked">
        CONFIRM
      </PushButton>
      <section
        v-if="isScreenLocked && !isCRContext"
        class='thank-you-screen'>
        <h2>Thank you for scoring</h2>
        <p>Please, wait for other judges to submit their scores.</p>
      </section>
    </div>
  </div>
</template>

<script>
import { listenOn } from '@/api'
import HandPushButton from '@/components/HandPushButton.vue'
import KeyboardKeypad from '@/components/judge/KeyboardKeypad'
import KeyPad from '@/components/judge/KeyPad'
import ScoreCard from '@/components/judge/ScoreCard'
import Menu from '@/components/Menu.vue'
import PanelInfo from '@/components/PanelInfo'
import ParticipantInfo from '@/components/ParticipantInfo'
import PushButton from '@/components/PushButton.vue'
import GoToCurrentParticipantButton from '@/components/GoToCurrentParticipantButton'
import Events from '@/enums/Events'
import GroupNames from '@/enums/GroupNames'
import EventsMap from '@/maps/EventsMap'
import { judge, routine } from '@/store/modules'
import { mapActions, mapGetters } from 'vuex'
import { authorize, setupAuthorization } from '@/services/auth-service'
import { cond, constant, identity, isNil, isEmpty, stubTrue } from 'lodash'

const eq = ref => value => ref === value

export default {
  name: 'Judge',
  components: {
    KeyboardKeypad,
    Menu,
    ParticipantInfo,
    ScoreCard,
    KeyPad,
    HandPushButton,
    PanelInfo,
    PushButton,
    GoToCurrentParticipantButton
  },
  data () {
    return {
      isCRContext: false,
      stopListeningHandler: null,
      isDestroyed: false,
      ...setupAuthorization({ component: this, requiredRoles: ['judge', 'chief-recorder'] })
    }
  },
  watch: {
    '$route.query': {
      handler: async function () {
        const panel = this.$route.query

        if (panel && !isEmpty(panel)) {
          this.isCRContext = true
        }
      },
      immediate: true
    }
  },
  async created () {
    if (authorize({
      component: this,
      additionalCheck: () => !isNil(this.panelBasedIdentifier.id)
    })) {
      this.stopListeningHandler = await listenOn({
        isDestroyed: () => this.isDestroyed,
        group: GroupNames.JUDGE,
        events: {
          [EventsMap[Events.SCORES_RECALLED]]:
            () => this.clearScoresSubmission(),
          [EventsMap[Events.SCORE_SUBMITTED]]:
            async ({ unitId, participantId }) => {
              if (unitId === this.unitId && participantId === this.participant.id) {
                await this.fetchSavedScores()
              }
            },
          [EventsMap[Events.JUDGE_HAND_IS_LOWERED]]: () => this.setIsHandRaised(false),
          [EventsMap[Events.JUDGE_HAND_IS_RAISED]]: () => this.setIsHandRaised(true)
        }
      })

      if (this.isCRContext) {
        this.clearScoresSubmission()
      }
    }
  },
  async beforeDestroy () {
    this.isDestroyed = true
    await this.stopListeningHandler?.()
  },
  computed: {
    ...mapGetters(
      judge.namespace,
      [
        judge.getters.panelBasedIdentifier,
        judge.getters.selectedScore,
        judge.getters.paddedScores,
        judge.getters.unitName,
        judge.getters.isReadyForSubmission,
        judge.getters.isSubmitPending,
        judge.getters.hasAlreadySubmitted,
        judge.getters.panelInfo,
        judge.getters.isEraseAllowed,
        judge.getters.isClearAllowed,
        judge.getters.keypadMode,
        judge.getters.selectedScoreValueBeforeSelection,
        judge.getters.isHandRaised
      ]
    ),
    ...mapGetters(
      routine.namespace,
      [
        routine.getters.participant,
        routine.getters.unitId
      ]
    ),

    isConfirmDisabled () {
      return this.isScreenLocked || !this.isReadyForSubmission
    },
    isClearDisabled () {
      return this.isScreenLocked || !this.isClearAllowed
    },
    isEraseDisabled () {
      return this.isScreenLocked || !this.isEraseAllowed
    },

    isScreenLocked () {
      return this.hasAlreadySubmitted
    },

    selectedScoreValue () {
      const { value } = this.selectedScore || {}
      return value
    },

    selectedScoreIndex () {
      const { index } = this.selectedScore || {}
      return index
    },

    getGoBackButtonText () {
      return this.isCRContext ? 'Go Back' : null
    }
  },
  methods: {
    ...mapActions(
      judge.namespace,
      [
        judge.actions.updateScore,
        judge.actions.clearScores,
        judge.actions.fetchSavedScores,
        judge.actions.fetchConfiguration,
        judge.actions.submitScores,
        judge.actions.selectScore,
        judge.actions.raiseHand,
        judge.actions.clearScoresSubmission,
        judge.actions.eraseSelectedScoreOrLast10,
        judge.actions.keyboardKeyPressed,
        judge.actions.setIsHandRaised
      ]
    ),

    async handClicked () {
      await this.raiseHand({
        panel: this.panelBasedIdentifier
      })
    },

    async confirmClicked () {
      await this.submitScores()
      if (this.isCRContext) {
        this.$router.push('/chief-recorder')
      }
    },

    onKeyboardKeyPressed (key) {
      const keyToPropagate = cond([
        [eq('Enter'), constant(judge.consts.SpecialKeys.enter)],
        [eq('Backspace'), constant(judge.consts.SpecialKeys.backspace)],
        [eq('.'), constant(judge.consts.SpecialKeys.comma)],
        [eq(','), constant(judge.consts.SpecialKeys.comma)],
        [eq('Down'), constant(judge.consts.SpecialKeys.next)],
        [eq('ArrowDown'), constant(judge.consts.SpecialKeys.next)],
        [eq('s'), constant(judge.consts.SpecialKeys.next)],
        [eq('Up'), constant(judge.consts.SpecialKeys.prev)],
        [eq('ArrowUp'), constant(judge.consts.SpecialKeys.prev)],
        [eq('w'), constant(judge.consts.SpecialKeys.prev)],
        [stubTrue, identity]
      ])(key)
      this.keyboardKeyPressed(keyToPropagate)
    }
  }
}
</script>

<style lang='scss' scoped>
@use "~@/styles/mixins";
@import '~@/styles/variables';
@import '~@/styles/typology';
@import '~@/styles/container';

.judge-view {
  @include mixins.from-maps((
    grid-template-areas:
    (
      "default":
        "info   info   info  info"
        "scores keypad keypad hand"
        "scores keypad keypad sub"
        "scores clr    clrall sub",
      "lg-only":
        "info   info   info  info"
        "scores keypad keypad hand"
        "scores keypad keypad sub"
        "scores clr    clrall sub",
      "xl-only":
        "info   info   info  info info"
        "scores keypad keypad hand  hand"
        "scores keypad keypad clr   clrall"
        "scores keypad keypad sub   sub"),
    grid-template-columns: (
      "default": 3fr 1fr 1fr 1fr,
      "xl-only": 2.5fr 1fr 1fr 0.8fr 0.8fr),
    grid-template-rows: (
      "default": 0.6fr 2.7fr 2.7fr 1fr,
      "xl-only": 0.6fr 2.7fr 1.85fr 1.85fr)));

  display: grid;
  height: 90vh;
  margin: 0 auto;
  padding: 2rem;

  &__info {
    grid-area: info;
  }

  &__scores {
    grid-area: scores;
    overflow: auto;
  }

  &__keypad {
    grid-area: keypad;
  }

  &__button {
    &--clear {
      grid-area: clrall;
    }

    &--erase {
      grid-area: clr;
    }

    &--hand {
      grid-area: hand;
    }

    &--confirm {
      grid-area: sub;
    }
  }
}

.thank-you-screen {
  @include border('active');
  @include vcontainer('center', 'center');

  grid-column: 2 / span 3;
  grid-row: 3 / span 3;

  position: relative;
  top: 10%;
  left: 10%;

  background-color: $white;
  width: 80%;
  height: 90%;

  & > h2 {
    color: $darkgray;
    font-size: 2.6rem;
    font-weight: bold;
  }

  & > p {
    color: $darkgray;
    font-size: 2rem;
  }
}
</style>
