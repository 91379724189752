import { isNil } from 'lodash'
import { mutations } from './names'
import { initializeDifficultyTechnicalControllerScores } from '../shared'

export const dtcDefaultState = Object.freeze({
  dtcScores: {},
  inProgress: {},
  datcCount: 2
})

export const dtcMutations = Object.freeze({
  [mutations.updateDtcScores]: (store, scores = []) => {
    store.dtcScores = scores
  },
  [mutations.updateDtcScore]: (store, { index, mark }) => {
    const newScores = { ...store.dtcScores }
    if (isNil(newScores.difficultyTechnicalControllerFeedback)) {
      newScores.difficultyTechnicalControllerFeedback = []
    }

    newScores.difficultyTechnicalControllerFeedback[index] = mark
    store.dtcScores = newScores
  },
  [mutations.updateAssistantScore]: (store, { seat, index, mark }) => {
    const newScores = { ...store.dtcScores }
    const datcCount = store.datcCount

    initializeDifficultyTechnicalControllerScores(newScores, datcCount)

    newScores.assistantsDifficultyTechnicalControllerFeedback[seat - 1][index] = mark
    store.dtcScores = newScores
  },
  [mutations.setInProgress]: (store, { index, inProgress }) => {
    store.inProgress = {
      ...store.inProgress,
      [index]: inProgress
    }
  }
})
