<template>
  <article>
    <section
      class="units-list">
      <drop-down-list
        placeholder="Phase / Unit"
        :selectedItemId.sync="selectedId"
        :data="displayableUnits" />
      <v-btn
        color="primary"
        :disabled="!canStartUnit"
        @click="onStartUnit">
        Start
      </v-btn>
    </section>
    <header
      class="units-caption">
      <h1>{{ selectedUnitName }}</h1>
      <unit-status-menu
        v-if="selectedUnitStateName"
        :selectedUnitId="selectedUnitId"
        :currentStatus="selectedUnitStateName"/>
      <flat-spinner :active="isUnitStateChangeInProgress" />
    </header>
  </article>
</template>

<script>
import DropDownList from '@/components/DropDownList'
import FlatSpinner from '@/components/FlatSpinner'
import { mapActions, mapGetters } from 'vuex'
import { unit } from '@/store/modules'
import { find, sortBy, get } from 'lodash'
import { UnitStatus } from '@/enums/UnitStatus'
import UnitStatusMenu from './UnitStatusMenu'

export default {
  name: 'UnitSelector',
  async created () {
    await this.fetchAll()
    this.setDefaultUnit()
  },
  props: {
    selectedUnitId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      dataSelectedUnitId: this.selectedUnitId
    }
  },
  components: {
    FlatSpinner,
    DropDownList,
    UnitStatusMenu
  },
  emits: [
    'selectedUnitChanged'
  ],
  watch: {
    selectedUnitId (newValue, _oldValue) {
      this.selectedId = newValue
    },
    [unit.getters.allUnits] (newValue, oldValue) {
      this.$emit('selectedUnitChanged', this.selected)
    }
  },
  computed: {
    selectedId: {
      get () {
        return this.dataSelectedUnitId
      },
      set (value) {
        this.dataSelectedUnitId = value
        this.$emit('selectedUnitChanged', this.selected)
      }
    },
    ...mapGetters(
      unit.namespace,
      [
        unit.getters.allUnits,
        unit.getters.isUnitStateChangeInProgress
      ]
    ),
    rawUnitsData () {
      return this.allUnits
    },
    displayableUnits () {
      return sortBy(
        this.rawUnitsData,
        ['scheduledDateStart'])
    },
    selected () {
      return find(this.rawUnitsData, ['id', this.selectedId])
    },
    selectedUnitName () {
      return get(this.selected, 'name', '')
    },
    selectedUnitStateName () {
      return this.selected === undefined
        ? ''
        : this.selected.status
    },
    canStartUnit () {
      const { status } = this.selected || {}
      return status !== undefined && status !== UnitStatus.Running
    }
  },
  methods: {
    ...mapActions(
      unit.namespace,
      [
        unit.actions.fetchAll,
        unit.actions.startUnit,
        unit.actions.postResetUnit
      ]
    ),
    async onStartUnit () {
      await this.startUnit({ unitId: this.selectedId })
    },
    setDefaultUnit () {
      const runningUnit = this.allUnits
        ?.find(unit => unit.status.toLowerCase() === UnitStatus.Running)
      if (runningUnit) {
        this.selectedId = runningUnit.id
        return
      }

      const units = this.allUnits
        ?.filter(unit => unit.status.toLowerCase() !== UnitStatus.Official)
        ?.map(unit => ({
          id: unit.id,
          timeDifference: new Date(unit.scheduledDateStart).getTime() - Date.now()
        }))
        ?.filter(unit => unit.timeDifference >= 0) ?? []

      const unitWithLowestTimeDifference = units.reduce(
        (a, b) =>
            a?.timeDifference < b?.timeDifference
              ? a
              : b,
        null
      )

      this.selectedId = unitWithLowestTimeDifference?.id
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/button";
@import "~@/styles/variables";
@import "~@/styles/typology";
@import "~@/styles/container";

.units-caption {
  & > * {
    @include secondary-caption-l4;
  }
}

.units-list {
  @include container;

  align-items: baseline;
  align-content: stretch;
  gap: 0.5rem;

  &__start-unit {
    @include button($size: $small);
    padding: 0;

    &:disabled {
      @include button(true, $size: $small);
      padding: 0;
    }
  }
}
</style>
