export const getters = {
  unitId: 'unitId',
  participantId: 'participantId',
  participant: 'participant',
  status: 'status',
  getLastAssessedIfCurrentMissing: 'getLastAssessedIfCurrentMissing'
}

export const gettersDefinition = {
  [getters.unitId]: ({ unitId }) => unitId,
  [getters.participantId]: ({ participant: { id } }) => id,
  [getters.participant]: ({ participant }) => participant,
  [getters.status]: ({ participant: { status } = {} }) => status,
  [getters.getLastAssessedIfCurrentMissing]:
    ({ getLastAssessedIfCurrentMissing }) => getLastAssessedIfCurrentMissing
}
