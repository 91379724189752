<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-ripple
        v-on="on"
        v-bind="attrs"
        @click="pscbAllOff"
        color="error"
        class="all-off-button"
        :loading="isPscbAllOffInProgress">
        All off
      </v-btn>
    </template>
    Takes all PSCB scenes off air
  </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { olr } from '@/store/modules'

export default {
  name: 'PscbAllOffButton',
  computed: {
    ...mapGetters(
      olr.namespace,
      [
        olr.getters.isPscbAllOffInProgress
      ]
    )
  },
  methods: {
    ...mapActions(
      olr.namespace,
      [
        olr.actions.pscbAllOff
      ]
    )
  }
}
</script>

<style lang="scss" scoped>
.all-off-button {
  position: fixed;
  top: 2rem;
  right: calc(2 * 2rem + 48px); // 2x margin (menu + pscb-all-off-button) + 48px (width of menu).
}
</style>
