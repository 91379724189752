import { validateParticipant } from '../validations'
import { httpget, httppost, joinUri, newUrl } from '../base-api'
import { toUri } from './base'

const toRoutineUri = ({ unitId, uri }) =>
  joinUri(
    toUri({
      unitId,
      uri: 'routine'
    }),
    uri
  )

export default {
  current: async ({ unitId, getLastAssessedIfCurrentMissing }) => {
    return validateParticipant(
      await httpget(toRoutineUri({ unitId, uri: newUrl('current', { getLastAssessedIfCurrentMissing }) }))
    )
  },
  abort: async ({ unitId, participantId }) => {
    const payload = {
      participantId
    }
    return await httppost(toRoutineUri({ unitId, uri: 'abort' }), payload)
  }
}
