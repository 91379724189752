<template>
  <div>
    <div class='unit-name'>{{ selectedUnit && selectedUnit.name }}</div>
    <ul class="scenes">
      <li
        v-for="{id, name, channel, disabled } in pscbScenes"
        :key="id">
        <div class="scene">
          <div class="scene__name">{{ name }}</div>
          <div class="scene__controls">
            <v-btn
              class="scene__display-button"
              color="warning"
              :disabled="disabled || disabledScenes[id]"
              :outlined="channel !== 'Preview'"
              @click="toggleScene({
                id,
                channel:'Preview',
                tournamentNodeId: selectedUnit ? selectedUnit.id : null,
                additionalData: additionalData[id]
              })">
              Preview
            </v-btn>
            <v-btn
              class="scene__display-button"
              color="error"
              :disabled="disabled || disabledScenes[id]"
              :outlined="channel !== 'Live'"
              @click="toggleScene({
                id,
                channel: 'Live',
                tournamentNodeId: selectedUnit ? selectedUnit.id : null,
                additionalData: additionalData[id]
              })">
              Live
            </v-btn>
          </div>
        </div>
        <div
          class="scene__additional-data"
          v-if="id === SceneId.Freetext">
          <v-textarea
            v-model="additionalData[id].text"
            clearable
            full-width
            rows="3"
          ></v-textarea>
        </div>
        <div
          class="scene__additional-data"
          v-if="id === SceneId.TechnicalReview">
          <v-textarea
            v-model="additionalData[id].technicalReviewText"
            clearable
            full-width
            rows="3"
          ></v-textarea>
        </div>
        <div
          class="scene__podium-data"
          v-if="id === SceneId.Podium">
          <v-select
            v-model="additionalData[id].place"
            label="Place"
            :items="['1st', '2nd', '3rd']"
          ></v-select>
          <v-radio-group v-model="additionalData[id].ceremony">
            <v-radio label="Venue Ceremony" value="1"></v-radio>
            <v-radio label="Medal Ceremony" value="2"></v-radio>
          </v-radio-group>
        </div>
        <div
          class="scene__scores-data"
          v-if="id === SceneId.Score">
          <v-select
            v-model="additionalData[id].participantId"
            label="Select participant"
            :items="assessedParticipants"
            :item-value="(x) => x.id"
            :item-text="(x) => x.members.join(' / ')"
          ></v-select>
        </div>
        <flat-spinner :active="isPscbDisplaySceneInProgress[id]"/>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { olr } from '@/store/modules'
import FlatSpinner from '@/components/FlatSpinner'
import SceneId from '@/enums/SceneId'
import ParticipantStatus from '@/enums/ParticipantStatus'

export default {
  name: 'Pscb',
  components: {
    FlatSpinner
  },
  data () {
    return {
      disabledScenes: {
        [SceneId.Score]: true
      },
      additionalData: {
        [SceneId.Freetext]: {
          text: 'Type freetext here...'
        },
        [SceneId.TechnicalReview]: {
          technicalReviewText: 'TECHNICAL REVIEW IN PROGRESS'
        },
        [SceneId.Podium]: {
          place: '3rd',
          ceremony: '1'
        },
        [SceneId.Score]: {
          participantId: ''
        }
      },
      assessedParticipants: []
    }
  },
  async created () {
    await this.fetchPscbScenes()
  },
  computed: {
    // hack to expose SceneId dict to HTML part to remove 'magic strings' in v-if's
    SceneId () {
      return SceneId
    },
    ...mapGetters(
      olr.namespace,
      [
        olr.getters.selectedUnit,
        olr.getters.pscbScenes,
        olr.getters.isPscbDisplaySceneInProgress,
        olr.getters.allParticipants
      ]
    )
  },
  methods: {
    ...mapActions(
      olr.namespace,
      [
        olr.actions.fetchPscbScenes,
        olr.actions.toggleScene
      ]
    )
  },
  watch: {
    [olr.getters.allParticipants]: {
      handler: function (newVal) {
        this.assessedParticipants = newVal.filter(x => x.status === ParticipantStatus.Assessed ||
          x.irm)
      }
    },
    additionalData: {
      handler: function (nevVal) {
        if (nevVal[SceneId.Score].participantId) {
          this.disabledScenes[SceneId.Score] = false
        } else {
          this.disabledScenes[SceneId.Score] = true
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/container";
@import "~@/styles/typology";

.unit-name {
  @include primary-bold-caption-l4;
}

.scenes {
  text-align: left;

  li {
    margin-bottom: 1rem;
  }

  .scene {
    @include container($alignTo: "justified", $vAlignTo: "center");

    &__name {
      @include primary-bold-caption-l4;
    }

    &__display-button {
      width: 100px;
    }

    &__display-button:last-child {
      margin-left: 0.5rem;
    }

    &__additional-data {
      width: 100%;
    }
  }
}
</style>
