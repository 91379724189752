import ParticipantStatus from '@/enums/ParticipantStatus'
import Irms from '@/enums/Irms'
import { isEmpty } from 'lodash'

export const formatTimeStamp = (timeStamp) => {
  if (isEmpty(timeStamp)) {
    return ''
  }

  const date = new Date(timeStamp)
  const tzOffset = date.getTimezoneOffset() * 60 * 1000
  const iso = new Date(date - tzOffset).toISOString().slice(0, -1) // remove leading Z
  const [datePart, timePart] = iso.split('T')
  return `${datePart} ${timePart}`
}

export const getSelectedParticipant = participants => {
  const participantsWithoutIrms = participants
    .filter(p => !p.irm || !Irms.includes(p.irm))

  return participantsWithoutIrms?.find(p => p.status === ParticipantStatus.Performing) ??
    participantsWithoutIrms?.find(p => p.status === ParticipantStatus.Active) ??
    participantsWithoutIrms?.slice(-1)[0] ??
    null
}
