<template>
  <article>
    <div class='unit-name'>{{ selectedUnit && selectedUnit.name }}</div>
    <div v-if="selectedUnit && selectedUnit.name">
      <section
        v-for="( printoutTournamentLevel, index )
        in printouts.filter(p => p.printouts && p.printouts.length > 0)"
        :key="index">
        <div class="tournamentLevel-name">
          {{ printoutTournamentLevel.name }}
        </div>
        <div
          v-for="( printout, index ) in printoutTournamentLevel.printouts"
          :key="index"
          class="printout">
        <span class="printout__description">
          {{ `${printout.key} - ${printout.description}` }}
          <v-checkbox
            v-if='isC51(printout.key)'
            v-model="shouldHideDDC51"
            class="printout__description--checkbox ma-0 ml-8">
            <template v-slot:label>
              <span class="printout__description--checkbox-label">Hide DD</span>
            </template>
          </v-checkbox>
        </span>
        <div>
          <v-btn
            v-if='showDownloadLink(printout.key)'
            class="printout__action"
            small
            @click="() => downloadPrintout({uri: getPrintoutUri(printout.key), filename: `${printout.key}.pdf`})">
            {{ "Download" }}
          </v-btn>
          <span
            v-if='showPrintoutStatus(printout.key)'
            class="printout__status">
            {{ getPrintoutStatus(printout.key) }}
          </span>
          <v-btn
            class="printout__action"
            color="primary"
            small
            :loading="isPrintoutGenerationInProgress[getPrintoutInProgressKey(
              printoutTournamentLevel.name,
              printout.key)]"
            :disabled="disableGenerate(printout)"
            @click="() => printoutTournamentLevel &&
            generatePrintout(printoutTournamentLevel.name, printout.key)">
            Generate
          </v-btn>
          </div>
        </div>
      </section>
    </div>
    <div v-else>
      <span>Select a unit to see printouts</span>
    </div>
  </article>
</template>

<script>
import { Printouts } from '@/enums/Printouts'
import { PrintoutStatuses } from '@/enums/PrintoutStatuses'
import { UnitStatus } from '@/enums/UnitStatus'
import { olr, unit } from '@/store/modules'
import { isEmpty } from 'lodash'
import { mapActions, mapGetters } from 'vuex'

const competitionLevel = 'Competition'
const eventLevel = 'Event'
const unitLevel = 'Unit'

export default {
  name: 'PrintoutsGenerator',
  data: () => ({
    shouldHideDDC51: false
  }),
  methods: {
    ...mapActions(
      olr.namespace,
      [
        olr.actions.fetchCompetitionPrintoutsStatuses,
        olr.actions.fetchEventPrintoutsStatuses,
        olr.actions.fetchUnitPrintoutsStatuses,
        olr.actions.generateCompetitionPrintout,
        olr.actions.generateEventPrintout,
        olr.actions.generateUnitPrintout,
        olr.actions.downloadPrintout
      ]
    ),
    async generatePrintout (tournamentLevelName, printoutCode) {
      switch (tournamentLevelName) {
        case competitionLevel:
          return await this.generateCompetitionPrintout({ printoutCode })
        case eventLevel:
          return await this.generateEventPrintout({
            eventId: this.selectedUnit.eventId,
            printoutCode
          })
        case unitLevel:
          return await this.generateUnitPrintout({
            unitId: this.selectedUnit.id,
            printoutCode,
            options: { shouldHideDDC51: this.shouldHideDDC51 }
          })
      }
    },
    getPrintoutInProgressKey (tournamentLevelName, printoutCode) {
      switch (tournamentLevelName) {
        case competitionLevel:
          return printoutCode
        case eventLevel:
          return `${this.selectedUnit.eventId}${printoutCode}`
        case unitLevel:
          return `${this.selectedUnit.id}${printoutCode}`
      }

      return ''
    },

    getPrintoutStatus (printoutCode) {
      const printoutInfo = this.printoutsStatuses.filter(a => a.code === printoutCode)[0]
      return printoutInfo && printoutInfo.status
    },
    getPrintoutUri (printoutCode) {
      const printoutInfo = this.printoutsStatuses.filter(a => a.code === printoutCode)[0]
      return printoutInfo && printoutInfo.uri
    },
    showDownloadLink (printoutCode) {
      if (!printoutCode || !this.printoutsStatuses) {
        return false
      }
      const printoutInfo = this.printoutsStatuses.filter(a => a.code === printoutCode)[0]
      return printoutInfo && printoutInfo.status === PrintoutStatuses.Ready
    },
    showPrintoutStatus (printoutCode) {
      if (!printoutCode || !this.printoutsStatuses) {
        return false
      }
      const printoutInfo = this.printoutsStatuses.filter(a => a.code === printoutCode)[0]
      return printoutInfo &&
        (printoutInfo.status === PrintoutStatuses.Failed ||
          printoutInfo.status === PrintoutStatuses.Pending)
    },
    disableGenerate (printout) {
      return !printout || printout.disabled
    },
    getCompetitionPrintouts () {
      const competitionPrintouts = {
        name: competitionLevel,
        printouts: []
      }

      competitionPrintouts.printouts.push({ key: 'C08', description: Printouts.C08, disabled: false })
      competitionPrintouts.printouts.push({ key: 'C32A', description: Printouts.C32A, disabled: false })
      competitionPrintouts.printouts.push({ key: 'C35B', description: Printouts.C35B, disabled: false })
      competitionPrintouts.printouts.push({ key: 'C93', description: Printouts.C93, disabled: false })

      return competitionPrintouts
    },
    getEventPrintouts () {
      const eventPrintouts = {
        name: eventLevel,
        printouts: []
      }

      if (isEmpty(this.selectedUnit)) {
        return []
      }

      const finalUnits = this.allUnits.filter(u => u.eventId === this.selectedUnit.eventId && u.id.includes('FNL'))
      const medalistsDisabled = finalUnits.some(u => u.status !== UnitStatus.Official)

      eventPrintouts.printouts.push({ key: 'C92B', description: Printouts.C92B, disabled: medalistsDisabled })

      if (this.selectedUnit.isSoloRoutine) {
        eventPrintouts.printouts.push({ key: 'C31C', description: Printouts.C31C, disabled: false })
      }

      if (this.selectedUnit.isDuetRoutine) {
        eventPrintouts.printouts.push({ key: 'C32C1', description: Printouts.C32C1, disabled: false })
      }

      if (this.selectedUnit.isTeamRoutine) {
        eventPrintouts.printouts.push({ key: 'C32C2', description: Printouts.C32C2, disabled: false })
      }

      return eventPrintouts
    },
    getUnitPrintouts () {
      const unitPrintouts = {
        name: unitLevel,
        printouts: []
      }

      if (this.selectedUnit.id) {
        unitPrintouts.printouts.push({ key: 'C53', description: Printouts.C53, disabled: false })
        unitPrintouts.printouts.push({ key: 'C57', description: Printouts.C57, disabled: false })
      }

      if ((this.selectedUnit.isDuetRoutine || this.selectedUnit.isSoloRoutine) &&
          this.selectedUnit.isTechnicalRoutine) {
        unitPrintouts.printouts.push({ key: 'C51A', description: Printouts.C51A, disabled: false })
        unitPrintouts.printouts.push({ key: 'C73A', description: Printouts.C73A, disabled: false })
        unitPrintouts.printouts.push({ key: 'C77A', description: Printouts.C77A, disabled: false })
      }

      if ((this.selectedUnit.isDuetRoutine || this.selectedUnit.isSoloRoutine) &&
          this.selectedUnit.isFreeRoutine) {
        unitPrintouts.printouts.push({ key: 'C51B', description: Printouts.C51B, disabled: false })
        unitPrintouts.printouts.push({ key: 'C73B', description: Printouts.C73B, disabled: false })
        unitPrintouts.printouts.push({ key: 'C77B', description: Printouts.C77B, disabled: false })
      }

      if (this.selectedUnit.isTeamRoutine && this.selectedUnit.isTechnicalRoutine) {
        unitPrintouts.printouts.push({ key: 'C51C', description: Printouts.C51C, disabled: false })
        unitPrintouts.printouts.push({ key: 'C73C', description: Printouts.C73C, disabled: false })
        unitPrintouts.printouts.push({ key: 'C77C', description: Printouts.C77C, disabled: false })
      }

      if (this.selectedUnit.isTeamRoutine && this.selectedUnit.isFreeRoutine) {
        unitPrintouts.printouts.push({ key: 'C51D', description: Printouts.C51D, disabled: false })
        unitPrintouts.printouts.push({ key: 'C73D', description: Printouts.C73D, disabled: false })
        unitPrintouts.printouts.push({ key: 'C77D', description: Printouts.C77D, disabled: false })
      }

      if (this.selectedUnit.isAcrobaticRoutine) {
        unitPrintouts.printouts.push({ key: 'C51E', description: Printouts.C51E, disabled: false })
        unitPrintouts.printouts.push({ key: 'C73E', description: Printouts.C73E, disabled: false })
        unitPrintouts.printouts.push({ key: 'C77E', description: Printouts.C77E, disabled: false })
      }

      return unitPrintouts
    },
    isC51 (printoutCode) {
      return printoutCode.includes('C51')
    }
  },
  computed: {
    ...mapGetters(
      olr.namespace,
      [
        olr.getters.selectedUnit,
        olr.getters.printoutsStatuses,
        olr.getters.isPrintoutGenerationInProgress
      ]
    ),
    ...mapGetters(
      unit.namespace,
      [
        unit.getters.allUnits
      ]
    ),
    printouts () {
      if (isEmpty(this.selectedUnit)) {
        return []
      }

      const allPrintouts = []
      allPrintouts.push(this.getCompetitionPrintouts())
      allPrintouts.push(this.getEventPrintouts())
      allPrintouts.push(this.getUnitPrintouts())

      return allPrintouts
    }
  },
  watch: {
    selectedUnit: {
      handler: async function () {
        if (!isEmpty(this.selectedUnit)) {
          await this.fetchCompetitionPrintoutsStatuses()
          await this.fetchEventPrintoutsStatuses({
            eventId: this.selectedUnit.eventId
          })
          await this.fetchUnitPrintoutsStatuses({
            unitId: this.selectedUnit.id
          })
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/button";
@import "~@/styles/container";
@import "~@/styles/typology";
@import "~@/styles/variables";

.unit-name {
  @include primary-bold-caption-l4;
}

.tournamentLevel-name {
  @include primary-bold-caption-l4;

  margin-bottom: 0.5rem;
  margin-top: 0.5rem;

  text-align: start;
}

.printout {
  @include container('justified', 'center');

  margin-bottom: 0.5rem;

  &__description {
    @include primary-caption-l4;
    @include vcontainer('left');

    padding-left: 2rem;

    &--checkbox {
      &-label {
        @include primary-caption-l4;
      }
    }
  }

  &__status {
    @include primary-caption-l4;

    margin-right: 1rem;
    padding: 0.5rem;
  }

  &__action {
    margin-left: 1rem;
    width: 100px;
  }
}

</style>
