export const Printouts = Object.freeze({
  C08: 'Competition Schedule',
  C32A: 'Entries by NAT',
  C31C: 'Entries by Event',
  C32C1: 'Entries List by Event (Duet)',
  C32C2: 'Entries List by Event (Team)',
  C35B: 'Judges List',
  C51A: 'Start List (Solo/Duet - Technical Routine)',
  C51B: 'Start List (Solo/Duet - Free Routine)',
  C51C: 'Start List (Team - Technical Routine)',
  C51D: 'Start List (Team - Free Routine)',
  C51E: 'Start List (Team - Acrobatic)',
  C53: 'Coach Card Listing',
  C57: 'Panel of Judges',
  C73A: 'Results (Solo/Duet - Technical Routine)',
  C73B: 'Results (Solo/Duet - Free Routine)',
  C73C: 'Results (Team - Technical Routine)',
  C73D: 'Results (Team - Free Routine)',
  C73E: 'Results (Team - Acrobatic)',
  C77A: 'Detailed Results (Solo/Duet - Technical Routine)',
  C77B: 'Detailed Results (Solo/Duet - Free Routine)',
  C77C: 'Detailed Results (Team - Technical Routine)',
  C77D: 'Detailed Results (Team - Free Routine)',
  C77E: 'Detailed Results (Team - Acrobatic)',
  C92B: 'Medallists',
  C93: 'Medallists by Event'
})
