import ContextSelection from '@/views/ContextSelection'

const routes = [{
  path: '/context-selection',
  name: 'context-selection',
  component: ContextSelection
}]

export default {
  routes
}
