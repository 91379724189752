import { retrieveCancellationTokenForBase } from '../shared'

const moduleMapping = {
  getters: {
    getAll: 'getAllCancellationTokens'
  },
  mutations: {
    replaceContext: 'replaceCancellationTokenForContext'
  }
}

export const retrieveCancellationTokenFor = (store, payload) => {
  return retrieveCancellationTokenForBase({
    store,
    payload,
    moduleMapping,
    shouldReturnExistingToken: true
  })
}
