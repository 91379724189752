import { scoresDefaultState, scoresMutations } from './scores'
import { configurationDefaultState, configurationMutations } from './configuration'
import { summaryDefaultState, summaryMutations } from './summary'
import { dtcDefaultState, dtcMutations } from './difficulty-technical-controller'
export { mutations } from './names'

export const defaultState = {
  ...scoresDefaultState,
  ...configurationDefaultState,
  ...summaryDefaultState,
  ...dtcDefaultState
}

export const mutationsDefinition = {
  ...scoresMutations,
  ...configurationMutations,
  ...summaryMutations,
  ...dtcMutations
}
