export const defaultState = {
  competitionCoachCards: [],
  isInProgress: { }
}

export const mutations = Object.freeze({
  setCompetitionCoachCards: 'setCompetitionCoachCards',
  setIsInProgress: 'setIsInProgress'
})

export const mutationsDefinition = Object.freeze({
  [mutations.setCompetitionCoachCards]: (store, competitionCoachCards) => {
    store.competitionCoachCards = competitionCoachCards
  },
  [mutations.setIsInProgress]: (store, { element, pending }) => {
    const isInProgress = { ...store.isInProgress }
    isInProgress[element] = pending
    store.isInProgress = isInProgress
  }
})
