import { actions } from './actions'
import { mutations } from './mutations'

export const subscribe = ({ store, dispatchLocal, commitLocal } = {}) => {
  return store.subscribeAction(action => {
    switch (action.type) {
      case 'fetchCurrentUnitSucceeded':
        dispatchLocal(actions.fetchConfiguration)
        break

      case 'submittingModifyJudgeScores':
        commitLocal(mutations.isSubmitScorePending, { pending: true })
        break

      case 'submitModifyJudgeScoresSucceeded':
      case 'submitModifyJudgeScoresFailed':
        commitLocal(mutations.isSubmitScorePending, { pending: false })
        break

      case 'fetchCurrentRoutineInfoSucceeded':
        dispatchLocal(actions.fetchSavedScores)
        dispatchLocal(actions.fetchIsHandRaised)
        break

      case 'fetchCurrentRoutineInfoFailed':
        dispatchLocal(actions.clearScores)
        break
    }
  })
}
