<template>
  <div v-if="authorized">
    <Menu :authState="authState" skipRole="referee"/>
    <page-title title="Referee"/>
    <section class="referee">
      <v-container fluid>
        <v-row justify="space-between">
          <v-col cols="7" align-self="center">
            <v-row>
              <v-col><h3>{{ currentUnit.name }}</h3></v-col>
              <v-col class="text-left d-flex justify-center">
                <span>
                  {{ formatDate(currentUnit.scheduledDateStart) }}
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row>
              <v-col align-self="center" class="participant-info">
                <h4>{{participant.startOrder}}. {{participant.noc}} {{ participantMembers }}</h4>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <chief-recorder-scores
        v-if="currentUnit.id"
        :readonly="true"
        :unit-id="currentUnit.id"
        class="scores-chief-recorder"
        :participant-id="participant.id"
        :use-online-judges="false"
        :show-syn-error-log-button="false"
      />
    </section>
  </div>
</template>

<script>
import { authorize, setupAuthorization } from '@/services/auth-service'
import PageTitle from '@/components/PageTitle'
import Menu from '@/components/Menu'
import ChiefRecorderScores from '@/components/chief-recorder/ChiefRecorderScores.vue'
import { mapActions, mapGetters } from 'vuex'
import { chiefRecorder, participants, routine, synchronizationErrors, unit } from '@/store/modules'
import { listenOn } from '@/api'
import GroupNames from '@/enums/GroupNames'
import EventsMap from '@/maps/EventsMap'
import Events from '@/enums/Events'
import { get, join, throttle } from 'lodash'

export default {
  name: 'Referee',
  components: {
    ChiefRecorderScores,
    Menu,
    PageTitle
  },
  methods: {
    ...mapActions(
      routine.namespace,
      [
        routine.actions.fetchCurrent
      ]
    ),
    ...mapActions(
      participants.namespace,
      [
        participants.actions.fetchRefereeConfiguration,
        participants.actions.fetchParticipantScores,
        participants.actions.updateAssistantScores
      ]
    ),
    ...mapActions(
      synchronizationErrors.namespace,
      [
        synchronizationErrors.actions.fetchSynchronizationErrors
      ]
    ),
    ...mapActions(
      chiefRecorder.namespace,
      [
        chiefRecorder.actions.fetchRaisedHands
      ]
    ),
    formatDate (input) {
      const date = new Date(input).toISOString()
      const d = date.split('T')[0]
      const t = date.split('T')[1].split('.')[0]
      return `${d} ${t}`
    }
  },
  computed: {
    ...mapGetters(
      unit.namespace,
      [
        unit.getters.currentUnit
      ]
    ),
    ...mapGetters(
      routine.namespace,
      [
        routine.getters.participant
      ]
    ),
    participantMembers () {
      return join(
        get(this.participant, 'members', []),
        ' / ')
    }
  },
  data () {
    return {
      ...setupAuthorization({ component: this, requiredRoles: ['referee'] }),
      stopListeningHandler: null,
      isDestroyed: false
    }
  },
  watch: {
    participant: {
      handler: async function () {
        if (this.participant?.id && this.currentUnit?.id) {
          await this.fetchRefereeConfiguration({
            unitId: this.currentUnit.id,
            participantId: this.participant.id
          })
        }
      },
      immediate: true
    }
  },
  async beforeDestroy () {
    this.isDestroyed = true
    await this.stopListeningHandler?.()
  },
  async created () {
    if (authorize({ component: this })) {
      const onScoreSubmitted = throttle(async () => {
        await this.fetchParticipantScores({
          unitId: this.currentUnit.id,
          participantId: this.participant.id
        })
      }, 5000)
      this.stopListeningHandler = await listenOn({
        isDestroyed: () => this.isDestroyed,
        group: GroupNames.REFEREE,
        events: {
          [EventsMap[Events.ROUTINE_STARTED]]:
            async () => {
              await this.fetchCurrent()
            },
          [EventsMap[Events.ROUTINE_ABORTED]]:
            async () => {
              await this.fetchCurrent()
            },
          [EventsMap[Events.SCORE_SUBMITTED]]:
            async () => {
              await onScoreSubmitted()
            },
          [EventsMap[Events.ROUTINE_FINISHED]]:
            async () => {
              await onScoreSubmitted()
            },
          [EventsMap[Events.DTAC_SCORE_SUBMITTED]]:
            async ({ seat, judgedDegreeOfDifficultyCard }) => {
              this.updateAssistantScores({ seat, judgedDegreeOfDifficultyCard })
            },
          [EventsMap[Events.JUDGE_HAND_IS_RAISED]]:
            async () => await this.fetchRaisedHands({
              unitId: this.currentUnit.id,
              participantId: this.participant.id
            }),
          [EventsMap[Events.JUDGE_HAND_IS_LOWERED]]:
            async () => await this.fetchRaisedHands({
              unitId: this.currentUnit.id,
              participantId: this.participant.id
            })
        }
      })
    }
  }
}
</script>
