<template>
  <div v-if="authorized">
    <GoToCurrentParticipantButton/>
    <Menu :authState="authState" skipRole="timekeeper" />
    <panel-info :unitName="currentUnit.name" :panel="panelInfo" />
    <div class='waiting'>
      <waiting-info />
    </div>
  </div>
</template>

<script>
import Menu from '@/components/Menu'
import GoToCurrentParticipantButton from '@/components/GoToCurrentParticipantButton'
import WaitingInfo from '@/components/WaitingInfo'
import PanelInfo from '@/components/PanelInfo'
import { authorize, setupAuthorization } from '@/services/auth-service'
import { mapGetters } from 'vuex'
import { unit } from '@/store/modules'

export default {
  name: 'TimekeeperWaiting',
  components: {
    Menu,
    WaitingInfo,
    PanelInfo,
    GoToCurrentParticipantButton
  },
  data () {
    return setupAuthorization({ component: this, requiredRoles: ['timekeeper'] })
  },
  created () {
    if (authorize({ component: this })) {
      // placeholder for init logic
    }
  },
  computed: {
    ...mapGetters(
      unit.namespace,
      [
        unit.getters.currentUnit
      ]
    ),
    panelInfo () {
      return {
        name: 'Timekeeper'
      }
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/variables';
@import '~@/styles/container';

.waiting {
  @include vcontainer;

  height: 80vh;
  max-width: $max-view-width;
  margin: 0 auto;
  padding: 2rem;
}

</style>
