import { Container, createStandardWebsocketConnector, createStandardWebsocketDisconnector } from './base'
import JudgeWaiting from '@/views/JudgeWaiting'
import Judge from '@/views/Judge'
import JudgeSync from '@/views/JudgeSync'
import JudgeResults from '@/views/JudgeResults'
import DifficultyTechnicalController from '@/views/DifficultyTechnicalController'
import DifficultyAssistantTechnicalController from '@/views/DifficultyAssistantTechnicalController'
import { judge, routine, unit } from '@/store/modules'
import store from '@/store'
import ParticipantStatus from '@/enums/ParticipantStatus'
import Events from '@/enums/Events'
import GroupNames from '@/enums/GroupNames'
import { judgeListeners } from '@/store/actionListeners'
import { registerActionListeners } from '@/store/base/store-listeners'
import PanelId from '@/enums/PanelId'

const serialize = (obj) => {
  return Object.keys(obj).map(key => `${key}=${obj[key]}`).join('&')
}

const judgeSubRoutes = Object.freeze({
  [ParticipantStatus.Performing]: {
    path: (store) => {
      const panelInfo = judge.globals.getters.panelBasedIdentifier(store)

      var thePath = '/judge/score'
      switch (panelInfo.id) {
        case PanelId.Synchronization:
          thePath = '/judge/sync'
          break
        case PanelId.DifficultyTechnicalController:
          thePath = '/judge/dtc-score'
          break
        case PanelId.DifficultyAssistantTechnicalController:
          thePath = '/judge/datc-score'
          break
      }

      return thePath
    },
    enterWhenEvent: Events.ROUTINE_STARTED
  },
  [ParticipantStatus.Assessed]: {
    path: (store) => {
      const panelInfo = judge.globals.getters.panelBasedIdentifier(store)
      const panels = [PanelId.Elements, PanelId.ArtisticImpression]
      if (panels.every(p => p !== panelInfo.id)) {
        return null
      }

      return '/judge/results'
    },
    enterWhenEvent: Events.ROUTINE_FINISHED
  }
})

const routes = [
  {
    path: '/judge',
    component: Container,
    name: 'judge',
    children: [
      { path: 'wait', component: JudgeWaiting, name: 'judge' },
      { path: 'score', component: Judge, name: 'judge' },
      { path: 'sync', component: JudgeSync, name: 'judge' },
      { path: 'results', component: JudgeResults, name: 'judge' },
      { path: 'dtc-score', component: DifficultyTechnicalController, name: 'judge' },
      { path: 'datc-score', component: DifficultyAssistantTechnicalController, name: 'judge' }
    ],
    meta: {
      socket: {
        open: createStandardWebsocketConnector({
          store,
          abortPath: '/judge/wait',
          hubGroup: GroupNames.JUDGE,
          subRoutes: judgeSubRoutes,
          additionalAction: async ({ eventName, payload }) => {
            switch (eventName) {
              case Events.ROUTINE_STARTED:
                await judge.globals.actions.clearScores(store)
                await judge.globals.actions.clearMarks(store)
                await judge.globals.actions.fetchConfiguration(store)
                break
              case Events.ROUTINE_FINISHED: {
                const { participantInfo, scoresSummary } = payload
                await routine.globals.actions.setParticipantInfo(store, participantInfo)
                await judge.globals.actions.setScoresSummary(store, scoresSummary)
                break
              }
            }
          }
        }),
        close: createStandardWebsocketDisconnector({
          hubGroup: GroupNames.JUDGE
        })
      }
    },
    async beforeEnter (to, from, next) {
      registerActionListeners({ store, subscribers: judgeListeners })
      routine.globals.actions.setGetLastAssessedIfCurrentMissing(
        store,
        true
      )
      judge.globals.actions.clearScores(store)
      await unit.globals.actions.fetchCurrent(store)
      handleRoutineStatus(to, next)
    }
  }
]

export default {
  routes
}

function handleRoutineStatus (to, next) {
  const status = routine.globals.getters.status(store)
  // todo https://dev.azure.com/SWTIDEVEXTERNAL/SWA-OLR/_workitems/edit/3555
  if (status === undefined) {
    setTimeout(() => handleRoutineStatus(to, next), 200)
  } else {
    let nextPage = judgeSubRoutes[status]?.path(store) || '/judge/wait'

    const query = serialize(to.query)
    if (query) {
      nextPage += `?${query}`
    }

    if (nextPage === to.path || (nextPage.includes('?') && nextPage.includes(to.path))) {
      next()
    } else {
      next(nextPage)
    }
  }
}
