import { currentContextId } from '@/api/authorization/context'
import { HUB_URI } from '../apiconsts'
import { getToken } from '../authorization/token'
import { HttpTransportType, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr'
import GroupNames from '@/enums/GroupNames'
import HubEvents from '@/enums/HubEvents'
import HubEventsMap from '@/maps/HubEventsMap'

let _connection = null
export const getConnection = () => _connection

const createHubToken = () => {
  const token = {
    token: getToken(),
    contextId: currentContextId()
  }
  const encodedToken = btoa(JSON.stringify(token))
  return encodedToken
}

const createNewConnection = () =>
  new HubConnectionBuilder()
    .withUrl(HUB_URI, {
      accessTokenFactory: createHubToken,
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets
    })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Warning)
    .build()

export const connect = async (args = {}) => {
  if (_connection && _connection.state !== HubConnectionState.Disconnected) {
    await _connection.stop()
  }
  const { onconnect, setup } = args
  const connection = createNewConnection()

  await setup?.(connection)

  connection.onreconnected(async () => await connection.invoke(
    HubEventsMap[HubEvents.JOIN_GROUP],
    GroupNames.GENERAL))

  await connection.start()

  await connection.invoke(
    HubEventsMap[HubEvents.JOIN_GROUP],
    GroupNames.GENERAL)

  await onconnect?.()
  _connection = connection
}
