import Home from '@/views/Home'

const routes = [{
  path: '/',
  name: 'home',
  component: Home
}]

export default {
  routes
}
