export default Object.freeze({
  DT_SCHEDULE: 'DT_SCHEDULE',
  DT_SCHEDULE_UPDATE: 'DT_SCHEDULE_UPDATE',
  DT_PARTIC: 'DT_PARTIC',
  DT_PARTIC_UPDATE: 'DT_PARTIC_UPDATE',
  DT_MEDALS: 'DT_MEDALS',
  DT_MEDALLISTS: 'DT_MEDALLISTS',
  DT_MEDALLISTS_DISCIPLINE: 'DT_MEDALLISTS_DISCIPLINE',
  DT_PARTIC_TEAMS: 'DT_PARTIC_TEAMS',
  DT_PARTIC_TEAMS_UPDATE: 'DT_PARTIC_TEAMS_UPDATE',
  DT_RANKING: 'DT_RANKING',
  DT_RESULT: 'DT_RESULT',
  DT_CUMULATIVE_RESULT: 'DT_CUMULATIVE_RESULT'
})
