import { callApi, configuration, createSimpleHandler, dispatchGlobal, participants, scores as scoresApi } from '@/api'
import ParticipantStatus from '@/enums/ParticipantStatus'
import { defaultTo, isEmpty, isNil, overSome, filter } from 'lodash'
import { getters } from './getters'
import { mutations } from './mutations'
import PanelId from '@/enums/PanelId'

export const actions = {
  fetchCurrentUnitParticipants: 'fetchCurrentUnitParticipants',
  fetchAllParticipants: 'fetchAllParticipants',
  fetchDetails: 'fetchDetails',
  fetchChiefRecorderConfiguration: 'fetchChiefRecorderConfiguration',
  fetchRefereeConfiguration: 'fetchRefereeConfiguration',
  presentSelected: 'presentSelected',
  fetchParticipantScores: 'fetchParticipantScores',
  sendScores: 'sendScores',
  isEditMode: 'isEditMode',
  updateSelectedParticipantScores: 'updateSelectedParticipantScores',
  updateSelectedParticipantPenalties: 'updateSelectedParticipantPenalties',
  recallScores: 'recallScores',
  selectParticipantScore: 'selectParticipantScore',
  modifyJudgeScore: 'modifyJudgeScore',
  clearSelectedScore: 'clearSelectedScore',
  setMissingScores: 'setMissingScores',
  updateIrm: 'updateIrm',
  updateAssistantScores: 'updateAssistantScores',
  calculateTotals: 'calculateTotals',
  generateRandomScores: 'generateRandomScores'
}

const createFetchCurrentUnitParticipantsSucceeded = (
  { dispatch, commit }
) => participants => {
  commit(mutations.setCurrentUnitParticipants, participants)
  dispatchGlobal(dispatch, 'fetchCurrentUnitParticipantsSucceeded', participants)
}

const createFetchCurrentUnitParticipantsFailed = ({ dispatch, commit }) => error => {
  commit(mutations.setCurrentUnitParticipants, [])
  dispatchGlobal(dispatch, 'fetchCurrentUnitParticipantsFailed', error)
}

const createFetchAllParticipantsSucceeded = (
  { dispatch, commit },
  payload
) => participants => {
  const { unitId } = payload
  commit(mutations.setParticipants, { unitId, participants })
  dispatchGlobal(dispatch, 'fetchAllParticipantsSucceeded', { unitId, participants })
}

const createFetchAllParticipantsFailed = ({ dispatch, commit }) => error => {
  commit(mutations.clearParticipants)
  dispatchGlobal(dispatch, 'fetchAllParticipantsFailed', error)
}

const createFetchParticipantSucceeded = ({
  dispatch,
  commit
}) => participant => {
  commit(mutations.setParticipant, participant)
  commit(mutations.updateParticipantModifiedScores)
  commit(mutations.clearSelectedScore)
  commit(mutations.isEditMode, false)
  commit(
    mutations.scoresSent,
    participant.status === ParticipantStatus.Assessed.toLowerCase()
  )
  dispatchGlobal(dispatch, 'fetchParticipantDetailsSucceeded')
}

const createFetchParticipantScoresSucceeded = (
  { dispatch, commit },
  participantId
) => data => {
  commit(mutations.updateParticipantScores, {
    participantId,
    participantScores: data
  })
  dispatchGlobal(dispatch, 'fetchParticipantScoresSucceeded')
}

const createFetchParticipantScoresFailed = ({ dispatch, commit }) => data => {
  commit(mutations.clearCurrentParticipantScores)
  dispatchGlobal(dispatch, 'fetchParticipantScoresFailed', data)
}

const createSubmitSendScoresSucceeded = (
  { dispatch, commit },
  payload
) => () => {
  commit(mutations.scoresSent, true)
  dispatchGlobal(dispatch, 'submitSendScoresSucceeded', payload)
}

const createModifyJudgeScoresSucceeded = (
  { dispatch, commit },
  payload
) => () => {
  commit(mutations.clearSelectedScore)
  dispatchGlobal(dispatch, 'submitModifyJudgeScoresSucceeded', payload)
}

const createModifyJudgeScoresFailed = (
  { dispatch, commit },
  { ...payload }
) => () => {
  commit(mutations.clearSelectedScore)
  dispatchGlobal(dispatch, 'submitModifyJudgeScoresFailed', payload)
}

const createSubmitIrmSucceeded = (
  { dispatch, commit },
  { participantId, irm, unitId, currentUnitParticipants: participants }
) => () => {
  commit(
    mutations.updateParticipantIrm,
    { participantId, irm: irm === 'Default' ? undefined : irm })
  const participant = participants.find(p => p.id === participantId)
  participant.irm = irm
  dispatchGlobal(dispatch, 'submitIrmSucceeded', { participants, unitId })
}

const createFetchChiefRecorderOrRefereeConfigurationSucceeded = (
  { dispatch, commit },
  payload,
  actionName
) => configuration => {
  commit(mutations.setUnitScoresStructure, configuration)
  commit(mutations.updateParticipantModifiedScores)
  dispatchGlobal(dispatch, `fetch${actionName}Succeeded`, payload)
}

const createFetchChiefRecorderOrRefereeConfigurationFailed = (
  { dispatch, commit }, actionName) => () => {
  commit(mutations.clearUnitScoresStructure)
  commit(mutations.updateParticipantModifiedScores)
  dispatchGlobal(dispatch, `fetch${actionName}Failed`)
}

const createSubmitRecallParticipantScoresSucceeded = (
  { dispatch, commit },
  payload
) => () => {
  commit(mutations.clearModifiedJudgeScore, { panel: payload.panel })
  dispatchGlobal(dispatch, 'submitRecallParticipantScoresSucceeded', payload)
}

const createSubmittingMissingParticipantScoresHandler = ({ dispatch, commit }, payload) => () => {
  commit(
    mutations.setMissingJudgeInProgress,
    {
      panelId: payload.panel.id,
      seat: payload.panel.seat,
      inProgress: true
    })
  dispatchGlobal(dispatch, 'submittingMissingParticipantScores', payload)
}

const createSubmitMissingParticipantScoresSucceededHandler = (
  { dispatch, commit },
  payload) => () => {
  commit(
    mutations.setMissingJudgeInProgress,
    {
      panelId: payload.panel.id,
      seat: payload.panel.seat,
      inProgress: false
    })
  dispatchGlobal(dispatch, 'submitMissingParticipantScoresSucceeded', payload)
}

const createSubmitMissingParticipantScoresFailedHandler = (
  { dispatch, commit },
  payload) => () => {
  commit(
    mutations.setMissingJudgeInProgress,
    {
      panelId: payload.panel.id,
      seat: payload.panel.seat,
      inProgress: false
    })
  dispatchGlobal(dispatch, 'submitMissingParticipantScoresFailed')
}

const fetchParticipantScores = async (store, { unitId, participantId }) => {
  const queryParameters = {
    unitId,
    participantId
  }

  await callApi(
    async () => await scoresApi.summary(queryParameters),
    createSimpleHandler('fetchingParticipantScores', store),
    createFetchParticipantScoresSucceeded(store, participantId),
    createFetchParticipantScoresFailed(store)
  )
}

const isNothing = overSome([isNil, isEmpty])

const fetchConfigurationForRefereeOrChiefRecorder = async (
  { actionName, apiRequest, store, unitId, participantId }) => {
  const { commit } = store
  if (isNothing(unitId)) {
    commit(mutations.clearUnitScoresStructure)
    return
  }

  const payload = {
    unitId,
    participantId
  }
  await callApi(
    async () => {
      return await apiRequest(payload)
    },
    createSimpleHandler(`fetching${actionName}`, store, payload),
    createFetchChiefRecorderOrRefereeConfigurationSucceeded(store, payload, actionName),
    createFetchChiefRecorderOrRefereeConfigurationFailed(store, actionName)
  )
}

export const actionsDefinition = {
  async [actions.fetchCurrentUnitParticipants] (store, { unitId }) {
    const { commit } = store
    if (isNil(unitId) || isEmpty(unitId)) {
      commit(mutations.setCurrentUnitParticipants, [])
      return
    }

    const payload = { unitId }
    await callApi(
      async () => {
        return await participants.list(payload)
      },
      createSimpleHandler('fetchingCurrentUnitParticipants', store, payload),
      createFetchCurrentUnitParticipantsSucceeded(store),
      createFetchCurrentUnitParticipantsFailed(store)
    )
  },
  async [actions.fetchAllParticipants] (store, { unitId }) {
    const { commit } = store
    if (isNil(unitId) || isEmpty(unitId)) {
      commit(mutations.clearParticipants)
      return
    }

    const payload = { unitId }
    await callApi(
      async () => {
        return await participants.list(payload)
      },
      createSimpleHandler('fetchingAllParticipants', store, payload),
      createFetchAllParticipantsSucceeded(store, payload),
      createFetchAllParticipantsFailed(store)
    )
  },
  async [actions.fetchDetails] (store, { unitId, participantId }) {
    const { commit } = store
    if (isNothing(unitId) || isNothing(participantId)) {
      commit(mutations.clearParticipant)
      return
    }

    await callApi(
      async () => {
        return await participants.get({ unitId, participantId })
      },
      createSimpleHandler('fetchingParticipantDetails', store),
      createFetchParticipantSucceeded(store),
      createSimpleHandler('fetchParticipantDetailsFailed', store)
    )
  },
  async [actions.presentSelected] (store) {
    const { getters: localGetters } = store
    const selectedParticipant = localGetters[getters.selectedParticipant]
    const unitId = localGetters[getters.unitId]
    if (selectedParticipant === null || isNothing(unitId)) {
      return
    }

    const payload = {
      unitId,
      participantId: selectedParticipant.id,
      noc: selectedParticipant.noc,
      members: selectedParticipant.members
    }
    await callApi(
      async () => {
        return await participants.present(payload)
      },
      createSimpleHandler('submittingPresentParticipant', store, payload),
      createSimpleHandler('submitPresentParticipantSucceeded', store, payload),
      createSimpleHandler('submitPresentParticipantFailed', store)
    )
  },
  async [actions.fetchParticipantScores] (store, { unitId, participantId }) {
    await fetchParticipantScores(store, { unitId, participantId })
  },
  async [actions.sendScores] (store, { unitId, participantId }) {
    if (isNothing(participantId) || isNothing(unitId)) {
      return
    }
    const { getters: localGetters } = store
    const scores = localGetters[getters.selectedParticipantScoresInApiFormat]
    if (isNothing(scores)) {
      return
    }

    const payload = {
      unitId,
      participantId,
      scores
    }
    await callApi(
      async () => {
        return await scoresApi.sendScores(payload)
      },
      createSimpleHandler('submittingSendScores', store, payload),
      createSubmitSendScoresSucceeded(store, payload),
      createSimpleHandler('submitSendScoresFailed', store)
    )
  },
  async [actions.updateSelectedParticipantScores] (store) {
    const { getters: localGetters } = store
    const unitId = localGetters[getters.unitId]
    const selectedParticipant = localGetters[getters.selectedParticipant]

    await fetchParticipantScores(store, {
      unitId,
      participantId: selectedParticipant.id
    })
  },
  async [actions.updateSelectedParticipantPenalties] (store, args) {
    const { getters: localGetters } = store
    const unitId = localGetters[getters.unitId]
    const selectedParticipant = localGetters[getters.selectedParticipant]
    const selectedParticipantScores =
      localGetters[getters.selectedParticipantScores]

    if (isNothing(unitId) || isNothing(selectedParticipant)) {
      return
    }

    const artisticImpressionPenalty = defaultTo(
      args.artisticImpressionPenalty,
      selectedParticipantScores?.panels?.artisticImpression?.penalty
    )

    const elementsPenalty = defaultTo(
      args.elementsPenalty,
      selectedParticipantScores?.panels?.elements?.penalty
    )

    const otherPenalties = defaultTo(
      args.otherPenalties,
      selectedParticipantScores?.penalty
    )

    const payload = {
      unitId,
      participantId: selectedParticipant.id,
      payload: {
        artisticImpression: { penalty: artisticImpressionPenalty },
        elements: { penalty: elementsPenalty },
        otherPenalties,
        penaltyName: args.penaltyName
      }
    }
    await callApi(
      async () => {
        return await scoresApi.sendPenalties(payload)
      },
      createSimpleHandler('submittingPenalties', store, payload),
      createSimpleHandler('submitPenaltiesSucceeded', store, payload),
      createSimpleHandler('submitPenaltiesFailed', store, payload)
    )
  },
  async [actions.recallScores] (store, { panel }) {
    const { getters: localGetters } = store
    const unitId = localGetters[getters.unitId]
    const selectedParticipant = localGetters[getters.selectedParticipant]

    const payload = {
      unitId,
      participantId: selectedParticipant.id,
      panel
    }
    await callApi(
      async () => {
        return await scoresApi.recallScores(payload)
      },
      createSimpleHandler('submittingRecallParticipantScores', store, payload),
      createSubmitRecallParticipantScoresSucceeded(store, payload),
      createSimpleHandler('submitRecallParticipantScoresFailed', store)
    )
  },
  async [actions.calculateTotals] (store) {
    const { getters: localGetters } = store
    const unitId = localGetters[getters.unitId]
    const selectedParticipant = localGetters[getters.selectedParticipant]

    const payload = {
      unitId,
      participantId: selectedParticipant.id
    }
    await callApi(
      async () => {
        return await scoresApi.calculateTotals(payload)
      },
      createSimpleHandler('submittingCalculateScores', store, payload),
      createSimpleHandler('submitCalculateScoresSucceeded', store),
      createSimpleHandler('submitCalculateScoresFailed', store, payload)
    )
  },
  async [actions.generateRandomScores] (store) {
    const { getters: localGetters } = store
    const unitId = localGetters[getters.unitId]
    const selectedParticipant = localGetters[getters.selectedParticipant]

    const payload = {
      unitId,
      participantId: selectedParticipant.id
    }
    await callApi(
      async () => {
        return await scoresApi.generateRandomScores(payload)
      },
      createSimpleHandler('submittingGenerateRandomScores', store, payload),
      createSimpleHandler('submitGenerateRandomScoresSucceeded', store, payload),
      createSimpleHandler('submitGenerateRandomScoresFailed', store, payload)
    )
  },
  async [actions.modifyJudgeScore] ({ getters: localGetters, ...store },
    newValue
  ) {
    const unitId = localGetters[getters.unitId]
    const selectedParticipant = localGetters[getters.selectedParticipant]
    const { panelId, partIndex, seat } = localGetters[
      getters.selectedScore
    ]
    const panels = localGetters[
      getters.selectedParticipantScores
    ].panels

    const updatedScoresPanel = panelId === PanelId.Elements
      ? panels.elements
      : panels.artisticImpression

    updatedScoresPanel.parts[partIndex].scores[seat - 1].value = newValue

    const scores = updatedScoresPanel.parts.reduce(
      (result, { scores }) => result
        .concat(filter(scores, x => x?.seat === seat)
          .map(scores => ({ ...scores }).value)),
      []
    )

    const payload = {
      unitId,
      participantId: selectedParticipant.id,
      panel: {
        id: panelId,
        seat: seat
      },
      scores: scores,
      newValue
    }
    store.commit(mutations.saveUpdatedScore, newValue)
    await callApi(
      async () => await scoresApi.submit(payload),
      createSimpleHandler('submittingModifyJudgeScores', store),
      createModifyJudgeScoresSucceeded(store, payload),
      createModifyJudgeScoresFailed(store, payload)
    )
  },
  [actions.selectParticipantScore] ({ commit }, { panelId, partIndex, seat, value }) {
    commit(mutations.selectParticipantScore, {
      panelId,
      partIndex,
      seat,
      value
    })
  },
  [actions.clearSelectedScore] ({ commit }) {
    commit(mutations.clearSelectedScore)
  },
  async [actions.fetchChiefRecorderConfiguration] (
    store,
    { unitId, participantId }
  ) {
    await fetchConfigurationForRefereeOrChiefRecorder({
      actionName: 'ChiefRecorderConfig',
      store,
      unitId,
      participantId,
      apiRequest: configuration.chiefRecorder
    })
  },
  async [actions.fetchRefereeConfiguration] (
    store,
    { unitId, participantId }
  ) {
    await fetchConfigurationForRefereeOrChiefRecorder({
      actionName: 'RefereeConfig',
      store,
      unitId,
      participantId,
      apiRequest: configuration.referee
    })
  },
  async [actions.setMissingScores] (store, { panel }) {
    const { getters: localGetters } = store
    const unitId = localGetters[getters.unitId]
    const selectedParticipant = localGetters[getters.selectedParticipant]

    const payload = {
      unitId,
      participantId: selectedParticipant.id,
      panel
    }
    await callApi(
      async () => {
        return await scoresApi.setMissingScores(payload)
      },
      createSubmittingMissingParticipantScoresHandler(store, payload),
      createSubmitMissingParticipantScoresSucceededHandler(store, payload),
      createSubmitMissingParticipantScoresFailedHandler(store, payload)
    )
  },
  [actions.isEditMode] ({ commit }, value) {
    commit(mutations.isEditMode, value)
  },
  async [actions.updateIrm] (store, { irm, participantId }) {
    const { getters: localGetters } = store
    const unitId = localGetters[getters.unitId]
    const currentUnitParticipants = localGetters[getters.currentUnitParticipants]

    const payload = {
      unitId,
      participantId,
      irm,
      currentUnitParticipants
    }
    await callApi(
      async () => await participants.changeIrm(payload),
      createSimpleHandler('submittingIrm', store),
      createSubmitIrmSucceeded(store, payload),
      createSimpleHandler('submitIrmFailed', store)
    )
  },
  async [actions.updateAssistantScores] (store, { seat, judgedDegreeOfDifficultyCard }) {
    const { participantScores } = store.state
    const newParticipantScores = { ...participantScores }
    const { parts } = newParticipantScores.panelsScores.elements

    for (let index = 0; index < judgedDegreeOfDifficultyCard.length; index++) {
      parts[index].judgedDegreeOfDifficultyAssistantsFeedback[seat - 1] =
        judgedDegreeOfDifficultyCard[index]
    }

    store.commit(mutations.setParticipantScores, newParticipantScores)
  }
}
