import { isEmpty } from 'lodash'

export const getters = Object.freeze({
  isUserSnapEnabled: 'isUserSnapEnabled',
  getUserSnapUri: 'getUserSnapUri',
  isCopyTokenToClipboardEnabled: 'isCopyTokenToClipboardEnabled',
  isSettingsSaveInProgress: 'isSettingsSaveInProgress',
  allSettings: 'allSettings',
  'settings.synchroTimeframeWindow': 'settings.synchroTimeframeWindow',
  'settings.competitionName': 'settings.competitionName',
  'settings.aiJudgesCount': 'settings.aiJudgesCount',
  'settings.elJudgesCount': 'settings.elJudgesCount',
  'settings.coachCardPushEnabled': 'settings.coachCardPushEnabled',
  'settings.timezone': 'settings.timezone',
  'settings.oneCountryOneRankEnabled': 'settings.oneCountryOneRankEnabled',
  'settings.automaticODFGeneration': 'settings.automaticODFGeneration',
  'settings.automaticPublisher': 'settings.automaticPublisher',
  'settings.datcJudgesCount': 'settings.datcJudgesCount',
  'settings.dtcJudgesCount': 'settings.dtcJudgesCount',
  'settings.testMode': 'settings.testMode'
})

const getUserSnapApiKey = store => store?.features?.userSnap?.key ?? ''
export const gettersDefinition = Object.freeze({
  [getters.isUserSnapEnabled] (store) {
    return !isEmpty(getUserSnapApiKey(store))
  },

  [getters.getUserSnapUri] (store) {
    const key = getUserSnapApiKey(store)
    return `https://widget.usersnap.com/global/load/${key}?onload=onUsersnapCXLoad`
  },

  [getters.isCopyTokenToClipboardEnabled] (store) {
    return store?.features?.copyTokenToClipboard ?? false
  },

  [getters.isSettingsSaveInProgress] ({ requests }) {
    return requests.submitSettings
  },

  [getters.allSettings] ({ settings }) {
    return settings
  },

  [getters['settings.synchroTimeframeWindow']] ({ settings }) {
    return settings.synchroTimeframeWindow
  },
  [getters['settings.competitionName']] ({ settings }) {
    return settings.competitionName
  },
  [getters['settings.aiJudgesCount']] ({ settings }) {
    return {
      value: settings.judges.value.requiredAI,
      'read-only': settings.judges['read-only']
    }
  },
  [getters['settings.elJudgesCount']] ({ settings }) {
    return {
      value: settings.judges.value.requiredElements,
      'read-only': settings.judges['read-only']
    }
  },
  [getters['settings.coachCardPushEnabled']] ({ settings }) {
    return settings.coachCardPushEnabled
  },
  [getters['settings.timezone']] ({ settings }) {
    return {
      value: settings.timezone.value,
      'read-only': false
    }
  },
  [getters['settings.oneCountryOneRankEnabled']] ({ settings }) {
    return {
      value: settings.oneCountryOneRankEnabled.value,
      'read-only': settings.oneCountryOneRankEnabled['read-only']
    }
  },
  [getters['settings.testMode']] ({ settings }) {
    return {
      value: settings.testMode.value,
      'read-only': settings.testMode['read-only']
    }
  },
  [getters['settings.automaticODFGeneration']] ({ settings }) {
    return {
      value: settings.automaticODFGeneration.value,
      'read-only': false
    }
  },
  [getters['settings.automaticPublisher']] ({ settings }) {
    return {
      value: settings.automaticPublisher.value,
      'read-only': false
    }
  },
  [getters['settings.dtcJudgesCount']] ({ settings }) {
    return {
      value: settings.judges.value.requiredDTC,
      'read-only': settings.judges['read-only']
    }
  },
  [getters['settings.datcJudgesCount']] ({ settings }) {
    return {
      value: settings.judges.value.requiredDATC,
      'read-only': settings.judges['read-only']
    }
  }
})
