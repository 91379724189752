<template>
  <div
    @click.stop="!disabled && $emit('clicked')"
    :class="classForScore({selected, disabled, flat})"
    :autofocus='selected'
    :tabindex="tabindex"
    ref='scoreBox'
    class="score">
    <span
      v-if='readonly || disabled'
      :class="[
        'score__static',
        {
          'score__static--placeholder': isScorePlaceholderActive
        }]"
      v-html="displayableScore">
    </span>
    <input
      v-else
      v-model='editableScore'
      class='score__input'
      :max="max"
      :min="min"
      :step="step"
      type="number"
      @change="emitScoreToParent"
      @blur="onEditableScoreBlur"
      @click.stop/>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'ScoreBox',
  emits: ['clicked', 'scoreUpdated', 'scoreDeleted'],
  data: () => ({
    updatedScore: '',
    keyBoardScore: ''
  }),
  watch: {
    readonly () {
      if (!this.readonly) {
        this.updatedScore = ''
      }
    },
    selected () {
      if (this.selected) {
        this.$refs.scoreBox.focus()
      }
      this.keyBoardScore = ''
    }
  },
  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 10
    },
    step: {
      type: Number,
      default: 0.1
    },
    score: {
      type: [Number, String],
      default: () => ''
    },
    tabindex: {
      type: [Number]
    },
    selected: {
      type: Boolean,
      default: () => false
    },
    readonly: {
      type: Boolean,
      default: () => false
    },
    disabled: Boolean,
    flat: Boolean,
    placeholder: {
      type: String,
      default: () => null
    },
    debounceWait: {
      type: [Number],
      default: 0
    }
  },
  computed: {
    displayableKeyBoardScore () {
      if (this.isScoreComplete) {
        return null
      }
      if (this.keyBoardScore.length === 1) {
        return `${this.keyBoardScore}.00`
      }
      if (this.keyBoardScore.length === 3) {
        return this.keyBoardScore[2] !== '0' ? `${this.keyBoardScore}5` : `${this.keyBoardScore}0`
      }
      return this.keyBoardScore
    },
    displayableScore () {
      return this.scoreUpdatedInEditableMode ||
        this.displayableKeyBoardScore ||
        (this.isScorePlaceholderActive ? this.placeholder : (this.score.length === 0 ? '&nbsp' : this.score))
    },
    editableScore: {
      set: function (val) {
        this.updatedScore = val
      },
      get: function () {
        return this.score.length === 0
          ? '0.0'
          : this.score
      }
    },
    isScorePlaceholderActive () {
      return this.selected && this.placeholder !== null
    },
    emitScoreToParent () {
      return debounce(() => {
        this.$emit('scoreUpdated', { value: this.updatedScore })
      },
      this.debounceWait,
      {
        leading: false
      })
    },
    isScoreComplete () {
      return (this.score && this.score.length) === 4 || this.score === '10'
    },
    scoreUpdatedInEditableMode () {
      return this.score && this.updatedScore
    }
  },
  methods: {
    classForScore ({ selected, readonly, flat }) {
      return {
        'score--selected': selected,
        'score--readonly': readonly,
        'score--flat': flat
      }
    },
    commitScore (score, goToNext = false) {
      this.$emit('scoreUpdated', { value: score, goToNext })
      this.keyBoardScore = ''
    },
    onEditableScoreBlur () {
      setTimeout(() => {
        this.updatedScore = ''
      }, this.debounceWait)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/typology";
@import "~@/styles/variables";
@import "~@/styles/container";
@import "~@/styles/button";

.score {
  @include value-l3();
  @include border();

  position: relative;

  display: inline-block;
  padding: 0px 7px;

  &--selected {
    @include border('active');
  }

  &--flat {
    border: none;
  }

  &__static {
    &--placeholder {
      color: $lightgray;
    }
  }

  &__input {
    @include value-l4();

    text-align: center;
  }
}
</style>
