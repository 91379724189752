import { chiefRecorder, judge, olr, participants, routine, timekeeper, unit, configuration, coachCards } from './modules'

export const chiefRecorderListeners = [
  { namespace: chiefRecorder.namespace, subscribe: chiefRecorder.subscriber },
  { namespace: routine.namespace, subscribe: routine.subscriber },
  { namespace: unit.namespace, subscribe: unit.subscriber },
  { namespace: participants.namespace, subscribe: participants.subscriber },
  { namespace: judge.namespace, subscribe: judge.subscriber }
]

export const olrListeners = [
  { namespace: olr.namespace, subscribe: olr.subscriber },
  { namespace: unit.namespace, subscribe: unit.subscriber },
  { namespace: participants.namespace, subscribe: participants.subscriber },
  { namespace: configuration.namespace, subscribe: configuration.subscriber }
]

export const coachCardsListeners = [
  { namespace: coachCards.namespace, subscribe: coachCards.subscriber }
]

export const judgeListeners = [
  { namespace: routine.namespace, subscribe: routine.subscriber },
  { namespace: judge.namespace, subscribe: judge.subscriber }
]

export const timekeeperListeners = [
  { namespace: routine.namespace, subscribe: routine.subscriber },
  { namespace: timekeeper.namespace, subscribe: timekeeper.subscriber }
]

export const refereeListeners = [
  { namespace: routine.namespace, subscribe: routine.subscriber },
  { namespace: unit.namespace, subscribe: unit.subscriber }
]
