export { default as timers } from './participants/timers'
export { default as participants } from './participants/participants'
export { default as configuration } from './configuration'
export { setToken } from './authorization'
export { default as scores } from './participants/scores'
export { default as hands } from './participants/hands'
export { default as routine } from './unit/routine'
export { default as unit } from './unit/unit'
export { default as importApi } from './import'
export { default as coachCards } from './coach-cards'
export { default as events } from './events'
export { default as phases } from './phases'
export { default as competitions } from './competitions'
export { default as degreeOfDifficulty } from './degree-of-difficulty'
export { default as synchronizationErrors } from './synchronization-errors'
export { default as odf } from './odf'
export { default as publisher } from './publisher'
export { default as pscb } from './pscb'
export { listenOn, connectToWebsocket } from './websocket'

export async function callApi (apiCall, onBeforeCall, onSuccess, onFailure) {
  let beforeResult
  try {
    beforeResult = onBeforeCall()
    const response = await apiCall()
    onSuccess(await response, beforeResult)
    return response
  } catch (e) {
    onFailure({
      error: e
    })
  }

  return null
}

export function dispatchGlobal (dispatch, actionName, data) {
  return dispatch(actionName, data !== undefined ? data : null, { root: true })
}

export const createSimpleHandler = (
  actionName,
  { dispatch },
  payload = undefined
) => data =>
  dispatchGlobal(dispatch, actionName, payload === undefined ? data : payload)
