<template>
  <div class="container">
    <Menu skipRole="all" :skipPages="['settings']" />
    <div v-if="competitions.length === 0">
      You don't have access to any competition.
    </div>
    <div v-else class="wireframe">
      <div
        class="wireframe__item"
        v-for="competition in competitions"
        :key="competition.id"
        @click="onCompetitonClicked(competition.id)">
        {{ competition.name}}
      </div>
    </div>
  </div>
</template>

<script>
import Menu from '@/components/Menu'
import { mapActions, mapGetters } from 'vuex'
import { configuration, contextSelection } from '@/store/modules'

export default {
  name: 'ContextSelection',
  components: {
    Menu
  },
  async beforeRouteEnter (_to, _from, next) {
    next(async vm => await vm.fetchCompetitions())
  },
  computed: {
    ...mapGetters(contextSelection.namespace, [
      contextSelection.getters.competitions
    ])
  },
  data () {
    return {
      ...this.mapAuthState()
    }
  },
  methods: {
    ...mapActions(
      contextSelection.namespace, [
        contextSelection.actions.selectContext,
        contextSelection.actions.fetchCompetitions
      ]
    ),
    ...mapActions(
      configuration.namespace, [
        configuration.actions.fetchConfiguration
      ]
    ),
    async onCompetitonClicked (competitionId) {
      this.selectContext(competitionId)
      this.fronteggAuth.tenantsActions.switchTenant({ tenantId: competitionId })
      await this.fetchConfiguration()
      this.$router.replace('/')
    }
  }
}
</script>

<style lang='scss' scoped>
@import "~@/styles/container";

.container {
  @include container;
}

.wireframe {
  @include wireframe;
}
</style>
