<template>
  <h2>{{ currentStatus }}
    <DropDownMenu
      @optionSelected="onOptionSelected"
      :options='options' />
  </h2>
</template>

<script>
import { mapActions } from 'vuex'
import DropDownMenu from '@/components/DropDownMenu'
import { UnitStatus } from '@/enums/UnitStatus'
import { UnitStatusMap } from '@/maps/UnitStatusMap'
import { unit } from '@/store/modules'

export default {
  name: 'UnitStatusMenu',
  props: {
    currentStatus: {
      type: String,
      default: null
    },
    selectedUnitId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  components: {
    DropDownMenu
  },
  computed: {
    options () {
      return Object.keys(UnitStatus).reduce((options, key) => {
        switch (UnitStatus[key]) {
          case this.currentStatus:
          case UnitStatus.Running:
            return options
          case UnitStatus.Unofficial:
          case UnitStatus.Official:
          case UnitStatus.Protested:
            if (this.postRunningStatuses(this.currentStatus)) {
              options.push(UnitStatus[key])
            }
            return options
          case UnitStatus.Scheduled:
          case UnitStatus.Delayed:
          case UnitStatus.GettingReady:
          case UnitStatus.Postponed:
            if (this.preRunningStatuses(this.currentStatus)) {
              options.push(UnitStatus[key])
            }
            return options
        }
      }, [])
    }
  },
  methods: {
    ...mapActions(
      unit.namespace,
      [
        unit.actions.modifyStatus
      ]
    ),
    async onOptionSelected (option) {
      await this.modifyStatus(
        {
          unitId: this.selectedUnitId,
          expectedStatus: UnitStatusMap[option]
        })
    },
    preRunningStatuses (status) {
      return status === UnitStatus.Delayed || status === UnitStatus.GettingReady ||
        status === UnitStatus.Scheduled || status === UnitStatus.Postponed
    },
    postRunningStatuses (status) {
      return status === UnitStatus.Running || status === UnitStatus.Unofficial ||
        status === UnitStatus.Official || status === UnitStatus.Protested
    }
  }
}
</script>
